import React, { Ref } from 'react';
import { Input as $Input } from 'antd';
import { InputProps as $InputProps, InputRef } from 'antd/lib/input/Input';
import { ControllerRenderProps } from 'react-hook-form';
import { getCountryNumber } from 'utils/countryPhoneNumber.utils';

export type InputProps = ControllerRenderProps & $InputProps;

export default React.forwardRef((props: InputProps, ref: Ref<InputRef>) => {
  const { value, ...restProps } = props;
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <$Input
      {...restProps}
      value={value}
      onFocus={() => {
        if (!value) restProps.onChange(getCountryNumber());
      }}
      ref={ref}
    />
  );
});
