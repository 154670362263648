import React, { useState, createContext, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';

import Spin from 'components/Spin';
import { AuthorizedUserModel } from 'services/models/AuthorizedUser.model';

type LoginRequest = {
  email: string;
  password: string;
};

type SignUpRequest = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
};

type ConfirmSignUpRequest = {
  email: string;
  code: string;
};
type ResendSignUpRequest = {
  email: string;
};
type ForgotPasswordRequest = {
  email: string;
};
type ForgotPasswordSubmitRequest = {
  email: string;
  code: string;
  password: string;
};

interface IAuthState {
  user: AuthorizedUserModel | null;
  isAuthenticated: boolean;
  login: (params: LoginRequest) => Promise<void>;
  logout: () => Promise<void>;
  signUp: (params: SignUpRequest) => Promise<void>;
  confirmSignUp: (params: ConfirmSignUpRequest) => Promise<void>;
  resendSignUp: (params: ResendSignUpRequest) => Promise<void>;
  forgotPassword: (params: ForgotPasswordRequest) => Promise<void>;
  forgotPasswordSubmit: (params: ForgotPasswordSubmitRequest) => Promise<void>;
}

export const AuthContext = createContext<IAuthState>({
  user: null,
  isAuthenticated: false,
  login: async () => undefined,
  logout: async () => undefined,
  signUp: async () => undefined,
  confirmSignUp: async () => undefined,
  resendSignUp: async () => undefined,
  forgotPassword: async () => undefined,
  forgotPasswordSubmit: async () => undefined,
});

type Props = {
  children: JSX.Element;
};

export const AuthProvider = (props: Props) => {
  const { children } = props;
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);
  const [user, setUser] = useState<IAuthState['user']>(null);

  useEffect(() => {
    const loadInitialUser = async () => {
      try {
        const initialUser = await Auth.currentAuthenticatedUser();

        setUser(initialUser?.signInUserSession?.idToken?.payload);
      } catch {
        setUser(null);
      } finally {
        setIsAuthLoaded(true);
      }
    };

    loadInitialUser();
  }, []);

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'autoSignIn') {
        setUser(payload.data?.signInUserSession?.idToken?.payload);
      }
    });
  }, []);

  const signUp: IAuthState['signUp'] = async ({
    email,
    password,
    lastName,
    firstName,
    phone,
  }) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        family_name: lastName,
        given_name: firstName,
        phone_number: phone,
        'custom:accepted_agreement': '1',
      },
      autoSignIn: {
        enabled: true,
        clientMetaData: {
          origin: window.location.origin,
        },
      },
    });
  };

  const confirmSignUp: IAuthState['confirmSignUp'] = async ({
    email,
    code,
  }) => {
    await Auth.confirmSignUp(email, code);
  };
  const resendSignUp: IAuthState['resendSignUp'] = async ({ email }) => {
    await Auth.resendSignUp(email);
  };
  const forgotPassword: IAuthState['forgotPassword'] = async ({ email }) => {
    await Auth.forgotPassword(email);
  };
  const forgotPasswordSubmit: IAuthState['forgotPasswordSubmit'] = async ({
    email,
    code,
    password,
  }) => {
    await Auth.forgotPasswordSubmit(email, code, password);
  };
  const login: IAuthState['login'] = async ({ email, password }) => {
    const loggedInUser = await Auth.signIn(email, password, {
      origin: window.location.origin,
    });

    setUser(loggedInUser?.signInUserSession?.idToken?.payload);
  };

  const logout: IAuthState['logout'] = async () => {
    await Auth.signOut();

    setUser(null);
  };

  const values = React.useMemo(
    () => ({
      user,
      isAuthenticated: !!user,
      login,
      logout,
      signUp,
      confirmSignUp,
      resendSignUp,
      forgotPassword,
      forgotPasswordSubmit,
    }),
    [user],
  );

  if (!isAuthLoaded) return <Spin />;

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error(
      '`useAuth` hook must be used within a `AuthProvider` component',
    );
  }

  return context;
};
