/* eslint-disable react/jsx-pascal-case */
import { Dropdown, Layout } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { useAppSettings } from 'components/AppSettingsProvider';
import Accessor from 'components/Accessor';
import { ExternalUserRole } from 'services/api';
import { getImageUrl, ImageSize } from 'utils/image.utils';
import logoImg from 'assets/images/logo-light.svg';
import { useMediaMatch } from 'rooks';
import { MobileScreenQuery } from 'utils/mediaQuery.utils';
import styles from './Footer.module.less';

const { Footer: $Footer } = Layout;

export default function Footer() {
  const isMobileScreen = useMediaMatch(MobileScreenQuery);

  const { settings } = useAppSettings();
  const { t } = useTranslation();

  const footerData = {
    phone: '855 243 7767',
    email: 'info@bidthisproperty.com',
    linkedInUrl: 'https://www.linkedin.com/company/89552566/',
    facebookUrl: 'https://www.facebook.com/profile.php?id=100086492913503',
    address: '696 San Ramon Valley Blvd, Ste 326, Danville, CA 94526',
    dreDefault: '01128525',
  };
  const { phone, email, linkedInUrl, facebookUrl, address, dreDefault } =
    footerData;
  const {
    color_logo,
    grey_logo,
    linkedin_profile,
    facebook_profile,
    office_address,
    office_phone_number,
    office_email,
    state,
    city,
    zip,
    dre,
  } = settings || {};

  return (
    <$Footer className={styles.footer}>
      <div className="container">
        <nav className={styles.footerNav}>
          <div>
            <ul className={styles.footerNavList}>
              <li>
                <NavLink className={styles.footerLink} to="/listings" end>
                  {t('header.Listings')}
                </NavLink>
              </li>
              <Accessor
                roles={[
                  ExternalUserRole.SUPER_ADMIN,
                  ExternalUserRole.LISTING_AGENT,
                  ExternalUserRole.BROKERAGE_ADMIN,
                ]}
              >
                <li>
                  <NavLink
                    className={styles.footerLink}
                    to="/listings-management"
                    end
                  >
                    {t('header.Dashboard')}
                  </NavLink>
                </li>
              </Accessor>
              <li>
                <Dropdown
                  overlay={
                    <ul className="drop">
                      <li>
                        <Link className="drop-item" to="/about-us">
                          <i className="icon icon-heart drop-item__icon" />
                          {t('header.AboutUs')}
                        </Link>
                      </li>
                      <li>
                        <Link className="drop-item" to="/how-to-buy">
                          <i className="icon icon-info drop-item__icon" />
                          {t('header.HowToBuy')}
                        </Link>
                      </li>
                      <li>
                        <Link className="drop-item" to="/sellers">
                          <i className="icon icon-coffee drop-item__icon" />
                          {t('header.Sellers')}
                        </Link>
                      </li>
                      <li>
                        <Link className="drop-item" to="/certified-agents">
                          <i className="icon icon-users drop-item__icon" />
                          {t('header.certifiedAgents')}
                        </Link>
                      </li>
                      <li>
                        <Link className="drop-item" to="/faq">
                          <i className="icon icon-circle-question drop-item__icon" />
                          {t('header.FAQ')}
                        </Link>
                      </li>
                    </ul>
                  }
                >
                  <span className={cx(styles.footerLink, 'drop-opener')}>
                    {t('header.More')}
                    <i className="icon icon-caret-down"></i>
                  </span>
                </Dropdown>
              </li>
            </ul>
          </div>
          <div>
            <ul className={styles.footerNavList}>
              <li>
                <NavLink
                  className={cx(styles.footerLink, styles.footerLinkSm)}
                  to="/terms-and-conditions-of-service"
                >
                  {t('header.TermsAndConditions')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={cx(styles.footerLink, styles.footerLinkSm)}
                  to="/privacy-policy"
                >
                  {t('header.PrivacyPolicy')}
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
        <div className={styles.footerContact}>
          <ul className={styles.footerContactList}>
            <li>
              <a
                className={cx(styles.footerLink, styles.footerContactLink)}
                href={`tel:${office_phone_number || phone}`}
              >
                <i className="icon icon-phone"></i>
                {office_phone_number || phone}
              </a>
            </li>
            <li>
              <a
                className={cx(styles.footerLink, styles.footerContactLink)}
                target="_blank"
                rel="noreferrer"
                href={`mailto:${office_email || email}`}
              >
                <i className="icon icon-mail"></i>
                {office_email || email}
              </a>
            </li>
            <li>
              <a
                className={cx(styles.footerLink, styles.footerContactLink)}
                href={!!linkedin_profile ? linkedin_profile : linkedInUrl}
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon icon-linkedin"></i>
                LinkedIn
              </a>
            </li>
            <li>
              <a
                className={cx(styles.footerLink, styles.footerContactLink)}
                href={!!facebook_profile ? facebook_profile : facebookUrl}
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon icon-facebook"></i>
                Facebook
              </a>
            </li>
            <li>
              <address
                className={cx(styles.footerLink, styles.footerContactLink)}
              >
                <i className="icon icon-map"></i>
                {!!office_address && !!city && !!state && !!zip
                  ? `${office_address}, ${city}, ${state} ${zip}`
                  : address}
              </address>
            </li>
          </ul>
        </div>

        {isMobileScreen ? (
          <div className={styles.footerRow}>
            {!!color_logo && (
              <>
                <div className={styles.footerSubImg}>
                  <img
                    src={getImageUrl(
                      !!grey_logo ? grey_logo : color_logo,
                      ImageSize.SMALL,
                    )}
                    alt="logo"
                  />
                  <div>
                    {t('footer.PoweredBy')} <b>{t('footer.BidThisProperty')}</b>
                  </div>
                </div>
              </>
            )}
            {!color_logo && (
              <div className={styles.footerImg}>
                <img src={logoImg} alt="logo" />
              </div>
            )}
            <div className={styles.footerLicense}>
              <div>{t('footer.Line1')}</div>
              <div>
                <span>
                  {t('footer.Line2')} <b> / DRE# {dre || dreDefault}</b>
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.footerRow}>
            {!!color_logo && (
              <>
                <div className={styles.footerSubImg}>
                  <img
                    src={getImageUrl(
                      !!grey_logo ? grey_logo : color_logo,
                      ImageSize.SMALL,
                    )}
                    alt="logo"
                  />
                </div>
                <span className={styles.footerPrefix}>by</span>
              </>
            )}
            <div className={styles.footerImg}>
              <img src={logoImg} alt="logo" />
            </div>
            <div className={styles.footerLicense}>
              <div>{t('footer.Line1')}</div>
              <div>
                <span>
                  {t('footer.Line2')} <b> / DRE# {dre || dreDefault}</b>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </$Footer>
  );
}
