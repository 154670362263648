import { Button, ButtonProps } from 'antd';
import ForgotPasswordModal from 'features/auth/ForgotPasswordModal';
import SignUpConfirmationModal from 'features/auth/SignUpConfirmationModal';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalListingList } from 'services/api';
import { getBidNowButtonProps } from 'utils/listing.utils';
import BidNowModal from '../BidNowModal';

type Props = {
  listing: ExternalListingList;
  buttonProps: Omit<ButtonProps, 'onClick'>;
};

export default function BidNowButton(props: Props) {
  const { listing, buttonProps } = props;
  const { t } = useTranslation();
  const [isBidNowModalVisible, setIsBidNowModalVisible] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [isResetPasswordModalVisible, setIsResetPasswordModalVisible] =
    useState(false);

  const bidNowButtonProps = getBidNowButtonProps(listing.status, t);
  const [signUpEmail, setSignUpEmail] = useState('');

  return (
    <>
      {isBidNowModalVisible && (
        <BidNowModal
          onForgotPassword={() => {
            setIsResetPasswordModalVisible(true);
            setIsBidNowModalVisible(false);
          }}
          listing={listing}
          onUnconfirmed={(email) => {
            setSignUpEmail(email);
            setIsBidNowModalVisible(false);
            setIsConfirmationModalVisible(true);
          }}
          onClose={() => {
            setIsBidNowModalVisible(false);
          }}
        />
      )}
      {isConfirmationModalVisible && (
        <span onClick={(e) => e.stopPropagation()}>
          <SignUpConfirmationModal
            email={signUpEmail}
            onClose={() => {
              setIsConfirmationModalVisible(false);
            }}
          />
        </span>
      )}
      {isResetPasswordModalVisible && (
        <span onClick={(e) => e.stopPropagation()}>
          <ForgotPasswordModal
            onClose={() => {
              setIsBidNowModalVisible(true);
              setIsResetPasswordModalVisible(false);
            }}
          />
        </span>
      )}
      <Button
        {...buttonProps}
        onClick={(e) => {
          e.preventDefault(); // to handle click inside Link
          setIsBidNowModalVisible(true);
        }}
        {...bidNowButtonProps}
      />
    </>
  );
}
