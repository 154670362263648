import { useEffect, useState } from 'react';
import { Button, Row, Col, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import SubscriptionForm from './SubscriptionForm';
import { ExternalUserDetail, ListingApi } from 'services/api';
import { displayErrorMessage } from 'components/message';

import styles from './MyProfilePage.module.less';

import Spin from 'components/Spin';
import { useAuth } from 'components/AuthProvider';

export default function MyProfilePage() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [profileSettings, setProfileSettings] = useState<ExternalUserDetail>();
  const navigate = useNavigate();
  useEffect(() => {
    const loadProfileSettings = async () => {
      try {
        setIsLoading(true);
        const result = await ListingApi.getProfileValue(
          user?.user_id as string,
        );
        setProfileSettings(result);
      } catch (error) {
        displayErrorMessage({
          error,
        });
      } finally {
        setIsLoading(false);
      }
    };

    loadProfileSettings();
  }, [user?.user_id]);
  if (isLoading)
    return (
      <div className="layout-wrap">
        <Spin />
      </div>
    );
  if (!profileSettings) return null;
  return (
    <div className="layout-wrap">
      <div className={styles.wrap}>
        <div className="container">
          <div className={styles.backBtn}>
            <Button
              className="btn-icon"
              size="small"
              icon={<i className="icon icon-arrow-left"></i>}
              onClick={() => {
                if (window.history.state && window.history.state.idx > 0) {
                  navigate(-1);
                } else {
                  navigate('/');
                }
              }}
            ></Button>
          </div>
          <Row>
            <Col xs={24} xl={{ span: 20, offset: 2 }}>
              <h1 className="h4">
                {profileSettings?.first_name && profileSettings?.last_name && (
                  <>
                    {profileSettings?.first_name} {profileSettings?.last_name}
                  </>
                )}
              </h1>
              <Divider className="divider-light" />
              <div className="paper">
                <SubscriptionForm profileSettings={profileSettings} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
