import { Col, Row } from 'antd';

import { BlockDivider } from 'components/BlockDivider';
import ContactUsSection from 'components/ContactUsSection';

import imgHow from 'assets/images/sellers/imageMain.webp';

import styles from '../SellersPage.module.less';

export default function SellersPageEn() {
  return (
    <div className={styles.bgWrap}>
      <section className={styles.whatSection}>
        <div className="container">
          <Row gutter={16} align="middle">
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h2 m-0">
                <>
                  <span>What</span>
                  <div>Sellers Need to Know?</div>
                </>
              </h2>
            </Col>
            <Col xs={24} md={15} xl={16}>
              <div className={styles.imgBox}>
                <img src={imgHow} alt="" />
              </div>
            </Col>
            <Col
              xs={24}
              md={{ span: 15, offset: 9 }}
              xl={{ span: 14, offset: 8 }}
            >
              <div>
                <h3 className="h5">
                  “Alright folks, how will Bid This Property do a rockstar job
                  at getting my house sold?”
                </h3>
                <div>
                  <p>
                    Here's the most important fact and bottom line; with BTP,
                    it's just WAY easier! Realtors have a lot of tactics,
                    they've got a brokerage website, or they flyer local
                    neighborhoods, they've got a multiple listing service to put
                    you on, maybe a real estate listing in the paper, or even
                    craigslist. These fragmented, outdated strategies to market
                    a home have been blown out of the water. Bid This Property
                    is a well-tuned machine of marketing specialists. BTP
                    consistently boosts its property listings to the first pages
                    of major search engines. Bid This Property listings are the
                    first to pop up when buyers type in their search.
                  </p>
                  <p>
                    As the seller of the property, you get the internet
                    marketing team rocketing your listings visibility, with
                    total control of the moves made in the sale of your home.
                    You can see potential buyers viewing your listing and track
                    offers in real time! If you see an offer you like, the
                    choice is yours; You can Love it, or Leave it, or Change it.
                  </p>
                  <p>
                    The automated experience provided by selling with Bid This
                    Property gives you outta this world convenience, and the
                    kind of listing viewing traffic that will get you multiple
                    good offers, and quickly.
                  </p>
                  <p>
                    You can even feel good about saving all that paper! Going
                    BTP online is the green choice.
                  </p>
                </div>
                <div className={styles.itsNutselGettingStarted}>
                  <h3 className="h5">In a nutshell:</h3>
                  <div>
                    <p>
                      Net more money — The bidding process brings dramatic
                      results plus the buyer’s premium = Cha-ching.
                    </p>
                    <p>
                      Less Buyer’s remorse — Winning Buyer knows there were
                      other people bidding and they won... they worked hard to
                      be the winner!
                    </p>
                    <p>
                      Less Appraisal issues — Bidding history can be used by
                      appraisers!
                    </p>
                    <p>
                      Less Negotiating — Next highest bidder is right behind the
                      highest bidder putting pressure on them resulting in less
                      shenanigans.
                    </p>
                    <p>Less games — Transparency 100%.</p>
                    <p>
                      Better than competitors — BTP does not require money to
                      participate and does not ask for sensitive information or
                      credit cards making it easy for the Buyer to bid!
                    </p>
                    <p>
                      Full MLS listing — Your property will be all over the MLS
                      but we do not stop there...your property will be all over
                      the Internet. We got you!
                    </p>
                    <p>
                      No reserve price — We do not hold you hostage to a reserve
                      price — You can Accept, Reject, or Counter for any reason!
                    </p>
                  </div>
                </div>
                <div className={styles.itsNutselGettingStarted}>
                  <h3 className="h5">Getting Started:</h3>
                  <div>
                    <p>
                      Contact us to hook you up with a wonderful agent in your
                      area who knows how to make the BTP magic happen.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <div className="container">
        <BlockDivider />
      </div>

      <section className={styles.contactSection}>
        <div className="container">
          <ContactUsSection />
        </div>
      </section>
    </div>
  );
}
