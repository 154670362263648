import styles from './Header.module.less';
/* eslint-disable react/jsx-pascal-case */

import { useTranslation } from 'react-i18next';
import { NavLink, useMatch } from 'react-router-dom';
import { Button, Dropdown } from 'antd';
import cx from 'classnames';

import Accessor from 'components/Accessor';
import { useAppSettings } from 'components/AppSettingsProvider';
import { useAuth } from 'components/AuthProvider';
import { getImageUrl, ImageSize } from 'utils/image.utils';
import { ExternalUserRole } from 'services/api';
import logoColor from 'assets/images/logo-color.svg';
import logoImg from 'assets/images/logo-white.svg';
import { HeaderProps } from './Header';
export const HeaderDesktop = (props: HeaderProps) => {
  const { onSignInClick, onLogoutClick } = props;
  const { settings } = useAppSettings();
  const { color_logo, white_logo } = settings || {};
  const { t } = useTranslation();
  const { user } = useAuth();

  const isHomePage = useMatch('/');

  const mainHeaderLogo = (
    <strong data-test-id="header-logo-brokerage" className={styles.headerLogo}>
      <img src={isHomePage ? logoImg : logoColor} alt="logo" />
    </strong>
  );

  return (
    <>
      <nav>
        <ul className={styles.headerNav}>
          {!!color_logo && (
            <>
              <li>
                <NavLink to="/" data-test-id="header-logo">
                  <div className={styles.headerSubLogo}>
                    <img
                      src={getImageUrl(
                        isHomePage && !!white_logo ? white_logo : color_logo,
                        ImageSize.SMALL,
                      )}
                      alt="logo"
                    />
                  </div>
                </NavLink>
              </li>
              <li className={styles.headerPrefix}>by</li>
            </>
          )}
          <li>
            {!!color_logo && mainHeaderLogo}
            {!color_logo && <NavLink to="/">{mainHeaderLogo}</NavLink>}
          </li>
          <li>
            <NavLink className={styles.headerNavLink} to="/listings" end>
              {t('header.Listings')}
            </NavLink>
          </li>
          <Accessor
            roles={[
              ExternalUserRole.SUPER_ADMIN,
              ExternalUserRole.LISTING_AGENT,
              ExternalUserRole.BROKERAGE_ADMIN,
            ]}
          >
            <li>
              <NavLink
                className={styles.headerNavLink}
                data-test-id="dashbord-link"
                to="/listings-management"
                end
              >
                {t('header.Dashboard')}
              </NavLink>
            </li>
          </Accessor>
          <li>
            <Dropdown
              overlay={
                <ul className="drop">
                  <li>
                    <NavLink
                      data-test-id="about-us-link"
                      className="drop-item"
                      to="/about-us"
                    >
                      <>
                        <i className="icon icon-heart drop-item__icon" />
                        {t('header.AboutUs')}
                      </>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      data-test-id="how-to-buy-link"
                      className="drop-item"
                      to="/how-to-buy"
                    >
                      <>
                        <i className="icon icon-info drop-item__icon" />
                        {t('header.HowToBuy')}
                      </>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="drop-item" to="/sellers">
                      <>
                        <i className="icon icon-coffee drop-item__icon" />
                        {t('header.Sellers')}
                      </>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="drop-item" to="/certified-agents">
                      <>
                        <i className="icon icon-users drop-item__icon" />
                        {t('header.certifiedAgents')}
                      </>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="drop-item" to="/faq">
                      <>
                        <i className="icon icon-circle-question drop-item__icon" />
                        {t('header.FAQ')}
                      </>
                    </NavLink>
                  </li>
                </ul>
              }
            >
              <span
                data-test-id="more-dropdown"
                className={styles.headerNavLink}
              >
                {t('header.More')}
                <i className="icon icon-caret-down"></i>
              </span>
            </Dropdown>
          </li>
        </ul>
      </nav>
      <div className={styles.rightSection}>
        {!user && (
          <Button
            type="link"
            data-test-id="join-button"
            size="small"
            className={styles.headerLogin}
            onClick={onSignInClick}
            icon={<i className="icon icon-login"></i>}
          >
            {t('header.Join')}
          </Button>
        )}
        {!!user && (
          <div>
            <Dropdown
              overlay={
                <ul className="drop">
                  <li>
                    <NavLink className="drop-item" to="/my-profile">
                      <>
                        <i className="icon icon-user drop-item__icon" />
                        {t('header.MyProfile')}
                      </>
                    </NavLink>
                  </li>
                  <li>
                    <div
                      className={cx(styles.headerLogout, 'drop-item')}
                      onClick={onLogoutClick}
                    >
                      <i className="icon icon-logout drop-item__icon" />
                      {t('header.SignOut')}
                    </div>
                  </li>
                </ul>
              }
            >
              <span className={styles.headerProfile}>
                <i className="icon icon-user"></i> {user.given_name}{' '}
                {user.family_name}
              </span>
            </Dropdown>
          </div>
        )}
      </div>
    </>
  );
};
