/* eslint-disable react/jsx-pascal-case */
import { useTranslation } from 'react-i18next';
import type { PaginationProps } from 'antd';
import { Pagination as $Pagination } from 'antd';

type Props = PaginationProps;

export const DefaultPageSizeOptions = [12, 24, 36, 48, 60, 72];

const DefaultItemRender: PaginationProps['itemRender'] = (
  _,
  type,
  originalElement,
) => {
  if (type === 'prev') {
    return (
      <button className="ant-pagination-item-link">
        <i className="icon icon-arrow-left"></i>
      </button>
    );
  }
  if (type === 'next') {
    return (
      <button className="ant-pagination-item-link">
        <i className="icon icon-arrow-right"></i>
      </button>
    );
  }
  return originalElement;
};

export default function Pagination(props: Props) {
  const { t } = useTranslation();
  const {
    pageSizeOptions = DefaultPageSizeOptions,
    itemRender = DefaultItemRender,
    ...other
  } = props;

  return (
    <$Pagination
      locale={{ items_per_page: t('pagination.perPage') }}
      pageSizeOptions={pageSizeOptions}
      itemRender={itemRender}
      {...other}
    />
  );
}
