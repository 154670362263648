/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum MultimediaType {
    IMAGE = 'image',
    DOCUMENT = 'document',
}
