import React from 'react';
import { Button, Progress, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { UploadFileS3 } from 'utils/file.utils';
import { getImageUrl, ImageSize } from 'utils/image.utils';

import styles from './UploadImageItem.module.less';

export default function UploadImageItem(
  _originNode: React.ReactElement,
  file: UploadFileS3,
  _fileList: UploadFileS3[],
  actions: {
    download: () => void;
    preview: () => void;
    remove: () => void;
  },
  props:
    | {
        isPreviewAvailable?: boolean;
        isSortable?: boolean;
        imageSize?: ImageSize;
      }
    | undefined = {},
) {
  const {
    isPreviewAvailable = true,
    isSortable = false,
    imageSize = ImageSize.SMALL,
  } = props;
  const { t } = useTranslation();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: file.uid, disabled: file.status !== 'done' });

  if (isDragging && transform) {
    transform.scaleX = 1.05;
    transform.scaleY = 1.05;
  }

  const sortableProps = isSortable && {
    ref: setNodeRef,
    style: {
      transform: CSS.Transform.toString(transform),
      transition,
    },
    ...attributes,
    ...listeners,
  };

  if (file.status === 'uploading') {
    return (
      <div className={cx(styles.wrap, styles.uploading)} {...sortableProps}>
        <div>
          <div className={styles.progressBar}>
            <Progress
              size="small"
              type="line"
              percent={file.percent}
              showInfo={false}
            />
          </div>
          <div className={styles.text}>{t('UploadImageItem.Uploading')}</div>
        </div>
      </div>
    );
  }

  if (file.status === 'error') {
    return (
      <Tooltip
        overlayClassName={styles.tooltip}
        title={file.error?.message}
        getPopupContainer={(node: HTMLElement) =>
          node.parentNode as HTMLElement
        }
      >
        <div className={cx(styles.wrap, styles.error)} {...sortableProps}>
          <div className={styles.overlay}>
            {isPreviewAvailable && (
              <Button
                className={styles.button}
                type="text"
                disabled
                icon={<i className="icon icon-eye"></i>}
              />
            )}

            <Button
              className={styles.button}
              type="text"
              onClick={actions.remove}
              icon={<i className="icon icon-trash"></i>}
            />
          </div>
          <div className={styles.text}>
            <i className={cx('icon icon-close-square', styles.icon)}></i>
            <div>{file.name}</div>
          </div>
        </div>
      </Tooltip>
    );
  }

  if (file.status === 'done') {
    return (
      <div
        className={cx(styles.wrap, {
          [styles.sortable]: isSortable,
          [styles.dragging]: isDragging,
        })}
        {...sortableProps}
      >
        <div className={styles.overlay}>
          {isPreviewAvailable && (
            <Button
              className={styles.button}
              type="text"
              onClick={actions.preview}
              icon={<i className="icon icon-eye"></i>}
            />
          )}

          <Button
            className={styles.button}
            type="text"
            onClick={actions.remove}
            icon={<i className="icon icon-trash"></i>}
          />
        </div>
        <img
          className={styles.img}
          src={file.thumbUrl || getImageUrl(file.url, imageSize)}
          alt={file.name}
        />
      </div>
    );
  }
}
