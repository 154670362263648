import { useState } from 'react';
import { Button, Carousel, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';

import { QueryUrlKey } from 'components/SearchInput';
import HomePageList from './HomePageList';
import HomePageAdvertisingBanner from './HomePageAdvertisingBanner';
import { ExternalListingOrderBy } from 'services/api';
import { useAppSettings } from 'components/AppSettingsProvider';
import useNetworkNotificationHelper from 'hooks/useNetworkNotificationHelper';

import homeImg from 'assets/images/home.webp';

import styles from './HomePage.module.less';

export default function HomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { settings, isMainSite } = useAppSettings();
  const { home_page } = settings || {};
  const [query, setQuery] = useState('');
  useNetworkNotificationHelper();

  const handleSearch = () => {
    navigate({
      pathname: '/listings',
      search: createSearchParams({ [QueryUrlKey]: query }).toString(),
    });
  };

  return (
    <div className={styles.wrapper}>
      <section className={styles.heroSection}>
        <Carousel className={styles.carousel}>
          <div>
            <div
              className={styles.carouselSlide}
              style={{
                backgroundImage: `url(${
                  !!home_page?.image ? home_page?.image : homeImg
                })`,
              }}
            />
          </div>
        </Carousel>
        <div className={styles.contentBox}>
          <h1 className="h2">
            {!!home_page?.headline ? (
              <div>{home_page?.headline}</div>
            ) : (
              <>
                <span>{t('HomePage.banner1')}</span>
                <div>{t('HomePage.banner2')}</div>
              </>
            )}
          </h1>

          <div className={styles.input}>
            <Input
              placeholder={t('HomePage.search.placeholder')}
              prefix={<i className="icon icon-search" />}
              onChange={(event) => setQuery(event.target.value)}
              onPressEnter={handleSearch}
              suffix={
                <Button type="primary" onClick={handleSearch}>
                  {t('HomePage.search.button')}
                </Button>
              }
            />
          </div>

          <div className={styles.sellers}>
            {t('HomePage.sellers.info')}{' '}
            <Link to="/sellers">{t('HomePage.sellers.link')}</Link>
          </div>
        </div>
        {isMainSite && <HomePageAdvertisingBanner />}
      </section>

      <HomePageList
        order={ExternalListingOrderBy.ENDS_AT}
        title={t('HomePageList1.BiddingEndingSoon')}
      />

      <HomePageList
        order={ExternalListingOrderBy._VIEWS}
        title={t('HomePageList2.MostPopular')}
      />
    </div>
  );
}
