import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { displayErrorMessage } from 'components/message';
import Pagination from 'components/Pagination';
import { AdminUserResponse, DashboardApi } from 'services/api';
import SidebarLayout from '../SidebarLayout';
import AdminsAndAgentsPageTable from './AdminsAndAgentsTable';
import { scrollTop } from 'utils/scrollTop.utils';
import AdminsAndAgentsFilter, {
  AdminsAndAgentsFilterBy,
  AdminsAndAgentsFilterByOption,
} from './AdminsAndAgentsFilter';

import styles from './AdminsAndAgentsPage.module.less';

const pageSizeOptions = [24, 48, 72];
const DefaultPageSize = pageSizeOptions[0];

export default function AdminsAndAgentsPage() {
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPageSize);
  const [filter, setFilter] = useState(AdminsAndAgentsFilterBy.ALL);
  const [usersData, setUsersData] = useState<AdminUserResponse>();
  const { t } = useTranslation();

  const getTotalCount = () => {
    if (!usersData) return;

    const {
      total_by_roles: { listing_agent, brokerage_admin },
    } = usersData;

    switch (filter) {
      case AdminsAndAgentsFilterBy.ALL:
        return listing_agent + brokerage_admin;

      case AdminsAndAgentsFilterBy.LISTING_AGENT:
        return listing_agent;

      case AdminsAndAgentsFilterBy.BROKERAGE_ADMIN:
        return brokerage_admin;
    }
  };
  const totalCount = getTotalCount();

  useEffect(() => {
    const loadUsers = async () => {
      setIsUsersLoading(true);

      try {
        const result = await DashboardApi.getAdminsAndAgentsList({
          page,
          pageSize,
          filter,
        });
        setUsersData(result);
      } catch (error) {
        displayErrorMessage({ error });
      } finally {
        setIsUsersLoading(false);
      }
    };

    loadUsers();
  }, [page, pageSize, filter]);

  const handlePaginationChange = (newPage: number, newPageSize: number) => {
    if (isUsersLoading) return;

    setPage(newPage);
    setPageSize(newPageSize);
    scrollTop();
  };

  const handleFilterChange = (newFilter: AdminsAndAgentsFilterByOption) => {
    if (isUsersLoading) return;

    setPage(1);
    setFilter(newFilter);
  };

  return (
    <SidebarLayout>
      <div className="paper">
        <Row
          justify="space-between"
          align="middle"
          gutter={16}
          className={styles.titleBox}
        >
          <Col>
            <h3 className="h4">
              {t('Dashboard.AdminsAndAgentsPage.AdminsAndAgents')}
            </h3>
          </Col>
          <Col>
            <Link to="/admins-and-agents/new-user">
              <Button type="primary" size="large" className="btn-secondary">
                {t('Dashboard.AdminsAndAgentsPage.NewUser')}
              </Button>
            </Link>
          </Col>
        </Row>
        {usersData && (
          <AdminsAndAgentsFilter
            isLoading={isUsersLoading}
            onChange={handleFilterChange}
            filter={filter}
            usersData={usersData}
          />
        )}
        <div>
          <AdminsAndAgentsPageTable
            data={usersData?.results}
            isLoading={isUsersLoading}
          />

          {!!totalCount && totalCount > DefaultPageSize && (
            <Pagination
              current={page}
              pageSize={pageSize}
              showSizeChanger
              onChange={handlePaginationChange}
              total={totalCount}
              pageSizeOptions={pageSizeOptions}
            />
          )}
        </div>
      </div>
    </SidebarLayout>
  );
}
