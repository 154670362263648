/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ErrorCode {
    TIME_IS_UP = 'TIME_IS_UP',
    AMOUNT_IS_LOW = 'AMOUNT_IS_LOW',
    TRY_AGAIN = 'TRY_AGAIN',
    CHANGE_STATUS_NOT_ALLOWED = 'CHANGE_STATUS_NOT_ALLOWED',
    PENDING_PRICE_REQUIRED = 'PENDING_PRICE_REQUIRED',
    SOLD_PRICE_REQUIRED = 'SOLD_PRICE_REQUIRED',
    HIGHER_MAXIMUM_AUTOBID = 'HIGHER_MAXIMUM_AUTOBID',
    USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
    DOMAIN_ALREADY_EXISTS = 'DOMAIN_ALREADY_EXISTS',
    SUBDOMAIN_ALREADY_EXISTS = 'SUBDOMAIN_ALREADY_EXISTS',
    MARKET_CENTER_ALREADY_EXISTS = 'MARKET_CENTER_ALREADY_EXISTS',
    UNSUBSCRIBE_INVALID_TOKEN = 'UNSUBSCRIBE_INVALID_TOKEN',
    NOT_FOUND = 'NOT_FOUND',
}
