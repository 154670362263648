import { Button, Divider, Input, Popover } from 'antd';
import { useFormContext } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import cx from 'classnames';

import FileUpload from 'components/form/FileUpload';
import UploadImageItem from 'components/form/FileUpload/UploadImageItem';
import FormItem from 'components/form/FormItem';
import TextEditor from 'components/form/TextEditor/TextEditor';
import InfoBox, { BoxColor, BoxSize } from 'components/InfoBox';
import { MultimediaType } from 'services/api';
import { UploadFileS3, ValidateFiles } from 'utils/file.utils';
import { ValidateFormatter } from 'utils/validation.utils';

import styles from '../NewBrokeragePage.module.less';

type Props = {
  onBack: () => void;
  onSave: () => void;
  isEditMode?: boolean;
};

export interface ICustomFormValues {
  home_page: {
    headline: string;
    image: UploadFileS3[];
  };
  listing_page: {
    headline: string;
  };
  about_us_page: {
    images: UploadFileS3[];
    section_1_headline: string;
    section_2_headline: string;
    section_1_subtitle: string;
    section_2_subtitle: string;
    section_1_body: string;
    section_2_body: string;
    section_3_body: string;
    section_3_image: UploadFileS3[];
  };
}

export const getCustomValidationSchema = (t: TFunction) =>
  yup.object().shape({
    home_page: yup.object().shape({
      headline: yup.string().max(
        72,
        t('validation.maxLength', {
          count: 72,
        }),
      ),
      image: yup.array().test('ValidateFiles', '', ValidateFiles),
    }),
    listing_page: yup.object().shape({
      headline: yup.string().max(
        72,
        t('validation.maxLength', {
          count: 72,
        }),
      ),
    }),
    about_us_page: yup.object().shape({
      images: yup
        .array()
        .test(
          'noneOrFour',
          t('NewBrokeragePage.AboutUsPage.FileSubmitting.validation'),
          (val: UploadFileS3[] | undefined) => {
            if (!val) return true;
            return val.length === 0 || val.length === 4;
          },
        )
        .test('ValidateFiles', '', ValidateFiles),
      section_1_headline: yup
        .string()
        .max(
          40,
          t('validation.maxLength', {
            count: 40,
          }),
        )
        .required(t('validation.required')),
      section_2_headline: yup
        .string()
        .max(
          40,
          t('validation.maxLength', {
            count: 40,
          }),
        )
        .required(t('validation.required')),
      section_1_subtitle: yup
        .string()
        .max(
          110,
          t('validation.maxLength', {
            count: 110,
          }),
        )
        .required(t('validation.required')),
      section_2_subtitle: yup
        .string()
        .max(
          110,
          t('validation.maxLength', {
            count: 110,
          }),
        )
        .required(t('validation.required')),
      section_1_body: yup
        .string()
        .test('test', t('validation.required'), ValidateFormatter)
        .max(
          1000,
          t('validation.maxLength', {
            count: 1000,
          }),
        )
        .required(t('validation.required')),
      section_2_body: yup
        .string()
        .test('test', t('validation.required'), ValidateFormatter)
        .max(
          1000,
          t('validation.maxLength', {
            count: 1000,
          }),
        )
        .required(t('validation.required')),
      section_3_body: yup
        .string()
        .test('test', t('validation.required'), ValidateFormatter)
        .max(
          1000,
          t('validation.maxLength', {
            count: 1000,
          }),
        )
        .required(t('validation.required')),
      section_3_image: yup.array().test('ValidateFiles', '', ValidateFiles),
    }),
  });
type UploaderButtonProps = {
  isMaxCountUploaded: boolean;
};

const UploaderButton = ({ isMaxCountUploaded }: UploaderButtonProps) => {
  const { t } = useTranslation();

  if (isMaxCountUploaded) return null;

  return (
    <div>
      <i className="icon icon-upload"></i>
      <div>{t('FileUpload.Upload')}</div>
    </div>
  );
};
export default function NewBrokerageCustomTab(props: Props) {
  const { onSave, onBack, isEditMode = false } = props;
  const { t } = useTranslation();
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<ICustomFormValues>();

  return (
    <div className={styles.form}>
      <div className={styles.formWrapLg}>
        <h3 className={styles.title}>{t('NewBrokeragePage.HomePage')}</h3>
        <FormItem
          name="home_page.headline"
          control={control}
          render={({ field }) => (
            <Input.Group compact className="input-group">
              <div className="prefix-label prefix-label--lg">
                {t('NewBrokeragePage.HomePage.Headline')}
              </div>
              <Input
                size="large"
                placeholder={t(
                  'NewBrokeragePage.HomePage.Headline.placeholder',
                )}
                {...field}
              />
            </Input.Group>
          )}
        />
        <div>
          <FormItem
            className={cx(styles.uploadWrap, 'clear-shadow')}
            name="home_page.image"
            control={control}
            render={({ field, fieldState }) => (
              <FileUpload
                field={field}
                bucket={MultimediaType.IMAGE}
                error={fieldState.error}
                listType="picture-card"
                acceptedFileFormat={['.png', '.jpg']}
                acceptedFileSize={50}
                itemRender={UploadImageItem}
                renderUploadButton={UploaderButton}
              />
            )}
          />
          <h4 className={cx(styles.uploadTitle, styles.uploadTitleFull)}>
            {t('NewBrokeragePage.HomePage.Image.title')}
            <Popover
              placement="bottom"
              getPopupContainer={() =>
                document.getElementById('root') as HTMLElement
              }
              content={
                <div className={styles.popoverContainer}>
                  {t('NewBrokeragePage.HomePage.Image.popover')}
                </div>
              }
            >
              <i
                className={cx(
                  styles.popoverOpener,
                  styles.popoverOpenerSm,
                  'icon icon-info',
                )}
              ></i>
            </Popover>
            <div className={styles.uploadSubtitle}>
              {t('NewBrokeragePage.HomePage.Image.subtitle')}
            </div>
          </h4>
        </div>
        <h3 className={styles.title}>{t('NewBrokeragePage.ListingsPage')}</h3>
        <FormItem
          name="listing_page.headline"
          control={control}
          render={({ field }) => (
            <Input.Group compact className="input-group">
              <div className="prefix-label prefix-label--lg">
                {t('NewBrokeragePage.ListingsPage.Headline')}
              </div>
              <Input
                size="large"
                placeholder={t(
                  'NewBrokeragePage.ListingsPage.Headline.placeholder',
                )}
                {...field}
              />
            </Input.Group>
          )}
        />
        <h3 className={styles.title}>{t('NewBrokeragePage.AboutUsPage')}</h3>
        <div>
          <FormItem
            className={cx(styles.uploadWrap, 'clear-shadow')}
            control={control}
            name="about_us_page.images"
            render={({ field, fieldState }) => (
              <FileUpload
                maxCount={4}
                field={field}
                error={fieldState.error}
                multiple
                bucket={MultimediaType.IMAGE}
                acceptedFileFormat={['.jpg', '.png']}
                acceptedFileSize={50}
                itemRender={UploadImageItem}
                listType="picture-card"
                renderUploadButton={UploaderButton}
              />
            )}
          />
          <h4 className={cx(styles.uploadTitle, styles.uploadTitleFull)}>
            {t('NewBrokeragePage.AboutUsPage.Image.title')}
            <Popover
              getPopupContainer={() =>
                document.getElementById('root') as HTMLElement
              }
              placement="bottom"
              content={
                <div className={styles.popoverContainer}>
                  {t('NewBrokeragePage.AboutUsPage.Image.popover')}
                </div>
              }
            >
              <i
                className={cx(
                  styles.popoverOpener,
                  styles.popoverOpenerSm,
                  'icon icon-info',
                )}
              ></i>
            </Popover>
            <div className={styles.uploadSubtitle}>
              {t('NewBrokeragePage.AboutUsPage.Image.subtitle')}
            </div>
          </h4>
        </div>

        <Divider className="divider-light" />

        <div className={styles.subTitle}>
          {t('NewBrokeragePage.AboutUsPage.Section1')}
        </div>
        <FormItem
          name="about_us_page.section_1_headline"
          control={control}
          render={({ field }) => (
            <Input.Group compact className="input-group">
              <div className="prefix-label prefix-label--lg">
                {t('NewBrokeragePage.AboutUsPage.Section1.MainHeadline')}
              </div>
              <Input
                size="large"
                placeholder={t(
                  'NewBrokeragePage.AboutUsPage.Section1.MainHeadline.placeholder',
                )}
                {...field}
              />
            </Input.Group>
          )}
        />
        <FormItem
          name="about_us_page.section_1_subtitle"
          control={control}
          render={({ field }) => (
            <Input.Group compact className="input-group">
              <div className="prefix-label prefix-label--lg">
                {t('NewBrokeragePage.AboutUsPage.Section1.Subtitle')}
              </div>
              <Input
                size="large"
                placeholder={t(
                  'NewBrokeragePage.AboutUsPage.Section1.Subtitle.placeholder',
                )}
                {...field}
              />
            </Input.Group>
          )}
        />
        <FormItem
          name="about_us_page.section_1_body"
          control={control}
          render={({ field }) => (
            <TextEditor
              {...field}
              placeholder={t(
                'NewBrokeragePage.AboutUsPage.Section1.Body.placeholder',
              )}
            />
          )}
        />
        <Divider className="divider-light" />
        <div className={styles.subTitle}>
          {t('NewBrokeragePage.AboutUsPage.Section2')}
        </div>
        <FormItem
          name="about_us_page.section_2_headline"
          control={control}
          render={({ field }) => (
            <Input.Group compact className="input-group">
              <div className="prefix-label prefix-label--lg">
                {t('NewBrokeragePage.AboutUsPage.Section2.MainHeadline')}
              </div>
              <Input
                size="large"
                placeholder={t(
                  'NewBrokeragePage.AboutUsPage.Section2.MainHeadline.placeholder',
                )}
                {...field}
              />
            </Input.Group>
          )}
        />
        <FormItem
          name="about_us_page.section_2_subtitle"
          control={control}
          render={({ field }) => (
            <Input.Group compact className="input-group">
              <div className="prefix-label prefix-label--lg">
                {t('NewBrokeragePage.AboutUsPage.Section2.Subtitle')}
              </div>
              <Input
                size="large"
                placeholder={t(
                  'NewBrokeragePage.AboutUsPage.Section2.Subtitle.placeholder',
                )}
                {...field}
              />
            </Input.Group>
          )}
        />
        <FormItem
          name="about_us_page.section_2_body"
          control={control}
          render={({ field }) => (
            <TextEditor
              {...field}
              placeholder={t(
                'NewBrokeragePage.AboutUsPage.Section2.Body.placeholder',
              )}
            />
          )}
        />
        <Divider className="divider-light" />
        <div className={styles.subTitle}>
          {t('NewBrokeragePage.AboutUsPage.Section3')}
        </div>
        <div>
          <FormItem
            className={cx(styles.uploadWrap, 'clear-shadow')}
            name="about_us_page.section_3_image"
            control={control}
            render={({ field, fieldState }) => (
              <FileUpload
                error={fieldState.error}
                field={field}
                bucket={MultimediaType.IMAGE}
                listType="picture-card"
                acceptedFileFormat={['.png', '.jpg']}
                acceptedFileSize={50}
                itemRender={UploadImageItem}
                renderUploadButton={UploaderButton}
              />
            )}
          />
          <h4 className={cx(styles.uploadTitle, styles.uploadTitleFull)}>
            {t('NewBrokeragePage.AboutUsPage.Section3.Image.title')}
            <Popover
              getPopupContainer={() =>
                document.getElementById('root') as HTMLElement
              }
              content={
                <div className={styles.popoverContainer}>
                  {t('NewBrokeragePage.AboutUsPage.Section3.Image.popover')}
                </div>
              }
              placement="bottom"
            >
              <i
                className={cx(
                  styles.popoverOpener,
                  styles.popoverOpenerSm,
                  'icon icon-info',
                )}
              ></i>
            </Popover>
            <div className={styles.uploadSubtitle}>
              {t('NewBrokeragePage.AboutUsPage.Section3.Image.subtitle')}
            </div>
          </h4>
        </div>
        <FormItem
          name="about_us_page.section_3_body"
          control={control}
          render={({ field }) => (
            <TextEditor
              {...field}
              placeholder={t(
                'NewBrokeragePage.AboutUsPage.Section3.Body.placeholder',
              )}
            />
          )}
        />
        <div className={styles.btnWrap}>
          <Button
            type="primary"
            size="large"
            className="with-icon btn-secondary"
            onClick={onBack}
            disabled={isSubmitting}
          >
            <i className="icon icon-arrow-left"></i>
            {t('NewBrokeragePage.Back')}
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={onSave}
            loading={isSubmitting}
          >
            {isEditMode
              ? t('NewBrokeragePage.Update')
              : t('NewBrokeragePage.Save')}
          </Button>
        </div>
        <div className={styles.infoBox}>
          <InfoBox
            color={BoxColor.Grey}
            size={BoxSize.Small}
            icon={<i className="icon icon-flag"></i>}
            text={<span>{t('NewBrokeragePage.infoBox')}</span>}
          />
        </div>
      </div>
    </div>
  );
}
