import { useAuth } from 'components/AuthProvider';
import { ExternalUserRole } from 'services/api';
import { hasUserRequiredRole } from './accessorUtils';

type Props = {
  children: JSX.Element;
  roles?: ExternalUserRole[];
};

export default function Accessor(props: Props) {
  const { children, roles } = props;
  const { user, isAuthenticated } = useAuth();
  const hasAccessByRole = roles ? hasUserRequiredRole(roles, user?.role) : true;

  if (!isAuthenticated || !hasAccessByRole) return null;

  return <>{children}</>;
}
