export const getFormattedNumber = (value?: number, country = 'en-US') => {
  return value?.toLocaleString(country);
};

export const getRoundHalf = (displayValue?: string) => {
  if (displayValue === undefined) return undefined;

  const val = parseFloat(displayValue);

  return Math.round(val * 2) / 2;
};

export const numberFormatter = (value: any) => {
  if (value === '' || value === undefined || value === null) {
    return '';
  }

  const numberValue = Number(value);

  return getFormattedNumber(numberValue) || '';
};
