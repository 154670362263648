import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Form, InputNumber, Divider, Space } from 'antd';
import { TFunction, useTranslation } from 'react-i18next';

import { ListingApi, ExternalListingDetail, ErrorCode } from 'services/api';
import FormItem from 'components/form/FormItem';
import { displayErrorMessage } from 'components/message';
import HorizontalListingCard from 'components/cards/HorizontalListingCard';
import { getFormattedNumber, numberFormatter } from 'utils/number.utils';
import { getCurrencySign } from 'utils/price.utils';
import { useAuth } from 'components/AuthProvider';
import { getBidNowButtonProps } from 'utils/listing.utils';
import { ModalScrollWrapper, ModalFooterWrapper } from 'components/Modal';
import InfoBox, { BoxColor, BoxSize } from 'components/InfoBox';
import Switch from 'components/form/Switch';

import styles from './BidNowDataForm.module.less';

interface IFormValues {
  bidValue: null | number;
  enabled: boolean;
}

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  refreshDataOnError: () => Promise<void>;
  listing: ExternalListingDetail;
};

const getValidationSchema = (t: TFunction, listing: ExternalListingDetail) => {
  const priceLimit = 999999999;
  return yup.object().shape({
    bidValue: yup
      .number()
      .nullable()
      .required(t('validation.required'))
      .test('bidValueTest', (value, { parent, createError, path }) => {
        const { bids, bid_increment, initial_price } = listing;
        const hasBids = !!bids?.length;
        const currentPrice = hasBids
          ? bids[0].price.value
          : initial_price!.value;
        const minBid = hasBids ? currentPrice + bid_increment! : currentPrice;
        const currentBid = Number(value);
        const differenceBidPrices = currentBid - currentPrice;
        if (
          currentBid >= minBid &&
          differenceBidPrices % bid_increment! === 0
        ) {
          return true;
        }
        if (parent.enabled) {
          return createError({
            path,
            message: t(
              'ListingDetailsPage.BidNowModal.AutoBid.SetYourMaximumPrice',
            ),
          });
        }
        return createError({
          path,
          message: t('ListingDetailsPage.BidNowModal.IncorrectBidAmount'),
        });
      })
      .max(
        priceLimit,
        t('ListingDetailsPage.BidNowModal.ReachedPriceLimit', {
          counter: getFormattedNumber(priceLimit),
        }),
      ),
  });
};

export default function BidNowDataForm(props: Props) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { onClose, onSuccess, refreshDataOnError, listing } = props;

  const validationSchema = useMemo(
    () => getValidationSchema(t, listing),
    [t, listing],
  );
  const bidNowButtonProps = getBidNowButtonProps(listing.status, t);
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<IFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      bidValue: listing.auto_bid ? listing.auto_bid?.max_price?.value : null,
      enabled: true,
    },
  });

  const listingAutoBidPrice = !!listing.bids?.length
    ? listing.bids[0].price.value + listing.bid_increment!
    : listing.initial_price!.value;

  const switchVal = watch('enabled');
  const onSubmit = async (values: IFormValues) => {
    const { bidValue, enabled } = values;

    if (bidValue === null || !user) return;
    try {
      if (enabled) {
        await ListingApi.createAutoBid({
          buyer_id: user.user_id,
          listing_id: listing.id,
          price: listingAutoBidPrice,
          currency: listing.initial_price!.currency,
          increment: listing.bid_increment!,
          max_price: bidValue,
        });
      } else {
        await ListingApi.createBid({
          buyer_id: user.user_id,
          listing_id: listing.id,
          price: bidValue,
          currency: listing.initial_price!.currency,
        });
      }

      onSuccess();
    } catch (error: any) {
      if (
        error.message === 'Generic Error' ||
        error.message === 'Internal Server Error'
      ) {
        error.message = t('BidsNowError.refreshThePage');
      } else if (
        error?.body?.message === ErrorCode.AMOUNT_IS_LOW ||
        error?.body?.message === ErrorCode.HIGHER_MAXIMUM_AUTOBID ||
        error?.body?.message === ErrorCode.TRY_AGAIN ||
        error?.body?.message === ErrorCode.TIME_IS_UP
      ) {
        await refreshDataOnError();
      } else {
        error.message = error.body.message;
      }
      displayErrorMessage({
        error,
        containerSelector: '.ant-modal',
      });
    }
  };

  return (
    <>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
        <ModalScrollWrapper>
          <HorizontalListingCard listing={listing} />

          <Divider className="modal__divider divider-light" />
          <FormItem
            name="bidValue"
            control={control}
            render={({ field }) => {
              return (
                <InputNumber
                  id={`bidNow-your-bid-input-${listing.id}`}
                  addonBefore={
                    getCurrencySign(listing.initial_price!.currency) || '$'
                  }
                  addonAfter={
                    listing.initial_price!.currency?.toUpperCase() || 'USD'
                  }
                  placeholder={t(
                    'ListingDetailsPage.BidNowModal.YourBidPlaceholder',
                  )}
                  size="large"
                  precision={0}
                  controls={false}
                  formatter={numberFormatter}
                  {...field}
                />
              );
            }}
          />
          <FormItem
            name="enabled"
            control={control}
            render={({ field }) => {
              return (
                <>
                  <Space size={12} align="start">
                    <div className={styles.switchBox}>
                      <Switch size="small" {...field} />
                    </div>
                    <div className={styles.switchText}>
                      {switchVal ? (
                        <strong>
                          {t('ListingDetailsPage.BidNowModal.Enabled')}
                        </strong>
                      ) : (
                        <strong className={styles.switchGray}>
                          {t('ListingDetailsPage.BidNowModal.Disabled')}
                        </strong>
                      )}

                      {switchVal && (
                        <>
                          <div>
                            {t(
                              'ListingDetailsPage.BidNowModal.AutoBid.PlaceYourMaximumBid',
                            )}
                          </div>
                          <div className={styles.switchGray}>
                            {t(
                              'ListingDetailsPage.BidNowModal.AutoBid.YourNextBidWillBe',
                            )}
                            <b>{`${getCurrencySign(
                              listing.initial_price!.currency,
                            )}${getFormattedNumber(listingAutoBidPrice)}`}</b>
                          </div>
                        </>
                      )}
                    </div>
                  </Space>
                </>
              );
            }}
            className="clear-shadow"
          />

          <div className="modal__message">
            <InfoBox
              color={BoxColor.Grey}
              size={BoxSize.Small}
              icon={<i className="icon icon-flag"></i>}
              text={
                <strong>
                  {t('ListingDetailsPage.BidNowModal.MinimumBidIncrement', {
                    count: listing.bid_increment,
                    currency: getCurrencySign(listing.initial_price!.currency),
                  })}
                </strong>
              }
            />
          </div>
        </ModalScrollWrapper>

        <ModalFooterWrapper>
          <div>
            <Button
              data-test-id="bidNow-cancel-btn"
              onClick={onClose}
              type="link"
              className="custom-link"
              size="large"
            >
              {t('ListingDetailsPage.BidNowModal.CancelButton')}
            </Button>
          </div>
          <div>
            <Button
              data-test-id="bidNow-bid-now-btn"
              type="primary"
              loading={isSubmitting}
              htmlType="submit"
              size="large"
              {...bidNowButtonProps}
            />
          </div>
        </ModalFooterWrapper>
      </Form>
    </>
  );
}
