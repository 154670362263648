import { useTranslation } from 'react-i18next';
import Spin from 'components/Spin';
import SearchInput from 'components/SearchInput';
import cx from 'classnames';

import styles from './CertifiedAgents.module.less';
import { useEffect, useState } from 'react';
import { isNil } from 'lodash';
import { scrollTop } from 'utils/scrollTop.utils';
import Pagination from 'components/Pagination';
import { CommonApi, ExternalUserStaffResponse } from 'services/api';
import CertifiedAgentsList from '../CertifiedAgentsList/CertifiedAgentsList';

export const DefaultPageSizeOptions = [15, 30, 45, 60, 75];

const DefaultPageSize = DefaultPageSizeOptions[0];
export default function CertifiedAgents() {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>();
  const [lastLoadedQuery, setLastLoadedQuery] = useState<string>();
  const [agentsData, setAgentsData] = useState<ExternalUserStaffResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPageSize);

  useEffect(() => {
    const loadData = async () => {
      if (isNil(query)) return;

      try {
        setIsLoading(true);

        const result = await CommonApi.getAgentsList({
          query,
          pageSize,
          page,
        });

        setAgentsData(result);
      } catch (error) {
        setAgentsData({
          total: 0,
          page,
          page_size: pageSize,
          results: [],
        });
      } finally {
        setIsLoading(false);
        setLastLoadedQuery(query);
      }
    };

    loadData();
  }, [query, page, pageSize]);

  const handlePaginationChange = (newPage: number, newPageSize: number) => {
    if (isLoading) return;

    setPage(newPage);
    setPageSize(newPageSize);

    scrollTop();
  };

  const handleSearchInputChange = (newQuery: string) => {
    setPage(1);
    setQuery(newQuery);
  };

  return (
    <section className={styles.wrapper}>
      <div className="container">
        <div className={styles.block}>
          <h2 className={cx(styles.title, 'h2')}>
            {t('CertifiedAgents.header')}
          </h2>
          <p className={styles.subtitle}>{t('CertifiedAgents.subheader')}</p>
          <SearchInput
            lastLoadedQuery={lastLoadedQuery}
            loading={isLoading}
            onChange={handleSearchInputChange}
            placeholder={t('CertifiedAgents.SearchInput.placeholder')}
          />
        </div>
      </div>

      <Spin className={styles.spinner} spinning={isLoading}>
        <div className={cx('container', styles.container)}>
          <CertifiedAgentsList
            agentsData={agentsData}
            query={lastLoadedQuery}
          />

          {!!agentsData?.total && agentsData.total > DefaultPageSize && (
            <Pagination
              current={page}
              pageSize={pageSize}
              showSizeChanger
              pageSizeOptions={DefaultPageSizeOptions}
              onChange={handlePaginationChange}
              total={agentsData.total}
            />
          )}
        </div>
      </Spin>
    </section>
  );
}
