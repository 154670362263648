/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExternalAutoBidCreate } from '../models/ExternalAutoBidCreate';
import type { ExternalBidCreate } from '../models/ExternalBidCreate';
import type { ExternalBidDetail } from '../models/ExternalBidDetail';
import type { ExternalBidOrderBy } from '../models/ExternalBidOrderBy';
import type { ExternalBidResponse } from '../models/ExternalBidResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BidsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Bids
     * @param listingId
     * @param page
     * @param pageSize
     * @param order
     * @returns ExternalBidResponse Successful Response
     * @throws ApiError
     */
    public listBids(
        listingId: string,
        page: number = 1,
        pageSize: number = 50,
        order?: ExternalBidOrderBy,
    ): CancelablePromise<ExternalBidResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/bids/',
            query: {
                'page': page,
                'page_size': pageSize,
                'listing_id': listingId,
                'order': order,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Bid
     * @param requestBody
     * @returns ExternalBidDetail Successful Response
     * @throws ApiError
     */
    public createBid(
        requestBody: ExternalBidCreate,
    ): CancelablePromise<ExternalBidDetail> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bids/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Auto Bid
     * @param requestBody
     * @returns ExternalBidDetail Successful Response
     * @throws ApiError
     */
    public createAutoBid(
        requestBody: ExternalAutoBidCreate,
    ): CancelablePromise<ExternalBidDetail> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/bids/auto',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
