import { message as $message } from 'antd';
import { ArgsProps } from 'antd/lib/message';
import cx from 'classnames';

import styles from './message.module.less';
import { I18n } from 'aws-amplify';
import i18n from 'i18next';
import { ErrorCode } from 'services/api';

const translateErrorMessage = (error: any) => {
  if (error.message in ErrorCode || error.body?.message in ErrorCode) {
    if (error?.body?.message) {
      switch (error?.body?.message) {
        case ErrorCode.PENDING_PRICE_REQUIRED:
          error.message = i18n.t('NewListingPage.pendingPrice.restriction');
          break;
        case ErrorCode.SOLD_PRICE_REQUIRED:
          error.message = i18n.t('NewListingPage.soldPrice.restriction');
          break;
        case ErrorCode.CHANGE_STATUS_NOT_ALLOWED:
          error.message = i18n.t('NewListingPage.status.restriction');
          break;
        case ErrorCode.AMOUNT_IS_LOW:
          error.message = i18n.t('BidsNowError.bidAlreadyPlaced');
          break;
        case ErrorCode.TRY_AGAIN:
          error.message = i18n.t('BidsNowError.tryAgain');
          break;
        case ErrorCode.TIME_IS_UP:
          error.message = i18n.t('BidsNowError.youAreLate');
          break;
        case ErrorCode.HIGHER_MAXIMUM_AUTOBID:
          error.message = i18n.t('BidsNowError.higherMaximumAutobid');
          break;
        case ErrorCode.DOMAIN_ALREADY_EXISTS:
          error.message = i18n.t('BidsNowError.domainAlreadyExists');
          break;
        case ErrorCode.USER_ALREADY_EXISTS:
          error.message = i18n.t('BidsNowError.userAlreadyExists');
          break;
        case ErrorCode.MARKET_CENTER_ALREADY_EXISTS:
          error.message = i18n.t('BidsNowError.marketCenterAlreadyExists');
          break;
        case ErrorCode.SUBDOMAIN_ALREADY_EXISTS:
          error.message = i18n.t('BidsNowError.subdomainAlreadyExists');
          break;
        case ErrorCode.UNSUBSCRIBE_INVALID_TOKEN:
          error.message = i18n.t('BidsNowError.BadRequest');
          break;
        case ErrorCode.NOT_FOUND:
          error.message = i18n.t('BidsNowError.NotFound');
          break;
        default:
          break;
      }
    }
  } else {
    switch (error.status) {
      case 400:
        error.message = i18n.t('ErrorBE.BadRequest');
        break;
      case 401:
        error.message = i18n.t('ErrorBE.Unauthorized');
        break;
      case 404:
        error.message = i18n.t('ErrorBE.NotFound');
        break;
      case 422:
        error.message = i18n.t('ErrorBE.ValidationError');
        break;
      case 500:
        error.message = i18n.t('ErrorBE.InternalServerError');
        break;
      default:
        break;
    }
  }
  const nameError = I18n.get(error.code);
  if (nameError) {
    if (error.code === 'ERR_NETWORK') {
      error.message = i18n.t('BidsNowError.NetworkError');
    } else {
      error.message = nameError;
    }
  }

  return error;
};

export const message = {
  success: (props: ArgsProps) => {
    $message.success({
      ...props,
      icon: <i className="icon icon-check-square"></i>,
      className: cx(styles.message, styles.success),
    });
  },
  error: (props: ArgsProps) => {
    const { className, ...restProps } = props;
    $message.error({
      icon: <i className="icon icon-close-square"></i>,
      className: cx(styles.message, styles.error, className),
      ...restProps,
    });
  },
  destroy: $message.destroy,
};
export default message;

export const displayErrorMessage = ({
  error,
  containerSelector,
  props,
}: {
  error: any;
  containerSelector?: string;
  props?: Omit<ArgsProps, 'content' | 'getPopupContainer'>;
}) => {
  translateErrorMessage(error);

  const container = containerSelector
    ? document.querySelector<HTMLElement>(containerSelector)
    : null;
  const getPopupContainer = () => container || document.body;

  message.destroy();
  message.error({
    ...props,
    content: error?.message,
    getPopupContainer,
  });
};
