/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ExternalListingOrderBy {
    ENDS_AT = 'ends_at',
    INITIAL_PRICE = 'initial_price',
    VIEWS = 'views',
    _ENDS_AT = '-ends_at',
    _INITIAL_PRICE = '-initial_price',
    _VIEWS = '-views',
}
