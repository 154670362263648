import { Auth } from 'aws-amplify';

import { ApiClient } from './.openapi';

export * from './.openapi';
export const apiClient = new ApiClient({
  BASE: `${process.env.REACT_APP_API_BASE_URL}/api`,
  TOKEN: async () => {
    try {
      const session = await Auth.currentSession();

      return session.isValid() ? session.getIdToken().getJwtToken() : '';
    } catch {
      return '';
    }
  },
});

// global AXIOS interceptors can be added here
// import axios from 'axios';
// axios.interceptors.request.use

export { default as ListingApi } from './Listing.api';
export { default as DashboardApi } from './Dashboard.api';
export { default as ContactApi } from './Contact.api';
export { default as CommonApi } from './common.api';
