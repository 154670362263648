const enCognito = {
  NotAuthorizedException: 'Incorrect username or password.',
  UserNotFoundException: 'User does not exist.',
  UserNotConfirmedException: 'User has not been confirmed.',
  InvalidParameterException: 'Invalid parameter.',
  CodeMismatchException: 'Incorrect recovery code',
  CodeMismatchExceptionSignUp:
    'Invalid verification code provided, please try again.',
  ExpiredCodeException:
    'The confirmation code has expired, please request a new one.',
  UsernameExistsException: 'This account already exists. Please sign in',
  PasswordResetRequiredException: 'Password reset is required for the user.',
  InvalidPasswordException: 'The password you provided is invalid.',
  LimitExceededException: 'Attempt limit exceeded, please try after some time.',
  NotAuthorizedDeviceException: 'This device is not authorized.',
  TooManyRequestsException: 'Too many requests.',
  InternalErrorException: 'Internal server error.',
  CodeDeliveryFailureException:
    'Unable to send the email message, please try again',
};
export default enCognito;
