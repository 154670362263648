import { Button, Col, Input, Popover, Row } from 'antd';
import cx from 'classnames';
import FileUpload from 'components/form/FileUpload';
import UploadImageItem from 'components/form/FileUpload/UploadImageItem';
import FormItem from 'components/form/FormItem';
import { useFormContext } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { MultimediaType } from 'services/api';
import { UploadFileS3, ValidateFiles } from 'utils/file.utils';
import {
  onlyNumbersRegExp,
  onlyLettersRegExp,
  onlyLettersNumbersRegExp,
  phoneE164RegExp,
} from 'utils/validation.utils';
import * as yup from 'yup';
import styles from '../NewBrokeragePage.module.less';

type Props = {
  onBack: () => void;
  onNext: () => void;
};

export interface ICompanyFormValues {
  color_logo: UploadFileS3[];
  white_logo: UploadFileS3[];
  grey_logo: UploadFileS3[];
  favicon: UploadFileS3[];
  office_address: string;
  office_phone_number: string;
  office_email: string;
  city: string;
  state: string;
  zip: string;
  linkedin_profile: string;
  facebook_profile: string;
  dre: string;
}

export const getCompanyValidationSchema = (t: TFunction) =>
  yup.object().shape({
    color_logo: yup
      .array()
      .min(1, t('validation.required'))
      .test('ValidateFiles', '', ValidateFiles),
    white_logo: yup.array().test('ValidateFiles', '', ValidateFiles),
    grey_logo: yup.array().test('ValidateFiles', '', ValidateFiles),
    favicon: yup
      .array()
      .min(1, t('validation.required'))
      .test('ValidateFiles', '', ValidateFiles),
    office_address: yup
      .string()
      .max(
        40,
        t('validation.maxLength', {
          count: 40,
        }),
      )
      .required(t('validation.required')),

    office_phone_number: yup
      .string()
      .max(
        16,
        t('validation.maxLength', {
          count: 16,
        }),
      )
      .matches(phoneE164RegExp, t('validation.format'))
      .required(t('validation.required')),
    office_email: yup
      .string()
      .email(t('validation.format'))
      .max(
        120,
        t('validation.maxLength', {
          count: 120,
        }),
      )
      .required(t('validation.required')),
    city: yup
      .string()
      .max(
        25,
        t('validation.maxLength', {
          count: 25,
        }),
      )
      .required(t('validation.required')),
    zip: yup
      .string()
      .required(t('validation.required'))
      .max(6, t('validation.format'))
      .matches(onlyNumbersRegExp, t('validation.format')),
    state: yup
      .string()
      .required(t('validation.required'))
      .max(2, t('validation.format'))
      .matches(onlyLettersRegExp, t('validation.format')),
    linkedin_profile: yup.string().required(t('validation.required')),
    facebook_profile: yup.string().required(t('validation.required')),
    dre: yup
      .string()
      .matches(onlyLettersNumbersRegExp, t('validation.format'))
      .max(
        12,
        t('validation.maxLength', {
          count: 12,
        }),
      )
      .required(t('validation.required')),
  });

type UploaderButtonProps = {
  isMaxCountUploaded: boolean;
};

const UploaderButton = ({ isMaxCountUploaded }: UploaderButtonProps) => {
  const { t } = useTranslation();

  if (isMaxCountUploaded) return null;

  return (
    <div>
      <i className="icon icon-upload"></i>
      <div>{t('FileUpload.Upload')}</div>
    </div>
  );
};
export default function NewBrokerageCompanyTab(props: Props) {
  const { onNext, onBack } = props;
  const { t } = useTranslation();
  const { handleSubmit, control } = useFormContext<ICompanyFormValues>();

  return (
    <div className={styles.form}>
      <h3 className={styles.title}>{t('NewBrokeragePage.Logos.title')}</h3>
      <Row gutter={16} className={styles.formWrapXl}>
        <Col xs={24} md={12} lg={6}>
          <FormItem
            className={cx(styles.uploadWrap, 'clear-shadow')}
            control={control}
            name="color_logo"
            render={({ field, fieldState }) => (
              <FileUpload
                field={field}
                bucket={MultimediaType.IMAGE}
                listType="picture-card"
                error={fieldState.error}
                acceptedFileFormat={['.png', '.svg']}
                acceptedFileSize={50}
                itemRender={UploadImageItem}
                renderUploadButton={UploaderButton}
              />
            )}
          />
          <h4 className={styles.uploadTitle}>
            {t('NewBrokeragePage.ColorLogo.title')}
            <div className={styles.uploadSubtitle}>
              {t('NewBrokeragePage.ColorLogo.subtitle')}
              <Popover
                placement="bottom"
                getPopupContainer={() =>
                  document.getElementById('root') as HTMLElement
                }
                content={
                  <div className={styles.popoverContainer}>
                    {t('NewBrokeragePage.ColorLogo.popover')}
                  </div>
                }
              >
                <i
                  className={cx(
                    styles.popoverOpener,
                    styles.popoverOpenerSm,
                    'icon icon-info',
                  )}
                ></i>
              </Popover>
            </div>
          </h4>
        </Col>
        <Col xs={24} md={12} lg={6}>
          <FormItem
            className={cx(styles.uploadWrap, 'clear-shadow')}
            name="white_logo"
            control={control}
            render={({ field, fieldState }) => (
              <FileUpload
                field={field}
                bucket={MultimediaType.IMAGE}
                acceptedFileFormat={['.png', '.svg']}
                acceptedFileSize={50}
                error={fieldState.error}
                itemRender={UploadImageItem}
                listType="picture-card"
                renderUploadButton={UploaderButton}
              />
            )}
          />
          <h4 className={styles.uploadTitle}>
            {t('NewBrokeragePage.WhiteLogo.title')}

            <div className={styles.uploadSubtitle}>
              {t('NewBrokeragePage.WhiteLogo.subtitle')}

              <Popover
                getPopupContainer={() =>
                  document.getElementById('root') as HTMLElement
                }
                content={
                  <div className={styles.popoverContainer}>
                    {t('NewBrokeragePage.WhiteLogo.popover')}
                  </div>
                }
                placement="bottom"
              >
                <i
                  className={cx(
                    styles.popoverOpener,
                    styles.popoverOpenerSm,
                    'icon icon-info',
                  )}
                ></i>
              </Popover>
            </div>
          </h4>
        </Col>
        <Col xs={24} md={12} lg={6}>
          <FormItem
            className={cx(styles.uploadWrap, 'clear-shadow')}
            name="grey_logo"
            control={control}
            render={({ field, fieldState }) => (
              <FileUpload
                field={field}
                error={fieldState.error}
                acceptedFileFormat={['.png', '.svg']}
                acceptedFileSize={50}
                listType="picture-card"
                bucket={MultimediaType.IMAGE}
                itemRender={UploadImageItem}
                renderUploadButton={UploaderButton}
              />
            )}
          />
          <h4 className={styles.uploadTitle}>
            {t('NewBrokeragePage.GreyLogo.title')}
            <div className={styles.uploadSubtitle}>
              {t('NewBrokeragePage.GreyLogo.subtitle')}
              <Popover
                getPopupContainer={() =>
                  document.getElementById('root') as HTMLElement
                }
                placement="bottom"
                content={
                  <div className={styles.popoverContainer}>
                    {t('NewBrokeragePage.GreyLogo.popover')}
                  </div>
                }
              >
                <i
                  className={cx(
                    styles.popoverOpener,
                    styles.popoverOpenerSm,
                    'icon icon-info',
                  )}
                ></i>
              </Popover>
            </div>
          </h4>
        </Col>
        <Col xs={24} md={12} lg={6}>
          <FormItem
            name="favicon"
            control={control}
            className={cx(styles.uploadWrap, 'clear-shadow')}
            render={({ field, fieldState }) => (
              <FileUpload
                error={fieldState.error}
                field={field}
                bucket={MultimediaType.IMAGE}
                acceptedFileFormat={['.ico']}
                acceptedFileSize={50}
                listType="picture-card"
                itemRender={(...params) =>
                  UploadImageItem(...params, { isPreviewAvailable: false })
                }
                renderUploadButton={UploaderButton}
              />
            )}
          />
          <h4 className={styles.uploadTitle}>
            {t('NewBrokeragePage.Favicon.title')}
            <div className={styles.uploadSubtitle}>
              {t('NewBrokeragePage.Favicon.subtitle')}
              <Popover
                getPopupContainer={() =>
                  document.getElementById('root') as HTMLElement
                }
                placement="bottom"
                content={
                  <div className={styles.popoverContainer}>
                    <div>{t('NewBrokeragePage.Favicon.popover1')}</div>
                    <div>
                      {t('NewBrokeragePage.Favicon.popover2')}{' '}
                      <Button className="custom-link" type="link">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.icoconverter.com"
                        >
                          https://www.icoconverter.com
                        </a>
                      </Button>{' '}
                      {t('NewBrokeragePage.Favicon.popover3')}
                    </div>
                  </div>
                }
              >
                <i
                  className={cx(
                    styles.popoverOpener,
                    styles.popoverOpenerSm,
                    'icon icon-info',
                  )}
                ></i>
              </Popover>
            </div>
          </h4>
        </Col>
      </Row>

      <h3 className={styles.title}>Contact us</h3>
      <div className={styles.formWrapXs}>
        <FormItem
          name="office_phone_number"
          control={control}
          render={({ field }) => (
            <Input.Group compact className="input-group">
              <div className="prefix-label prefix-label--lg">
                {t('NewBrokeragePage.OfficePhoneNumber')}
              </div>
              <Input
                size="large"
                placeholder={t(
                  'NewBrokeragePage.OfficePhoneNumber.placeholder',
                )}
                {...field}
              />
            </Input.Group>
          )}
        />
        <FormItem
          name="office_email"
          control={control}
          render={({ field }) => (
            <Input.Group compact className="input-group">
              <div className="prefix-label prefix-label--lg">
                {t('NewBrokeragePage.OfficeEmail')}
              </div>
              <Input
                size="large"
                placeholder={t('NewBrokeragePage.OfficeEmail.placeholder')}
                {...field}
              />
            </Input.Group>
          )}
        />
      </div>
      <div className={styles.formWrapLg}>
        <FormItem
          name="office_address"
          control={control}
          render={({ field }) => (
            <Input.Group compact className="input-group">
              <div className="prefix-label prefix-label--lg">
                {t('NewBrokeragePage.OfficeAddress')}
              </div>
              <Input
                size="large"
                placeholder={t('NewBrokeragePage.OfficeAddress.placeholder')}
                {...field}
              />
            </Input.Group>
          )}
        />
        <Row gutter={10}>
          <Col xs={24} md={14}>
            <FormItem
              name="city"
              control={control}
              render={({ field }) => (
                <Input
                  size="large"
                  placeholder={t('NewBrokeragePage.City.placeholder')}
                  {...field}
                />
              )}
            />
          </Col>
          <Col xs={24} md={5}>
            <FormItem
              name="state"
              control={control}
              render={({ field }) => (
                <Input
                  size="large"
                  placeholder={t('NewBrokeragePage.State.placeholder')}
                  {...field}
                />
              )}
            />
          </Col>
          <Col xs={24} md={5}>
            <FormItem
              name="zip"
              control={control}
              render={({ field }) => (
                <Input
                  size="large"
                  placeholder={t('NewBrokeragePage.Zip.placeholder')}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
        <FormItem
          name="linkedin_profile"
          control={control}
          render={({ field }) => (
            <Input.Group compact className="input-group">
              <div className="prefix-label prefix-label--lg">
                {t('NewBrokeragePage.LinkedInProfile')}
              </div>
              <Input
                size="large"
                placeholder={t('NewBrokeragePage.LinkedinProfile.placeholder')}
                {...field}
              />
            </Input.Group>
          )}
        />
        <FormItem
          name="facebook_profile"
          control={control}
          render={({ field }) => (
            <Input.Group compact className="input-group">
              <div className="prefix-label prefix-label--lg">
                {t('NewBrokeragePage.FacebookProfile')}
              </div>
              <Input
                size="large"
                placeholder={t('NewBrokeragePage.FacebookProfile.placeholder')}
                {...field}
              />
            </Input.Group>
          )}
        />
      </div>
      <h3 className={styles.title}>{t('NewBrokeragePage.DRE.title')}</h3>
      <FormItem
        className={styles.formWrapXs}
        name="dre"
        control={control}
        render={({ field }) => (
          <Input.Group compact className="input-group">
            <div className="prefix-label prefix-label--lg">
              {t('NewBrokeragePage.DRE.label')}
            </div>
            <Input
              size="large"
              placeholder={t('NewBrokeragePage.DRE.placeholder')}
              {...field}
            />
          </Input.Group>
        )}
      />
      <div className={styles.btnWrap}>
        <Button
          type="primary"
          size="large"
          className="with-icon btn-secondary"
          onClick={onBack}
        >
          <i className="icon icon-arrow-left"></i> {t('NewBrokeragePage.Back')}
        </Button>
        <Button
          type="primary"
          size="large"
          className="with-icon"
          onClick={handleSubmit(onNext)}
        >
          {t('NewBrokeragePage.Next')} <i className="icon icon-arrow-right"></i>
        </Button>
      </div>
    </div>
  );
}
