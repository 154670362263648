/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ApprovalUserStatus {
    BUYER_WITHOUT_AGENT = 'BuyerWithoutAgent',
    BUYER_WITH_AGENT = 'BuyerWithAgent',
    AGENT = 'Agent',
}
