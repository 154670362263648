import { useEffect, useState } from 'react';

import { Button, Col, Row } from 'antd';
import { displayErrorMessage } from 'components/message';
import Pagination from 'components/Pagination';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AdminBrokerageResponse, ListingApi } from 'services/api';

import SidebarLayout from '../SidebarLayout';
import styles from './BrokeragePage.module.less';
import BrokerageTable from './BrokerageTable';
import { scrollTop } from 'utils/scrollTop.utils';

const pageSizeOptions = [24, 48, 72];
const DefaultPageSize = pageSizeOptions[0];

export default function BrokeragePage() {
  const [isBrokeragesLoading, setIsBrokeragesLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPageSize);
  const [brokeragesData, setBrokeragesData] =
    useState<AdminBrokerageResponse>();
  const { t } = useTranslation();

  useEffect(() => {
    const loadBrokerages = async () => {
      setIsBrokeragesLoading(true);

      try {
        const result = await ListingApi.getBrokeragesList({
          page,
          pageSize,
        });
        setBrokeragesData(result);
      } catch (error) {
        displayErrorMessage({ error });
      } finally {
        setIsBrokeragesLoading(false);
      }
    };

    loadBrokerages();
  }, [page, pageSize]);

  const handlePaginationChange = (newPage: number, newPageSize: number) => {
    if (isBrokeragesLoading) return;

    setPage(newPage);
    setPageSize(newPageSize);
    scrollTop();
  };

  return (
    <SidebarLayout>
      <div className="paper">
        <Row
          justify="space-between"
          align="middle"
          gutter={16}
          className={styles.titleBox}
        >
          <Col>
            <h3 className="h4">
              {t('Dashboard.BrokeragesManagement.Brokerages')}
            </h3>
          </Col>
          <Col>
            <Link to="/brokerages/new-brokerage">
              <Button type="primary" size="large" className="btn-secondary">
                {t('Dashboard.BrokeragesManagement.NewBrokerage')}
              </Button>
            </Link>
          </Col>
        </Row>
        <div>
          <BrokerageTable
            data={brokeragesData?.results}
            isLoading={isBrokeragesLoading}
          />

          {!!brokeragesData?.total &&
            brokeragesData.total > DefaultPageSize && (
              <Pagination
                current={page}
                pageSize={pageSize}
                showSizeChanger
                onChange={handlePaginationChange}
                total={brokeragesData.total}
                pageSizeOptions={pageSizeOptions}
              />
            )}
        </div>
      </div>
    </SidebarLayout>
  );
}
