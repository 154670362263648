/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ExternalUserRole {
    BUYER = 'buyer',
    SUPER_ADMIN = 'super_admin',
    LISTING_AGENT = 'listing_agent',
    ANONYMOUS = 'anonymous',
    BROKERAGE_ADMIN = 'brokerage_admin',
}
