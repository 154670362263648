import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'services/store';

const initialState: any = {
  slice: null,
};

export const slices = createSlice({
  name: 'slice',
  initialState,
  reducers: {
    update: (state) => state,
  },
});

export default slices.reducer;

export const { update } = slices.actions;

export const select = (state: RootState) => state;
