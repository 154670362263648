import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import HowToBuyFAQCollapse from './HowToBuyFAQCollapse';

export default function HowToBuyFAQSection() {
  const { t } = useTranslation();

  return (
    <Row gutter={20}>
      <Col xs={24} md={9} lg={{ span: 6, offset: 2 }}>
        <h2 className="h2">{t('HowToBuyFAQSection.FAQ')}</h2>
      </Col>
      <Col xs={24} md={15} lg={14}>
        <HowToBuyFAQCollapse />
      </Col>
    </Row>
  );
}
