/* eslint-disable react/jsx-pascal-case */
import { Layout as $Layout } from 'antd';

import Router from 'Router';
import Footer from '../Footer';
import Header from '../Header';

import ConsentPopUp from 'components/ConsentPopUp';
import AdminSetPasswordModal from 'features/auth/AdminSetPasswordModal';

const { Content } = $Layout;

export default function Layout() {
  return (
    <$Layout>
      <Header />
      <Content>
        <ConsentPopUp />
        <AdminSetPasswordModal />
        <Router />
      </Content>
      <Footer />
    </$Layout>
  );
}
