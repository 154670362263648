/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ListingStatus {
    DRAFT = 'Draft',
    SOLD = 'Sold',
    ACTIVE = 'Active',
    ENDED = 'Ended',
    COMING_SOON = 'ComingSoon',
    WITHDRAWN = 'Withdrawn',
    PENDING = 'Pending',
}
