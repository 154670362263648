import styles from './Header.module.less';
/* eslint-disable react/jsx-pascal-case */

import { useTranslation } from 'react-i18next';
import { NavLink, useMatch } from 'react-router-dom';
import { Button, Dropdown, Drawer } from 'antd';
import cx from 'classnames';
import { useState } from 'react';
import Accessor from 'components/Accessor';
import { useAppSettings } from 'components/AppSettingsProvider';
import { useAuth } from 'components/AuthProvider';
import { getImageUrl, ImageSize } from 'utils/image.utils';
import { ExternalUserRole } from 'services/api';
import logoColor from 'assets/images/logo-color.svg';
import logoImg from 'assets/images/logo-white.svg';
import { HeaderProps } from './Header';

export const HeaderMobile = (props: HeaderProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const { onSignInClick, onLogoutClick } = props;
  const { settings } = useAppSettings();
  const { color_logo, white_logo } = settings || {};
  const { t } = useTranslation();
  const { user } = useAuth();

  const isHomePage = useMatch('/');

  return (
    <>
      <nav>
        <ul className={styles.headerNav}>
          {!!color_logo && (
            <>
              <li>
                <NavLink to="/" data-test-id="header-logo">
                  <div className={styles.headerSubLogo}>
                    <img
                      src={getImageUrl(
                        isHomePage && !!white_logo ? white_logo : color_logo,
                        ImageSize.SMALL,
                      )}
                      alt="logo"
                    />
                  </div>
                </NavLink>
                <span className={styles.headerLogoText}>
                  {t('headerMobile.PoweredBy')}{' '}
                  <b>{t('headerMobile.BidThisProperty')}</b>
                </span>
              </li>
            </>
          )}
          {!color_logo && (
            <NavLink to="/" data-test-id="header-logo">
              <div className={styles.headerLogo}>
                <strong
                  data-test-id="header-logo-brokerage"
                  className={styles.headerLogo}
                >
                  <img src={isHomePage ? logoImg : logoColor} alt="logo" />
                </strong>
              </div>
            </NavLink>
          )}
        </ul>
      </nav>
      <div className={styles.rightSection}>
        {!user && (
          <Button
            type="link"
            data-test-id="join-button"
            size="small"
            className={styles.headerLogin}
            onClick={onSignInClick}
            icon={<i className="icon icon-login"></i>}
          />
        )}
        {!!user && (
          <Dropdown
            overlayClassName={styles.headerDropdownMob}
            trigger={['click']}
            overlay={
              <ul className="drop">
                <li>
                  <NavLink to="/my-profile" className="drop-item">
                    <>
                      <i className="icon icon-user drop-item__icon" />
                      {t('header.MyProfile')}
                    </>
                  </NavLink>
                </li>
                <li>
                  <div
                    onClick={onLogoutClick}
                    className={cx(styles.headerLogout, 'drop-item')}
                  >
                    <i className="icon icon-logout drop-item__icon" />
                    {t('header.SignOut')}
                  </div>
                </li>
              </ul>
            }
          >
            <div className={styles.headerProfileMob}>
              <span>
                {user.given_name[0]}
                {user.family_name[0]}
              </span>
            </div>
          </Dropdown>
        )}
      </div>
      <Button
        type="link"
        data-test-id="burger-button"
        size="small"
        className={styles.headerLogin}
        onClick={showDrawer}
      >
        <i className="icon icon-burger" />
      </Button>
      <Drawer
        placement="right"
        closable={false}
        visible={isDrawerOpen}
        width="100vw"
        className={styles.headerMenuMob}
      >
        <Button
          type="link"
          data-test-id="Close-button"
          size="small"
          className={styles.headerMenuClose}
          onClick={closeDrawer}
        >
          <i className="icon icon-close" />
        </Button>
        <ul className={styles.headerNavMob}>
          <Accessor
            roles={[
              ExternalUserRole.SUPER_ADMIN,
              ExternalUserRole.LISTING_AGENT,
              ExternalUserRole.BROKERAGE_ADMIN,
            ]}
          >
            <li>
              <span onClick={closeDrawer}>
                <NavLink
                  className={styles.headerNavLink}
                  data-test-id="dashbord-link"
                  to="/listings-management"
                  end
                >
                  <>
                    <i className="icon icon-dashboard" />
                    {t('header.Dashboard')}
                  </>
                </NavLink>
              </span>
            </li>
          </Accessor>
          <li>
            <span onClick={closeDrawer}>
              <NavLink className={styles.headerNavLink} to="/listings" end>
                <>
                  <i className="icon icon-list" /> {t('header.Listings')}
                </>
              </NavLink>
            </span>
          </li>
          <li>
            <span onClick={closeDrawer}>
              <NavLink
                data-test-id="about-us-link"
                className={styles.headerNavLink}
                to="/about-us"
              >
                <>
                  <i className="icon icon-heart" /> {t('header.AboutUs')}
                </>
              </NavLink>
            </span>
          </li>
          <li>
            <span onClick={closeDrawer}>
              <NavLink
                data-test-id="how-to-buy-link"
                className={styles.headerNavLink}
                to="/how-to-buy"
              >
                <>
                  <i className="icon icon-info" /> {t('header.HowToBuy')}
                </>
              </NavLink>
            </span>
          </li>
          <li>
            <span onClick={closeDrawer}>
              <NavLink
                data-test-id="sellers-link"
                className={styles.headerNavLink}
                to="/sellers"
              >
                <>
                  <i className="icon icon-coffee" /> {t('header.Sellers')}
                </>
              </NavLink>
            </span>
          </li>
          <li>
            <span onClick={closeDrawer}>
              <NavLink className={styles.headerNavLink} to="/certified-agents">
                <>
                  <i className="icon icon-users" />
                  {t('header.certifiedAgents')}
                </>
              </NavLink>
            </span>
          </li>
          <li>
            <span onClick={closeDrawer}>
              <NavLink className={styles.headerNavLink} to="/faq">
                <>
                  <i className="icon icon-circle-question" />
                  {t('header.FAQ')}
                </>
              </NavLink>
            </span>
          </li>
        </ul>
      </Drawer>
    </>
  );
};
