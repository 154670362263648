import React, { useEffect, useMemo } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useSearchParams } from 'react-router-dom';
import { TFunction, useTranslation } from 'react-i18next';
import { findIndex } from 'lodash';

import { ExternalListingOrderBy } from 'services/api';

type SortingType = {
  label: string;
  value: ExternalListingOrderBy;
};

const getDefaultOption = (t: TFunction): SortingType => ({
  label: t('ListingsSorting.MostPopular'),
  value: ExternalListingOrderBy._VIEWS,
});

const getSortingOptions = (t: TFunction): SortingType[] => [
  getDefaultOption(t),
  {
    label: t('ListingsSorting.BiddingEndingSoon'),
    value: ExternalListingOrderBy.ENDS_AT,
  },
];

type Props = {
  onChange: (value: ExternalListingOrderBy) => void;
};

export const SortingUrlKey = 's';

export default function ListingsSorting(props: Props) {
  const { onChange } = props;
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderBy = searchParams.get(SortingUrlKey);
  const sortingOptions = useMemo(() => getSortingOptions(t), [t]);
  const selectedOption =
    sortingOptions.find(({ value }) => value === orderBy) ||
    getDefaultOption(t);

  useEffect(() => {
    onChange(selectedOption.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy]);

  const getDropdownOptions = () => {
    const selectedOptionIndex = findIndex(sortingOptions, selectedOption);
    const items: ItemType[] = sortingOptions.map((option, index) => ({
      key: `sorting-option-${index}`,
      option,
      label: option.label,
      onClick: () => {
        const params = Object.fromEntries(searchParams);

        params[SortingUrlKey] = option.value;

        setSearchParams(params, {
          replace: true,
        });
      },
    }));

    // insert dividers
    items.splice(1, 0, {
      type: 'divider',
    });

    return (
      <Menu
        selectable
        defaultSelectedKeys={[`sorting-option-${selectedOptionIndex}`]}
        items={items}
      />
    );
  };

  return (
    <>
      <Dropdown
        overlay={getDropdownOptions}
        trigger={['click']}
        placement="bottomRight"
      >
        <Button
          size="small"
          type="link"
          className="with-icon"
          icon={<i className="icon icon-sorting-alt"></i>}
        >
          {selectedOption.label}
        </Button>
      </Dropdown>
    </>
  );
}
