import { useState } from 'react';

import { ExternalListingList } from 'services/api';
import GetApprovedDataForm from './GetApprovedDataForm';
import GetApprovedSuccessStep from './GetApprovedSuccessStep';

type Props = {
  listing: ExternalListingList;
  initialSuccessStep: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export default function GetApprovedForm(props: Props) {
  const { listing, initialSuccessStep, onClose, onSuccess } = props;
  const [isSuccessStep, setIsSuccessStep] = useState(initialSuccessStep);

  return !isSuccessStep ? (
    <GetApprovedDataForm
      listing={listing}
      onClose={onClose}
      onSuccess={() => setIsSuccessStep(true)}
    />
  ) : (
    <GetApprovedSuccessStep
      listing={listing}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );
}
