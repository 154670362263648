import { TFunction } from 'react-i18next';
import { ExternalUserRole } from 'services/api';

export const getUserRoleName = (
  role: ExternalUserRole | undefined,
  t: TFunction,
) => {
  switch (role) {
    case ExternalUserRole.LISTING_AGENT:
      return t('getUserRoleName.Agent');
    case ExternalUserRole.BROKERAGE_ADMIN:
      return t('getUserRoleName.SiteAdministrator');
    default:
      return '';
  }
};
