/* eslint-disable react/jsx-pascal-case */
import { useState } from 'react';
import { useMatch } from 'react-router-dom';
import { Layout } from 'antd';
import cx from 'classnames';
import ForgotPasswordModal from 'features/auth/ForgotPasswordModal';
import SignInModal from 'features/auth/SignInModal';
import SignUpConfirmationModal from 'features/auth/SignUpConfirmationModal';
import SignUpModal from 'features/auth/SignUpModal';
import { useMediaMatch } from 'rooks';
import { MobileScreenQuery } from 'utils/mediaQuery.utils';

import styles from './Header.module.less';
import { HeaderDesktop } from './HeaderDesktop';
import { HeaderMobile } from './HeaderMobile';
import { useAuth } from 'components/AuthProvider';
export interface HeaderProps {
  onSignInClick: () => void;
  onLogoutClick: () => void;
}
const { Header: $Header } = Layout;

export default function Header() {
  const isMobileScreen = useMediaMatch(MobileScreenQuery);
  const [isSignInModalVisible, setIsSignInModalVisible] = useState(false);
  const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] =
    useState(false);

  const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [signUpEmail, setSignUpEmail] = useState('');
  const isHomePage = useMatch('/');
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch {}
  };
  return (
    <>
      {isForgotPasswordModalVisible && (
        <ForgotPasswordModal
          onClose={() => {
            setIsSignInModalVisible(true);
            setIsForgotPasswordModalVisible(false);
          }}
        />
      )}
      {isSignInModalVisible && (
        <SignInModal
          onForgotPassword={() => {
            setIsForgotPasswordModalVisible(true);
            setIsSignInModalVisible(false);
          }}
          onUnconfirmed={(email) => {
            setSignUpEmail(email);
            setIsSignInModalVisible(false);
            setIsConfirmationModalVisible(true);
          }}
          onClose={() => setIsSignInModalVisible(false)}
          onRegisterClick={() => {
            setIsSignInModalVisible(false);
            setIsSignUpModalVisible(true);
          }}
        />
      )}
      {isSignUpModalVisible && (
        <SignUpModal
          onClose={() => setIsSignUpModalVisible(false)}
          onSignInClick={() => {
            setIsSignUpModalVisible(false);
            setIsSignInModalVisible(true);
          }}
          onSuccess={(email) => {
            setSignUpEmail(email);

            setIsSignUpModalVisible(false);
            setIsConfirmationModalVisible(true);
          }}
        />
      )}
      {isConfirmationModalVisible && (
        <SignUpConfirmationModal
          email={signUpEmail}
          onClose={() => setIsConfirmationModalVisible(false)}
        />
      )}
      <$Header
        className={cx(styles.header, {
          [styles.homeHeader]: isHomePage,
        })}
      >
        {isMobileScreen ? (
          <HeaderMobile
            onLogoutClick={handleLogout}
            onSignInClick={() => setIsSignInModalVisible(true)}
          />
        ) : (
          <HeaderDesktop
            onLogoutClick={handleLogout}
            onSignInClick={() => setIsSignInModalVisible(true)}
          />
        )}
      </$Header>
    </>
  );
}
