import { useMemo, useRef, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import cx from 'classnames';
import moment, { Moment } from 'moment';
import { ControllerRenderProps, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isNumber } from 'lodash';

import { useAuth } from 'components/AuthProvider';
import FileUpload from 'components/form/FileUpload';
import UploadImageItem from 'components/form/FileUpload/UploadImageItem';
import FormItem from 'components/form/FormItem';
import message, { displayErrorMessage } from 'components/message';
import {
  AdminListingCreate,
  AdminListingDetail,
  AdminListingUpdate,
  Currency,
  ExternalUserRole,
  IncrementType,
  ListingApi,
  ListingStatus,
  ListingType,
  MultimediaType,
  PremiumType,
} from 'services/api';
import TextEditor from 'components/form/TextEditor/TextEditor';
import { UploadFileS3, ValidateFiles } from 'utils/file.utils';
import { getDisabledDates } from 'utils/listing.utils';
import { getRoundHalf, numberFormatter } from 'utils/number.utils';
import {
  onlyLettersRegExp,
  onlyNumbersRegExp,
  ValidateFormatter,
  ValidateVideoURL,
} from 'utils/validation.utils';
import {
  bidIncrementOptions,
  buyersPremiumOptions,
  getStatusOptions,
  getTypeOptions,
} from './newListingPage.utils';
import PendingPriceModal from './PendingPriceModal';
import SoldPriceModal from './SoldPriceModal';
import VideoTutorial from './VideoTutorial';
import WithdrawnModal from './WithdrawnModal';

import styles from './NewListingPage.module.less';
import { useAppSettings } from 'components/AppSettingsProvider';
import { getCurrencySign } from 'utils/price.utils';

const { Option } = Select;

interface IFormValues {
  title: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  endDate: any; // not "Moment | null" due to https://github.com/react-hook-form/resolvers/issues/258
  startingBid: number | null;
  buyersPremium: PremiumType;
  bidIncrement: IncrementType;
  type?: ListingType;
  built: number | null;
  houseArea: number | null;
  lotArea: number | null;
  beds: number | null;
  baths: number | null;
  carSpaces: number | null;

  subtitle: string;
  description: string;
  photos: UploadFileS3[];
  status: ListingStatus;
  video1: string;
  video2: string;
}

enum SubmitModeEnum {
  Draft,
  Submit,
}

const getValidationSchema = (t: TFunction, submitMode: SubmitModeEnum) => {
  let schema = {
    title: yup.string().max(
      61,
      t('validation.maxLength', {
        count: 61,
      }),
    ),
    address: yup
      .string()
      .max(
        40,
        t('validation.maxLength', {
          count: 40,
        }),
      )
      .required(t('validation.required')),
    city: yup
      .string()
      .max(
        25,
        t('validation.maxLength', {
          count: 25,
        }),
      )
      .required(t('validation.required')),
    state: yup
      .string()
      .required(t('validation.required'))
      .max(2, t('validation.format'))
      .matches(onlyLettersRegExp, t('validation.format')),
    zip: yup
      .string()
      .required(t('validation.required'))
      .max(6, t('validation.format'))
      .matches(onlyNumbersRegExp, t('validation.format')),
    endDate: yup
      .date()
      .test('DateTest', t('validation.TimeIsUp'), (val, type) => {
        if (val && type.parent.status === ListingStatus.ACTIVE)
          return val > new Date();
        return true;
      })
      .nullable(),
    startingBid: yup
      .number()
      .nullable()
      .min(0, t('validation.format'))
      .max(999999999, t('validation.format')),
    buyersPremium: yup.number(),
    bidIncrement: yup.number(),
    type: yup.string(),
    built: yup
      .number()
      .nullable()
      .min(1000, t('validation.format'))
      .max(9999, t('validation.format')),
    houseArea: yup
      .number()
      .nullable()
      .min(0, t('validation.format'))
      .max(999999, t('validation.format')),
    lotArea: yup
      .number()
      .nullable()
      .min(0, t('validation.format'))
      .max(999999, t('validation.format')),
    beds: yup
      .number()
      .nullable()
      .min(0, t('validation.format'))
      .max(999, t('validation.format')),
    baths: yup
      .number()
      .nullable()
      .min(0, t('validation.format'))
      .max(999.5, t('validation.format')),
    carSpaces: yup
      .number()
      .nullable()
      .min(0, t('validation.format'))
      .max(999, t('validation.format')),
    subtitle: yup.string().max(
      90,
      t('validation.maxLength', {
        count: 90,
      }),
    ),
    description: yup.string().max(
      3200,
      t('validation.maxLength', {
        count: 3200,
      }),
    ),
    photos: yup.array().max(30).test('ValidateFiles', '', ValidateFiles),
    status: yup.string(),
    video1: yup
      .string()
      .test(
        'videoTest',
        t('NewListingPage.validation.VideoURL'),
        ValidateVideoURL,
      ),
    video2: yup
      .string()
      .test(
        'videoTest',
        t('NewListingPage.validation.VideoURL'),
        ValidateVideoURL,
      ),
  };

  if (submitMode === SubmitModeEnum.Submit) {
    schema.title = schema.title.required(t('validation.required'));
    schema.endDate = schema.endDate.required(t('validation.required'));
    schema.startingBid = schema.startingBid.required(t('validation.required'));
    schema.buyersPremium = schema.buyersPremium.required(
      t('validation.required'),
    );
    schema.bidIncrement = schema.bidIncrement.required(
      t('validation.required'),
    );
    schema.type = schema.type.required(t('validation.required'));
    schema.houseArea = schema.houseArea.required(t('validation.required'));
    schema.beds = schema.beds.required(t('validation.required'));
    schema.baths = schema.baths.required(t('validation.required'));
    schema.carSpaces = schema.carSpaces.required(t('validation.required'));
    schema.description = schema.description
      .test('validateDescription', t('validation.required'), ValidateFormatter)
      .required(t('validation.required'));
    schema.photos = schema.photos.min(
      4,
      t('NewListingPage.validation.AddMorePhotos'),
    );
    schema.status = schema.status.required(t('validation.required'));
  }

  return yup.object().shape(schema);
};

export enum NewListingPageModeEnum {
  Add,
  Edit,
}

type Props = {
  mode?: NewListingPageModeEnum; // default to Add
  defaultValues?: IFormValues; // required for Edit mode
  listing?: AdminListingDetail; // required for Edit mode
};

export default function NewListingPage(props: Props) {
  const { t } = useTranslation();
  const { settings } = useAppSettings();
  const { units, currency } = settings || {};
  const navigate = useNavigate();
  const { user } = useAuth();
  const {
    mode = NewListingPageModeEnum.Add,
    defaultValues = {
      title: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      endDate: null,
      startingBid: null,
      buyersPremium: PremiumType._3,
      bidIncrement: IncrementType._1000,
      type: undefined,
      built: null,
      houseArea: null,
      lotArea: null,
      beds: null,
      baths: null,
      carSpaces: null,
      subtitle: '',
      description: '',
      photos: [],
      status: ListingStatus.ACTIVE,
      video1: '',
      video2: '',
    },
    listing,
  } = props;
  const isEditMode = mode === NewListingPageModeEnum.Edit;
  const submitModeRef = useRef(SubmitModeEnum.Submit);
  const {
    control,
    handleSubmit,
    setValue: setFormValue,
    formState: { isSubmitting },
  } = useForm<IFormValues>({
    mode: 'onTouched',
    resolver: async (data, context, options) => {
      const validationSchema = getValidationSchema(t, submitModeRef.current);
      const validationResult = await yupResolver(validationSchema)(
        data,
        context,
        options,
      );
      submitModeRef.current = SubmitModeEnum.Submit;
      return validationResult;
    },
    defaultValues,
  });
  const isPublishedListing =
    listing?.status === ListingStatus.ACTIVE ||
    listing?.status === ListingStatus.ENDED ||
    listing?.status === ListingStatus.PENDING ||
    listing?.status === ListingStatus.WITHDRAWN ||
    listing?.status === ListingStatus.SOLD;
  const [isDraftHandling, setIsDraftHandling] = useState(false);
  const [isPendingPriceModalVisible, setIsPendingPriceModalVisible] =
    useState(false);
  const [isSoldPriceModalVisible, setIsSoldPriceModalVisible] = useState(false);
  const [isWithdrawnModalModalVisible, setIsWithdrawnModalVisible] =
    useState(false);
  const statusOptions = useMemo(
    () => getStatusOptions(t, listing, user),
    [t, listing, user],
  );
  const typeOptions = useMemo(() => getTypeOptions(t), [t]);

  const getFormattedData = (values: IFormValues) => {
    const property_pictures = values.photos.map(
      (photo) => photo.response?.s3Key as string,
    );
    const ends_at = values.endDate
      ? moment(values.endDate)
          .set({
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .toISOString()
      : undefined;

    return {
      title: values.title || undefined,
      initial_price: isNumber(values.startingBid)
        ? {
            value: values.startingBid,
            currency: currency || Currency.USD,
          }
        : undefined,
      ends_at,
      status: values.status, // Draft should override this value
      bedrooms: isNumber(values.beds) ? values.beds : undefined,
      bathrooms: isNumber(values.baths) ? values.baths : undefined,
      car_spaces: isNumber(values.carSpaces) ? values.carSpaces : undefined,
      house_area: isNumber(values.houseArea) ? values.houseArea : undefined,
      address: {
        address: values.address,
        state_abbreviation: values.state,
        city: values.city,
        postcode: values.zip,
      },
      bid_increment: values.bidIncrement,
      buyers_premium: values.buyersPremium,
      property_type: values.type,
      building_year: isNumber(values.built) ? values.built : undefined,
      lot_area: isNumber(values.lotArea) ? values.lotArea : undefined,
      subtitle: values.subtitle || undefined,
      description: values.description || undefined,
      property_pictures,
      videos: [values.video1, values.video2].filter(Boolean),
      units: units || 'sqft',
    };
  };

  const getCreateData = (values: IFormValues): AdminListingCreate => {
    return { ...getFormattedData(values), user_id: user?.user_id || '' };
  };

  const getEditData = (
    values: IFormValues,
    pendingPriceFormValue?: number,
    soldPriceFormValue?: number,
  ): AdminListingUpdate => {
    return {
      ...getFormattedData(values),
      pending_price: isNumber(pendingPriceFormValue)
        ? {
            value: pendingPriceFormValue,
            currency: listing?.initial_price!.currency,
          }
        : listing?.pending_price,
      sold_price: isNumber(soldPriceFormValue)
        ? {
            value: soldPriceFormValue,
            currency: listing?.initial_price!.currency,
          }
        : listing?.sold_price,
    };
  };

  const onSubmit = async (values: IFormValues) => {
    const currentStatus = listing?.status;

    if (values.status === ListingStatus.PENDING) {
      if (
        (currentStatus === ListingStatus.SOLD ||
          currentStatus === ListingStatus.WITHDRAWN) &&
        user?.role !== ExternalUserRole.SUPER_ADMIN
      ) {
        const error = new Error(t('NewListingPage.status.restriction'));
        displayErrorMessage({ error });
        return;
      }
      setIsPendingPriceModalVisible(true);
      return;
    }

    if (values.status === ListingStatus.SOLD) {
      if (
        currentStatus === ListingStatus.WITHDRAWN &&
        user?.role !== ExternalUserRole.SUPER_ADMIN
      ) {
        const error = new Error(t('NewListingPage.status.restriction'));

        displayErrorMessage({ error });
        return;
      }
      setIsSoldPriceModalVisible(true);
      return;
    }

    if (
      currentStatus !== ListingStatus.WITHDRAWN &&
      values.status === ListingStatus.WITHDRAWN
    ) {
      setIsWithdrawnModalVisible(true);
      return;
    }

    await sendRequest(values);
  };

  const sendRequest = async (
    values: IFormValues,
    pendingPrice?: number,
    soldPrice?: number,
  ) => {
    try {
      if (isEditMode) {
        const editData = getEditData(values, pendingPrice, soldPrice);
        await ListingApi.updateListing(listing?.id as string, editData);

        if (isPublishedListing) {
          message.success({
            content: t('NewListingPage.ChangesSavedSuccessfully'),
          });
          navigate('/listings-management');
        } else {
          navigate(`/listings/${listing?.id}`);
        }
      } else {
        const createData = getCreateData(values);
        const result = await ListingApi.createListing(createData);
        navigate(`/listings/${result.id}`);
      }
    } catch (error: any) {
      displayErrorMessage({ error });
    }
  };

  const onDraft = async (values: IFormValues) => {
    try {
      setIsDraftHandling(true);

      if (isEditMode) {
        const editData = getEditData(values);
        editData.status = ListingStatus.DRAFT; // should be always DRAFT
        await ListingApi.updateListing(listing?.id as string, editData);
      } else {
        const createData = getCreateData(values);
        createData.status = ListingStatus.DRAFT; // should be always DRAFT
        await ListingApi.createListing(createData);
      }

      message.success({
        content: t('NewListingPage.ChangesSavedSuccessfully'),
      });
      navigate('/listings-management');
    } catch (error) {
      displayErrorMessage({ error });
    } finally {
      setIsDraftHandling(false);
    }
  };

  const handleDraft = async () => {
    submitModeRef.current = SubmitModeEnum.Draft;
    handleSubmit(onDraft)();
  };

  const handleDateChange = (
    date: Moment | null,
    field: ControllerRenderProps,
  ) => {
    if (isEditMode && listing?.status === ListingStatus.ENDED) {
      setFormValue('status', ListingStatus.ACTIVE);
    }

    field.onChange(date);

    if (date === null) {
      // handle click on the 'Clear' button
      field.onBlur();
    }
  };

  const onPendingPriceModalSuccess = (pendingPriceFormValue: number) => {
    setIsPendingPriceModalVisible(false);
    handleSubmit(async (values) => {
      await sendRequest(values, pendingPriceFormValue);
    })();
  };

  const onSoldPriceModalSuccess = (soldPriceFormValue: number) => {
    setIsSoldPriceModalVisible(false);
    handleSubmit(async (values) => {
      await sendRequest(values, undefined, soldPriceFormValue);
    })();
  };

  const onWithdrawnModalSuccess = () => {
    setIsWithdrawnModalVisible(false);
    handleSubmit(async (values) => {
      await sendRequest(values);
    })();
  };

  return (
    <div className="layout-wrap">
      <div className={styles.wrap}>
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <div className="container">
            <div className={styles.backBtn}>
              <Link to="/listings-management">
                <Button
                  className="btn-icon"
                  size="small"
                  icon={<i className="icon icon-arrow-left"></i>}
                ></Button>
              </Link>
            </div>
            <div>
              <Row gutter={[32, 16]} align="middle">
                <Col xs={24} sm={12} md={16} xl={{ span: 14, offset: 2 }}>
                  <h1 className="h4">
                    {!isEditMode
                      ? t('NewListingPage.NewListing')
                      : listing?.title}
                  </h1>
                </Col>
                <Col xs={24} sm={12} md={8} xl={6}>
                  <div className="btn-group">
                    <div>
                      <Button
                        loading={isDraftHandling}
                        disabled={isPublishedListing || isSubmitting}
                        type="primary"
                        className="btn-secondary"
                        onClick={handleDraft}
                        size="large"
                        block
                      >
                        {t('NewListingPage.SaveDraft')}
                      </Button>
                    </div>
                    <div>
                      {isPendingPriceModalVisible && (
                        <PendingPriceModal
                          listing={listing!}
                          onClose={() => setIsPendingPriceModalVisible(false)}
                          onSuccess={onPendingPriceModalSuccess}
                        />
                      )}
                      {isSoldPriceModalVisible && (
                        <SoldPriceModal
                          listing={listing!}
                          onClose={() => setIsSoldPriceModalVisible(false)}
                          onSuccess={onSoldPriceModalSuccess}
                        />
                      )}
                      {isWithdrawnModalModalVisible && (
                        <WithdrawnModal
                          onClose={() => setIsWithdrawnModalVisible(false)}
                          onSuccess={onWithdrawnModalSuccess}
                        />
                      )}
                      <Button
                        loading={isSubmitting && !isDraftHandling}
                        disabled={isSubmitting}
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                      >
                        {isPublishedListing
                          ? t('NewListingPage.Update')
                          : t('NewListingPage.Publish')}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} xl={{ span: 20, offset: 2 }}>
                  <Divider className="divider-light" />
                </Col>
                <Col xs={24} md={{ span: 8, order: 1 }} xl={6}>
                  <div className="paper">
                    <h3 className={cx(styles.label, 'form-label')}>
                      {t('NewListingPage.PropertyStatus')}
                    </h3>
                    <div>
                      <FormItem
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <Select
                            size="large"
                            suffixIcon={
                              <i className="icon icon-caret-down"></i>
                            }
                            {...field}
                          >
                            {statusOptions.map((option) => (
                              <Option
                                key={`status-option-${option.value}`}
                                value={option.value}
                              >
                                <span
                                  className={styles.dot}
                                  style={{
                                    backgroundColor: option.color,
                                  }}
                                ></span>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        )}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={16} xl={{ span: 14, offset: 2 }}>
                  <div className="paper paper--lg">
                    <div>
                      <h3 className="form-label">
                        {t('NewListingPage.BasicParameters')}
                      </h3>
                      <Row gutter={16}>
                        <Col xs={24}>
                          <FormItem
                            name="title"
                            control={control}
                            render={({ field }) => (
                              <Input
                                size="large"
                                placeholder={t('NewListingPage.Title')}
                                {...field}
                              />
                            )}
                          />
                        </Col>
                        <Col xs={24}>
                          <FormItem
                            name="address"
                            control={control}
                            render={({ field }) => (
                              <Input
                                size="large"
                                placeholder={t('NewListingPage.TypeAddress')}
                                {...field}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <div className={styles.formIndent}>
                        <Row gutter={16}>
                          <Col xs={24} lg={12}>
                            <FormItem
                              name="city"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  size="large"
                                  placeholder={t('NewListingPage.City')}
                                  {...field}
                                />
                              )}
                            />
                          </Col>
                          <Col xs={12} lg={6}>
                            <FormItem
                              name="state"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  size="large"
                                  placeholder={t('NewListingPage.StateAbbr')}
                                  {...field}
                                />
                              )}
                            />
                          </Col>
                          <Col xs={12} lg={6}>
                            <FormItem
                              name="zip"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  size="large"
                                  placeholder={t('NewListingPage.ZIPcode')}
                                  {...field}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div>
                      <h3 className="form-label">
                        {t('NewListingPage.DetailedParameters')}
                      </h3>
                      <div className={styles.formIndent}>
                        <Row>
                          <Col xs={24} lg={18}>
                            <FormItem
                              name="endDate"
                              control={control}
                              render={({ field }) => (
                                <Input.Group compact className="input-group">
                                  <div className="prefix-label prefix-label--lg">
                                    {t('NewListingPage.EndDateTime')}
                                  </div>
                                  <DatePicker
                                    size="large"
                                    placeholder={t(
                                      'NewListingPage.SelectDateTime',
                                    )}
                                    format="MM/DD/YYYY — HH:00"
                                    showTime={{
                                      format: 'HH:00',
                                    }}
                                    suffixIcon={
                                      <i className="icon icon-calendar"></i>
                                    }
                                    disabledDate={getDisabledDates}
                                    showNow={false}
                                    {...field}
                                    onChange={(date) =>
                                      handleDateChange(date, field)
                                    }
                                  />
                                </Input.Group>
                              )}
                            />
                          </Col>
                          <Col xs={24} lg={18}>
                            <FormItem
                              name="startingBid"
                              control={control}
                              render={({ field }) => (
                                <Input.Group compact className="input-group">
                                  <div className="prefix-label prefix-label--lg">
                                    {t('NewListingPage.StartingBid')}
                                  </div>
                                  <InputNumber
                                    size="large"
                                    precision={0}
                                    controls={false}
                                    addonBefore={getCurrencySign(currency)}
                                    addonAfter={
                                      currency?.toUpperCase() || 'USD'
                                    }
                                    placeholder={t('NewListingPage.EnterPrice')}
                                    formatter={numberFormatter}
                                    {...field}
                                  />
                                </Input.Group>
                              )}
                            />
                          </Col>
                          <Col xs={24} lg={18}>
                            <FormItem
                              name="buyersPremium"
                              control={control}
                              render={({ field }) => (
                                <Input.Group compact className="input-group">
                                  <div className="prefix-label prefix-label--lg">
                                    {t('NewListingPage.BuyersPremium')}
                                  </div>
                                  <Select
                                    size="large"
                                    suffixIcon={
                                      <i className="icon icon-caret-down"></i>
                                    }
                                    {...field}
                                  >
                                    {buyersPremiumOptions.map((option) => (
                                      <Option
                                        key={`buyers-premium-option-${option.value}`}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </Option>
                                    ))}
                                  </Select>
                                </Input.Group>
                              )}
                            />
                          </Col>
                          <Col xs={24} lg={18}>
                            <FormItem
                              name="bidIncrement"
                              control={control}
                              render={({ field }) => (
                                <Input.Group compact className="input-group">
                                  <div className="prefix-label prefix-label--lg">
                                    {t('NewListingPage.BidIncrement')}
                                  </div>
                                  <Select
                                    size="large"
                                    suffixIcon={
                                      <i className="icon icon-caret-down"></i>
                                    }
                                    {...field}
                                  >
                                    {bidIncrementOptions.map((option) => (
                                      <Option
                                        key={`bid-increment-option-${option.value}`}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </Option>
                                    ))}
                                  </Select>
                                </Input.Group>
                              )}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="paper paper--lg">
                    <div>
                      <h3 className="form-label">
                        {t('NewListingPage.Details')}
                      </h3>
                      <div className={styles.formIndent}>
                        <Row gutter={16}>
                          <Col xs={24} lg={12}>
                            <FormItem
                              name="type"
                              control={control}
                              render={({ field }) => (
                                <Input.Group compact className="input-group">
                                  <div className="prefix-label prefix-label--lg">
                                    {t('NewListingPage.Type')}
                                  </div>
                                  <Select
                                    size="large"
                                    placeholder={t(
                                      'NewListingPage.SearchToSelect',
                                    )}
                                    showSearch
                                    optionFilterProp="children"
                                    suffixIcon={
                                      <i className="icon icon-caret-down"></i>
                                    }
                                    {...field}
                                  >
                                    {typeOptions.map((option) => (
                                      <Option
                                        key={`type-option-${option.value}`}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </Option>
                                    ))}
                                  </Select>
                                </Input.Group>
                              )}
                            />
                          </Col>
                          <Col xs={24} lg={12}>
                            <FormItem
                              name="built"
                              control={control}
                              render={({ field }) => (
                                <Input.Group compact className="input-group">
                                  <div className="prefix-label prefix-label--lg">
                                    {t('NewListingPage.Built')}
                                  </div>
                                  <InputNumber
                                    size="large"
                                    precision={0}
                                    controls={false}
                                    addonAfter={t('NewListingPage.YEAR')}
                                    placeholder="2010"
                                    maxLength={4}
                                    {...field}
                                  />
                                </Input.Group>
                              )}
                            />
                          </Col>
                          <Col xs={24} lg={12}>
                            <FormItem
                              name="houseArea"
                              control={control}
                              render={({ field }) => (
                                <Input.Group compact className="input-group">
                                  <div className="prefix-label prefix-label--lg">
                                    {t('NewListingPage.HouseArea')}
                                  </div>
                                  <InputNumber
                                    size="large"
                                    precision={0}
                                    controls={false}
                                    addonAfter={units?.toUpperCase() || 'SQFT'}
                                    placeholder="1,000"
                                    maxLength={7}
                                    formatter={numberFormatter}
                                    {...field}
                                  />
                                </Input.Group>
                              )}
                            />
                          </Col>
                          <Col xs={24} lg={12}>
                            <FormItem
                              name="lotArea"
                              control={control}
                              render={({ field }) => (
                                <Input.Group compact className="input-group">
                                  <div className="prefix-label prefix-label--lg">
                                    {t('NewListingPage.LotArea')}
                                  </div>
                                  <InputNumber
                                    size="large"
                                    precision={0}
                                    controls={false}
                                    addonAfter={units?.toUpperCase() || 'SQFT'}
                                    placeholder="1,000"
                                    maxLength={7}
                                    formatter={numberFormatter}
                                    {...field}
                                  />
                                </Input.Group>
                              )}
                            />
                          </Col>
                          <Col xs={12} lg={6}>
                            <FormItem
                              name="beds"
                              control={control}
                              render={({ field }) => (
                                <Input.Group compact className="input-group">
                                  <div className="prefix-label prefix-label--lg">
                                    {t('NewListingPage.Beds')}
                                  </div>
                                  <InputNumber
                                    size="large"
                                    precision={0}
                                    controls={false}
                                    placeholder="4"
                                    maxLength={3}
                                    {...field}
                                  />
                                </Input.Group>
                              )}
                            />
                          </Col>
                          <Col xs={12} lg={6}>
                            <FormItem
                              name="baths"
                              control={control}
                              render={({ field }) => (
                                <Input.Group compact className="input-group">
                                  <div className="prefix-label prefix-label--lg">
                                    {t('NewListingPage.Baths')}
                                  </div>
                                  <InputNumber
                                    size="large"
                                    parser={getRoundHalf}
                                    controls={false}
                                    placeholder="3.5"
                                    maxLength={5}
                                    {...field}
                                  />
                                </Input.Group>
                              )}
                            />
                          </Col>
                          <Col xs={24} md={12}>
                            <FormItem
                              name="carSpaces"
                              control={control}
                              render={({ field }) => (
                                <Input.Group compact className="input-group">
                                  <div className="prefix-label prefix-label--lg">
                                    {t('NewListingPage.CarSpaces')}
                                  </div>
                                  <InputNumber
                                    size="large"
                                    precision={0}
                                    controls={false}
                                    placeholder="4"
                                    maxLength={3}
                                    {...field}
                                  />
                                </Input.Group>
                              )}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="paper paper--lg">
                    <div>
                      <h3 className="form-label">
                        {t('NewListingPage.Description')}
                      </h3>
                      <Row gutter={16}>
                        <Col xs={24}>
                          <FormItem
                            name="subtitle"
                            control={control}
                            render={({ field }) => (
                              <Input
                                size="large"
                                placeholder={t('NewListingPage.Subtitle')}
                                {...field}
                              />
                            )}
                          />
                        </Col>
                        <Col xs={24}>
                          <FormItem
                            name="description"
                            control={control}
                            render={({ field }) => (
                              <TextEditor
                                {...field}
                                placeholder={t(
                                  'NewListingPage.FullDescription',
                                )}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="paper paper--lg">
                    <div>
                      <h3 className="form-label">
                        {t('NewListingPage.UploadPhotos')}
                      </h3>
                      <h5 className={styles.uploadTitle}>
                        {t('NewListingPage.PropertyPictures')}
                      </h5>
                      <div className={styles.uploadBox}>
                        <FormItem
                          className="clear-shadow"
                          name="photos"
                          control={control}
                          render={({ field, fieldState }) => (
                            <FileUpload
                              maxCount={30}
                              multiple
                              field={field}
                              error={fieldState.error}
                              bucket={MultimediaType.IMAGE}
                              acceptedFileFormat={[
                                '.jpg',
                                '.jpeg',
                                '.gif',
                                '.png',
                              ]}
                              acceptedFileSize={50}
                              listType="picture-card"
                              itemRender={(...params) =>
                                UploadImageItem(...params, {
                                  isSortable: true,
                                })
                              }
                              renderUploadButton={({ isMaxCountUploaded }) =>
                                !isMaxCountUploaded && (
                                  <div className={styles.uploaderWrap}>
                                    <i className="icon icon-upload"></i>
                                    <div>{t('FileUpload.Upload')}</div>
                                  </div>
                                )
                              }
                            />
                          )}
                        />
                      </div>
                      <div className={styles.formIndent}>
                        <Row>
                          <Col xs={24} lg={18}>
                            <h5 className={styles.uploadTitle}>
                              {t('NewListingPage.video.AddVideoByURL')}
                            </h5>
                            <h4 className={styles.hint}>
                              <div>{t('NewListingPage.video.description')}</div>
                            </h4>
                            <VideoTutorial />
                            <div>
                              <FormItem
                                name="video1"
                                control={control}
                                render={({ field }) => (
                                  <Input
                                    size="large"
                                    placeholder={t(
                                      'NewListingPage.video.placeholder',
                                    )}
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                            <div>
                              <FormItem
                                name="video2"
                                control={control}
                                render={({ field }) => (
                                  <Input
                                    size="large"
                                    placeholder={t(
                                      'NewListingPage.video.placeholder',
                                    )}
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
