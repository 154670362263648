import { Row, Col, Divider, Tabs, Collapse, Button } from 'antd';
import { CaretUpOutlined } from '@ant-design/icons';

import ContactUsSection from 'components/ContactUsSection';
import styles from '../FAQ.module.less';

const { Panel } = Collapse;

enum TabsEnum {
  SELLERS = 'Sellers',
  BUYERS = 'Buyers',
  AGENTS = ' Agents',
}

const BlockDivider = () => {
  return (
    <Row gutter={16}>
      <Col xs={24} xl={{ span: 20, offset: 2 }}>
        <Divider />
      </Col>
    </Row>
  );
};

export default function FAQEnPage() {
  return (
    <div className={styles.bgWrap}>
      <div className="container">
        <section className={styles.indentBox}>
          <Row>
            <Col xs={24} xl={{ span: 20, offset: 2 }}>
              <div className={styles.titleBox}>
                <h2 className="h2">FAQ</h2>
                <h5 className="h5">Frequently Asked Questions</h5>
              </div>

              <Tabs className={styles.tabs}>
                <Tabs.TabPane tab={TabsEnum.SELLERS} key={TabsEnum.SELLERS}>
                  <Collapse
                    className={styles.collapse}
                    accordion
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <CaretUpOutlined rotate={isActive ? 0 : 180} />
                    )}
                  >
                    <Panel
                      header="Do I have to accept the highest bid?"
                      key="header1"
                    >
                      <p>
                        No way Jose! The choice is ALL YOURS. You leave it by
                        rejecting, or change it with a counter, and your BTP
                        certified agent is excited to talk you through your
                        options.
                      </p>
                    </Panel>
                    <Panel header="Do I have to use an agent?" key="header2">
                      <p>
                        Yes, an agent is the way to go. Selling a home is a long
                        journey from start to finish, and your agent can keep
                        you on the right path to get you where you want to be
                        (successful sale!).
                      </p>
                    </Panel>
                    <Panel
                      header="Will my home definitely sell, like 100%?"
                      key="header3"
                    >
                      <p>
                        It's your call in the end! You have the decision power
                        to accept offers that line up with your expectations, or
                        decline if they don't.
                      </p>
                    </Panel>
                    <Panel
                      header="Can I save some dough on paying commission?"
                      key="header4"
                    >
                      <p>
                        It's common on bidding platforms for the buyer to pay a
                        premium above the highest bid. With Bid This Property,
                        people usually choose the option where the buyer will
                        pay half of the commission, as well as 0.5% fee BTP adds
                        to the highest bid. At the end of the process during
                        closing, the commission and 0.5% fee to BTP get paid
                        from the selling price.
                      </p>
                    </Panel>
                    <Panel
                      header="How many open houses or showings do I have to have?"
                      key="header5"
                    >
                      <p>The minimum is one, sometimes 2.</p>
                    </Panel>
                    <Panel
                      header="Is there an agent available to work with me?"
                      key="header6"
                    >
                      <p>
                        Definitely! Bid This Property has amazing agents to
                        choose from, with specialty training to best serve you.
                      </p>
                    </Panel>

                    <Panel
                      header="Do I have to deal with special forms or paperwork?"
                      key="header7"
                    >
                      <p>
                        No, it's just all the usual suspects. Bid This Property
                        only requires the standard real estate forms.
                      </p>
                    </Panel>
                    <Panel
                      header="Why/how is Bid This Property different from an auction?"
                      key="header8"
                    >
                      <p>
                        Generally speaking, auctions usually deal with
                        distressed properties and foreclosures. BTP is a bidding
                        platform for all kinds of properties, even high-end.
                      </p>
                    </Panel>
                    <Panel
                      header="Why not just go the regular route? What's so great about selling with Bid This Property?"
                      key="header9"
                    >
                      <p>
                        Up there on the list of reasons is time-saving
                        convenience! Then there's the extra excellent online
                        visibility resulting in multiple offers and a speedier
                        selling process often times being able to skip the
                        costly laundry list of repairs.
                      </p>
                    </Panel>
                    <Panel
                      header="Say I'm up late at night considering my options  and I think of even more questions, who do I ask?"
                      key="header10"
                    >
                      <p>
                        You can email{' '}
                        <Button className="custom-link" type="link">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`mailto:info@bidthisproperty.com`}
                          >
                            info@bidthisproperty.com
                          </a>
                        </Button>
                        , and there's also the option to use the contact form.
                      </p>
                    </Panel>
                  </Collapse>
                </Tabs.TabPane>

                <Tabs.TabPane tab={TabsEnum.BUYERS} key={TabsEnum.BUYERS}>
                  <Collapse
                    className={styles.collapse}
                    accordion
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <CaretUpOutlined rotate={isActive ? 0 : 180} />
                    )}
                  >
                    <Panel
                      header="Can other buyers peep my bids?"
                      key="header1"
                    >
                      <p>
                        Yes, sir-y bob. A huge point of pride for BTP is total
                        transparency. All bids are visible to all parties.
                      </p>
                    </Panel>
                    <Panel header="Is it tough to place a bid?" key="header2">
                      <p>
                        It is easy peasy lemon squeezy. Once you request and
                        receive manual approval from the listing agent, you can
                        bid to your heart's content. If you'd rather outsource,
                        your agent can get approved and bid in your place.
                      </p>
                    </Panel>
                    <Panel header="Do I have to use an agent?" key="header3">
                      <p>
                        Yes, it's the best choice. When things really get going
                        with your home purchase and there's a lot happening, an
                        agent will have your back. They will make sure
                        everything is done properly and goes off without a
                        hitch.
                      </p>
                    </Panel>
                    <Panel
                      header="I’ve got someone I really like, can I use my own agent while using Bid This Property?"
                      key="header4"
                    >
                      <p>Sure thing! Share the realtor love.</p>
                    </Panel>
                    <Panel
                      header="Can I use an auto bid function on Bid This Property?"
                      key="header5"
                    >
                      <p>
                        You betcha. It makes it easy so you can step away
                        without losing.
                      </p>
                    </Panel>
                    <Panel
                      header="What's so important about this transparency you speak of?"
                      key="header6"
                    >
                      <p>
                        It allows you to see all the moves being made by other
                        bidders, so you can track all the action the property
                        you're interested in is getting.
                      </p>
                    </Panel>
                    <Panel
                      header="What exactly is the Buyer's Premium?"
                      key="header7"
                    >
                      <p>
                        The highest bid plus the Buyers Premium equals the sales
                        price, and the Buyers Premium is just a percentage fee
                        that is paid by the buyer. It's the price of
                        transparency and being able to decide if you want to do
                        what it takes to win.
                      </p>
                    </Panel>
                    <Panel
                      header="Do I have to utilize a bunch of different/special forms?"
                      key="header8"
                    >
                      <p>
                        Not here guys! The paperwork is all super standard real
                        estate contracts.
                      </p>
                    </Panel>
                    <Panel
                      header="If I get outbid, how will I find out the bad news?"
                      key="header9"
                    >
                      <p>
                        In the process of tracking the bidding online, you can
                        view it in real time as it happens. Or if you are not
                        closely tracking, you will get a bummer email
                        notification.
                      </p>
                    </Panel>
                    <Panel
                      header="Is there some kind of reserve/buy it now price option?"
                      key="header10"
                    >
                      <p>
                        Sorry friends, this is not ebay. The seller can choose
                        to accept, reject or counter any offer they receive.
                      </p>
                    </Panel>
                  </Collapse>
                </Tabs.TabPane>
                <Tabs.TabPane tab={TabsEnum.AGENTS} key={TabsEnum.AGENTS}>
                  <Collapse
                    className={styles.collapse}
                    accordion
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <CaretUpOutlined rotate={isActive ? 0 : 180} />
                    )}
                  >
                    <Panel header="Why would I want to use BTP?" key="header1">
                      <p>Results, Results, and did we mention Results?</p>
                    </Panel>
                    <Panel
                      header="This sounds like a gimmick, what's the deal?"
                      key="header2"
                    >
                      <p>
                        Making technology our friend, being transparent in price
                        and commission, and offering our clients solutions
                        sounds pretty cutting edge...no?
                      </p>
                    </Panel>
                    <Panel
                      header="Ok, I see the future and this is the way...How can I get certified?"
                      key="header3"
                    >
                      <p>
                        Fill out the contact me button and someone from BTP will
                        let you know how YOU and your Brokerage can take their
                        business to the next level.
                      </p>
                    </Panel>
                  </Collapse>
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </section>

        <BlockDivider />

        <section className={styles.contactSection}>
          <ContactUsSection />
        </section>
      </div>
    </div>
  );
}
