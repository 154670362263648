import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';
import { isArray } from 'lodash';

import AboutUsCarousel, { IAboutUsSlide } from '../AboutUsCarousel';
import ContactUsSection from 'components/ContactUsSection';
import AboutUsWhyJoinUsSection from '../AboutUsWhyJoinUsSection';
import parse from 'html-react-parser';
import img1 from 'assets/images/aboutUs/image1.webp';
import img2 from 'assets/images/aboutUs/image2.webp';
import img3 from 'assets/images/aboutUs/image3.webp';
import img4 from 'assets/images/aboutUs/image4.webp';
import defaultImgMain from 'assets/images/aboutUs/image5.webp';
import styles from './AboutUsPage.module.less';
import { useAppSettings } from 'components/AppSettingsProvider';

const defaultSlides: IAboutUsSlide[] = [
  {
    imageUrl: img1,
  },
  {
    imageUrl: img2,
  },
  {
    imageUrl: img3,
  },
  {
    imageUrl: img4,
  },
];

export default function AboutUsPage() {
  const { settings } = useAppSettings();
  const { about_us_page } = settings || {};
  const {
    images,
    section_1_headline,
    section_1_subtitle,
    section_1_body,
    section_2_headline,
    section_2_subtitle,
    section_2_body,
    section_3_body,
    section_3_image,
  } = about_us_page || {};

  const settingSlides = images?.map((str) => ({ imageUrl: str }));

  const { t } = useTranslation();
  const HereAreTheDeetsDescription = t<string, string[]>(
    'AboutUsPage.HereAreTheDeets.description',
    {
      returnObjects: true,
    },
  );

  return (
    <div className={styles.bgWrap}>
      <section className={styles.whatSection}>
        <div className="container">
          <Row gutter={16} align="middle">
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h2 m-0">
                {!!section_1_headline ? (
                  <div>{section_1_headline}</div>
                ) : (
                  <>
                    <span>{t('AboutUsPage.WhatIs')}</span>
                    <div>{t('AboutUsPage.Bid')}</div>
                  </>
                )}
              </h2>
            </Col>
            <Col xs={24} md={15} xl={16}>
              <AboutUsCarousel
                slides={!!settingSlides?.length ? settingSlides : defaultSlides}
              />
            </Col>
            <Col
              xs={24}
              md={{ span: 15, offset: 9 }}
              xl={{ span: 14, offset: 8 }}
            >
              <div>
                {!!section_1_subtitle ? (
                  <h3 className="h5">{section_1_subtitle}</h3>
                ) : (
                  <h3 className="h5">{t('AboutUsPage.WhatIs.title')}</h3>
                )}
                {!!section_1_body ? (
                  parse(section_1_body)
                ) : (
                  <>
                    <div>{t('AboutUsPage.WhatIs.description1')}</div>
                    <div>{t('AboutUsPage.WhatIs.description2')}</div>
                    <div>{t('AboutUsPage.WhatIs.description3')}</div>
                  </>
                )}
              </div>
              <Divider />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h2">
                {!!section_2_headline ? (
                  <div>{section_2_headline}</div>
                ) : (
                  <>
                    <span>{t('AboutUsPage.YouHaveQuestions')}</span>
                    <div>{t('AboutUsPage.HereAreTheDeets')}</div>
                  </>
                )}
              </h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <div>
                {!!section_2_subtitle ? (
                  <h3 className="h5">{section_2_subtitle}</h3>
                ) : (
                  <h3 className="h5">
                    {t('AboutUsPage.HereAreTheDeets.title')}
                  </h3>
                )}
                {!!section_2_body ? (
                  parse(section_2_body)
                ) : (
                  <ul className="default-list">
                    {isArray(HereAreTheDeetsDescription) &&
                      HereAreTheDeetsDescription.map((point, index) => (
                        <li key={`point-${index}`}>{point}</li>
                      ))}
                  </ul>
                )}
              </div>
            </Col>
            <Col xs={24} xl={{ span: 20, offset: 2 }}>
              <Divider />
            </Col>
          </Row>
        </div>
      </section>

      <section>
        <div className="container">
          <Row>
            <Col xs={24} xl={{ span: 20, offset: 2 }}>
              <div className={styles.imgBox}>
                <img
                  src={!!section_3_image ? section_3_image : defaultImgMain}
                  alt=""
                />
              </div>
              <div className={styles.textBox}>
                {!!section_3_body ? (
                  parse(section_3_body)
                ) : (
                  <Row gutter={16}>
                    <Col xs={24} md={12}>
                      <div>
                        {t('AboutUsPage.description.column1.thisIsSome')}
                      </div>
                      <div>
                        {t('AboutUsPage.description.column1.inJustTwo')}
                      </div>
                      <div>
                        {t('AboutUsPage.description.column1.ditchTheBig')}
                      </div>
                    </Col>
                    <Col xs={24} md={12}>
                      <div>
                        {t('AboutUsPage.description.column2.youcanwatch')}
                      </div>
                      <div>
                        {t('AboutUsPage.description.column2.saveABoat')}
                      </div>
                    </Col>
                  </Row>
                )}
              </div>

              <Divider />
            </Col>
          </Row>
        </div>
      </section>

      <section className={styles.faqSection}>
        <div className="container">
          <AboutUsWhyJoinUsSection />
        </div>
      </section>

      <div className="container">
        <Row gutter={16}>
          <Col xs={24} xl={{ span: 20, offset: 2 }}>
            <Divider />
          </Col>
        </Row>
      </div>

      <section className={styles.contactSection}>
        <div className="container">
          <ContactUsSection />
        </div>
      </section>
    </div>
  );
}
