import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Col, Row } from 'antd';

import { displayErrorMessage } from 'components/message';
import { CommonApi } from 'services/api';

import imgSuccess from 'assets/images/successUnsubscribe.svg';
import img from 'assets/images/unsubscribe-icon.svg';

import styles from './EmailUnsubscribePage.module.less';

export default function EmailUnsubscribePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const email = searchParams.get('email') || '';
  const userId = searchParams.get('user_id') || '';
  const token = searchParams.get('token') || '';

  const handleUnsubscribe = async () => {
    try {
      setIsSubmitting(true);

      await CommonApi.emailUnsubscribe({ userId, token });

      setIsSuccess(true);
    } catch (error) {
      displayErrorMessage({ error });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="layout-wrap">
      <div className="container">
        <Row justify="center">
          <Col xs={24} md={20} lg={16}>
            <div className={styles.wrap}>
              <div className="paper">
                <div className={styles.content}>
                  {!isSuccess && (
                    <>
                      <div className={styles.img}>
                        <img src={img} alt="unsubscribe-icon" />
                      </div>
                      <h1 className="h4">{t('EmailUnsubscribePage.title')}</h1>
                      <p>
                        {t('EmailUnsubscribePage.text')} <b>{email}</b>{' '}
                        {t('EmailUnsubscribePage.text2')}
                      </p>
                      <div>
                        <Button
                          loading={isSubmitting}
                          onClick={handleUnsubscribe}
                          type="primary"
                          size="large"
                        >
                          {t('EmailUnsubscribePage.Unsubscribe')}
                        </Button>
                        <Button
                          onClick={() => navigate('/')}
                          type="link"
                          className="custom-link"
                          size="large"
                        >
                          {t('EmailUnsubscribePage.Cancel')}
                        </Button>
                      </div>
                    </>
                  )}
                  {isSuccess && (
                    <>
                      <div className={styles.img}>
                        <img src={imgSuccess} alt="unsubscribe-icon" />
                      </div>
                      <h1 className="h4">
                        {t('EmailUnsubscribePage.success.title')}
                      </h1>
                      <p>
                        {t('EmailUnsubscribePage.success.text')} <b>{email}</b>{' '}
                        {t('EmailUnsubscribePage.success.text2')}
                      </p>
                      <div>
                        <Button
                          onClick={() => navigate('/')}
                          type="primary"
                          size="large"
                        >
                          {t('EmailUnsubscribePage.success.GoToHomepage')}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
