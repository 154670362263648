import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Empty } from 'antd';
import { ListingApi, AdminBrokerageDetail } from 'services/api';
import Spin from 'components/Spin';
import NewBrokeragePage, {
  NewBrokeragesPageModeEnum,
} from '../NewBrokeragePage';
import { getFormattedFiles } from 'utils/file.utils';
import styles from './EditBrokerages.module.less';

export default function EditBrokeragesPage() {
  const { brokerageId = '' } = useParams<'brokerageId'>();
  const [isLoading, setIsLoading] = useState(false);
  const [brokeragesDetails, setBrokeragesDetails] =
    useState<AdminBrokerageDetail>();

  useEffect(() => {
    const loadBrokerageDetails = async () => {
      try {
        setIsLoading(true);

        const result = await ListingApi.getAdminBrokerageDetails(brokerageId);

        setBrokeragesDetails(result);
      } catch {
      } finally {
        setIsLoading(false);
      }
    };

    loadBrokerageDetails();
  }, [brokerageId]);

  if (isLoading)
    return (
      <div className={styles.spinWrap}>
        <Spin />
      </div>
    );

  if (!brokeragesDetails)
    return (
      <div className="layout-wrap">
        <Empty />
      </div>
    );

  const {
    name = '',
    market_center = '',
    brokerage_site_name = '',
    subdomain = '',
    color_logo,
    grey_logo,
    white_logo,
    favicon,
    office_address = '',
    office_phone_number = '',
    office_email = '',
    city = '',
    state = '',
    zip = '',
    linkedin_profile = '',
    facebook_profile = '',
    dre = '',
    home_page,
    listing_page,
    about_us_page,
  } = brokeragesDetails;

  const defaultValues = {
    name: name,
    market_center: market_center,
    brokerage_site_name: brokerage_site_name,
    subdomain: subdomain,
    color_logo: getFormattedFiles(color_logo),
    grey_logo: getFormattedFiles(grey_logo),
    white_logo: getFormattedFiles(white_logo),
    favicon: getFormattedFiles(favicon),
    office_address: office_address,
    office_phone_number: office_phone_number,
    office_email: office_email,
    city: city,
    state: state,
    zip: zip,
    linkedin_profile: linkedin_profile,
    facebook_profile: facebook_profile,
    dre: dre,
    listing_page: {
      headline: listing_page?.headline || '',
    },
    home_page: {
      headline: home_page?.headline || '',
      image: getFormattedFiles(home_page?.image),
    },
    about_us_page: {
      images: getFormattedFiles(about_us_page?.images),
      section_1_headline: about_us_page?.section_1_headline || '',
      section_2_headline: about_us_page?.section_2_headline || '',
      section_1_subtitle: about_us_page?.section_1_subtitle || '',
      section_2_subtitle: about_us_page?.section_2_subtitle || '',
      section_1_body: about_us_page?.section_1_body || '',
      section_2_body: about_us_page?.section_2_body || '',
      section_3_body: about_us_page?.section_3_body || '',
      section_3_image: getFormattedFiles(about_us_page?.section_3_image),
    },
  };

  return (
    <NewBrokeragePage
      mode={NewBrokeragesPageModeEnum.Edit}
      brokerage={brokeragesDetails}
      defaultValues={defaultValues}
    />
  );
}
