/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AdminService } from './services/AdminService';
import { ApprovalsService } from './services/ApprovalsService';
import { BidsService } from './services/BidsService';
import { BrokeragesService } from './services/BrokeragesService';
import { DefaultService } from './services/DefaultService';
import { ListingsService } from './services/ListingsService';
import { MultimediaService } from './services/MultimediaService';
import { NotificationsService } from './services/NotificationsService';
import { UsersService } from './services/UsersService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

    public readonly admin: AdminService;
    public readonly approvals: ApprovalsService;
    public readonly bids: BidsService;
    public readonly brokerages: BrokeragesService;
    public readonly default: DefaultService;
    public readonly listings: ListingsService;
    public readonly multimedia: MultimediaService;
    public readonly notifications: NotificationsService;
    public readonly users: UsersService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '/api',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.admin = new AdminService(this.request);
        this.approvals = new ApprovalsService(this.request);
        this.bids = new BidsService(this.request);
        this.brokerages = new BrokeragesService(this.request);
        this.default = new DefaultService(this.request);
        this.listings = new ListingsService(this.request);
        this.multimedia = new MultimediaService(this.request);
        this.notifications = new NotificationsService(this.request);
        this.users = new UsersService(this.request);
    }
}

