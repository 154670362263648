import { useEffect, useState } from 'react';

import { Button, Empty } from 'antd';
import ListingCard from 'components/cards/ListingCard';
import Spin from 'components/Spin';
import { SortingUrlKey } from 'features/listing/ListingsSorting';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  ExternalListingOrderBy,
  ExternalListingResponse,
  ListingApi,
} from 'services/api';
import { useGetSocketRequestMultiple } from 'utils/socket.utils';

import styles from '../HomePage.module.less';

type Props = {
  title: string;
  order: ExternalListingOrderBy;
};

export default function HomePageList(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { title, order } = props;
  const [listingsData, setListingsData] = useState<ExternalListingResponse>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const result = await ListingApi.getListingList({
          query: '',
          pageSize: 8,
          page: 1,
          order,
        });

        setListingsData(result);
      } catch (error) {
        setListingsData({
          total: 0,
          page: 0,
          page_size: 0,
          results: [],
        });
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGetSocketRequestMultiple(
    listingsData,
    (val) => setListingsData(val),
    '',
    1,
    8,
    order,
  );

  const handleSeeMore = () => {
    navigate({
      pathname: '/listings',
      search: createSearchParams({
        [SortingUrlKey]: order,
      }).toString(),
    });
  };

  return (
    <section>
      <div className="container">
        <div className={styles.sectionHead}>
          <h4 className="h5">{title}</h4>
          <div>
            <Button
              type="link"
              size="small"
              className="with-icon"
              onClick={handleSeeMore}
            >
              {t('HomePage.SeeMore')} <i className="icon icon-arrow-right"></i>
            </Button>
          </div>
        </div>
        <Spin spinning={isLoading}>
          {listingsData && !listingsData.results?.length && <Empty />}
          {!!listingsData?.results?.length && (
            <div className="grid-wrapper">
              {listingsData.results.map((listing) => (
                <ListingCard key={listing.id} listing={listing} />
              ))}
            </div>
          )}
        </Spin>
      </div>
    </section>
  );
}
