import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Form, Input } from 'antd';
import { TFunction, useTranslation } from 'react-i18next';

import FormItem from 'components/form/FormItem';
import { ContactApi, ExternalNotificationType } from 'services/api';

interface IFormValues {
  user_name: string;
  user_email: string;
  body: string;
}

type Props = {
  type: ExternalNotificationType;
  listing_id?: string;
};
const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    user_name: yup
      .string()
      .max(
        46,
        t('validation.maxLength', {
          count: 46,
        }),
      )
      .required(t('validation.required')),
    user_email: yup
      .string()
      .email(t('validation.format'))
      .required(t('validation.required')),
    body: yup
      .string()
      .max(
        430,
        t('validation.maxLength', {
          count: 430,
        }),
      )
      .required(t('validation.required')),
  });

export default function ContactUsForm(props: Props) {
  const { t } = useTranslation();
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm<IFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      user_email: '',
      user_name: '',
      body: '',
    },
  });

  useEffect(() => {
    // It's recommended to reset inside useEffect after submission.
    // https://react-hook-form.com/api/useform/reset
    isSubmitSuccessful && reset();
  }, [isSubmitSuccessful, reset]);

  const onSubmit = async (values: IFormValues) => {
    try {
      const { type, listing_id } = props;
      const data = { ...values, type, listing_id };

      await ContactApi.sendContactUsFormRequest(data);
    } catch {}
  };

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
      <FormItem
        name="user_name"
        control={control}
        render={({ field }) => (
          <Input
            placeholder={t('ContactUsForm.Name')}
            size="large"
            {...field}
          />
        )}
      />

      <FormItem
        name="user_email"
        control={control}
        render={({ field }) => (
          <Input
            placeholder={t('ContactUsForm.Email')}
            size="large"
            {...field}
          />
        )}
      />

      <FormItem
        name="body"
        control={control}
        render={({ field }) => (
          <Input.TextArea
            size="large"
            placeholder={t('ContactUsForm.textarea')}
            {...field}
          />
        )}
      />

      <Button
        loading={isSubmitting}
        type="primary"
        htmlType="submit"
        size="large"
        block
      >
        {t('ContactUsForm.submit')}
      </Button>
    </Form>
  );
}
