import { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { Button, Modal, SpinProps, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { TFunction, useTranslation } from 'react-i18next';

import { displayErrorMessage } from 'components/message';
import { ModalFooterWrapper, ModalScrollWrapper } from 'components/Modal';
import Price from 'components/Price';
import Spin from 'components/Spin';
import {
  AdminBidDetail,
  AdminAutoBidDetail,
  Price as PriceType,
  ApprovalUserStatus,
  ListingApi,
  ListingStatus,
  AdminListingDetail,
} from 'services/api';
import { dateTimeFormat } from 'utils/date.utils';
import BuyerInfo from '../BuyerInfo';
import ContactList from '../ContactList';
import DataPlaceholder from '../DataPlaceholder';
import { getLabelByStatus } from './allBids.utils';
import { downloadFileByObjectURL, getSafeFileName } from 'utils/file.utils';
import InfoBox, { BoxColor, BoxSize } from 'components/InfoBox';

import styles from './AllBids.module.less';
import { useAppSettings } from 'components/AppSettingsProvider';

type AdminAutoBidDetailExtended = AdminAutoBidDetail & { isAutoBid: boolean };

const DownloadCSVButton = ({
  t,
  listing,
}: {
  t: TFunction;
  listing?: AdminListingDetail;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { settings } = useAppSettings();
  const { language } = settings || {};
  const handleClick = async () => {
    setIsLoading(true);

    try {
      const result = await ListingApi.getAllBidsCSV(listing!.id, language);
      const blob = new Blob([result]);
      const urlObject = URL.createObjectURL(blob);

      const { address, city, postcode } = listing?.address!;
      const fileName = getSafeFileName(
        `${address}_${city}_${postcode}_report.csv`,
      );

      await downloadFileByObjectURL(urlObject, fileName);
    } catch (error) {
      displayErrorMessage({ error });
    } finally {
      setIsLoading(false);
    }
  };

  if (
    !listing ||
    listing?.status === ListingStatus.DRAFT ||
    listing.status === ListingStatus.COMING_SOON
  ) {
    return null;
  }

  return (
    <Button
      type="link"
      className="with-icon"
      size="small"
      icon={<i className="icon icon-download" />}
      onClick={handleClick}
      loading={isLoading}
    >
      {t('BidManagement.Tabs.Table.DownloadCSV')}
    </Button>
  );
};

const getColumns = (
  t: TFunction,
  onVoidBidClick: (bidId: string) => void,
  onDisableAutobidClick: (autoBidId: string) => void,
  listing?: AdminListingDetail,
): ColumnsType<AdminAutoBidDetailExtended | AdminBidDetail> => [
  {
    key: 'date',
    title: t('BidManagement.Tabs.Table.Date'),
    render: (_, bid) => {
      if (!!(bid as AdminAutoBidDetailExtended).isAutoBid) {
        return (
          <>
            <span>{moment(bid.created_at).format(dateTimeFormat)}</span>

            <div>
              <span className={styles.label}>
                {t('DisableAutoList.AutobidEnabled')}
              </span>
            </div>
          </>
        );
      }
      const { is_next_void, created_at } = bid as AdminBidDetail;
      const isLabelVisible = !!is_next_void;

      return (
        <>
          <span>{moment(created_at).format(dateTimeFormat)}</span>
          {isLabelVisible && (
            <div>
              <span className={styles.label}>
                {getLabelByStatus(t, listing?.status)}
              </span>
            </div>
          )}
        </>
      );
    },
  },
  {
    key: 'bids',
    title: t('BidManagement.Tabs.AllBids.Table.Bids'),
    render: (_, bid) => {
      if (!!(bid as AdminAutoBidDetailExtended).isAutoBid) {
        return (
          <>
            <Price
              price={(bid as AdminAutoBidDetailExtended).max_price as PriceType}
              label={t('DisableAutoList.MaxBid')}
            />
          </>
        );
      }
      return (
        <>
          <Price
            price={(bid as AdminBidDetail).price}
            label={
              !!(bid as AdminBidDetail).auto_bid_id
                ? t('DisableAutoList.Autobid')
                : ''
            }
          />
        </>
      );
    },
  },
  {
    key: 'buyer',
    title: t('BidManagement.Tabs.Table.Buyer'),
    render: (_, { buyer }) => (
      <>
        {buyer && (
          <BuyerInfo
            firstName={buyer.first_name || ''}
            lastName={buyer.last_name || ''}
            nickname={buyer?.nickname || ''}
            // BE should describe model better in the future, but now it might work only that way
            status={buyer.status as ApprovalUserStatus}
            dre={buyer.dre}
          />
        )}
      </>
    ),
  },
  {
    key: 'contacts',
    title: t('BidManagement.Tabs.Table.Contacts'),
    render: (_, bid) => <>{bid.buyer && <ContactList user={bid.buyer} />}</>,
  },
  {
    key: 'actions',
    width: '12%',
    className: styles.actionsCell,
    render: (_, bid) => {
      if (!!(bid as AdminAutoBidDetailExtended).isAutoBid) {
        return (
          <>
            {!!(bid as AdminAutoBidDetailExtended).is_enabled &&
              listing?.status === ListingStatus.ACTIVE && (
                <Button
                  type="link"
                  className={cx('with-icon', styles.btn)}
                  size="small"
                  icon={<i className="icon icon-danger" />}
                  onClick={() => onDisableAutobidClick(bid.id)}
                  danger
                >
                  {t('DisableAutoList.DisableAutobid')}
                </Button>
              )}
          </>
        );
      }
      const { is_next_void, id, void_user, is_voided } = bid as AdminBidDetail;

      return (
        <>
          {!!is_next_void && listing?.status === ListingStatus.ACTIVE && (
            <Button
              type="link"
              className={cx('with-icon', styles.btn)}
              size="small"
              icon={<i className="icon icon-shield-cross" />}
              onClick={() => onVoidBidClick(id)}
            >
              {t('BidManagement.Tabs.Table.VoidABid')}
            </Button>
          )}
          {!!is_voided && !!void_user && (
            <div>
              <span className={styles.label}>
                {t('BidManagement.Tabs.Table.VoidedBy')} {void_user?.first_name}{' '}
                {void_user?.last_name}
              </span>
            </div>
          )}
        </>
      );
    },
  },
  {
    key: 'download-csv-button',
    width: '12%',
    title: <DownloadCSVButton t={t} listing={listing} />,
    render: () => null,
  },
];

type Props = {
  isActive: boolean;
  listingId?: string;
  listing?: AdminListingDetail;
  onChangeReloadListingDetail: () => void;
  onDisableAutobidClick?: () => void;
};

export default function AllBids({
  isActive,
  listingId,
  listing,
  onChangeReloadListingDetail,
}: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] =
    useState<Array<AdminBidDetail | AdminAutoBidDetailExtended>>();
  const [voidBidId, setVoidBidId] = useState('');
  const [disableAutobidId, setDisableAutobidId] = useState('');
  const [
    isVoidBidConfirmationModalVisible,
    setIsVoidBidConfirmationModalVisible,
  ] = useState(false);
  const [
    isDisableAutobidConfirmationModalVisible,
    setIsDisableAutobidConfirmationModalVisible,
  ] = useState(false);
  const [isVoidBidSubmitting, setIsVoidBidSubmitting] = useState(false);
  const [isDisableAutobidSubmitting, setIsDisableAutobidSubmitting] =
    useState(false);
  const onVoidBidClick = useCallback((bidId: string) => {
    setVoidBidId(bidId);
    setIsVoidBidConfirmationModalVisible(true);
  }, []);
  const onDisableAutobidClick = useCallback((autoBidId: string) => {
    setDisableAutobidId(autoBidId);
    setIsDisableAutobidConfirmationModalVisible(true);
  }, []);
  const columns = useMemo(
    () => getColumns(t, onVoidBidClick, onDisableAutobidClick, listing),
    [t, onVoidBidClick, onDisableAutobidClick, listing],
  );

  const loadAllBids = async () => {
    setIsLoading(true);

    try {
      const result = await ListingApi.getBidsManagementAllBids(listingId!);

      const autoBids = result?.auto_bids!.map((autoBid: AdminAutoBidDetail) => {
        (autoBid as AdminAutoBidDetailExtended).isAutoBid = true;
        return autoBid;
      });
      setData([...(autoBids as AdminAutoBidDetailExtended[]), ...result.bids!]);
    } catch (error) {
      displayErrorMessage({ error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isActive && loadAllBids();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const tableLoading: SpinProps = {
    spinning: isLoading,
    indicator: <Spin />,
  };

  const closeVoidBidConfirmationModal = () => {
    setIsVoidBidConfirmationModalVisible(false);
  };
  const closeDisableAutoBidConfirmationModal = () => {
    setIsDisableAutobidConfirmationModalVisible(false);
  };

  const handleDisableAutoBid = async () => {
    try {
      setIsDisableAutobidSubmitting(true);

      await ListingApi.disableAutobid(disableAutobidId);

      loadAllBids(); // update current tab data
      onChangeReloadListingDetail(); // update listing card

      closeDisableAutoBidConfirmationModal();
    } catch {
      const error = new Error(t('DisableAutobidConfirmationModal.error'));
      displayErrorMessage({ error });
    } finally {
      setIsDisableAutobidSubmitting(false);
    }
  };
  const handleVoidBid = async () => {
    try {
      setIsVoidBidSubmitting(true);

      await ListingApi.voidBid(voidBidId);

      loadAllBids(); // update current tab data
      onChangeReloadListingDetail(); // update listing card

      closeVoidBidConfirmationModal();
    } catch {
      const error = new Error(t('VoidBidConfirmationModal.error'));
      displayErrorMessage({ error });
    } finally {
      setIsVoidBidSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        title={null}
        visible={isVoidBidConfirmationModalVisible}
        centered
        closable={false}
        maskClosable={false}
        onCancel={closeVoidBidConfirmationModal}
        footer={null}
        destroyOnClose
        className={cx(styles.modal, 'modal')}
        width={550}
      >
        <Button
          className={cx('btn-icon', styles.modalBtn)}
          size="small"
          icon={<i className="icon icon-close"></i>}
          onClick={closeVoidBidConfirmationModal}
        ></Button>
        <h2 className="modal__title">{t('VoidBidConfirmationModal.title')}</h2>
        <ModalScrollWrapper>
          <div className={styles.modalText}>
            <p>
              {t('VoidBidConfirmationModal.info1')}{' '}
              <b>{t('VoidBidConfirmationModal.info2')}</b>
            </p>
            <div className={styles.info}>
              <InfoBox
                color={BoxColor.Grey}
                size={BoxSize.Small}
                icon={<i className="icon icon-flag"></i>}
                text={
                  <div className={styles.infoText}>
                    {t('VoidBidConfirmationModal.infobox')}
                  </div>
                }
              />
            </div>
          </div>
        </ModalScrollWrapper>

        <ModalFooterWrapper>
          <div>
            <Button
              onClick={closeVoidBidConfirmationModal}
              type="link"
              className="custom-link"
              size="large"
            >
              {t('VoidBidConfirmationModal.Cancel')}
            </Button>
          </div>
          <div>
            <Button
              type="primary"
              loading={isVoidBidSubmitting}
              size="large"
              onClick={handleVoidBid}
            >
              {t('VoidBidConfirmationModal.VoidBid')}
            </Button>
          </div>
        </ModalFooterWrapper>
      </Modal>
      <Modal
        title={null}
        visible={isDisableAutobidConfirmationModalVisible}
        centered
        closable={false}
        maskClosable={false}
        onCancel={closeDisableAutoBidConfirmationModal}
        footer={null}
        destroyOnClose
        className={cx(styles.modal, 'modal')}
        width={550}
      >
        <Button
          className={cx('btn-icon', styles.modalBtn)}
          size="small"
          icon={<i className="icon icon-close"></i>}
          onClick={closeDisableAutoBidConfirmationModal}
        ></Button>
        <h2 className="modal__title">
          {t('DisableAutobidConfirmationModal.title')}
        </h2>
        <ModalScrollWrapper>
          <div className={styles.modalText}>
            <p>
              {t('DisableAutobidConfirmationModal.info1')}{' '}
              <b>{t('DisableAutobidConfirmationModal.info2')}</b>
            </p>
            <p>{t('DisableAutobidConfirmationModal.info3')}</p>
          </div>
        </ModalScrollWrapper>

        <ModalFooterWrapper>
          <div>
            <Button
              onClick={closeDisableAutoBidConfirmationModal}
              type="link"
              className="custom-link"
              size="large"
            >
              {t('DisableAutobidConfirmationModal.Cancel')}
            </Button>
          </div>
          <div>
            <Button
              type="primary"
              loading={isDisableAutobidSubmitting}
              size="large"
              onClick={handleDisableAutoBid}
            >
              {t('VoidBidConfirmationModal.DisableAutobid')}
            </Button>
          </div>
        </ModalFooterWrapper>
      </Modal>
      <DataPlaceholder
        isDataExists={!!data && !!data?.length}
        isLoading={!data?.length && isLoading}
        noDataDescription={t('BidManagement.Tabs.AllBids.Empty')}
      >
        <Table
          scroll={{
            x: '100%',
          }}
          rowClassName={(bid, idx) =>
            cx({
              [styles.currentBidRow]: !!(bid as AdminBidDetail).is_next_void,
              [styles.voidedBidRow]: !!(bid as AdminBidDetail).is_voided,
            })
          }
          dataSource={data}
          columns={columns}
          rowKey="id"
          loading={tableLoading}
          pagination={false}
        />
      </DataPlaceholder>
    </>
  );
}
