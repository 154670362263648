import { apiClient } from '.';

class CommonApi {
  public async emailUnsubscribe({
    userId,
    token,
  }: {
    userId: string;
    token: string;
  }) {
    return apiClient.users.unsubscribe(userId, token);
  }

  public async getAppSettings() {
    return apiClient.brokerages.getBrokerageSettings();
  }

  public async getAgentsList({
    query,
    pageSize,
    page,
  }: {
    query: string;
    pageSize: number;
    page: number;
  }) {
    return apiClient.users.staffUsers(page, pageSize, query);
  }
}

export default new CommonApi();
