import { useTranslation } from 'react-i18next';

import TimerBadge from 'components/TimerBadge';
import { ExternalListingList, ListingStatus } from 'services/api';
import { getCurrencySign } from 'utils/price.utils';
import { getFormattedAddress } from 'utils/location.utils';
import { getPriceData, getBidderData } from 'utils/listing.utils';
import { getFormattedNumber } from 'utils/number.utils';
import { getImageUrl, ImageSize } from 'utils/image.utils';
import emptyImg from 'assets/images/empty_img.svg';

import styles from './HorizontalListingCard.module.less';

type Props = {
  listing: ExternalListingList;
};

export default function HorizontalListingCard(props: Props) {
  const { t } = useTranslation();

  const { listing } = props;
  const {
    id,
    title,
    property_pictures = [],
    status,
    bedrooms,
    bathrooms,
    car_spaces,
    house_area,
    address,
    ends_at,
    units,
  } = listing;
  const isActive = status === ListingStatus.ACTIVE;
  const bidderData = getBidderData(listing, t);
  const price = getPriceData(listing);

  return (
    <div className={styles.card} key={id}>
      <picture className={styles.cardImg}>
        {property_pictures[0] ? (
          <img
            src={getImageUrl(property_pictures[0].url, ImageSize.BID_NOW_POPUP)}
            alt=""
          />
        ) : (
          <img className={styles.empty} src={emptyImg} alt="" />
        )}
        <div id={`bidNow-timer-${id}`} className={styles.cardBadge}>
          {isActive && <TimerBadge endDate={ends_at} />}
        </div>
      </picture>
      <div className={styles.cardContent}>
        <div className={styles.cardTitle}>{title}</div>
        <div
          id={`bidNow-current-initial-price-${id}`}
          className={styles.cardPrice}
        >
          <strong>{getCurrencySign(price?.currency)}</strong>
          {getFormattedNumber(price?.value)}
        </div>
        <div className={styles.cardData}>
          <span>{bidderData.text}</span> <strong>{bidderData.nickname}</strong>
        </div>

        <ul className={styles.cardInfo}>
          <li>
            <i className="icon icon-beds"></i>
            {bedrooms}
          </li>
          <li>
            <i className="icon icon-bathrooms"></i>
            {bathrooms}
          </li>
          <li>
            <i className="icon icon-garage"></i>
            {car_spaces}
          </li>
          <li>
            <i className="icon icon-size"></i>
            {getFormattedNumber(house_area)} {units || 'sqft'}
          </li>
        </ul>
        <address className={styles.cardAddress}>
          <i className="icon icon-map"></i>
          <span>{getFormattedAddress(address)}</span>
        </address>
      </div>
    </div>
  );
}
