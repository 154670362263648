const en = {
  // common
  'pagination.perPage': 'per page',
  'Password.tooltip':
    'Password should be at least 6 characters long including number, capital and lowercase letters, special symbol (^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + -). Spaces are not allowed',
  'bidNow.button.Active': 'Bid Now',
  'bidNow.button.Ended': 'Ended',
  'bidNow.button.CommingSoon': 'Comming Soon',
  'bidNow.button.Withdrawn': 'Withdrawn',
  'bidNow.button.Draft': 'Draft',
  'bidNow.button.Pending': 'Pending',
  'bidNow.button.Sold': 'Sold',
  'ListingStatus.Active': 'Active',
  'ListingStatus.Sold': 'Sold',
  'ListingStatus.Draft': 'Draft',
  'ListingStatus.Ended': 'Ended',
  'ListingStatus.ComingSoon': 'Coming Soon',
  'ListingStatus.Withdrawn': 'Withdrawn',
  'ListingStatus.Pending': 'Pending',

  'ListingType.Apartment': 'Apartment',
  'ListingType.Commercial': 'Commercial',
  'ListingType.Condo': 'Condo',
  'ListingType.Duet': 'Duet',
  'ListingType.Duplex': 'Duplex',
  'ListingType.Farm': 'Farm',
  'ListingType.FiveUnits': 'Five+Units',
  'ListingType.Fourplex': 'Fourplex',
  'ListingType.Hotel': 'Hotel',
  'ListingType.House': 'House',
  'ListingType.Industrial': 'Industrial',
  'ListingType.Land': 'Land',
  'ListingType.Luxury': 'Luxury',
  'ListingType.MobileManufacturedHome': 'Mobile/Manufactured Home',
  'ListingType.Studio': 'Studio',
  'ListingType.Townhouse': 'Townhouse',
  'ListingType.Triplex': 'Triplex',
  'ListingType.VacationHome': 'Vacation Home',

  'ListingDetailsBidderData.CurrentBidBy': 'current bid by',
  'ListingDetailsBidderData.InitialPrice': 'Initial Price',
  'ListingDetailsBidderData.PendingPrice': 'Pending Price',
  'ListingDetailsBidderData.SoldPrice': 'Sold Price',
  'ListingDetailsBidderData.LastBidBy': 'last bid by',
  // common

  // common validation
  'validation.required': 'Field is required',
  'validation.maxLength': 'You have reached symbols limit: {{count}}',
  'validation.format': 'Format is not correct',
  'validation.noSpace':
    'Please match the requested format. Spaces are not allowed',
  'validation.awsCognitoFormat':
    'Format is not correct. Please match the requested format',
  'validation.userExists': 'This account already exists. Please sign in',
  'validation.TimeIsUp': 'Time is up. Choose another date',
  'validation.subdomain.format':
    'Format is not correct: special symbol and space are not allowed',
  'validation.PasswordNotMatch': 'Password does not match',
  'validation.mustAgree':
    'You must read and agree to the Terms & Conditions and Privacy Policy in order to proceed',
  // common validation

  //consent popup
  'consentPopUp.Cookies': 'Cookies..?',
  'consentPopUp.Cookies.description':
    'We use cookies to make your experience on this website better.',
  'consentPopUp.ReadMore': 'Read More',
  'consentPopUp.Cookies.Accept': 'Accept',
  //consent popup

  // header
  'header.Listings': 'Listings',
  'header.Dashboard': 'Dashboard',
  'header.More': 'More',
  'header.TermsAndConditions': 'Terms & Conditions',
  'header.PrivacyPolicy': 'Privacy Policy',

  'header.Join': 'Join',
  'header.AboutUs': 'About Us',
  'header.HowToBuy': 'How to Buy',
  'header.SignOut': 'Sign Out',
  'header.MyProfile': 'My Profile',
  'header.certifiedAgents': 'Certified Agents',
  'header.Sellers': 'Sellers',
  'header.FAQ': 'FAQ',

  'headerMobile.PoweredBy': 'Powered by',
  'headerMobile.BidThisProperty': 'Bid This Property',

  // header

  // footer
  'footer.Line1':
    '*All Bid This Property certified Agents are members of the NATIONAL ASSOCIATION OF REALTORS(R) and subscribe to its strict Code of Ethics.',
  'footer.Line2': '© Copyright 2022-2023. All rights reserved',
  'footer.PoweredBy': 'Powered by',
  'footer.BidThisProperty': 'Bid This Property',
  // footer

  // modals
  'SignInModal.SignIn': 'Sign In',
  'SignInForm.NoAccount': 'No account yet?',
  'SignInForm.Register': 'Register',
  'SignInForm.ForgotPassword': 'Forgot Password?',
  'SignInForm.Email': 'Email',
  'SignInForm.Password': 'Password',
  'SignInForm.Cancel': 'Cancel',
  'SignInForm.SignIn': 'Sign In',

  'ForgotPasswordModal.Title': 'Forgot Password',
  'ForgotPasswordModal.SignIn': 'Sign In',
  'ForgotPasswordModal.BackTo': 'Back to ',
  'ForgotPasswordModal.EnterYourEmail': 'Enter your email',
  'ForgotPasswordModal.WeWillSendRecoveryCode1':
    'We’ll send you a recovery code to change your password.',
  'ForgotPasswordModal.WeWillSendRecoveryCode2':
    'If you still need help, please, ',
  'ForgotPasswordModal.ContactUs': 'Contact us',
  'ForgotPasswordModal.Cancel': 'Cancel',
  'ForgotPasswordModal.ResetPassword': 'Request a password reset',

  'ResetPasswordModal.Title': 'Reset Password',
  'ResetPasswordModal.Email': 'Email',
  'ResetPasswordModal.RecoveryCode': 'Recovery Code',
  'ResetPasswordModal.NewPassword': 'New Password',
  'ResetPasswordModal.PlaceholderNewPassword': 'New Password',
  'ResetPasswordModal.PlaceholderRecoveryCode': 'Recovery Code (From Email)',
  'ResetPasswordModal.PlaceholderConfirmNewPassword': 'Confirm New Password',
  'ResetPasswordModal.Cancel': 'Cancel',
  'ResetPasswordModal.Reset': 'Reset',

  'AdminSetPasswordModal.SetPassword': 'Set Password',
  'AdminSetPasswordModal.description':
    'The password you set is secret and known only to you. We highly recommend do not share it with anyone.',
  'AdminSetPasswordModal.placeholder.CreatePassword': 'Create Password',
  'AdminSetPasswordModal.placeholder.ConfirmPassword': 'Confirm Password',
  'AdminSetPasswordModal.Cancel': 'Cancel',
  'AdminSetPasswordModal.button.SetPassword': 'Set Password',
  'AdminSetPasswordModal.checkbox.agree.label':
    'I agree with Terms & Conditions and Privacy Policy',
  'AdminSetPasswordModal.checkbox.agree.info':
    'I am aware that the email and phone number used for my admin account will be used to receive telemarketing and other messages. More details in ',
  'AdminSetPasswordModal.link.termsAndConditions': 'Terms & Conditions ',
  'AdminSetPasswordModal.link.and': 'and ',
  'AdminSetPasswordModal.link.privacyPolicy': 'Privacy Policy',

  'PendingPriceModal.title': 'Confirm Pending Price',
  'PendingPriceModal.description':
    'Please confirm the suggested Pending Price or enter another Pending Price. Pending Price is public and will be displayed for site visitors.',
  'PendingPriceModal.BuyersPremium': "Buyer's Premium",
  'PendingPriceModal.FinalBid': 'Final Bid',
  'PendingPriceModal.PendingPrice': 'PENDING PRICE',
  'PendingPriceModal.SuggestedPrice': 'SUGGESTED PRICE',
  'PendingPriceModal.ChangePrice': 'Change Price',
  'PendingPriceModal.input.PendingPrice': 'Pending Price',
  'PendingPriceModal.input.placeholder': 'Enter Price',
  'PendingPriceModal.Cancel': 'Cancel',
  'PendingPriceModal.Confirm': 'Confirm',
  'PendingPriceModal.ReachedPriceLimit':
    'You have reached price limit: {{limit}}',

  'SoldPriceModal.title': 'Confirm Sold Price',
  'SoldPriceModal.description':
    'Please confirm the suggested Sold Price or enter another Sold Price. Sold Price is public and will be displayed for site visitors.',
  'SoldPriceModal.SoldPrice': 'SOLD PRICE',
  'SoldPriceModal.SuggestedPrice': 'SUGGESTED PRICE',
  'SoldPriceModal.ChangePrice': 'Change Price',
  'SoldPriceModal.input.SoldPrice': 'Sold Price',
  'SoldPriceModal.input.placeholder': 'Enter Price',
  'SoldPriceModal.Cancel': 'Cancel',
  'SoldPriceModal.Confirm': 'Confirm',
  'SoldPriceModal.ReachedPriceLimit': 'You have reached price limit: {{limit}}',

  'WithdrawnModal.title': 'Are you sure you want to withdraw this listing?',
  'WithdrawnModal.description':
    'After you withdraw the listing it will be removed from the public listings page and all approved buyers get an appropriate notification.',
  'WithdrawnModal.Cancel': 'Cancel',
  'WithdrawnModal.Withdraw': 'Withdraw',

  'SignUpModal.Register': 'Register',
  'SignUpForm.HaveAccount': 'Already have an account?',
  'SignUpForm.SignIn': 'Sign In',
  'SignUpForm.Account': 'Account',
  'SignUpForm.Email': 'Email',
  'SignUpForm.Password': 'Password',
  'SignUpForm.ContactInformation': 'Contact Information',
  'SignUpForm.FirstName': 'First Name',
  'SignUpForm.LastName': 'Last Name',
  'SignUpForm.Phone.placeholder': 'Phone (e.g. +12233344445)',
  'SignUpForm.Phone.format':
    'Format is not correct. An example of format is a +12233344445',
  'SignUpForm.Cancel': 'Cancel',
  'SignUpForm.Register': 'Register',
  'SignUpForm.agreement.checkbox':
    'I agree with Terms & Conditions and Privacy Policy',
  'SignUpForm.agreement.info':
    'By providing your email / phone number, you are expressly consenting, in writing, to receive telemarketing and other messages. More details in',
  'SignUpForm.agreement.info.and': 'and',
  'SignUpForm.agreement.info.TermsConditions': 'Terms & Conditions',
  'SignUpForm.agreement.info.PrivacyPolicy': 'Privacy Policy',
  'SignUpForm.AgreementRequired':
    'You must read and agree to the Terms & Conditions and Privacy Policy in order to proceed',

  'SignUpConfirmationModal.Confirmation': 'Confirmation',
  'SignUpConfirmationForm.ConfirmYourEmail': 'Confirm your email',
  'SignUpConfirmationForm.Code': 'Code',
  'SignUpConfirmationForm.ConfirmEmail': 'Confirm Email',
  'SignUpConfirmationForm.Cancel': 'Cancel',
  'SignUpConfirmationForm.NewCodeJustSent': 'New code just sent!',
  'SignUpConfirmationForm.SomethingWrong': 'Something wrong?',
  'SignUpConfirmationForm.ResendCode': 'Resend Code',

  'SignUpConfirmationForm.CodeWasSent': 'Code was sent to',
  'SignUpConfirmationForm.info':
    'Please, enter this code in the text field above, so we can confirm you entered the right email and everything is working normally.',

  'BidNowModal.step.SignIn': 'Sign In',
  'BidNowModal.step.Register': 'Register',
  'BidNowModal.step.Confirmation': 'Confirmation',
  'BidNowModal.step.GetApproved': 'Get Approved',
  'BidNowModal.step.BidNow': 'Bid Now',

  'GetApprovedDataForm.BiddingNickname': 'Bidding Nickname',
  'GetApprovedDataForm.ConfirmStatus': 'Please, confirm your status',
  'GetApprovedDataForm.BuyerStatus.BuyerWithoutAgent':
    "I'm a buyer without an agent",
  'GetApprovedDataForm.BuyerStatus.BuyerWithAgent': "I'm a buyer with an agent",
  'GetApprovedDataForm.BuyerStatus.Agent': "I'm an agent",
  'GetApprovedDataForm.BuyerStatus.required': 'Status confirmation is required',
  'GetApprovedDataForm.DRELicenseNumber': 'DRE License Number',
  'GetApprovedDataForm.SubmitPreapproval':
    'Submit preapproval letter and/or proof of funds to the listing agent',
  'GetApprovedDataForm.FileFormats':
    'Use .doc, .docx, .pdf, .xls, .xlsx file types, up to 20 MB each',
  'GetApprovedDataForm.DocumentRequired': 'Document upload is required',
  'GetApprovedDataForm.SecureInformation':
    'Make sure you do not provide us secure information about your bank account',
  'GetApprovedDataForm.agreement.checkbox':
    'I confirm that I read and agree with Buyer’s Premium rule',
  'GetApprovedDataForm.agreement.info':
    'Buyer’s Premium is a percentage fee paid by the Buyer. The amount of the Buyer’s Premium will be added on top of the winning bid which shall establish the final sales price. Winning Bid + Buyer’s Premium = Sales Price.',
  'GetApprovedDataForm.AgreementRequired':
    'Confirmation of agreement  is required',
  'GetApprovedDataForm.Cancel': 'Cancel',
  'GetApprovedDataForm.GetApproved': 'Get Approved',

  'GetApprovedSuccessStep.ThankYou': 'Thank You!',
  'GetApprovedSuccessStep.review':
    'Agent will review your documents and get back to you soon',
  'GetApprovedSuccessStep.Refresh': 'Refresh',
  'GetApprovedSuccessStep.Close': 'Close',

  'VoidBidConfirmationModal.title': 'Are you sure you want to void this bid?',
  'VoidBidConfirmationModal.info1':
    'When you void a bid the system keeps the highest bidder, but limit their bid to its current level. The buyer gets an email notification that their bid is voided.',
  'VoidBidConfirmationModal.info2':
    'It is not possible to unvoid a bid. Buyer should submit another bid, if they want to win this property.',
  'VoidBidConfirmationModal.infobox':
    'If you void an autobid please make sure you disable autobid for the bidder first. Otherwise, the same autobid will be triggered again.',
  'VoidBidConfirmationModal.Cancel': 'Cancel',
  'VoidBidConfirmationModal.VoidBid': 'Void Bid',
  'VoidBidConfirmationModal.error': 'Please refresh page or try later',

  'DisableAutoList.Autobid': 'Autobid',
  'DisableAutoList.AutobidEnabled': 'Autobid Enabled',
  'DisableAutoList.MaxBid': 'MaxBid',
  'DisableAutoList.DisableAutobid': 'Disable Autobid',

  'DisableAutobidConfirmationModal.title':
    'Are you sure you want to disable autobid for this user?',
  'DisableAutobidConfirmationModal.info1':
    'When you disable autobid for user system will no longer autobid on behalf of user.',
  'DisableAutobidConfirmationModal.info2':
    'It is not possible for you to enable autobid for this user again.',
  'DisableAutobidConfirmationModal.info3':
    'Buyer should set autobid with maximum price again, if they want to win this property.',
  'DisableAutobidConfirmationModal.Cancel': 'Cancel',
  'VoidBidConfirmationModal.DisableAutobid': 'Disable Autobid',
  'DisableAutobidConfirmationModal.error': 'Please refresh page or try later',

  // modals

  // pages
  'HomePage.banner1': 'Bringing Transparency to Real Estate,',
  'HomePage.banner2': 'One Bid at a Time',
  'HomePage.SeeMore': 'See More',
  'HomePage.search.placeholder': 'Search by address, city etc.',
  'HomePage.search.button': 'Search',
  'HomePage.sellers.info': 'Need to sell a house?',
  'HomePage.sellers.link': 'Get More Info',
  'HomePageAdvertisingBanner.banner':
    'If you are interested in how this tool can help you and your brokerage, please reach out to',

  'ListingsPage.banner1': 'Bringing Transparency to Real Estate,',
  'ListingsPage.banner2': 'One Bid at a Time',
  'ListingsPage.search.placeholder': 'Search by address, city etc.',
  'ListingsPage.search.button': 'Search',

  'AboutUsPage.Bid': 'Bid This Property?',
  'AboutUsPage.WhatIs': 'What is',
  'AboutUsPage.WhatIs.title':
    'So, how does Bid This Property REALLY work, and how does it benefit you?',
  'AboutUsPage.WhatIs.description1':
    'You get to skip the unnecessary time commitments and the paperwork. Bid This Property is a combination of the most excellent parts of old-school real estate and real estate auction. It’s a process that’s beneficial and easier than the traditional processes available to sellers and buyers. It takes the blindfold off all parties in the bidding games and provides a transparent, truthful transaction.',
  'AboutUsPage.WhatIs.description2':
    'BTP gives sellers and buyers more choices, more freedom, and more hands-on visibility throughout the journey.',
  'AboutUsPage.WhatIs.description3':
    'Using BTP, the real estate commission can be a team effort, paid by both buyer and seller, or just one or the other. “Buyerʼs premium” is the buyerʼs side of the commission. The sales price breaks down to the highest bid plus the buyerʼs premium combined.',

  'AboutUsPage.YouHaveQuestions': 'You have questions,',
  'AboutUsPage.HereAreTheDeets': 'here are the deets!',
  'AboutUsPage.HereAreTheDeets.title':
    'We were not kidding when we told you there are benefits!',
  'AboutUsPage.HereAreTheDeets.description': [
    'As a seller, you are in control. You can Love it or Leave it or Change it! You can counteroffer or negotiate; the choice is yours.',
    'The visibility is real! You can see the highest bid and the bid history on the website any old time you’d like.',
    'You will not get stuck in any kind of long, binding listing contracts.',
    'All the bidding is done online, and you don’t even have to stand up to do it.',
    'Buyers can be approved to start that bidding, either by instant buyer approval online with a credit card or by the listing agent.',
    'There are zero fees right off the bat from the seller.',
    'Major bonus; you don’t have to wait around for the property inspector before you can accept the highest bid.',
    'Escrow time! This goes down when the seller accepts the highest bid, and all the buyers’ inspection time frame and contingencies fall in line with the purchase contract particular to the customs where the property is located.',
    'Awesome for both buyers and sellers, the property can only be viewed on pre-disclosed open house dates (unless you can sweet talk a different deal).',
  ],
  'AboutUsPage.description.column1.thisIsSome':
    'This is some really cool, innovative, and efficient real estate technology right here. You still get expert Broker advocates, and specialists in professional marketing, but you donʼt have to put on pants!',

  'AboutUsPage.description.column1.inJustTwo':
    'In just two weeks and some change, Bid This Property can garner multiple offers on your property. Sellers have way more control and transparency from start to finish.',

  'AboutUsPage.description.column1.ditchTheBig':
    'Ditch the big ugly nonrefundable deposit required at auctions, before buyers can go into escrow with no power provided by contingencies.',

  'AboutUsPage.description.column2.youcanwatch':
    'You can watch every detail of the process in real-time, without ever having to walk out your front door. BTP provides buyers and sellers with control through transparency and the ultimate level of convenience.',
  'AboutUsPage.description.column2.saveABoat':
    'Save a boatload of money on commissions, be the person calling shots, and save your immensely valuable time by using Bid This Property.',

  'HowToBuyPage.YouLookReady': 'You look ready to buy!',
  'HowToBuyPage.HeresNow': 'Here’s how:',
  'HowToBuyPage.commingSoon': 'coming soon!',
  'HowToBuyPage.YouLookReady.description1':
    'When you are ready to take the glorious leap of purchasing a home, Bid This Property is the most awesomely beneficial way to go!',
  'HowToBuyPage.YouLookReady.description2':
    'Picking and purchasing a home is such a big, exciting life move. Buyers’ agents at Bid This Property can guide you every step of the way, with solely your best interest in mind. The goal is for you to smoothly and flawlessly move through the journey of a wonderful home purchase. Often when dealing with agents at an open house, or dealing with the homeowners, they have a different priority. Using an experienced Bid This Property buyer’s agent, you are guaranteed a highly qualified advocate in the process of procuring your dream home. Your house is possibly one of your biggest financial assets, it’s important that you get what you want for the right price. A buyer’s agent can identify important factors such as what is common and make certain that buying this house is an excellent investment.',
  'HowToBuyPage.YouLookReady.description3':
    'Something so valuable in life is worth bringing in a pro. When it comes down to the cost, the seller is the one who pays the commission. So, there’s no price tag holding you back from getting a specialist real estate broker with your perfect home purchase, at the best possible price, as their ultimate goal.',

  'HowToBuyPage.GettingStarted': 'Getting Started',
  'HowToBuyPage.GettingStarted.GetSignIn': 'Get Signed In',
  'HowToBuyPage.GettingStarted.GetSignIn.info1':
    'If you’re ready and you’d like to start bidding, you have to register on the website. Click on the Join in website’s header and choose the Register option.',
  'HowToBuyPage.GettingStarted.GetSignIn.info2':
    'The fields on the Register pop-up are required to fill in. Save your passwords etc., you need it to log back on in the future.',

  'HowToBuyPage.GettingStarted.AgentsWhoRep': 'Agents Who Rep',
  'HowToBuyPage.GettingStarted.AgentsWhoRep.info1':
    'If you are looking for an agent to navigate this buying process, please contact us.',
  'HowToBuyPage.GettingStarted.AgentsWhoRep.info2':
    'If you are an agent that’s already been brought on board to make dreams happen for a buyer, register as a common buyer and enter your license number in the provided space on Get Approved pop-up. After you choose the “I’m an agent” status option appropriate field is displayed for you.',

  'HowToBuyPage.GettingStarted.GetYourselfApproved': 'Get Yourself Approved',
  'HowToBuyPage.GettingStarted.GetYourselfApproved.info':
    'You have to be Approved before you can start to Bid or Offer.',

  'HowToBuyPage.GettingApproved': 'Getting Approved',
  'HowToBuyPage.GettingApproved.GetThatListingAgent':
    'Get that listing/agent approval',
  'HowToBuyPage.GettingApproved.GetThatListingAgent.info':
    'After you have pushed the Bid Now button, simply send the listing agent the preapproval letter or proof of funds (if cash).',
  'HowToBuyPage.GettingApproved.AboutApproval.title': 'About Approval',
  'HowToBuyPage.GettingApproved.AboutApproval.description': [
    'Set your nickname for bidding. You can use one nickname for bidding on one property.',
    'Confirm your status so a listing agent is aware of your collaboration with an agent.',
    'Provide documents. You can upload Preapproval or Proof of funds, and both of them. Be sure you use the appropriate file type and allowed size (up to 20 MB).',
    'Important! The listing agent does not need secure information about your bank account. Before providing a document check it for private information absence.',
    'Confirm that you read and agree with the Buyers’ Premium rule (The buyer’s premium percentage will be added to the final bid to establish the sales price once bidding is completed).',
    'After you submit your request a listing agent will review it and approve you for bidding. You will get the appropriate notification.',
  ],

  'HowToBuyPage.AutoBidAction': 'Auto Bid Action',
  'HowToBuyPage.AutoBidAction.info':
    'You can set your bid to the “maximum high”, the most you’re willing to bid for the property. The auto bid works by raising your bid by a pre-determined bid increment at a time, above the bid placed prior, until you hit your cap number.',
  'HowToBuyPage.AutoBidAction.HereWhatItLooksLike.title':
    'Here’s what it looks like',
  'HowToBuyPage.AutoBidAction.HereWhatItLooksLike.info':
    'The bid is at $100,000, and your ceiling is set at $150,000. The auto bid function will raise you to $101,000, and you’ll be the bidder on top. If another participant bids $102,000, the auto bid will raise you to $103,000 and so on until you reach your maximum bid or you are accepted by the seller as the highest bid.',

  'HowToBuyPage.LastSecondBidders': 'Last Second Bidders',
  'HowToBuyPage.LastSecondBidders.info':
    'Auto bid prevents people from sneaking in a last-second bid, by automatically extending it another extra amount of minutes, so you have time to respond. If a bidder places a bid in the last 5 minutes, it auto extends 10 minutes, to keep the response time fair.',
  'HowToBuyPage.LastSecondBidders.box.title':
    'If you get the highest bid, woo hoo!',
  'HowToBuyPage.LastSecondBidders.box.info':
    'If you get in the highest bid, you now need seller acceptance, your agent should submit your signed purchase agreement to the seller in the time window detailed in the “Sale Terms and Conditions”. If you don’t have an agent, we will hook you up with one!',

  'HowToBuyPage.HeadsUpHighestBidder': 'Heads up highest bidder',
  'HowToBuyPage.HeadsUpHighestBidder.title':
    'If you are selected as the highest bidder at the end of an auction, and you jump ship after being deemed the winning bid, you are breaching our terms of use. You may be charged a $500 withdrawal penalty fee, you will receive an invoice.',
  'HowToBuyPage.HeadsUpHighestBidder.box.title':
    'Important Notice to Winning Bidder',
  'HowToBuyPage.HeadsUpHighestBidder.box.info':
    'Also, be aware that as the highest bidder you now have the first chance to get the seller to accept your offer knowing that they can counter or reject your offer for any reason. Make sure to send over your best terms so the seller does not even think twice and signs on the dotted line.',

  'ListingDetailsPage.SellersReserves.infoBox':
    'Seller reserves the right to accept, counter, or reject any bids!',
  'ListingDetailsPage.SellersReserves.info':
    'Once the seller accepts the winning bid, everything follows like in any regular sale, the buyer will go in escrow with all buyer’s contingencies and inspections time frame. For more information, contact Listing Agent!',
  'ListingDetailsPage.WinningBid.infoBox':
    'Winning Bid + Buyer’s Premium = Sales Price',
  'ListingDetailsPage.WinningBid.info1.bold': 'The winning bidder ',
  'ListingDetailsPage.WinningBid.info1.text':
    'shall provide a purchase contract within 24 hours from the time of acceptance of the winning bid by the seller. If the purchase contract is not provided within 24 hours, the seller reserves the right to accept other offers.',
  'ListingDetailsPage.WinningBid.info2.bold': 'Bidding Auto Extend ',
  'ListingDetailsPage.WinningBid.info2.text':
    '— Our system PREVENTS LAST MINUTE bidding by AUTOMATICALLY EXTENDING the bidding time. If a bidder places a bid within 5 minutes of the end of the bidding time, the system will automatically extend the bidding time by 10 minutes in order to allow other bidders the opportunity to respond.',
  'ListingDetailsPage.MostViewedProperty': 'Most viewed property!',
  'ListingDetailsPage.Viewed': 'viewed',
  'ListingDetailsPage.Address': 'address',
  'ListingDetailsPage.ListingAgent': 'Listing agent',
  'ListingDetailsPage.BidIncrement': 'Bid Increment',
  'ListingDetailsPage.BuyersPremium': 'Buyer’s Premium',
  'ListingDetailsPage.BuyersPremium.info':
    '(Buyer’s premium will be added to final bid to establish sales price)',
  'ListingDetailsPage.Type': 'Type',
  'ListingDetailsPage.Built': 'Built',
  'ListingDetailsPage.LotSize': 'Lot Size',
  'ListingDetailsPage.ContactAgent': 'Contact Agent',
  'ListingDetailsPage.FAQ': 'FAQ',
  'ListingDetailsPage.BidNowModal.Title': 'Bid Now',
  'ListingDetailsPage.BidNowModal.MinimumBidIncrement':
    'Minimum Bid Increment: {{currency}} {{count}} ',
  'ListingDetailsPage.BidNowModal.ConfirmButton': 'Bid Now',
  'ListingDetailsPage.BidNowModal.CancelButton': 'Cancel',
  'ListingDetailsPage.BidNowModal.IncorrectBidAmount':
    'Incorrect bid amount or price is lower than bid increment',
  'ListingDetailsPage.BidNowModal.ReachedPriceLimit':
    'You have reached price limit: {{counter}}',
  'ListingDetailsPage.BidNowModal.YourBidPlaceholder': 'Your Bid',
  'ListingDetailsPage.BidNowModal.InitialPice': 'Initial Price',
  'ListingDetailsPage.YourBidPlacedSuccessfully':
    'Your Bid Placed Successfully!',
  'ListingDetailsPage.YourBidPlacedSuccessfully.infobox':
    'If you are busy put your maximum bid next time and we will auto bid for you.',
  'ListingDetailsPage.Disclaimer':
    'Information deemed reliable but not guaranteed. Buyer to verify',
  'ListingDetailsPage.BidNowModal.Enabled': 'Auto Bid Enabled',
  'ListingDetailsPage.BidNowModal.Disabled': 'Auto Bid Disabled',
  'ListingDetailsPage.BidNowModal.AutoBid.PlaceYourMaximumBid':
    'Place your maximum bid here, if you are busy like we are and want the system to bid for you. With auto bid enabled, we will bid with minimum increments until you hit your maximum bid.',
  'ListingDetailsPage.BidNowModal.AutoBid.YourNextBidWillBe':
    'Your next bid would be ',
  'ListingDetailsPage.BidNowModal.AutoBid.SetYourMaximumPrice':
    'Set your maximum price respecting the minimum bid increment',

  'Dashboard.ListingsManagement.NewListing': 'New Listing',
  'Dashboard.ListingsManagement.Listings': 'Listings',
  'Dashboard.ListingsManagement.Table.Address': 'Address',
  'Dashboard.ListingsManagement.Table.MarketCenter': 'Market Center',
  'Dashboard.ListingsManagement.Table.Brokerage': 'Brokerage',
  'Dashboard.ListingsManagement.Table.ListingAgent': 'Listing Agent',
  'Dashboard.ListingsManagement.Table.DatePublished': 'Date Published',
  'Dashboard.ListingsManagement.Table.Status': 'Status',
  'Dashboard.ListingsManagement.Table.EndsInEnded': 'Ends In/Ended',
  'Dashboard.ListingsManagement.Table.EndsIn.LessDay': 'less 1 day',
  'Dashboard.ListingsManagement.Table.EndsIn.Day_one': '{{count}} day',
  'Dashboard.ListingsManagement.Table.EndsIn.Day_other': '{{count}} days',
  'Dashboard.ListingsManagement.Table.Manage': 'Manage',

  'Dashboard.BrokeragesManagement.Brokerages': 'Brokerages',
  'Dashboard.BrokeragesManagement.NewBrokerage': 'New Brokerage',
  'Dashboard.BrokeragesManagement.Table.Name': 'Name',
  'Dashboard.BrokeragesManagement.Table.MarketCenter': 'Market Center',
  'Dashboard.BrokeragesManagement.Table.Address': 'Address',
  'Dashboard.BrokeragesManagement.Table.State': 'State',
  'Dashboard.BrokeragesManagement.Table.Link': 'Link',

  'Dashboard.Sidebar.AdminsAndAgents': 'Admins & Agents',
  'Dashboard.AdminsAndAgentsPage.AdminsAndAgents': 'Admins & Agents',
  'Dashboard.AdminsAndAgentsPage.NewUser': 'New User',
  'Dashboard.AdminsAndAgentsTable.Name': 'Name',
  'Dashboard.AdminsAndAgentsTable.Email': 'Email',
  'Dashboard.AdminsAndAgentsTable.Role': 'Role',
  'Dashboard.AdminsAndAgentsTable.MarketCenter': 'Market Center',
  'Dashboard.AdminsAndAgentsTable.Brokerage': 'Brokerage',

  'AdminsAndAgentsFilter.All': 'All',
  'AdminsAndAgentsFilter.SiteAdministrators': 'Site Administrators',
  'AdminsAndAgentsFilter.Agents': 'Agents',

  'getUserRoleName.Agent': 'Agent',
  'getUserRoleName.SiteAdministrator': 'Site Administrator',

  'BidManagement.Tabs.AllBids': 'All Bids',
  'BidManagement.Tabs.AllBids.Empty': 'No Bids yet',
  'BidManagement.Tabs.ApprovedBuyers': 'Approved Buyers',
  'BidManagement.Tabs.ApprovedBuyers.Empty': 'No Approved Buyers yet',
  'BidManagement.Tabs.PendingRequests': 'Pending Requests',
  'BidManagement.Tabs.PendingRequests.Empty': 'No Pending Requests yet',

  'BidManagement.Tabs.AllBids.Table.Date.CurrentBid': 'Current Bid',
  'BidManagement.Tabs.AllBids.Table.Date.LastBid': 'Last Bid',
  'BidManagement.Tabs.AllBids.Table.Date.FinalBid': 'Final Bid',
  'BidManagement.Tabs.AllBids.Table.Bids': 'Bids',

  'BidManagement.Tabs.Table.Date': 'Date',
  'BidManagement.Tabs.Table.Buyer': 'Buyer',
  'BidManagement.Tabs.Table.Buyer.WithAgent': 'works with an agent / broker',
  'BidManagement.Tabs.Table.Buyer.NoAgent': 'does not have an agent',
  'BidManagement.Tabs.Table.Buyer.BuyerAgent': 'buyer’s agent',
  'BidManagement.Tabs.Table.Contacts': 'Contacts',
  'BidManagement.Tabs.Table.ApprovalInformation': 'Approval Information',
  'BidManagement.Tabs.Table.ApprovalInformation.Approve': 'Approve',
  'BidManagement.Tabs.Table.ApprovalInformation.Approved': 'Approved',
  'BidManagement.Tabs.Table.VoidedBy': 'Voided By',
  'BidManagement.Tabs.Table.VoidABid': 'Void a Bid',
  'BidManagement.Tabs.Table.DownloadCSV': 'Download CSV',

  'NewListingPage.NewListing': 'New Listing',
  'NewListingPage.SaveDraft': 'Save Draft',
  'NewListingPage.Publish': 'Publish',
  'NewListingPage.Update': 'Update',
  'NewListingPage.BasicParameters': 'Basic Parameters',
  'NewListingPage.Title': 'Title',
  'NewListingPage.TypeAddress': 'Type Address',
  'NewListingPage.City': 'City',
  'NewListingPage.StateAbbr': 'State (Abbr.)',
  'NewListingPage.ZIPcode': 'ZIP code',
  'NewListingPage.DetailedParameters': 'Detailed Parameters',
  'NewListingPage.EndDateTime': 'End Date & Time',
  'NewListingPage.SelectDateTime': 'Select Date and Time',
  'NewListingPage.StartingBid': 'Starting Bid',
  'NewListingPage.EnterPrice': 'Enter Price',
  'NewListingPage.BuyersPremium': "Buyer's Premium",
  'NewListingPage.BidIncrement': 'Bid Increment',
  'NewListingPage.Details': 'Details',
  'NewListingPage.Type': 'Type',
  'NewListingPage.SearchToSelect': 'Search to Select',
  'NewListingPage.Built': 'Built',
  'NewListingPage.YEAR': 'YEAR',
  'NewListingPage.HouseArea': 'House Area',
  'NewListingPage.LotArea': 'Lot Area',
  'NewListingPage.Beds': 'Beds',
  'NewListingPage.Baths': 'Baths',
  'NewListingPage.CarSpaces': 'Car Spaces',
  'NewListingPage.Description': 'Description',
  'NewListingPage.Subtitle': 'Subtitle (optional)',
  'NewListingPage.FullDescription': 'Full description of property',
  'NewListingPage.UploadPhotos': 'Upload Photos',
  'NewListingPage.PropertyPictures': 'Property Pictures',
  'NewListingPage.validation.AddMorePhotos': 'Add more photos',
  'NewListingPage.PropertyStatus': 'Property Status',
  'NewListingPage.ChangesSavedSuccessfully': 'Changes saved successfully!',
  'NewListingPage.video.AddVideoByURL': 'Add Video by URL',
  'NewListingPage.video.description':
    'We accept links to videos on YouTube, Vimeo, and Matterport',
  'NewListingPage.video.placeholder': 'Paste the link here',
  'NewListingPage.video.AddOtherLink': 'Add other link',
  'NewListingPage.validation.VideoURL':
    'Please make sure you use the correct link. Use an embed link format to share',
  'NewListingPage.status.restriction':
    'You are not allowed to change listing status',
  'NewListingPage.soldPrice.restriction':
    'Sold price is required for sold status',
  'NewListingPage.pendingPrice.restriction':
    'Pending price is required for pending status',

  'VideoTutorial.rightLink.header': 'How to find the right link?',
  'VideoTutorial.rightLink.description':
    'Only embed links may be used to share the property video(s) with site visitors on the listing details page',
  'VideoTutorial.YouTube.step1': 'Click/tap on "Share"',
  'VideoTutorial.YouTube.step2': 'Choose "Embed"',
  'VideoTutorial.YouTube.step3':
    'Copy the source link from iframe code text, for example:',
  'VideoTutorial.Vimeo.step1': 'Click/tap on "Share"',
  'VideoTutorial.Vimeo.step2': 'Scroll to "Embed"',
  'VideoTutorial.Vimeo.step3':
    'Copy the source link from iframe code text, for example:',
  'VideoTutorial.Matterport.step1': 'Click/tap on "Share"',
  'VideoTutorial.Matterport.step2': 'Choose "Copy Embed"',
  'VideoTutorial.Matterport.step3':
    'Copy the source link from iframe code text, for example:',

  'NewBrokeragePage.NewBrokerage': 'New Brokerage',
  'NewBrokeragePage.Next': 'Next',
  'NewBrokeragePage.Back': 'Back',
  'NewBrokeragePage.Update': 'Update',
  'NewBrokeragePage.Save': 'Save',
  'NewBrokeragePage.Name': 'Brokerage Name',
  'NewBrokeragePage.Name.placeholder': 'Create Name',
  'NewBrokeragePage.MarketCenter': 'Market Center',
  'NewBrokeragePage.MarketCenter.placeholder': 'Example123',
  'NewBrokeragePage.BrokerageSiteName': 'Brokerage Site Name',
  'NewBrokeragePage.BrokerageSiteName.placeholder': 'Create Name',
  'NewBrokeragePage.Subdomain': 'Subdomain',
  'NewBrokeragePage.Subdomain.placeholder': 'typenameofyourcompany',
  'NewBrokeragePage.Subdomain.popover.text1':
    'Every brokerage gets a custom subdomain which looks like this:',
  'NewBrokeragePage.Subdomain.popover.text2':
    'https://<your-subdomain>.{{domain}} ',
  'NewBrokeragePage.Subdomain.popover.text3':
    'You can choose any subdomain part if it’s not used yet.',
  'NewBrokeragePage.Logos.title': 'Logos',
  'NewBrokeragePage.ColorLogo.title':
    'Submit color or black logo with transparent background',
  'NewBrokeragePage.ColorLogo.subtitle':
    'Use .png or .svg file type, at least 150x70px',
  'NewBrokeragePage.ColorLogo.popover':
    'The logo will be used for the site header on all site pages. Make sure the resolution of your file is appropriate for achieving the best logo view on the site.',
  'NewBrokeragePage.WhiteLogo.title':
    'Submit white logo with transparent background',
  'NewBrokeragePage.WhiteLogo.subtitle':
    'Use .png or .svg file type, at least 150x70px',
  'NewBrokeragePage.WhiteLogo.popover':
    'The logo will be used for the Home page. Make sure the resolution of your file is appropriate for achieving the best logo view on the site. If you do not provide this variant of logo the color logo will be used.',
  'NewBrokeragePage.GreyLogo.title':
    'Submit grey logo with transparent background',
  'NewBrokeragePage.GreyLogo.subtitle':
    'Use .png or .svg file type, at least 150x70px',
  'NewBrokeragePage.GreyLogo.popover':
    'The logo will be used for the site footer. Make sure the resolution of your file is appropriate for achieving the best logo view on the site. If you do not provide this variant of logo the color logo will be used.',
  'NewBrokeragePage.Favicon.title': 'Submit square logo (favicon)',
  'NewBrokeragePage.Favicon.subtitle':
    'Use .ico file type 32x32 px or 48x48 px',
  'NewBrokeragePage.Favicon.popover1':
    'A favicon is a small square image that displays next to a page’s title in browser tabs. Adding a custom favicon makes your site recognizable in a browser full of tabs. Make sure the resolution of your file is appropriate for achieving the best logo view in the tab.',
  'NewBrokeragePage.Favicon.popover2': 'You can use',
  'NewBrokeragePage.Favicon.popover3': 'to convert your image to .ico format.',
  'NewBrokeragePage.OfficePhoneNumber': 'Office Phone Number',
  'NewBrokeragePage.OfficePhoneNumber.placeholder': '+1 223 334 4445',
  'NewBrokeragePage.OfficeEmail': 'Office Email',
  'NewBrokeragePage.OfficeEmail.placeholder': 'mail@mail.com',
  'NewBrokeragePage.OfficeAddress': 'Office Address',
  'NewBrokeragePage.OfficeAddress.placeholder': 'Type Address',
  'NewBrokeragePage.City.placeholder': 'City',
  'NewBrokeragePage.State.placeholder': 'State(Abbr.)',
  'NewBrokeragePage.Zip.placeholder': 'ZIP code',
  'NewBrokeragePage.LinkedInProfile': 'LinkedIn Profile',
  'NewBrokeragePage.LinkedinProfile.placeholder':
    'https://www.linkedin.com/company/yourcompany/',
  'NewBrokeragePage.FacebookProfile': 'Facebook Profile',
  'NewBrokeragePage.FacebookProfile.placeholder':
    'https://www.facebook.com/yourcompany',
  'NewBrokeragePage.DRE.title': 'Brokerage DRE license',
  'NewBrokeragePage.DRE.label': 'DRE license',
  'NewBrokeragePage.DRE.placeholder': '12 34 56 78',
  'NewBrokeragePage.HomePage': 'Home Page',
  'NewBrokeragePage.HomePage.Headline': 'Headline',
  'NewBrokeragePage.HomePage.Headline.placeholder':
    'Headline up to 72 characters',
  'NewBrokeragePage.HomePage.Image.title': 'Please submit hero image or photo',
  'NewBrokeragePage.HomePage.Image.popover':
    'The image will be used for the Home page. Make sure resolution of your file is appropriate for achieving the best picture view on the site. If you do not provide file the Bid This Property hero image will be used.',
  'NewBrokeragePage.HomePage.Image.subtitle':
    'Use .png or .jpg file type, at least 1400x700px',
  'NewBrokeragePage.ListingsPage': 'Listings Page',
  'NewBrokeragePage.ListingsPage.Headline': 'Headline',
  'NewBrokeragePage.ListingsPage.Headline.placeholder':
    'Headline up to 72 characters',
  'NewBrokeragePage.AboutUsPage': 'About Us Page',
  'NewBrokeragePage.AboutUsPage.FileSubmitting.validation': 'Submit four files',
  'NewBrokeragePage.AboutUsPage.Image.title': 'Please submit 4 images',
  'NewBrokeragePage.AboutUsPage.Image.popover':
    'The images will be used for the About Us page carousel. Make sure the resolution of your files are appropriate for achieving the best pictures view on the site. If you do not provide four files the Bid This Property photos will be used.',
  'NewBrokeragePage.AboutUsPage.Image.subtitle':
    'Use .png or .jpg file type, at least 1200x700px',
  'NewBrokeragePage.AboutUsPage.Section1': 'Section 1',
  'NewBrokeragePage.AboutUsPage.Section1.MainHeadline': 'Main Headline',
  'NewBrokeragePage.AboutUsPage.Section1.MainHeadline.placeholder':
    'Headline up to 40 characters',
  'NewBrokeragePage.AboutUsPage.Section1.Subtitle': 'Subtitle',
  'NewBrokeragePage.AboutUsPage.Section1.Subtitle.placeholder':
    'Subtitle up to 110 characters',
  'NewBrokeragePage.AboutUsPage.Section1.Body.placeholder':
    'Body text, up to 1000 characters',
  'NewBrokeragePage.AboutUsPage.Section2': 'Section 2',
  'NewBrokeragePage.AboutUsPage.Section2.MainHeadline': 'Main Headline',
  'NewBrokeragePage.AboutUsPage.Section2.MainHeadline.placeholder':
    'Headline up to 40 characters',
  'NewBrokeragePage.AboutUsPage.Section2.Subtitle': 'Subtitle',
  'NewBrokeragePage.AboutUsPage.Section2.Subtitle.placeholder':
    'Subtitle up to 110 characters',
  'NewBrokeragePage.AboutUsPage.Section2.Body.placeholder':
    'Body text, up to 1000 characters',
  'NewBrokeragePage.AboutUsPage.Section3': 'Section 3',
  'NewBrokeragePage.AboutUsPage.Section3.Image.title': 'Please submit image',
  'NewBrokeragePage.AboutUsPage.Section3.Image.popover':
    'The image will be used for the About Us page (at the bottom of the screen). Make sure the resolution of your file is appropriate for achieving the best picture view on the site. If you do not provide a file the Bid This Property photo will be used.',
  'NewBrokeragePage.AboutUsPage.Section3.Image.subtitle':
    'Use .png or .jpg file type, at least 1200x700px',
  'NewBrokeragePage.AboutUsPage.Section3.Body.placeholder':
    'Body text, up to 1000 characters',
  'NewBrokeragePage.infoBox':
    'Creating a brokerage site may take up to 15 minutes to complete',
  'NewBrokeragePage.ChangesSavedSuccessfully': 'Changes saved successfully!',

  'NewUserPage.NewUser': 'New User',
  'NewUserPage.Save': 'Save',
  'NewUserPage.Update': 'Update',
  'NewUserPage.Realty': 'Realty',
  'NewUserPage.Role': 'Role',
  'NewUserPage.Brokerage': 'Brokerage',
  'NewUserPage.Brokerage.placeholder': 'Select market center/brokerage',
  'NewUserPage.Profile': 'Profile',
  'NewUserPage.FirstName': 'First Name',
  'NewUserPage.FirstName.placeholder': 'First Name',
  'NewUserPage.LastName': 'Last Name',
  'NewUserPage.LastName.placeholder': 'Last Name',
  'NewUserPage.Email': 'Email',
  'NewUserPage.Email.placeholder': 'mail@mail.com',
  'NewUserPage.Phone': 'Phone',
  'NewUserPage.Phone.placeholder': '+1 223 334 4445',
  'NewUserPage.Phone.popover':
    'Provide a phone number used to conduct business. It will be displayed on all listings, in the user’s contact info  shared via notifications, or in the Bid This Property Certified Agent list.',
  'NewUserPage.Phone.format':
    'Format is not correct. An example of format is a +12233344445',
  'NewUserPage.License': 'License',
  'NewUserPage.License.placeholder': 'DRE number',
  'NewUserPage.Title': 'Title',
  'NewUserPage.Title.placeholder': 'Realtor / Broker',
  'NewUserPage.Title.popover':
    'Share the title of an industry professional. It will be displayed in the Certified Agent list.',
  'NewUserPage.ServiceArea': 'Service Area',
  'NewUserPage.ServiceArea.placeholder': 'Add ZIP',
  'NewUserPage.ProfilePicture': 'Profile Picture',
  'NewUserPage.Add.Success': 'User added successfully!',
  'NewUserPage.Edit.Success': 'Changes saved successfully!',
  'NewUserPage.role.option.siteAdministrator': 'Site Administrator',
  'NewUserPage.role.option.agent': 'Agent',
  'NewUserPage.validation.maxLength':
    'You have reached zip codes limit: {{count}}',

  'EmailUnsubscribePage.title':
    'Are you sure you want to unsubscribe from email notifications?',
  'EmailUnsubscribePage.text':
    'In this case you will no longer receive email notifications on',
  'EmailUnsubscribePage.text2':
    'about this bidding and all future bidding that you will attend.',
  'EmailUnsubscribePage.Unsubscribe': 'Unsubscribe',
  'EmailUnsubscribePage.Cancel': 'Cancel',
  'EmailUnsubscribePage.success.title':
    'You have been successfully unsubscribed from email notifications',
  'EmailUnsubscribePage.success.text':
    'You will no longer receive email notifications on',
  'EmailUnsubscribePage.success.text2':
    'about this bidding and all future bidding that you will attend. If you did this in error, you may re-subscribe on your Profile page.',
  'EmailUnsubscribePage.success.GoToHomepage': 'Go to Homepage',

  'MyProfile.Update': 'Update',
  'MyProfile.Subscriptions': 'Subscriptions',
  'MyProfile.EmailSubscription': 'Email Subscription',
  'MyProfile.AllUpdates':
    'ALL updates about bidding on the property and site promo news. But you still receive technical emails (e.g. password reset).',
  // pages

  // components
  'SearchInput.buttonName': 'Search',

  'AgentCard.TextOrCall': 'TEXT OR CALL US',
  'AgentCard.DRE': 'DRE #',

  'CertifiedAgents.header': 'Certified Agents',
  'CertifiedAgents.found_one': '{{count}} agent found',
  'CertifiedAgents.found_other': '{{count}} agents found',
  'CertifiedAgents.NoUser.header': 'No users match your search',
  'CertifiedAgents.NoUser.subheader': 'Please try searching again',

  'CertifiedAgents.subheader':
    'Find Bid This Property Certified Agent in your area',
  'CertifiedAgents.SearchInput.placeholder': 'Search by name or ZIP Code',
  'CertifiedAgents.DRE': 'DRE #',

  'ContactUsSection.link.ContactUs': 'Contact Us',
  'ContactUsSection.NeedTo': 'Need to',
  'ContactUsSection.ReachUs': 'Reach Us?',
  'ContactUsSection.form.title': 'Contact us',
  'ContactPerson.statement.title': 'Personal Information Collection Statement',
  'ContactPerson.statement.info':
    'This form is only to be used for sending genuine email enquiries to the Agent. bidthisproperty.com reserves its right to take any legal or other appropriate action in relation to misuse of this service.',

  'ContactUsForm.Name': 'Name',
  'ContactUsForm.Email': 'Email',
  'ContactUsForm.textarea': 'What can I help you with?',
  'ContactUsForm.submit': 'Send',

  'AboutUsWhyJoinUsSection.Why': 'Why',
  'AboutUsWhyJoinUsSection.JoinUs': 'Join Us?',
  'AboutUsWhyJoinUsSection.WhoWeAre': 'Who We Are',
  'AboutUsWhyJoinUsSection.WhoWeAre.info1':
    'Bid This Property was created by agents for agents with consumers in mind.',
  'AboutUsWhyJoinUsSection.WhoWeAre.info2':
    'Bid This Property was created to fill the need for transparency and inclusivity by agents who knew intuitively that there had to be a better way. Real Estate is notoriously assumed to be a field where games are played, and the players are not to be trusted. We decided that enough was enough and it was time to use technology and innovation to play the game differently where everyone has a chance. Adding true transparency while netting both agents & consumers more money in doing so is what fuels our passion.',
  'AboutUsWhyJoinUsSection.WhoWeAre.info3':
    'Join us in recreating the experience, reputation and results.',
  'AboutUsWhyJoinUsSection.Mission': 'Mission',
  'AboutUsWhyJoinUsSection.Mission.info':
    'Bringing Transparency to Real Estate, One Bid at a Time.',
  'AboutUsWhyJoinUsSection.Vision': 'Vision',
  'AboutUsWhyJoinUsSection.Vision.info':
    'We seek to bring transparency and trust into the worldwide real estate industry by using technology alongside the agent to produce dramatic results for clients.',
  'AboutUsWhyJoinUsSection.Values': 'Values',
  'AboutUsWhyJoinUsSection.Values.info': [
    'Innovation (evolution of the real estate offer process).',
    'Trust (through transparency).',
    'Inclusivity (equitable chance for everyone).',
    'Tenacity (without limits).',
    'Results (always).',
  ],

  'HowToBuyFAQSection.FAQ': 'FAQ',

  'HowToBuyFAQCollapse.header1': 'Do I have to use an agent?',
  'HowToBuyFAQCollapse.info1':
    'Yes, it’s the best choice. When things really get going with your home purchase and there’s a lot happening, an agent will have your back. They will make sure everything is done properly and goes off without a hitch.',
  'HowToBuyFAQCollapse.header2':
    "I've got someone I really like, can I use my own agent while using Bid This Property?",
  'HowToBuyFAQCollapse.info2': 'Sure thing! Share the realtor love.',
  'HowToBuyFAQCollapse.header3':
    'If I get outbid, how will I find out the bad news?',
  'HowToBuyFAQCollapse.info3':
    'In the process of tracking the bidding online, you can view it in real time as it happens. Or if you are not closely tracking, you will get a bummer email notification.',
  'HowToBuyFAQCollapse.header4':
    'Is there some kind of reserve/buy it now price option?',
  'HowToBuyFAQCollapse.info4':
    'Sorry friends, this is not ebay. The seller can choose to accept, reject or counter any offer they receive.',

  'ListingCard.HotProperty': 'Hot Property',
  'ListingCard.BidNow': 'Bid Now',
  'ListingCard.Sold': 'Sold',

  'ListingListNotFound.NothingFound': 'Nothing found',
  'ListingListNotFound.in': 'in',
  'ListingListNotFound.YouMayLike': 'You may like these popular properties:',

  'TimerBadge.Day_one': 'Day',
  'TimerBadge.Day_other': 'Days',

  'HomePageList1.BiddingEndingSoon': 'Bidding Ending Soon',
  'HomePageList2.MostPopular': 'Most Popular',

  'ListingsSorting.MostPopular': 'Most Popular',
  'ListingsSorting.BiddingEndingSoon': 'Bidding Ending Soon',

  'ListingList.found_one': '{{count}} property found in',
  'ListingList.found_other': '{{count}} properties found in',

  'ListingDetailsGallery.photo_one': 'Photo',
  'ListingDetailsGallery.photo_other': 'Photos',

  'BidNowInfo.title1': "Buyer's Premium (built in the sales price)",
  'BidNowInfo.description1':
    'The highest bid plus the Buyers Premium equals the sales price, and Buyers Premium is just a percentage fee that is paid by the buyer. It’s the price of transparency and being able to decide if you want to do what it takes to win.',
  'BidNowInfo.title2': 'Notice to Bidders',
  'BidNowInfo.description2':
    'If you are selected as the highest bidder at the end of an auction, and you jump ship after being deemed the winning bid, you are breaching our terms of use. You may be charged a $500 withdrawal penalty fee to your credit card. If you did not provide a credit card, you will receive an invoice.',
  'BidNowInfo.title3': 'For information on how to get approved and bid online',
  'BidNowInfo.ClickHere': 'Click Here',
  'BidNowInfo.boxDescription':
    'Seller reserves the right to accept, counter or reject any bid!',
  'BidsHistory.title': 'Bids History',
  'BidsHistory.currentBid': 'Current bid',
  'BidsHistory.finalBid': 'Final bid',
  'BidsHistory.lastBid': 'Last bid',
  'BidsHistory.initialPrice': 'Initial Price',
  'BidsHistory.bidNow': 'Bid Now',
  'BidsHistory.noBidsYet': 'No Bids yet',
  'BidsHistory.moreButton': 'More Bids',
  'BidsHistory.lessButton': 'Less Bids',

  'BidsNowError.bidAlreadyPlaced': 'This bid is already placed',
  'BidsNowError.tryAgain':
    'Something wrong with your bid processing. Please try again.',
  'BidsNowError.youAreLate': 'You are late! Bidding ended for the property.',
  'BidsNowError.refreshThePage': 'Please refresh page or try later',
  'BidsNowError.higherMaximumAutobid':
    'You already set the higher maximum price for this property',
  'BidsNowError.domainAlreadyExists': 'Domain name already exists',
  'BidsNowError.userAlreadyExists': 'User already exists',
  'BidsNowError.marketCenterAlreadyExists': 'Market Center already exists',
  'BidsNowError.subdomainAlreadyExists': 'Subdomain name already exists',
  'BidsNowError.BadRequest': 'Bad Request',
  'BidsNowError.NotFound': 'Not Found',
  'BidsNowError.NetworkError': 'Network Error',

  'FileUpload.Upload': 'Upload',
  'FileUpload.Uploaded': 'Uploaded',
  'FileUpload.error.format': 'File format is not correct',
  'FileUpload.error.size':
    'The file size exceeds the maximum allowed size: {{limit}} MB',
  'FileUpload.error.upload': 'Uploading error. Please try again',

  'UploadImageItem.Uploading': 'Uploading',

  'TextEditor.link.placeholder': 'Paste your link here',
  'TextEditor.link.enterLink': 'Enter link:',
  'TextEditor.link.visitURL': 'Visit URL:',
  'TextEditor.link.save': 'Save',
  'TextEditor.link.edit': 'Edit',
  'TextEditor.link.remove': 'Remove',
  // components

  // hooks
  'useNetworkNotificationHelper.offline.title': 'Lost Internet Connection',
  'useNetworkNotificationHelper.offline.description1':
    'Your Internet connection seems to be broken. Therefore, you will not see new bids placed and other changes until reconnect again.',
  'useNetworkNotificationHelper.offline.description2':
    'You can try to reload the page or look at preferences on your device.',
  'useNetworkNotificationHelper.visibilitychange.title':
    'Nice to see you again!',
  'useNetworkNotificationHelper.visibilitychange.description':
    'Would you like to keep up with the current bids on the site? Please reload the page.',
  'useNetworkNotificationHelper.Reload': 'Reload Page',
  'useNetworkNotificationHelper.offline.message':
    'You are offline. Check your connection and reload the page for seeing the new bids. ',
  // hooks
  // BE errors
  'ErrorBE.NotFound': 'Not Found',
  'ErrorBE.BadRequest': 'Bad Request',
  'ErrorBE.Unauthorized': 'Unauthorized',
  'ErrorBE.ValidationError': 'Validation Error',
  'ErrorBE.InternalServerError': 'Internal Server Error',
  // BE errors
};

export default en;
