import { ReactNode } from 'react';
import styles from './InfoBox.module.less';
import cx from 'classnames';

export enum BoxSize {
  Small = 'small',
  Medium = 'medium',
}

export enum BoxColor {
  Grey = 'grey',
  Blue = 'blue',
}

type Props = {
  icon?: ReactNode;
  title?: ReactNode | string;
  color?: BoxColor;
  text?: ReactNode | string;
  size?: BoxSize;
};

export default function InfoBox({
  icon,
  title,
  color = BoxColor.Blue,
  text,
  size = BoxSize.Medium,
}: Props) {
  return (
    <div
      className={cx(styles.message, {
        [styles.messageGrey]: color === BoxColor.Grey,
        [styles.messageSmall]: size === BoxSize.Small,
      })}
    >
      {!!icon && <div className={styles.messageIcon}>{icon}</div>}
      <div className={styles.messageContent}>
        {!!title && <div className={styles.messageTitle}>{title}</div>}
        {!!text && <div className={styles.messageText}>{text}</div>}
      </div>
    </div>
  );
}
