import { useMemo } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import cx from 'classnames';
import Tooltip from 'antd/es/tooltip';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAuth } from 'components/AuthProvider';
import Checkbox from 'components/form/Checkbox';
import FormItem from 'components/form/FormItem';
import { displayErrorMessage } from 'components/message';
import {
  awsCognitoPasswordRegExp,
  noSpacesRegExp,
  onlySpacesHyphensRegExp,
  phoneE164RegExp,
  regularTextRegExp,
} from 'utils/validation.utils';
import PhoneInput from 'components/form/PhoneInput';

import styles from './SignUpForm.module.less';

interface IFormValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
  agreement: boolean;
}

const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('validation.format'))
      .max(
        120,
        t('validation.maxLength', {
          count: 120,
        }),
      )
      .required(t('validation.required')),
    password: yup
      .string()
      .required(t('validation.required'))
      .max(
        16,
        t('validation.maxLength', {
          count: 16,
        }),
      )
      .matches(awsCognitoPasswordRegExp, t('validation.awsCognitoFormat'))
      .matches(noSpacesRegExp, t('validation.noSpace')),
    firstName: yup
      .string()
      .required(t('validation.required'))
      .max(
        30,
        t('validation.maxLength', {
          count: 30,
        }),
      )
      .matches(regularTextRegExp, t('validation.format'))
      .test('fNameTest', t('validation.format'), (val) => {
        return !val?.match(onlySpacesHyphensRegExp);
      }),
    lastName: yup
      .string()
      .required(t('validation.required'))
      .max(
        30,
        t('validation.maxLength', {
          count: 30,
        }),
      )
      .matches(regularTextRegExp, t('validation.format'))
      .test('lNameTest', t('validation.format'), (val) => {
        return !val?.match(onlySpacesHyphensRegExp);
      }),
    phone: yup
      .string()
      .required(t('validation.required'))
      .matches(phoneE164RegExp, t('SignUpForm.Phone.format')),
    agreement: yup.boolean().oneOf([true], t('SignUpForm.AgreementRequired')),
  });

type Props = {
  onClose: () => void;
  onSignInClick: () => void;
  onSuccess: (email: string) => void;
};

export default function SignUpForm(props: Props) {
  const { t } = useTranslation();
  const { signUp } = useAuth();
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      phone: '',
      agreement: false,
    },
  });
  const { onClose, onSignInClick, onSuccess } = props;

  const onSubmit = async (values: IFormValues) => {
    try {
      await signUp(values);

      onSuccess(values.email);
    } catch (error: any) {
      displayErrorMessage({
        error,
        containerSelector: '.ant-modal',
      });
    }
  };
  return (
    <>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
        <div className="modal__scroll">
          <div className="modal__content">
            <div className="modal__subtitle">
              {t('SignUpForm.HaveAccount')} &nbsp;
              <Button
                type="link"
                className="custom-link"
                onClick={onSignInClick}
              >
                {t('SignUpForm.SignIn')}
              </Button>
            </div>
            <h3 className="modal__label">{t('SignUpForm.Account')}</h3>
            <FormItem
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder={t('SignUpForm.Email')}
                  size="large"
                  {...field}
                />
              )}
            />
            <FormItem
              name="password"
              control={control}
              render={({ field }) => (
                <Tooltip
                  overlayStyle={{ maxWidth: '470px', width: '100%' }}
                  placement="topLeft"
                  title={t('Password.tooltip')}
                >
                  <Input.Password
                    placeholder={t('SignUpForm.Password')}
                    size="large"
                    {...field}
                  />
                </Tooltip>
              )}
            />

            <h3 className="modal__label">
              {t('SignUpForm.ContactInformation')}
            </h3>
            <Row gutter={10}>
              <Col xs={24} md={12}>
                <FormItem
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder={t('SignUpForm.FirstName')}
                      size="large"
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col xs={24} md={12}>
                <FormItem
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder={t('SignUpForm.LastName')}
                      size="large"
                      {...field}
                    />
                  )}
                />
              </Col>
            </Row>
            <FormItem
              name="phone"
              control={control}
              render={({ field }) => (
                <>
                  <PhoneInput
                    placeholder={t('SignUpForm.Phone.placeholder')}
                    size="large"
                    {...field}
                  />
                </>
              )}
            />
            <FormItem
              className={cx('clear-shadow', styles.checkbox)}
              name="agreement"
              control={control}
              render={({ field }) => (
                <>
                  <Checkbox {...field}>
                    <div className="m-0">
                      {t('SignUpForm.agreement.checkbox')}
                    </div>
                  </Checkbox>
                  <div className={styles.caption}>
                    {t('SignUpForm.agreement.info')}{' '}
                    <Link
                      className="custom-link"
                      to="/terms-and-conditions-of-service"
                      target="_blank"
                    >
                      <Button className="custom-link" type="link">
                        {t('SignUpForm.agreement.info.TermsConditions')}
                      </Button>
                    </Link>{' '}
                    {t('SignUpForm.agreement.info.and')}{' '}
                    <Link to="/privacy-policy" target="_blank">
                      <Button className="custom-link" type="link">
                        {t('SignUpForm.agreement.info.PrivacyPolicy')}
                      </Button>
                    </Link>
                    {'.'}
                  </div>
                </>
              )}
            />
          </div>
        </div>

        <div className="modal__footer">
          <div className="modal__footer-row">
            <div>
              <Button
                type="link"
                className="custom-link"
                size="large"
                onClick={onClose}
              >
                {t('SignUpForm.Cancel')}
              </Button>
            </div>
            <div>
              <Button
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
                size="large"
              >
                {t('SignUpForm.Register')}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
