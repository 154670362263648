import { ReactNode, useEffect, useState, useMemo } from 'react';
import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ConfigProvider } from 'antd';
import { I18n } from 'aws-amplify';

import itIT from 'antd/lib/locale/it_IT';
import enUS from 'antd/lib/locale/en_US';
import Spin from 'components/Spin';
import { en, it } from './translations';

import { useAppSettings } from 'components/AppSettingsProvider';
import { enCognito } from './cognitoTranslations';
import { Language } from 'services/api';

const resources: Resource = {
  [Language.EN]: {
    translation: en,
  },
  [Language.IT]: {
    translation: it,
  },
};
const combinedVocabularies = {
  en: enCognito,
};
I18n.putVocabularies(combinedVocabularies);
export const initInternalization = async (lng: Language) => {
  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
};

type Props = {
  children: ReactNode;
};
export default function Localizer({ children }: Props) {
  const { settings } = useAppSettings();
  const { language = Language.EN } = settings || {};
  const [isLocalizerReady, setIsLocalizerReady] = useState(false);

  const antdLocale = useMemo(() => {
    switch (language) {
      case Language.IT:
        I18n.setLanguage(Language.IT);
        return itIT;
      case Language.EN:
      default:
        I18n.setLanguage(Language.EN);
        return enUS;
    }
  }, [language]);

  useEffect(() => {
    const init = async () => {
      await initInternalization(language || Language.EN);
      setIsLocalizerReady(true);
    };
    init();
  }, [language]);

  if (!isLocalizerReady) return <Spin />;

  return <ConfigProvider locale={antdLocale}>{children}</ConfigProvider>;
}
