import { useState } from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import styles from './ConsentPopUp.module.less';

const storageType = localStorage;
const consentPropertyName = 'accepted_cookie';

export default function ConsentPopUp() {
  const { t } = useTranslation();
  const [accepted, setAccepted] = useState(
    !!storageType.getItem(consentPropertyName),
  );
  if (accepted) return null;
  return (
    <div className={cx(styles.wrapper, 'auto-width')}>
      <div>
        <h4 className={styles.title}>{t('consentPopUp.Cookies')}</h4>
        <p className={styles.text}>{t('consentPopUp.Cookies.description')}</p>
      </div>
      <div className={styles.btn}>
        <Button
          type="primary"
          size="large"
          onClick={() => {
            storageType.setItem(consentPropertyName, JSON.parse('true'));
            setAccepted(true);
          }}
        >
          {t('consentPopUp.Cookies.Accept')}
        </Button>
        <Link to="/privacy-policy">
          <Button type="primary" size="large" className="btn-secondary">
            {t('consentPopUp.ReadMore')}
          </Button>
        </Link>
      </div>
    </div>
  );
}
