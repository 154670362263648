import { useEffect, useState } from 'react';

import { displayErrorMessage } from 'components/message';
import Pagination from 'components/Pagination';
import { useTranslation } from 'react-i18next';
import {
  AdminListingResponse,
  ListingApi,
  ExternalListingOrderBy,
} from 'services/api';

import SidebarLayout from '../SidebarLayout';
import styles from './ListingsManagementPage.module.less';
import ListingsTable from './ListingsTable';
import { scrollTop } from 'utils/scrollTop.utils';

const pageSizeOptions = [24, 48, 72];
const DefaultPageSize = pageSizeOptions[0];

export default function ListingsManagementPage() {
  const [isListingsLoading, setIsListingsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPageSize);
  const [listingsData, setListingsData] = useState<AdminListingResponse>();
  const { t } = useTranslation();

  useEffect(() => {
    const loadListings = async () => {
      setIsListingsLoading(true);

      try {
        const result = await ListingApi.getListingManagingList({
          page,
          pageSize,
          order: ExternalListingOrderBy.ENDS_AT,
        });

        setListingsData(result);
      } catch (error) {
        displayErrorMessage({ error });
      } finally {
        setIsListingsLoading(false);
      }
    };

    loadListings();
  }, [page, pageSize]);

  const handlePaginationChange = (newPage: number, newPageSize: number) => {
    if (isListingsLoading) return;

    setPage(newPage);
    setPageSize(newPageSize);
    scrollTop();
  };

  return (
    <SidebarLayout>
      <div className="paper">
        <div className={styles.titleBox}>
          <h3 className="h4">{t('Dashboard.ListingsManagement.Listings')}</h3>
        </div>

        <div>
          <ListingsTable
            data={listingsData?.results}
            isLoading={isListingsLoading}
          />

          {!!listingsData?.total && listingsData.total > DefaultPageSize && (
            <Pagination
              current={page}
              pageSize={pageSize}
              showSizeChanger
              onChange={handlePaginationChange}
              total={listingsData.total}
              pageSizeOptions={pageSizeOptions}
            />
          )}
        </div>
      </div>
    </SidebarLayout>
  );
}
