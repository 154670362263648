import { useTranslation } from 'react-i18next';

import { ApprovalUserStatus } from 'services/api';

import styles from './BuyerInfo.module.less';

type Props = {
  firstName: string;
  lastName: string;
  nickname: string;
  status?: ApprovalUserStatus;
  dre?: string;
};

export default function BuyerInfo({
  firstName,
  lastName,
  nickname,
  status,
  dre,
}: Props) {
  const { t } = useTranslation();

  const getBuyerTypeLabel = () => {
    switch (status) {
      case ApprovalUserStatus.BUYER_WITH_AGENT:
        return t('BidManagement.Tabs.Table.Buyer.WithAgent');
      case ApprovalUserStatus.BUYER_WITHOUT_AGENT:
        return t('BidManagement.Tabs.Table.Buyer.NoAgent');
      case ApprovalUserStatus.AGENT:
        return t('BidManagement.Tabs.Table.Buyer.BuyerAgent');

      default:
        return '';
    }
  };

  return (
    <div>
      <div>
        <span className={styles.name}>
          {firstName} {lastName}
        </span>
        &nbsp;
        {nickname && <span className={styles.nickname}>({nickname})</span>}
      </div>

      <div className={styles.label}>{getBuyerTypeLabel()}</div>
      {dre && <div className={styles.number}>{dre}</div>}
    </div>
  );
}
