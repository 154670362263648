import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { CaretUpOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

export default function HowToBuyFAQCollapse() {
  const { t } = useTranslation();

  return (
    <Collapse
      accordion
      bordered={false}
      expandIcon={({ isActive }) => (
        <CaretUpOutlined rotate={isActive ? 0 : 180} />
      )}
    >
      <Panel header={t('HowToBuyFAQCollapse.header1')} key="header1">
        <p>{t('HowToBuyFAQCollapse.info1')}</p>
      </Panel>
      <Panel header={t('HowToBuyFAQCollapse.header2')} key="header2">
        <p>{t('HowToBuyFAQCollapse.info2')}</p>
      </Panel>
      <Panel header={t('HowToBuyFAQCollapse.header3')} key="header3">
        <p>{t('HowToBuyFAQCollapse.info3')}</p>
      </Panel>
      <Panel header={t('HowToBuyFAQCollapse.header4')} key="header4">
        <p>{t('HowToBuyFAQCollapse.info4')}</p>
      </Panel>
    </Collapse>
  );
}
