import { Carousel } from 'antd';

import { getImageUrl, ImageSize } from 'utils/image.utils';

import styles from './AboutUsCarousel.module.less';

export interface IAboutUsSlide {
  imageUrl: string;
}

type AboutUsSlideProps = {
  slide: IAboutUsSlide;
};

const AboutUsSlide = (props: AboutUsSlideProps) => {
  const { slide } = props;
  const { imageUrl } = slide;

  return (
    <div>
      <div className={styles.slide}>
        <img src={getImageUrl(imageUrl, ImageSize.BIG)} alt="" />
      </div>
    </div>
  );
};

type AboutUsCarouselProps = {
  slides: IAboutUsSlide[];
};

export default function AboutUsCarousel(props: AboutUsCarouselProps) {
  const { slides } = props;
  return (
    <div className={styles.slider}>
      <Carousel>
        {slides.map((slide, index) => (
          <AboutUsSlide key={`slide-${index}`} slide={slide} />
        ))}
      </Carousel>
    </div>
  );
}
