import { useState } from 'react';
import cx from 'classnames';
import moment, { Moment } from 'moment';
import { useIntervalWhen } from 'rooks';
import 'moment-duration-format';
import { TFunction, useTranslation } from 'react-i18next';

import styles from './TimerBadge.module.less';

type Props = {
  size?: 'small' | 'large';
  endDate?: string;
};

export const getTimerData = (
  t: TFunction,
  endDate: Moment,
  dateNow: Moment,
) => {
  const diff = endDate.diff(dateNow);
  // typings was broken in the 'moment' update to 2.29.4
  // should be fixed in 'moment-duration-format'
  const duration = moment.duration(diff) as any;
  const endsSoon = duration.asDays() < 1;

  let formattedValue = '';
  if (endsSoon) {
    formattedValue = duration.format('hh:mm:ss', {
      trim: false,
    });
  } else {
    formattedValue = duration.format('d [DayPlaceholder], hh:mm:ss', {
      trim: false,
    });
    const days = +formattedValue.split(' ')[0];
    formattedValue = formattedValue.replace(
      'DayPlaceholder',
      t('TimerBadge.Day', {
        count: days,
      }),
    );
  }

  return {
    diff,
    endsSoon,
    formattedValue,
  };
};

export default function TimerBadge(props: Props) {
  const { t } = useTranslation();
  const { size = 'small', endDate } = props;

  const momentEndDate = moment.utc(endDate);
  const [dateNow, setDateNow] = useState(moment.utc());
  const timerData = getTimerData(t, momentEndDate, dateNow);

  useIntervalWhen(
    () => {
      setDateNow(moment.utc());
    },
    1000, // 1 sec
  );

  if (!endDate) return null;
  if (timerData.diff <= 0) return null; // should be handled by listing status (sold/inactive)

  return (
    <div
      className={cx(styles.badge, {
        [styles.small]: size === 'small',
        [styles.large]: size === 'large',
        [styles.endsSoon]: timerData.endsSoon,
      })}
    >
      <i className="icon icon-timer"></i> {timerData.formattedValue}
    </div>
  );
}
