import { apiClient } from '.';

import { AdminsAndAgentsFilterByOption } from 'features/dashboard/AdminsAndAgents/AdminsAndAgentsFilter';

class DashboardApi {
  public async getAdminsAndAgentsList({
    pageSize,
    page,
    filter,
  }: {
    pageSize: number;
    page: number;
    filter: AdminsAndAgentsFilterByOption;
  }) {
    return apiClient.admin.adminUsers(page, pageSize, undefined, filter);
  }
}

export default new DashboardApi();
