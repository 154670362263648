import { Button, Col, Row } from 'antd';
import { BlockDivider } from 'components/BlockDivider';

import styles from '../TermsAndConditionsOfServicePage.module.less';

export default function TermsAndConditionsOfServicePageEn() {
  return (
    <div className={styles.bgWrap}>
      <div className="container">
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} xl={{ span: 20, offset: 2 }}>
              <div className={styles.heroSection}>
                <h2 className="h2">Terms & Conditions Of Service</h2>
                <h5 className="h5">www.bidthisproperty.com</h5>
                <p>
                  Welcome to BidThisProperty.com (hereinafter “BID THIS
                  PROPERTY”), owned and operated by Fairy Pond Holdings, Inc.
                  (hereinafter “FAIRY”), and collectively “we” or “us”. By using
                  the www.bidthisproperty.com site (the "Website") and its
                  features, services and tools (collectively, the “Services”),
                  you are agreeing to these Terms and Conditions of Service, and
                  entering into a legally binding agreement with us.
                </p>
              </div>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Scope</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                Before you may create an account and become a member of BID THIS
                PROPERTY, you must read and accept the entirety of this Terms
                and Conditions of Service and the BID THIS PROPERTY Privacy
                Policy. We strongly recommend that, as you read this Terms and
                Conditions of Service, you also access and read the linked
                information. By accepting this Terms and Conditions of Service,
                you agree that this Terms and Conditions of Service and Privacy
                Policy will apply whenever you use BID THIS PROPERTY sites or
                Services, or when you use the tools we make available to
                interact with BID THIS PROPERTY sites and Services. If you use
                another BID THIS PROPERTY site, you agree to accept the Terms
                and Conditions of Service and Privacy Policy applicable to that
                site. Some BID THIS PROPERTY sites, services, and tools may have
                additional or other terms that we provide to you when you use
                those sites, services, or tools.
              </p>
              <p>
                If you do not agree to the Terms and Conditions of Service,
                including the binding arbitration clause and class action waiver
                contained herein, you may not use the Website, the Services or
                create an account.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Geographic Restrictions</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                The owner of the Website is based in the State of California in
                the United States. We provide this Website for use only by
                persons located in the United States. We make no claims that the
                Website or any of its content is accessible or appropriate
                outside of the United States. Access to the Website may not be
                legal by certain persons or in certain countries. If you access
                the Website from outside the United States, you do so on your
                own initiative and are responsible for compliance with local
                laws.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">
                Using <br />
                BID THIS PROPERTY
              </h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                While using BID THIS PROPERTY sites, services and tools, you
                will not:
              </p>
              <ul className="default-list">
                <li>violate these Terms and Conditions of Service;</li>
                <li>
                  copy, reproduce, modify, create derivative works from,
                  distribute, or publicly display any content (except for your
                  information) from the Website without our prior express
                  written permission, and permission of the appropriate third
                  party(ies), as applicable;
                </li>
                <li>
                  scrape, monitor, or copy any part of the Website in an
                  automated way, using any robot, scraper, or other method of
                  access other than manually accessing the publicly-available
                  portions of the Website;
                </li>
                <li>
                  post content or items in an inappropriate category or area on
                  the Website;
                </li>
                <li>
                  violate any laws or regulations applicable to you or us;
                </li>
                <li>
                  violate any rights of any other user of the Website or
                  Services (hereinafter “User(s)”) or of third parties,
                  including copyright, trademark, privacy rights, or any other
                  intellectual property or proprietary rights;
                </li>
                <li>
                  use our Website or the Services if you are not able to form
                  legally binding contracts, are under the age of 18, or are
                  temporarily or indefinitely suspended from using our Website
                  or Services;
                </li>
                <li>interfere with other Users’ listings;</li>
                <li>
                  circumvent or manipulate our fee structure, the billing
                  process, or fees owed to BID THIS PROPERTY;
                </li>
                <li>
                  threaten, stalk, harm, or harass others, mislead or deceive
                  others, promote bigotry or discrimination, or post, defamatory
                  or libelous content;
                </li>
                <li>
                  solicit personal information from other Users, except when
                  engaged in a transaction;
                </li>
                <li>
                  transfer your BID THIS PROPERTY account and user ID or
                  displayed Nickname to another party without our consent;
                </li>
                <li>
                  distribute or post spam, unsolicited, or bulk electronic
                  communications, chain letters, or pyramid schemes;
                </li>
                <li>
                  impair our computer systems, distribute viruses, worms or any
                  other harmful technologies;
                </li>
                <li>
                  export or re-export any BID THIS PROPERTY tools except in
                  compliance with the export control laws of any relevant
                  jurisdictions;
                </li>
                <li>
                  harvest or otherwise collect information about Users,
                  including email addresses, without their consent;
                </li>
                <li>
                  interfere with any other User’s use and enjoyment of the
                  Website and the Services;
                </li>
                <li>
                  attempt to gain unauthorized access to the Website or the
                  Services;
                </li>
                <li>
                  take any action that imposes or may impose (in our sole
                  discretion) an unreasonable or disproportionately large load
                  on our infrastructure;
                </li>
                <li>
                  advertise or offer to sell less than the entire fee simple
                  ownership in any property.
                </li>
              </ul>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">
                Abusing <br /> BID THIS PROPERTY
              </h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                BID THIS PROPERTY and the community work together to keep our
                sites and services working properly and the community safe.
                Please report problems, offensive content, and policy violations
                to us.
              </p>
              <p>
                We reserve the right to audit and monitor (manually or through
                automated means) the use of the Services to ensure compliance
                with the Terms and Conditions of Service and to maintain and
                improve the Website and the Services. We also may, but are not
                required to, monitor the content on the Website using manual
                review or technical measures to screen, block, filter, edit or
                remove content. We may terminate or suspend users’ accounts or
                delete, edit or remove content that we, in our sole discretion,
                deem illegal, offensive, abusive, in violation of the Terms and
                Conditions of Service or our other policies, or otherwise
                inappropriate or unacceptable. All enforcement determinations
                are made in our sole discretion, and we will not incur any
                liability or responsibility if we choose to remove or delete any
                content.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Fees and Services</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                Joining BID THIS PROPERTY and bidding on listed properties is
                free. We do charge fees that are paid by the agents/brokers who
                list properties for sale on our Website, in the event of a
                consummated sale, as detailed in a separate agreement. On some
                listings a Buyer’s premium may be required to be added to the
                bid price.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Content</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                When you give us content (a “User Contribution”), you grant us a
                non-exclusive, worldwide, perpetual, irrevocable, royalty-free,
                sublicensable right to publicly and commercially, use,
                distribute, copy, make, use and distribute derivative works from
                the User Contribution and/or to exercise any and all copyright,
                trademark, publicity, and database rights (but no other rights)
                you have in the User Contribution, in any form and in media now
                known of future developed.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">
                Limitation <br /> of Liability
              </h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                By using this Website and the Services, you expressly agree that
                you will not hold us responsible for other Users’ content,
                actions or inactions. You acknowledge that we are not a
                traditional auctioneer. Instead, our Website is a venue to
                advertise and market real estate. We are not involved in the
                actual transaction between buyers and sellers, and do not
                represent either buyers or sellers as a real estate broker or
                agent. We have no control over and do not guarantee the, the
                truth or accuracy of Users’ content or listings, the legal
                ability of sellers to sell properties, the financial ability of
                buyers to pay for properties, or that a buyer or seller will
                actually complete a transaction. A bid or offer made through our
                Website or Services is non-binding, and simply represents a
                buyer’s serious expression of interest in buying the seller’s
                property.
              </p>
              <p>
                We cannot guarantee continuous or secure access to our Website
                or Services and operation of our Website or Services may be
                interfered with by numerous factors outside of our control.
                Accordingly, to the extent legally permitted, we exclude all
                implied warranties, terms and conditions. We are not liable for
                any loss of money, goodwill or reputation, or any special,
                indirect or consequential damages arising, directly or
                indirectly, out of your use of or your inability to use our
                Website or Services.
              </p>
              <p>
                TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL BID THIS
                PROPERTY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS,
                EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES
                OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
                CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY
                WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER
                WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED
                TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
                LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
                OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
              </p>
              <p>
                THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
              <p>
                Regardless of the previous paragraphs, if we are found to be
                liable, our liability to you or to any third party is limited to
                the greater of (a) the total fees (under BID THIS PROPERTY Fees
                and Services), if any, you paid to us in the 12 months prior to
                the action giving rise to the liability, and (b) $100.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Disclaimer of Warranties</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                You understand that we cannot and do not guarantee or warrant
                that files available for downloading from the internet or the
                Website will be free of viruses or other destructive code. You
                are responsible for implementing sufficient procedures and
                checkpoints to satisfy your particular requirements for
                anti-virus protection and accuracy of data input and output, and
                for maintaining a means external to our site for any
                reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED
                BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
                DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
                TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
                EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
                MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS
                OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY
                MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
              </p>
              <p>
                YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
                OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE,
                ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS,
                WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
                NEITHER BID THIS PROPERTY NOR ANY PERSON ASSOCIATED WITH BID
                THIS PROPERTY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
                TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY,
                OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING,
                NEITHER BID THIS PROPERTY NOR ANYONE ASSOCIATED WITH BID THIS
                PROPERTY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT,
                OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE
                ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS
                WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
                AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR
                THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
              </p>
              <p>
                TO THE FULLEST EXTENT PROVIDED BY LAW, BID THIS PROPERTY HEREBY
                DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
                ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS
                FOR PARTICULAR PURPOSE.
              </p>
              <p>
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Reliance on Information Posted</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                The information presented on or through the Website is made
                available solely for general information purposes. We do not
                warrant the accuracy, completeness, or usefulness of this
                information. Any reliance you place on such information is
                strictly at your own risk. We disclaim all liability and
                responsibility arising from any reliance placed on such
                materials by you or any other visitor to the Website, or by
                anyone who may be informed of any of its contents.
              </p>
              <p>
                This Website includes content provided by third parties,
                including materials provided by other users, bloggers, and
                third-party licensors, syndicators, aggregators, and/or
                reporting services. All statements and/or opinions expressed in
                these materials, and all articles and responses to questions and
                other content, other than the content provided by BID THIS
                PROPERTY, are solely the opinions and the responsibility of the
                person or entity providing those materials. These materials do
                not necessarily reflect the opinion of the BID THIS PROPERTY. We
                are not responsible, or liable to you or any third party, for
                the content or accuracy of any materials provided by any third
                parties.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">
                Changes to <br /> the Website
              </h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                We may update the content on this Website from time to time, but
                its content is not necessarily complete or up-to-date. Any of
                the material on the Website may be out of date at any given
                time, and we are under no obligation to update such material.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Accessing the Website and Account Security</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                We reserve the right to withdraw or amend this Website, and any
                service or material we provide on the Website, in our sole
                discretion without notice. We will not be liable if for any
                reason all or any part of the Website is unavailable at any time
                or for any period. From time to time, we may restrict access to
                some parts of the Website, or the entire Website, to users,
                including registered users.
              </p>
              <p>You are responsible for both:</p>
              <ul className="default-list">
                <li>
                  Making all arrangements necessary for you to have access to
                  the Website and
                </li>
                <li>
                  Ensuring that all persons who access the Website through your
                  internet connection are aware of these Terms and Conditions of
                  Service and comply with them.
                </li>
              </ul>
              <p>
                To access the Website or some of the resources it offers, you
                may be asked to provide certain registration details or other
                information. It is a condition of your use of the Website that
                all the information you provide on the Website is correct,
                current, and complete. You agree that all information you
                provide to register with this Website or otherwise, including,
                but not limited to, through the use of any interactive features
                on the Website, is governed by our Privacy Policy and you
                consent to all actions we take with respect to your information
                consistent with our Privacy Policy.
              </p>
              <p>
                If you choose, or are provided with, a user name, password, or
                any other piece of information as part of our security
                procedures, you must treat such information as confidential, and
                you must not disclose it to any other person or entity. You also
                acknowledge that your account is personal to you and agree not
                to provide any other person with access to this Website or
                portions of it using your user name, password, or other security
                information. You agree to notify us immediately of any
                unauthorized access to or use of your user name or password or
                any other breach of security. You also agree to ensure that you
                exit from your account at the end of each session. You should
                use particular caution when accessing your account from a public
                or shared computer so that others are not able to view or record
                your password or other personal information.
              </p>
              <p>
                We have the right to disable any user name, password, or other
                identifier, whether chosen by you or provided by us, at any time
                in our sole discretion for any or no reason, including if, in
                our opinion, you have violated any provision of these Terms and
                Conditions of Service.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Monitoring and Enforcement; Termination</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>We have the right to:</p>
              <ul className="default-list">
                <li>
                  Remove or refuse to post any User Contributions for any or no
                  reason in our sole discretion.
                </li>
                <li>
                  Take any action with respect to any User Contribution that we
                  deem necessary or appropriate in our sole discretion,
                  including if we believe that such User Contribution violates
                  the Terms and Conditions of Service, including the Content
                  Standards, infringes any intellectual property right or other
                  right of any person or entity, threatens the personal safety
                  of users of the Website or the public, or could create
                  liability for BID THIS PROPERTY.
                </li>
                <li>
                  Disclose your identity or other information about you to any
                  third party who claims that material posted by you violates
                  their rights, including their intellectual property rights or
                  their right to privacy.
                </li>
                <li>
                  Take appropriate legal action, including without limitation,
                  referral to law enforcement, for any illegal or unauthorized
                  use of the Website.
                </li>
                <li>
                  Terminate or suspend your access to all or part of the Website
                  for any or no reason, including without limitation, any
                  violation of these Terms and Conditions of Service.
                </li>
              </ul>
              <p>
                Without limiting the foregoing, we have the right to cooperate
                fully with any law enforcement authorities or court order
                requesting or directing us to disclose the identity or other
                information of anyone posting any materials on or through the
                Website. YOU WAIVE AND HOLD HARMLESS BID THIS PROPERTY AND ITS
                AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS
                RESULTING FROM ANY ACTION TAKEN BY BID THIS PROPERTY /ANY OF THE
                FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF,
                INVESTIGATIONS BY EITHER BID THIS PROPERTY /SUCH PARTIES OR LAW
                ENFORCEMENT AUTHORITIES.
              </p>
              <p>
                However, we do not undertake to review material before it is
                posted on the Website and cannot ensure prompt removal of
                objectionable material after it has been posted. Accordingly, we
                assume no liability for any action or inaction regarding
                transmissions, communications, or content provided by any user
                or third party. We have no liability or responsibility to anyone
                for performance or nonperformance of the activities described in
                this section.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Intellectual Property Rights</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                The Website and its entire contents, features, and functionality
                (including but not limited to all information, software, text,
                displays, images, video, and audio, and the design, selection,
                and arrangement thereof) are owned by BID THIS PROPERTY, its
                licensors, or other providers of such material and are protected
                by United States and international copyright, trademark, patent,
                trade secret, and other intellectual property or proprietary
                rights laws.
              </p>
              <p>
                These Terms and Conditions of Service permit you to use the
                Website and the Agent Solution or Broker Solution Website for
                your personal use only. You must not reproduce, distribute,
                modify, create derivative works of, publicly display, publicly
                perform, republish, download, store, or transmit any of the
                material on our Website, except as follows:
              </p>
              <ul className="default-list">
                <li>
                  Your computer may temporarily store copies of such materials
                  in RAM incidental to your accessing and viewing those
                  materials.
                </li>
                <li>
                  You may store files that are automatically cached by your Web
                  browser for display enhancement purposes.
                </li>
                <li>
                  You may print or download one copy of a reasonable number of
                  pages of the Website for your own personal use and not for
                  further reproduction, publication, or distribution.
                </li>
              </ul>
              <p>You must not:</p>
              <ul className="default-list">
                <li>Modify copies of any materials from this site.</li>
                <li>
                  Use any illustrations, photographs, video or audio sequences,
                  or any graphics separately from the accompanying text.
                </li>
                <li>
                  Delete or alter any copyright, trademark, or other proprietary
                  rights notices from copies of materials from this site.
                </li>
                <li>
                  Access or use for any commercial purposes any part of the
                  Website or any services or materials available through the
                  Website.
                </li>
              </ul>
              <p>
                If you print, copy, modify, download, or otherwise use or
                provide any other person with access to any part of the Website
                in breach of the Terms and Conditions of Service, your right to
                use the Website will stop immediately and you must, at our
                option, return or destroy any copies of the materials you have
                made. No right, title, or interest in or to the Website or any
                content on the Website is transferred to you, and all rights not
                expressly granted are reserved by BID THIS PROPERTY. Any use of
                the Website not expressly permitted by these Terms and
                Conditions of Service is a breach of these Terms and Conditions
                of Service and may violate copyright, trademark, and other laws.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Reporting Claims of Copyright Infringement</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                We take claims of copyright infringement seriously. We will
                respond to notices of alleged copyright infringement that comply
                with applicable law. If you believe any materials accessible on
                or from this Website infringe your copyright, you may request
                removal of those materials (or access to them) from the Website
                by submitting written notification to our copyright agent
                designated below. In accordance with the Online Copyright
                Infringement Liability Limitation Act of the Digital Millennium
                Copyright Act (17 U.S.C. § 512) ("DMCA"), the written notice
                (the "DMCA Notice") must include substantially the following:
              </p>

              <ul className="default-list">
                <li>Your physical or electronic signature.</li>
                <li>
                  Identification of the copyrighted work you believe to have
                  been infringed or, if the claim involves multiple works on the
                  Website, a representative list of such works.
                </li>
                <li>
                  Identification of the material you believe to be infringing in
                  a sufficiently precise manner to allow us to locate that
                  material.
                </li>
                <li>
                  Adequate information by which we can contact you (including
                  your name, postal address, telephone number, and, if
                  available, email address).
                </li>
                <li>
                  A statement that you have a good faith belief that use of the
                  copyrighted material is not authorized by the copyright owner,
                  its agent, or the law.
                </li>
                <li>
                  A statement that the information in the written notice is
                  accurate.
                </li>
                <li>
                  A statement, under penalty of perjury, that you are authorized
                  to act on behalf of the copyright owner.
                </li>
              </ul>

              <p>Our designated copyright agent to receive DMCA Notices is:</p>

              <ul>
                <li>
                  <b>Angela Hern</b>
                </li>
                <li>
                  <address>
                    <strong>
                      696 San Ramon Valley Blvd, Suite 326
                      <br />
                      Danville, CA 94526
                    </strong>
                  </address>
                </li>
                <li>
                  <b>AngelaH@BidThisProperty.com</b>
                </li>
              </ul>
              <p>
                If you fail to comply with all of the requirements of Section
                512(c)(3) of the DMCA, your DMCA Notice may not be effective.
              </p>
              <p>
                Please be aware that if you knowingly materially misrepresent
                that material or activity on the Website is infringing your
                copyright, you may be held liable for damages (including costs
                and attorneys' fees) under Section 512(f) of the DMCA.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Counter Notification Procedures</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                If you believe that material you posted on the Website was
                removed or access to it was disabled by mistake or
                misidentification, you may file a counter notification with us
                (a "Counter Notice") by submitting written notification to our
                copyright agent designated above. Pursuant to the DMCA, the
                Counter Notice must include substantially the following:
              </p>
              <p>Your physical or electronic signature.</p>
              <ul className="default-list">
                <li>
                  An identification of the material that has been removed or to
                  which access has been disabled and the location at which the
                  material appeared before it was removed or access disabled.
                </li>
                <li>
                  Adequate information by which we can contact you (including
                  your name, postal address, telephone number, and, if
                  available, email address).
                </li>
                <li>
                  A statement under penalty of perjury by you that you have a
                  good faith belief that the material identified above was
                  removed or disabled as a result of a mistake or
                  misidentification of the material to be removed or disabled.
                </li>
                <li>
                  A statement that you will consent to the jurisdiction of the
                  Federal District Court for the judicial district in which your
                  address is located (or if you reside outside the United States
                  for any judicial district in which the Website may be found)
                  and that you will accept service from the person (or an agent
                  of that person) who provided the Website with the complaint at
                  issue.
                </li>
              </ul>
              <p>
                The DMCA allows us to restore the removed content if the party
                filing the original DMCA Notice does not file a court action
                against you within ten business days of receiving the copy of
                your Counter Notice.
              </p>
              <p>
                Please be aware that if you knowingly materially misrepresent
                that material or activity on the Website was removed or disabled
                by mistake or misidentification, you may be held liable for
                damages (including costs and attorneys' fees) under Section
                512(f) of the DMCA.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Repeat Infringers</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                It is our policy in appropriate circumstances to disable and/or
                terminate the accounts of users who are repeat infringers.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Release</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                If you have a dispute with one or more other Users, you release
                us (and our officers, directors, agents, subsidiaries, joint
                ventures, and employees) from any and all claims, demands, and
                damages (actual and consequential) of every kind and nature,
                known and unknown, arising out of or in any way connected with
                such disputes. If you are a California resident, you acknowledge
                and voluntarily waive all of your rights under California Civil
                Code §1542, which says:
              </p>
              <p>
                <b>
                  A general release does not extend to claims that the creditor
                  or releasing party does not know or suspect to exist in his or
                  her favor, at the time of executing the release, and that if
                  known by him or her would have materially affected his or her
                  settlement with the debtor or released party.
                </b>
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Privacy</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                We do not sell or rent your personal information to third
                parties for their marketing purposes without your explicit
                consent. We use your information only as described in the BID
                THIS PROPERTY Privacy Policy, which explains our practices
                relating to the collection and use of your information in
                connection with the Website and the Services, and is
                incorporated into these Terms and Conditions of Service. By
                using the Website or the Services, you consent to the collection
                and use of your information as set forth in the Privacy Policy.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Indemnity</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                You will indemnify and hold us (and our officers, directors,
                agents, subsidiaries, joint ventures, and employees) harmless
                from any claim or demand (including but not limited to
                attorney’s fees, costs and liabilities) made by any third party
                arising out of or relating to: (i) your access to or use of the
                Website or the Services; (ii) your violation of any third party
                right, including without limitation any copyright, property, or
                privacy right; (iii) the content provided by you; or (iv) your
                breach of the Terms and Conditions of Service. We reserve the
                right, at your expense, to assume the exclusive defense and
                control of any matter subject to indemnification by you, and you
                agree to cooperate in such defense. You agree not to settle any
                matter in which you have indemnity obligations without our prior
                written consent. We will use reasonable efforts to notify you of
                any such claim, action or proceeding upon becoming aware of it.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Our Relationship with You</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                Nothing in these Terms and Conditions of Service or any other
                agreement or arrangement between you and us shall be deemed to
                create any agency, partnership, joint venture,
                employee-employer, or franchiser-franchisee relationship.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Notices and Electronic Communications</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                We may provide you with notices, including those regarding
                changes to the Terms and Conditions of Service or Privacy Policy
                by email, regular mail, telephone or communications though the
                Services. When you use the Website or the Services, you consent
                to receive communications from us electronically and through
                each of the foregoing methods. Your participation in any
                telephone conversation with any of our agents or employees
                constitutes your consent to our recording such telephone call.
              </p>
              <p>
                By providing your telephone number or texting JOIN to a number
                we give you, you authorize us, to the extent permitted by
                applicable law, to send text messages with marketing messages,
                alerts and updates about properties you are interested in to the
                phone number you provide. The frequency of these text messages
                is recurring and on-going. Message and data rates may apply,
                which means you may incur charges from your carrier as a result
                of these messages. At any time you may opt-out of receiving text
                messages from us by replying with the word “STOP” to a text
                message from us or calling us at{' '}
                <Button className="custom-link" type="link">
                  <a href={`tel:(855) 243-7767`}>(855) 243-7767</a>
                </Button>
              </p>
              <p>
                You also consent to our use of an automatic telephone dialing
                system for telephone calls and texts (if you have opted-in to
                receive text message marketing). You may revoke this consent at
                any time through reasonable means.
              </p>
              <p>
                If your carrier is T-Mobile: T-Mobile is not liable for delayed
                or undelivered messages.
              </p>
              <p>
                You agree that all notices, disclosures, and other
                communications that we provide to you electronically satisfy any
                legal requirement that such communications be in writing. You
                agree that you have the ability to store electronic
                communications such that they remain accessible to you in an
                unchanged form.
              </p>
              <p>
                Any notices that you choose to provide to us must be served on
                BID THIS PROPERTY’s national registered agent.
              </p>
              <p>
                Notices delivered to you via email shall be deemed given 24
                hours after email is sent, unless we are notified that your
                email address is invalid. Alternatively, we may give you legal
                notice by mail to the address provided during the registration
                process. In such case, notice shall be deemed given three days
                after the date of mailing.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Resolution of Disputes</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                By using the Website and any and all services provided by us,
                you irrevocably agree: (a) to waive all rights to trial in a
                court before a judge or jury on any claim, action or dispute
                with us or relating in any way to your use of the Website or our
                services or the interpretation, applicability, enforceability or
                formation of these Terms and Conditions of Service including,
                but not limited to, any claim that all or any part of this
                agreement is void or voidable (“Claims”); (b) that all Claims
                will be determined exclusively by final and binding arbitration
                in Contra Costa County, California before one arbitrator; and
                (c) that the arbitrator will not have the authority to
                consolidate the Claims of other users of the Website Services
                (“Users”) and will not have the authority to fashion a
                proceeding as a class or collective action or to award relief to
                a group or class of Users in one arbitration proceeding.
              </p>
              <p>
                The arbitration shall be administered by JAMS pursuant to its
                Comprehensive Arbitration Rules and Procedures and in accordance
                with the Expedited Procedures in those Rules. Notwithstanding
                these rules, however, such proceeding shall be governed by the
                laws of the State of California, Contra Costa County. All
                parties shall maintain the confidential nature of the
                arbitration proceeding and the award, including the hearing,
                except as may be necessary to prepare for or conduct the
                arbitration hearing on the merits, or except as may be necessary
                in connection with a court application for a preliminary remedy,
                a judicial challenge to an award or its enforcement, or unless
                otherwise required by law or judicial decision.
              </p>
              <p>
                The arbitrator, and not any federal, state, or local court or
                agency, shall have exclusive authority to resolve any Claims.
                However, nothing in this section shall prevent us from enforcing
                our intellectual property rights and/or remedy unfair
                competition, misappropriation of trade secrets, unauthorized
                access, fraud or computer fraud, and/or industrial espionage in
                court.
              </p>
              <p>
                Judgment on any arbitration award may be entered in any court
                having jurisdiction. In any arbitration arising out of or
                related to these Terms and Conditions of Service, the arbitrator
                shall award to the prevailing party, if any, the costs and
                attorneys’ fees reasonably incurred by the prevailing party in
                connection with the arbitration. If the arbitrator determines a
                party to be a prevailing party under circumstances where the
                prevailing party won on some but not all of the claims and
                counterclaims, the arbitrator may award the prevailing party an
                appropriate percentage of the costs and attorneys’ fees
                reasonably incurred by the prevailing party in connection with
                the arbitration.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section className={styles.indentBox}>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">General</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                BID THIS PROPERTY is located at 696 San Ramon Valley Blvd Suite
                326, Danville, CA 94526. If any provision of this Agreement is
                held to be invalid or unenforceable, that provision will be
                enforced to the maximum extent permissible by law, and the other
                provisions of the Terms and Conditions of Service will remain in
                force. Headings are for reference purposes only and do not limit
                the scope or extent of such section. Our failure to exercise or
                enforce any right or provision of the Terms and Conditions of
                service shall not constitute a waiver of such right or provision
                unless acknowledged and agreed by us in writing. This Agreement
                may not be amended by you except in a writing hand signed by you
                and us. For purposes of this provision only, a “writing” doesn’t
                include an email message and a signature doesn’t include an
                electronic signature. We do not guarantee we will take action in
                response to all breaches of this Agreement.
              </p>
              <p>
                We may amend this Agreement at any time by posting the amended
                terms on the Website. Except as stated elsewhere, all amended
                terms shall automatically be effective 30 days after they are
                initially posted. Additionally, we will notify you of any
                amendments to these Terms and Conditions of Service or the
                Privacy Policy through the BID THIS PROPERTY Message Center.
              </p>
              <p>
                This Agreement sets forth the entire understanding and agreement
                between us with respect to the subject matter hereof. The
                following Sections survive any termination of this Agreement:
                Fees and Services (with respect to fees owed for our services),
                Release, Content, Liability, Indemnity and Resolution of
                Disputes.
              </p>
              <p>
                In accordance with Cal. Civ. Code §1789.3, you may report
                complaints to the Complaint Assistance Unit of the Division of
                Consumer Services of the California Department of Consumer
                Affairs by contacting them in writing at 400 R Street,
                Sacramento, CA 95814, or by telephone at{' '}
                <Button className="custom-link" type="link">
                  <a href={`tel:(800) 952-5210`}>(800) 952-5210</a>
                </Button>
              </p>
            </Col>
          </Row>
        </section>
      </div>
    </div>
  );
}
