/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExternalListingDetail } from '../models/ExternalListingDetail';
import type { ExternalListingOrderBy } from '../models/ExternalListingOrderBy';
import type { ExternalListingResponse } from '../models/ExternalListingResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ListingsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Listings
     * @param page
     * @param pageSize
     * @param order
     * @param search
     * @returns ExternalListingResponse Successful Response
     * @throws ApiError
     */
    public listListings(
        page: number = 1,
        pageSize: number = 12,
        order?: ExternalListingOrderBy,
        search?: string,
    ): CancelablePromise<ExternalListingResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/listings/',
            query: {
                'page': page,
                'page_size': pageSize,
                'order': order,
                'search': search,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Listing
     * @param listingId
     * @returns ExternalListingDetail Successful Response
     * @throws ApiError
     */
    public getListing(
        listingId: string,
    ): CancelablePromise<ExternalListingDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/listings/{listing_id}',
            path: {
                'listing_id': listingId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update Listing Views
     * @param listingId
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateListingViewsV1ListingsListingIdViewsPost(
        listingId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/listings/{listing_id}/views',
            path: {
                'listing_id': listingId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
