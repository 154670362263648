import React, { ReactNode } from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import {
  Control,
  ControllerFieldState,
  ControllerRenderProps,
  useController,
} from 'react-hook-form';

type Props = {
  name: string;
  control: Control<any>;
  hint?: string;
  render: ({
    field,
    fieldState,
  }: {
    field: ControllerRenderProps;
    fieldState: ControllerFieldState;
  }) => ReactNode;
} & FormItemProps;

export default function FormItem(props: Props) {
  const { name, control, render, label, className, hint } = props;
  const { field, fieldState } = useController({ control, name });
  const { error } = fieldState;
  const hasError = !!error?.message;

  return (
    <Form.Item
      className={className}
      label={label}
      validateStatus={!hasError ? 'success' : 'error'}
      help={<span className="hint">{hasError ? error?.message : hint}</span>}
    >
      {render({ field, fieldState })}
    </Form.Item>
  );
}
