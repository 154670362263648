/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ListingType {
    APARTMENT = 'Apartment',
    COMMERCIAL = 'Commercial',
    CONDO = 'Condo',
    DUET = 'Duet',
    DUPLEX = 'Duplex',
    FARM = 'Farm',
    FIVE_UNITS = 'Five Units',
    FOURPLEX = 'Fourplex',
    HOTEL = 'Hotel',
    HOUSE = 'House',
    INDUSTRIAL = 'Industrial',
    LAND = 'Land',
    LUXURY = 'Luxury',
    MOBILE_MANUFACTURED_HOME = 'Mobile/Manufactured Home',
    STUDIO = 'Studio',
    TOWNHOUSE = 'Townhouse',
    TRIPLEX = 'Triplex',
    VACATION_HOME = 'Vacation Home',
}
