import { useEffect, useState } from 'react';
import { Button, Col, Divider, Empty, Popover, Row } from 'antd';
import cx from 'classnames';
import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import parse from 'html-react-parser';

import ContactUsForm from 'components/ContactUsSection/ContactUsForm';
import Spin from 'components/Spin';
import TimerBadge from 'components/TimerBadge';
import HowToBuyFAQCollapse from 'features/howToBuy/HowToBuyFAQSection/HowToBuyFAQCollapse';
import AgentCard from 'components/AgentCard';
import BidsHistory from 'components/BidsHistory';
import BidNowInfo from './BidNowInfo';
import { getFormattedNumber } from 'utils/number.utils';
import BidNowButton from '../BidNowButton';
import {
  ExternalListingDetail,
  ExternalNotificationType,
  ListingApi,
  ListingStatus,
} from 'services/api';
import { getBidderData, getPriceData, IsHotOffer } from 'utils/listing.utils';
import { getFormattedAddress } from 'utils/location.utils';
import { getCurrencySign } from 'utils/price.utils';
import { useGetSocketRequestSingle } from 'utils/socket.utils';
import ListingDetailsGallery from './ListingDetailsGallery';
import useNetworkNotificationHelper from 'hooks/useNetworkNotificationHelper';

import styles from './ListingDetailsPage.module.less';

const dashPlaceholder = <span>&#8212;</span>;

export default function ListingDetailsPage() {
  const { t } = useTranslation();
  const { listingId = '' } = useParams<'listingId'>();
  const [isLoading, setIsLoading] = useState(false);
  const [listingDetails, setListingDetails] = useState<ExternalListingDetail>();

  useEffect(() => {
    const loadListingDetails = async () => {
      try {
        setIsLoading(true);

        const result = await ListingApi.getListingDetails(listingId);

        setListingDetails(result);
      } catch (error) {
        setListingDetails(undefined);
      } finally {
        setIsLoading(false);
      }
    };

    loadListingDetails();
  }, [listingId]);

  useEffect(() => {
    ListingApi.updateListingViews(listingId);
  }, [listingId]);

  useNetworkNotificationHelper();

  useGetSocketRequestSingle(listingDetails, (val) => {
    setListingDetails(val as ExternalListingDetail);
  });

  if (isLoading && !listingDetails)
    return (
      <div className={styles.spinWrap}>
        <Spin />
      </div>
    );

  if (!listingDetails)
    return (
      <div className="layout-wrap">
        <Empty />
      </div>
    );

  const {
    ends_at,
    status,
    title,
    subtitle,
    description,
    bedrooms,
    bathrooms,
    car_spaces,
    house_area,
    bid_increment,
    buyers_premium,
    building_year,
    property_type,
    lot_area,
    views,
    address,
    user,
    id,
    initial_price,
    units,
  } = listingDetails;
  const isActive = status === ListingStatus.ACTIVE;
  const isHotOffer = IsHotOffer(listingDetails);
  const price = getPriceData(listingDetails);

  const bidderData = getBidderData(listingDetails, t);

  return (
    <div className={cx(styles.wrap, 'layout-wrap')}>
      <Spin className={styles.spinWrap} spinning={isLoading}>
        <div className={styles.backBtn}>
          <Link to="/listings">
            <Button
              className="btn-icon"
              size="small"
              icon={<i className="icon icon-arrow-left"></i>}
            ></Button>
          </Link>
        </div>
        <div className="container">
          <div className={styles.pageHead}>
            <Row gutter={16}>
              <Col xs={24} md={12} lg={{ span: 6, offset: 2 }}>
                <div className={styles.container}>
                  {isActive && (
                    <div className={styles.timerBox}>
                      <TimerBadge size="large" endDate={ends_at} />
                    </div>
                  )}
                  <h1 className={cx('h4', styles.mainTitle)}>{title}</h1>
                  <ul className={styles.info}>
                    <li>
                      <i className="icon icon-beds"></i>
                      {bedrooms}
                    </li>
                    <li>
                      <i className="icon icon-bathrooms"></i>
                      {bathrooms}
                    </li>
                    <li>
                      <i className="icon icon-garage"></i>
                      {car_spaces}
                    </li>
                    <li>
                      <i className="icon icon-size"></i>
                      {getFormattedNumber(house_area)} {units || 'sqft'}
                    </li>
                  </ul>
                  <div className={styles.price}>
                    <strong>{getCurrencySign(price?.currency)}</strong>
                    <span>{getFormattedNumber(price?.value)}</span>
                  </div>
                  <div className={styles.caption}>
                    <span>{bidderData.text}</span>{' '}
                    <strong>{bidderData.nickname}</strong>
                  </div>
                  <div className={styles.buttonWrap}>
                    <div className={styles.buttonBox}>
                      <BidNowButton
                        listing={listingDetails}
                        buttonProps={{
                          type: 'primary',
                          size: 'large',
                          block: true,
                          children: t('ListingDetailsPage.BidNow'),
                        }}
                      />
                    </div>
                    <Popover
                      getPopupContainer={() =>
                        document.getElementById('root') as HTMLElement
                      }
                      placement="right"
                      overlayClassName="popover-lg"
                      content={<BidNowInfo />}
                    >
                      <div className={styles.icon}>
                        <i className="icon icon-info"></i>
                      </div>
                    </Popover>
                  </div>
                  <ul className={styles.infoList}>
                    {isHotOffer && (
                      <li
                        className={cx(
                          styles.infoListItem,
                          styles.infoListItemHot,
                        )}
                      >
                        <i
                          className={cx(
                            'icon icon-fire-filled',
                            styles.infoListItemIcon,
                          )}
                        ></i>
                        <div>{t('ListingDetailsPage.MostViewedProperty')}</div>
                      </li>
                    )}
                    <li className={styles.infoListItem}>
                      <i
                        className={cx('icon icon-eye', styles.infoListItemIcon)}
                      ></i>
                      <div>
                        <div className={styles.infoListItemLabel}>
                          {t('ListingDetailsPage.Viewed')}
                        </div>
                        <div>{views}</div>
                      </div>
                    </li>
                    <li className={styles.infoListItem}>
                      <i
                        className={cx('icon icon-map', styles.infoListItemIcon)}
                      ></i>
                      <div>
                        <address className={styles.infoListItemLabel}>
                          {t('ListingDetailsPage.Address')}
                        </address>
                        <span>{getFormattedAddress(address)}</span>
                      </div>
                    </li>
                    <li className={styles.infoListItem}>
                      <i
                        className={cx(
                          'icon icon-user',
                          styles.infoListItemIcon,
                        )}
                      ></i>
                      <div>
                        <div className={styles.infoListItemLabel}>
                          {t('ListingDetailsPage.ListingAgent')}
                        </div>
                        <div>
                          {user?.first_name} {user?.last_name}
                          {user?.dre && ` , ${t('AgentCard.DRE')}`} {user?.dre}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={24} md={12} lg={16}>
                <ListingDetailsGallery listing={listingDetails} />
              </Col>
            </Row>
          </div>
          <Row gutter={16}>
            <Col xs={24} md={15} lg={{ span: 14, offset: 2 }}>
              <h2 className={cx('h5', styles.title)}>{title}</h2>
              <h3 className={styles.subtitle}>{subtitle}</h3>
              <div className={styles.description}>
                {!!description && parse(description)}
              </div>
              <div className={styles.description}>
                <strong>
                  {t('ListingDetailsPage.BidIncrement')}:{' '}
                  {getCurrencySign(initial_price?.currency) || '$'}
                  {getFormattedNumber(bid_increment)}
                </strong>
              </div>
              <div className={styles.description}>
                <strong>
                  {t('ListingDetailsPage.BuyersPremium')}: {buyers_premium}%{' '}
                  {t('ListingDetailsPage.BuyersPremium.info')}
                </strong>
              </div>
              <ul className={styles.descriptionList}>
                <li>
                  <i className="icon icon-home"></i>{' '}
                  {t('ListingDetailsPage.Type')}: <span>{property_type}</span>
                </li>
                <li>
                  <i className="icon icon-calendar"></i>{' '}
                  {t('ListingDetailsPage.Built')}:{' '}
                  {isNumber(building_year) ? (
                    <span>{building_year}</span>
                  ) : (
                    dashPlaceholder
                  )}
                </li>
                <li>
                  <i className="icon icon-expand"></i>{' '}
                  {t('ListingDetailsPage.LotSize')}
                  {': '}
                  {isNumber(lot_area) ? (
                    <span>
                      {getFormattedNumber(lot_area)} {units || 'sqft'}
                    </span>
                  ) : (
                    dashPlaceholder
                  )}
                </li>
              </ul>

              <Divider className="divider-light" />

              <div className={styles.indentXs}>
                <div className={styles.alert}>
                  <i className={cx('icon icon-flag', styles.alertIcon)}></i>
                  <div className={styles.alertText}>
                    {t('ListingDetailsPage.SellersReserves.infoBox')}
                  </div>
                </div>
              </div>
              <div className={styles.indent}>
                <div>{t('ListingDetailsPage.SellersReserves.info')}</div>
              </div>
              <div className={styles.indentXs}>
                <div className={styles.alert}>
                  <i className={cx('icon icon-flag', styles.alertIcon)}></i>

                  <div className={styles.alertText}>
                    {t('ListingDetailsPage.WinningBid.infoBox')}
                  </div>
                </div>
              </div>
              <div>
                <b>{t('ListingDetailsPage.WinningBid.info1.bold')}</b>
                {t('ListingDetailsPage.WinningBid.info1.text')}
              </div>

              <div className={styles.indentXs}>
                <b>{t('ListingDetailsPage.WinningBid.info2.bold')}</b>
                {t('ListingDetailsPage.WinningBid.info2.text')}
              </div>
              <div className={cx(styles.alert, styles.alertGray)}>
                <div className={styles.alertText}>
                  {t('ListingDetailsPage.Disclaimer')}
                </div>
              </div>
              <Divider className="divider-light" />

              <div className={styles.formWrap}>
                <h3 className="h5">{t('ListingDetailsPage.ContactAgent')}</h3>
                <ContactUsForm
                  type={
                    ExternalNotificationType.CONTACT_US_FORM_SUBMISSION_NOTIFICATION
                  }
                  listing_id={id}
                />
              </div>
              <div className={styles.indent}>
                <h3 className="h5">{t('ListingDetailsPage.FAQ')}</h3>
                <HowToBuyFAQCollapse />
              </div>
            </Col>
            <Col xs={24} md={9} lg={6}>
              <AgentCard className={cx(styles.indent, 'paper')} agent={user} />
              <BidsHistory listing={listingDetails} />
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
}
