import { useEffect, useRef, useState } from 'react';
import { Badge, Button, Tabs, Row, Col, Space } from 'antd';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import HorizontalListingCard from 'components/cards/HorizontalListingCard';
import { displayErrorMessage } from 'components/message';
import {
  AdminListingApprovalCount,
  AdminListingDetail,
  CancelablePromise,
  ListingApi,
} from 'services/api';
import Spin from 'components/Spin';
import AllBids from './AllBids';
import ApprovedBuyers from './ApprovedBuyers';
import PendingRequests from './PendingRequests';
import { checkIsTabCorrect } from './bidManagementPageUtils';

import styles from './BidManagementPage.module.less';

export enum BidManagementTabsEnum {
  AllBids = 'all-bids',
  ApprovedBuyers = 'approved-buyers',
  PendingRequests = 'pending-requests',
}

export default function BidManagementPage() {
  const [isItemLoading, setIsItemLoading] = useState(false);
  const [listingData, setListingData] = useState<AdminListingDetail>();
  const [pendingRequestsCount, setPendingRequestsCount] =
    useState<AdminListingApprovalCount>();
  const lastItemRequestRef = useRef<CancelablePromise<AdminListingDetail>>();
  const lastCountRequestRef =
    useRef<CancelablePromise<AdminListingApprovalCount>>();
  const { listingId } = useParams<'listingId'>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const currentTab = searchParams.get('tab');

  useEffect(() => {
    const isTabCorrect = checkIsTabCorrect(currentTab);
    if (isTabCorrect) return;

    setSearchParams(
      { tab: BidManagementTabsEnum.AllBids },
      {
        replace: true,
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isTabCorrect = checkIsTabCorrect(currentTab);
    if (!isTabCorrect || !listingId) return;

    loadListingInfo();
    loadPendingRequestsCount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingId, currentTab]);

  const loadListingInfo = async () => {
    if (!listingId) return;

    setIsItemLoading(true);

    let request: CancelablePromise<AdminListingDetail> | undefined = undefined;
    try {
      lastItemRequestRef.current?.cancel();
      request = ListingApi.getAdminListingDetails(listingId);
      lastItemRequestRef.current = request;

      const result = await request;

      setListingData(result);
    } catch (error) {
      if (!request?.isCancelled) {
        displayErrorMessage({ error });
      }
    } finally {
      if (!request?.isCancelled) {
        setIsItemLoading(false);
      }
    }
  };

  const loadPendingRequestsCount = async () => {
    if (!listingId) return;

    let request: CancelablePromise<AdminListingApprovalCount> | undefined =
      undefined;
    try {
      lastCountRequestRef.current?.cancel();
      request = ListingApi.getBidPendingRequestCount(listingId);
      lastCountRequestRef.current = request;

      const result = await request;

      setPendingRequestsCount(result);
    } catch (error) {
      if (!request?.isCancelled) {
        displayErrorMessage({ error });
      }
    }
  };

  const handleTabChange = (tabKey: string) => {
    setSearchParams(
      { tab: tabKey },
      {
        replace: true,
      },
    );
  };

  return (
    <div className={styles.wrap}>
      <div className="container">
        <div className={styles.backBtn}>
          <Link to="../listings-management">
            <Button
              className="btn-icon"
              size="small"
              icon={<i className="icon icon-arrow-left" />}
            />
          </Link>
        </div>
        <Row>
          <Col xs={24} xl={{ span: 20, offset: 2 }}>
            <div className="paper">
              <Spin spinning={!listingData && isItemLoading}>
                <div className={styles.card}>
                  {listingData && (
                    <HorizontalListingCard listing={listingData} />
                  )}
                </div>
              </Spin>
              <Tabs
                className={styles.tabs}
                activeKey={currentTab || ''}
                onChange={handleTabChange}
              >
                <Tabs.TabPane
                  tab={t('BidManagement.Tabs.AllBids')}
                  key={BidManagementTabsEnum.AllBids}
                >
                  <AllBids
                    isActive={currentTab === BidManagementTabsEnum.AllBids}
                    listingId={listingId}
                    listing={listingData}
                    onChangeReloadListingDetail={loadListingInfo}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={t('BidManagement.Tabs.ApprovedBuyers')}
                  key={BidManagementTabsEnum.ApprovedBuyers}
                >
                  <ApprovedBuyers
                    isActive={
                      currentTab === BidManagementTabsEnum.ApprovedBuyers
                    }
                    listingId={listingId}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <Space>
                      {t('BidManagement.Tabs.PendingRequests')}
                      <Badge count={pendingRequestsCount?.total} />
                    </Space>
                  }
                  key={BidManagementTabsEnum.PendingRequests}
                >
                  <PendingRequests
                    isActive={
                      currentTab === BidManagementTabsEnum.PendingRequests
                    }
                    listingId={listingId}
                    listingStatus={listingData?.status}
                  />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
