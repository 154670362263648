import { useState, createContext, useEffect, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { isObject } from 'lodash';

import Spin from 'components/Spin';
import { CommonApi, ExternalBrokerageDetail } from 'services/api';

interface IAppSettingsState {
  settings: ExternalBrokerageDetail | null;
  isMainSite: boolean;
}

export const AppSettingsContext = createContext<IAppSettingsState>({
  settings: null,
  isMainSite: true,
});

type Props = {
  children: JSX.Element;
};

export const AppSettingsProvider = (props: Props) => {
  const { children } = props;
  const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);
  const [settings, setSettings] = useState<IAppSettingsState['settings']>(null);

  useEffect(() => {
    const loadAppSettings = async () => {
      try {
        const result = await CommonApi.getAppSettings();
        // API returns "OK" string for the main site
        const settingsResult = isObject(result) ? result : null;

        setSettings(settingsResult);
      } catch (error: any) {
        if (error.status === 404) {
          window.location.replace(error.body);
        }
        setSettings(null);
      } finally {
        setIsSettingsLoaded(true);
      }
    };

    loadAppSettings();
  }, []);

  const values = useMemo(
    () => ({
      settings,
      isMainSite: !settings,
    }),
    [settings],
  );

  if (!isSettingsLoaded) return <Spin />;

  return (
    <AppSettingsContext.Provider value={values}>
      <Helmet>
        {!!settings?.brokerage_site_name && (
          <title>{settings?.brokerage_site_name}</title>
        )}
        {!!settings?.favicon && (
          <link
            data-testid="favicon"
            rel="shortcut icon"
            href={settings?.favicon}
            data-react-helmet={true}
          />
        )}
      </Helmet>
      {children}
    </AppSettingsContext.Provider>
  );
};

export const useAppSettings = () => {
  const context = useContext(AppSettingsContext);
  if (context === undefined) {
    throw new Error(
      '`useAppSettings` hook must be used within a `AppSettingsProvider` component',
    );
  }

  return context;
};
