import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { ListingStatus } from 'services/api';

import styles from './ListingStatusLabel.module.less';

type Props = {
  status: ListingStatus;
};

export default function ListingStatusLabel({ status }: Props) {
  const { t } = useTranslation();

  const getLabel = () => {
    switch (status) {
      case ListingStatus.ACTIVE:
        return t('ListingStatus.Active');
      case ListingStatus.SOLD:
        return t('ListingStatus.Sold');
      case ListingStatus.DRAFT:
        return t('ListingStatus.Draft');
      case ListingStatus.ENDED:
        return t('ListingStatus.Ended');
      case ListingStatus.COMING_SOON:
        return t('ListingStatus.ComingSoon');
      case ListingStatus.WITHDRAWN:
        return t('ListingStatus.Withdrawn');
      case ListingStatus.PENDING:
        return t('ListingStatus.Pending');

      default:
        return '';
    }
  };

  return (
    <div
      className={cx(styles.status, {
        [styles.active]: status === ListingStatus.ACTIVE,
        [styles.sold]: status === ListingStatus.SOLD,
        [styles.pending]: status === ListingStatus.PENDING,
        [styles.comingSoon]: status === ListingStatus.COMING_SOON,
        [styles.ended]: status === ListingStatus.ENDED,
        [styles.withdrawn]: status === ListingStatus.WITHDRAWN,
        [styles.draft]: status === ListingStatus.DRAFT,
      })}
    >
      {getLabel()}
    </div>
  );
}
