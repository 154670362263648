/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum IncrementType {
    '_1000' = 1000,
    '_5000' = 5000,
    '_10000' = 10000,
    '_15000' = 15000,
    '_20000' = 20000,
}
