import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Form, Input } from 'antd';
import { TFunction, useTranslation } from 'react-i18next';

import FormItem from 'components/form/FormItem';
import { useAuth } from 'components/AuthProvider';
import { displayErrorMessage } from 'components/message';
import { ModalScrollWrapper, ModalFooterWrapper } from 'components/Modal';
interface IFormValues {
  email: string;
}

const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('validation.format'))
      .max(
        120,
        t('validation.maxLength', {
          count: 120,
        }),
      )
      .required(t('validation.required')),
  });

type Props = {
  onClose: () => void;
  onSuccess?: (val: string) => void;
};

export default function ForgotPasswordForm(props: Props) {
  const { t } = useTranslation();
  const { forgotPassword } = useAuth();
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
    },
  });
  const { onClose, onSuccess } = props;
  const handleError = (error: any) => {
    displayErrorMessage({
      error,
      containerSelector: '.ant-modal',
    });
  };
  const emailContactUs = 'info@bidthisproperty.com';
  const onSubmit = async (values: IFormValues) => {
    try {
      await forgotPassword({ email: values.email });
      onSuccess?.(values.email);
    } catch (error: any) {
      handleError(error);
    }
  };

  return (
    <>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
        <ModalScrollWrapper>
          <div className="modal__subtitle">
            {t('ForgotPasswordModal.BackTo')} &nbsp;
            <Button
              className="custom-link"
              type="link"
              onClick={() => {
                onClose();
              }}
            >
              {t('ForgotPasswordModal.SignIn')}
            </Button>
          </div>
          <div className="modal__label">
            {t('ForgotPasswordModal.EnterYourEmail')}
          </div>
          <FormItem
            name="email"
            control={control}
            render={({ field }) => (
              <Input placeholder="Email" size="large" {...field} />
            )}
          />
          <div className="modal__subtitle">
            <div>{t('ForgotPasswordModal.WeWillSendRecoveryCode1')}</div>
            <span>{t('ForgotPasswordModal.WeWillSendRecoveryCode2')}</span>
            <a
              target="_blank"
              rel="noreferrer"
              href={`mailto:${emailContactUs}`}
            >
              <Button className="custom-link" type="link">
                {t('ForgotPasswordModal.ContactUs')}
              </Button>
            </a>
          </div>
        </ModalScrollWrapper>

        <ModalFooterWrapper>
          <div>
            <Button
              onClick={() => {
                onClose();
              }}
              type="link"
              className="custom-link"
              size="large"
            >
              {t('ForgotPasswordModal.Cancel')}
            </Button>
          </div>
          <div>
            <Button
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              size="large"
            >
              {t('ForgotPasswordModal.ResetPassword')}
            </Button>
          </div>
        </ModalFooterWrapper>
      </Form>
    </>
  );
}
