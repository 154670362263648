import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Empty } from 'antd';
import moment from 'moment';
import { isNil, isNumber } from 'lodash';

import {
  AdminListingDetail,
  IncrementType,
  ListingApi,
  PremiumType,
} from 'services/api';
import Spin from 'components/Spin';
import NewListingPage, { NewListingPageModeEnum } from '../NewListingPage';
import { getDefaultStatus } from '../NewListingPage/newListingPage.utils';
import styles from './EditListingPage.module.less';
import { getFormattedFiles } from 'utils/file.utils';

export default function EditListingPage() {
  const { listingId = '' } = useParams<'listingId'>();
  const [isLoading, setIsLoading] = useState(false);
  const [listingDetails, setListingDetails] = useState<AdminListingDetail>();

  useEffect(() => {
    const loadListingDetails = async () => {
      try {
        setIsLoading(true);

        const result = await ListingApi.getAdminListingDetails(listingId);

        setListingDetails(result);
      } catch {
      } finally {
        setIsLoading(false);
      }
    };

    loadListingDetails();
  }, [listingId]);

  if (isLoading)
    return (
      <div className={styles.spinWrap}>
        <Spin />
      </div>
    );

  if (!listingDetails)
    return (
      <div className="layout-wrap">
        <Empty />
      </div>
    );

  const {
    ends_at,
    title,
    subtitle,
    description,
    bedrooms,
    bathrooms,
    car_spaces,
    house_area,
    bid_increment,
    buyers_premium,
    building_year,
    property_type,
    lot_area,
    address,
    initial_price,
    property_pictures,
    videos,
  } = listingDetails;

  return (
    <NewListingPage
      mode={NewListingPageModeEnum.Edit}
      listing={listingDetails}
      defaultValues={{
        title: title || '',
        address: address?.address || '',
        city: address?.city || '',
        state: address?.state_abbreviation || '',
        zip: address?.postcode || '',
        endDate: ends_at ? moment(ends_at) : null,
        startingBid:
          initial_price && !isNil(initial_price?.value)
            ? initial_price.value
            : null,
        buyersPremium: !isNil(buyers_premium) ? buyers_premium : PremiumType._3,
        bidIncrement: !isNil(bid_increment)
          ? bid_increment
          : IncrementType._1000,
        type: property_type || undefined,
        built: isNumber(building_year) ? building_year : null,
        houseArea: isNumber(house_area) ? house_area : null,
        lotArea: isNumber(lot_area) ? lot_area : null,
        beds: isNumber(bedrooms) ? bedrooms : null,
        baths: isNumber(bathrooms) ? bathrooms : null,
        carSpaces: isNumber(car_spaces) ? car_spaces : null,
        subtitle: subtitle || '',
        description: description || '',
        photos: getFormattedFiles(property_pictures),
        status: getDefaultStatus(listingDetails),
        video1: videos?.[0] || '',
        video2: videos?.[1] || '',
      }}
    />
  );
}
