import { Image, ImageProps } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { ExternalListingDetail } from 'services/api';
import { getImageUrl, ImageSize } from 'utils/image.utils';
import VideoCarousel, { VideoIFrame } from './VideoCarousel';
import emptyImg from 'assets/images/empty_img.svg';

import styles from './ListingDetailsGallery.module.less';

type Props = {
  listing: ExternalListingDetail;
};

export default function ListingDetailsGallery(props: Props) {
  const { t } = useTranslation();
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [isVideoCarouselVisible, setIsVideoCarouselVisible] = useState(false);
  const {
    listing: { property_pictures = [], videos = [] },
  } = props;
  const previewDefaultProps: ImageProps['preview'] = {
    mask: <div />,
  };
  const imageCountToDisplay = !!videos.length ? 3 : 4;
  const propertyPictures = useMemo(() => {
    if (property_pictures.length < imageCountToDisplay) {
      // possible only for Draft listings
      const countToAdd = imageCountToDisplay - property_pictures.length;
      for (let index = 0; index < countToAdd; index++) {
        property_pictures.push({
          url: '',
          key: `image-key-${index}`,
        });
      }
    }
    return property_pictures;
  }, [property_pictures, imageCountToDisplay]);
  const mainImage = propertyPictures[0];
  const thumbImages = propertyPictures.slice(1, imageCountToDisplay - 1);
  const lastThumbImage = propertyPictures[imageCountToDisplay - 1];
  const restImages = propertyPictures.slice(imageCountToDisplay);
  const isLargeGallery = propertyPictures.length > imageCountToDisplay;
  const additionalImageCount = propertyPictures.length - imageCountToDisplay;

  return (
    <div>
      {isVideoCarouselVisible && (
        <VideoCarousel
          onClose={() => setIsVideoCarouselVisible(false)}
          videos={videos}
        />
      )}
      <Image.PreviewGroup
        preview={{
          visible: isPreviewVisible,
          current: imageCountToDisplay - 1,
          onVisibleChange: (visible) => setIsPreviewVisible(visible),
        }}
      >
        <div className={styles.gallery}>
          <div className={styles.galleryMain}>
            {mainImage.url ? (
              <Image
                preview={{ ...previewDefaultProps, src: mainImage.url }}
                src={getImageUrl(mainImage.url, ImageSize.BIG)}
              />
            ) : (
              <div className={styles.emptyImg}>
                <img src={emptyImg} alt="empty" />
              </div>
            )}
          </div>

          <div className={styles.galleryThumb}>
            {thumbImages.map((thumbImage) => (
              <div
                key={`thumb-image-${thumbImage.key}`}
                className={styles.galleryThumbBox}
              >
                {thumbImage.url ? (
                  <Image
                    preview={{ ...previewDefaultProps, src: thumbImage.url }}
                    src={getImageUrl(thumbImage.url, ImageSize.SMALL)}
                  />
                ) : (
                  <div className={styles.emptyImg}>
                    <img src={emptyImg} alt="empty" />
                  </div>
                )}
              </div>
            ))}
            {isLargeGallery && (
              <div className={styles.galleryThumbBox}>
                <div
                  onClick={() => setIsPreviewVisible(true)}
                  className={styles.more}
                >
                  <img
                    src={getImageUrl(lastThumbImage.url, ImageSize.SMALL)}
                    alt=""
                    className={styles.moreImg}
                  />
                  <div className={styles.moreDesc}>
                    <strong>+ {additionalImageCount}</strong>
                    <div>
                      {t('ListingDetailsGallery.photo', {
                        count: additionalImageCount,
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className={cx(styles.galleryThumbBox, {
                [styles.hidden]: isLargeGallery,
              })}
            >
              {lastThumbImage.url ? (
                <Image
                  preview={{ ...previewDefaultProps, src: lastThumbImage.url }}
                  src={getImageUrl(lastThumbImage.url, ImageSize.SMALL)}
                />
              ) : (
                <div className={styles.emptyImg}>
                  <img src={emptyImg} alt="empty" />
                </div>
              )}
            </div>

            {restImages.map((image) => (
              <div key={`rest-image-${image.key}`} className={styles.hidden}>
                <Image src={image.url} loading="lazy" />
              </div>
            ))}

            {!!videos.length && (
              <div
                className={styles.galleryThumbBox}
                onClick={() => setIsVideoCarouselVisible(true)}
              >
                <div className={styles.videoThumb}>
                  <VideoIFrame
                    style={{ pointerEvents: 'none' }}
                    src={videos[0]}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Image.PreviewGroup>
    </div>
  );
}
