import { useTranslation } from 'react-i18next';
import { Collapse, Tabs } from 'antd';
import { CaretUpOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';

import styles from './VideoTutorial.module.less';

const { Panel } = Collapse;

export default function VideoTutorial() {
  const { t } = useTranslation();

  return (
    <div className={styles.collapse}>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretUpOutlined rotate={isActive ? 0 : 180} />
        )}
      >
        <Panel header={t('VideoTutorial.rightLink.header')} key="rightLink">
          <p className={styles.description}>
            {t('VideoTutorial.rightLink.description')}
          </p>
          <Tabs>
            <Tabs.TabPane tab="YouTube" key="YouTube">
              <div className={styles.player}>
                <ReactPlayer
                  url="/videos/YouTube.mov"
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
              <ol className={styles.list}>
                <li>{t('VideoTutorial.YouTube.step1')}</li>
                <li>{t('VideoTutorial.YouTube.step2')}</li>
                <li>
                  <span>{t('VideoTutorial.YouTube.step3')}</span>
                  <div className={styles.link}>
                    https://www.youtube.com/embed/xxxxxxxxxx
                  </div>
                </li>
              </ol>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Vimeo" key="Vimeo">
              <div className={styles.player}>
                <ReactPlayer
                  url="/videos/Vimeo.mov"
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
              <ol className={styles.list}>
                <li>{t('VideoTutorial.Vimeo.step1')}</li>
                <li>{t('VideoTutorial.Vimeo.step2')}</li>
                <li>
                  <span>{t('VideoTutorial.Vimeo.step3')}</span>
                  <div className={styles.link}>
                    https://player.vimeo.com/video/123456789
                  </div>
                </li>
              </ol>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Matterport" key="Matterport">
              <div className={styles.player}>
                <ReactPlayer
                  url="/videos/Matterport.mov"
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
              <ol className={styles.list}>
                <li>{t('VideoTutorial.Matterport.step1')}</li>
                <li>{t('VideoTutorial.Matterport.step2')}</li>
                <li>
                  <span>{t('VideoTutorial.Matterport.step3')}</span>
                  <div className={styles.link}>
                    https://my.matterport.com/show/?m=xxxxxxxxxx
                  </div>
                </li>
              </ol>
            </Tabs.TabPane>
          </Tabs>
        </Panel>
      </Collapse>
    </div>
  );
}
