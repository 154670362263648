import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

import Accessor from 'components/Accessor';
import { ExternalUserRole } from 'services/api';

import styles from './Sidebar.module.less';

export default function Sidebar() {
  const { t } = useTranslation();

  return (
    <aside>
      <ul className={styles.nav}>
        <Accessor
          roles={[ExternalUserRole.SUPER_ADMIN, ExternalUserRole.LISTING_AGENT]}
        >
          <li className={styles.navHead}>
            <Link to="/listings-management/new-listing">
              <Button
                type="primary"
                className="with-icon"
                size="large"
                icon={<i className="icon icon-plus"></i>}
                block
              >
                {t('Dashboard.ListingsManagement.NewListing')}
              </Button>
            </Link>
          </li>
        </Accessor>
        <li>
          <NavLink className={styles.navItem} to="/listings-management" end>
            <>
              <i className="icon icon-list" />
              <span>{t('Dashboard.ListingsManagement.Listings')}</span>
            </>
          </NavLink>
        </li>
        <Accessor
          roles={[
            ExternalUserRole.SUPER_ADMIN,
            ExternalUserRole.BROKERAGE_ADMIN,
          ]}
        >
          <li>
            <NavLink className={styles.navItem} to="/admins-and-agents" end>
              <>
                <i className="icon icon-sale" />
                {t('Dashboard.Sidebar.AdminsAndAgents')}
              </>
            </NavLink>
          </li>
        </Accessor>
        <Accessor roles={[ExternalUserRole.SUPER_ADMIN]}>
          <li>
            <NavLink className={styles.navItem} to="/brokerages" end>
              <>
                <i className="icon icon-brokerage" />
                {t('Dashboard.BrokeragesManagement.Brokerages')}
              </>
            </NavLink>
          </li>
        </Accessor>
      </ul>
    </aside>
  );
}
