import { useState } from 'react';
import { Button, Spin } from 'antd';
import cx from 'classnames';

import { useAuth } from 'components/AuthProvider';
import { useTranslation } from 'react-i18next';
import { ExternalListingList, ListingApi } from 'services/api';

import spinner from 'assets/images/spinnerXs.svg';

import styles from './GetApprovedSuccessStep.module.less';

type Props = {
  listing: ExternalListingList;
  onClose: () => void;
  onSuccess: () => void;
};

const spinIcon = (
  <>
    <div className={styles.spinnerWrap}>
      <div className={styles.spinner}>
        <img src={spinner} alt="spinner" />
      </div>
    </div>
  </>
);

export default function GetApprovedSuccessStep(props: Props) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { listing, onClose, onSuccess } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleRefresh = async () => {
    if (!user) return;

    try {
      setIsLoading(true);

      const result = await ListingApi.isApprovedForBid({
        listingId: listing.id,
        userId: user.user_id,
      });

      result.is_approved && onSuccess();
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.gridContainer}>
      <div className="modal__scroll">
        <div className={styles.wrap}>
          {isLoading && <Spin indicator={spinIcon} />}
          <h3 className={cx('h4', styles.title)}>
            {t('GetApprovedSuccessStep.ThankYou')}
          </h3>
          <div className={styles.text}>
            {t('GetApprovedSuccessStep.review')}
          </div>
          <div>
            <Button
              className="with-icon"
              icon={<i className="icon icon-reload"></i>}
              onClick={handleRefresh}
              type="link"
              size="small"
              disabled={isLoading}
            >
              {t('GetApprovedSuccessStep.Refresh')}
            </Button>
          </div>
        </div>
      </div>
      <div className="modal__footer">
        <div className="modal__footer-row">
          <div>
            <Button
              onClick={onClose}
              type="link"
              className="custom-link"
              size="large"
            >
              {t('GetApprovedSuccessStep.Close')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
