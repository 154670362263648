import { Button, Input, Popover } from 'antd';
import FormItem from 'components/form/FormItem';
import { useFormContext } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  atLeastOneLetterRegExp,
  onlyLettersNumbersDashesRegExp,
  onlyLettersNumbersSpacesRegExp,
  onlyLettersNumbersSpacesDashesRegExp,
  leadingEndingDashesRegExp,
} from 'utils/validation.utils';

import cx from 'classnames';

import styles from '../NewBrokeragePage.module.less';

type Props = {
  onNext: () => void;
  isEditMode?: boolean;
};

export interface IBasicFormValues {
  name: string;
  market_center: string;
  brokerage_site_name: string;
  subdomain: string;
}

export const getBasicValidationSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t('validation.required'))
      .max(
        40,
        t('validation.maxLength', {
          count: 40,
        }),
      )
      .matches(onlyLettersNumbersSpacesDashesRegExp, t('validation.format'))
      .matches(atLeastOneLetterRegExp, t('validation.format')),
    market_center: yup
      .string()
      .required(t('validation.required'))
      .max(
        10,
        t('validation.maxLength', {
          count: 10,
        }),
      )
      .matches(onlyLettersNumbersDashesRegExp, t('validation.format')),
    brokerage_site_name: yup
      .string()
      .required(t('validation.required'))
      .max(
        30,
        t('validation.maxLength', {
          count: 30,
        }),
      )
      .matches(onlyLettersNumbersSpacesRegExp, t('validation.format'))
      .matches(atLeastOneLetterRegExp, t('validation.format')),
    subdomain: yup
      .string()
      .required(t('validation.required'))
      .max(
        63,
        t('validation.maxLength', {
          count: 63,
        }),
      )
      .matches(onlyLettersNumbersDashesRegExp, t('validation.format'))
      .test(
        'subdomainTest',
        t('validation.subdomain.format'),
        (val) => !val?.match(leadingEndingDashesRegExp),
      ),
  });

export default function NewBrokerageBasicTab(props: Props) {
  const { t } = useTranslation();

  const { onNext, isEditMode = false } = props;
  const { control, handleSubmit } = useFormContext<IBasicFormValues>();
  return (
    <div className={styles.form}>
      <div className={styles.formWrapMd}>
        <FormItem
          name="name"
          control={control}
          render={({ field }) => (
            <Input.Group compact className="input-group">
              <div className="prefix-label prefix-label--lg">
                {t('NewBrokeragePage.Name')}
              </div>
              <Input
                size="large"
                placeholder={t('NewBrokeragePage.Name.placeholder')}
                {...field}
              />
            </Input.Group>
          )}
        />
        <FormItem
          name="market_center"
          control={control}
          render={({ field }) => (
            <Input.Group compact className="input-group">
              <div className="prefix-label prefix-label--lg">
                {t('NewBrokeragePage.MarketCenter')}
              </div>
              <Input
                size="large"
                placeholder={t('NewBrokeragePage.MarketCenter.placeholder')}
                disabled={isEditMode}
                {...field}
              />
            </Input.Group>
          )}
        />
        <FormItem
          name="brokerage_site_name"
          control={control}
          render={({ field }) => (
            <Input.Group compact className="input-group">
              <div className="prefix-label prefix-label--lg">
                {t('NewBrokeragePage.BrokerageSiteName')}
              </div>
              <Input
                size="large"
                placeholder={t(
                  'NewBrokeragePage.BrokerageSiteName.placeholder',
                )}
                {...field}
              />
            </Input.Group>
          )}
        />
        <div className={styles.popoverWrap}>
          <FormItem
            name="subdomain"
            control={control}
            render={({ field }) => (
              <Input.Group compact className="input-group">
                <div className="prefix-label prefix-label--lg">
                  {t('NewBrokeragePage.Subdomain')}
                </div>
                <Input
                  size="large"
                  addonBefore="https://"
                  addonAfter={`.${process.env.REACT_APP_DOMAIN}`}
                  placeholder={t('NewBrokeragePage.Subdomain.placeholder')}
                  disabled={isEditMode}
                  {...field}
                />
              </Input.Group>
            )}
          />
          <Popover
            getPopupContainer={() =>
              document.getElementById('root') as HTMLElement
            }
            placement="bottom"
            content={
              <div className={styles.popoverContainer}>
                <div>{t('NewBrokeragePage.Subdomain.popover.text1')}</div>
                <strong>
                  {t('NewBrokeragePage.Subdomain.popover.text2', {
                    domain: process.env.REACT_APP_DOMAIN,
                  })}
                </strong>
                <div>{t('NewBrokeragePage.Subdomain.popover.text3')}</div>
              </div>
            }
          >
            <i className={cx(styles.popoverOpener, 'icon icon-info')}></i>
          </Popover>
        </div>
        <div className={styles.btnWrap}>
          <Button
            type="primary"
            size="large"
            className="with-icon"
            onClick={handleSubmit(onNext)}
          >
            {t('NewBrokeragePage.Next')}
            <i className="icon icon-arrow-right"></i>
          </Button>
        </div>
      </div>
    </div>
  );
}
