import { useTranslation } from 'react-i18next';
import { ExternalUserStaffDetail } from 'services/api';

import { getImageUrl, ImageSize } from 'utils/image.utils';

import cx from 'classnames';
import styles from './CertifiedAgentCard.module.less';

type Props = {
  certifiedAgent: ExternalUserStaffDetail;
  className?: string;
};

export default function CertifiedAgentCard(props: Props) {
  const { t } = useTranslation();
  const { className = '', certifiedAgent } = props;
  const {
    first_name,
    last_name,
    dre,
    phone,
    email,
    photo,
    brokerage_names,
    title,
  } = certifiedAgent;

  return (
    <div className={cx(styles.contact, className)}>
      <div className={styles.contactImg}>
        {!!photo && <img src={getImageUrl(photo, ImageSize.SMALL)} alt="" />}
      </div>
      <div className={styles.contactInfo}>
        <div className={styles.contactName}>
          {first_name} {last_name}
        </div>
        {!!brokerage_names && (
          <div className={styles.contactCompany}>{brokerage_names[0]}</div>
        )}
        {!!title && <div className={styles.contactBroker}>{title}</div>}
        {!!dre && (
          <div className={styles.contactDre}>
            {t('CertifiedAgents.DRE')} {dre}
          </div>
        )}
        <ul className={styles.contactList}>
          <li>
            <a href={`tel:${phone}`}>
              <i className="icon icon-phone"></i>
              {phone}
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href={`mailto:${email}`}>
              <i className="icon icon-mail"></i>
              {email}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
