import { Switch, Space } from 'antd';
import { displayErrorMessage } from 'components/message';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListingApi, ExternalUserDetail } from 'services/api';

import styles from './SubscriptionForm.module.less';

interface SubscriptionValues {
  profileSettings: ExternalUserDetail;
}

export default function SubscriptionForm(props: SubscriptionValues) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { profileSettings } = props;
  const [checked, setIsChecked] = useState(profileSettings?.is_subscribed);
  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      setIsChecked(!checked);
      await ListingApi.changeSubscription(profileSettings.id, !checked);
    } catch (error) {
      setIsChecked(!!checked);
      displayErrorMessage({
        error,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <h3 className="form-label">{t('MyProfile.Subscriptions')}</h3>
      <Space size={8} align="start">
        <div className={styles.switchBox}>
          <Switch
            size="small"
            checked={checked}
            onChange={onSubmit}
            loading={isSubmitting}
          />
        </div>
        <div className={styles.label}>
          {t('MyProfile.EmailSubscription')}
          <div>{t('MyProfile.AllUpdates')}</div>
        </div>
      </Space>
    </>
  );
}
