import { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {
  lastLoadedQuery?: string;
  loading: boolean;
  onChange: (query: string) => void;
  className?: string;
  buttonName?: string;
  placeholder?: string;
};

export const QueryUrlKey = 'q';

export default function SearchInput(props: Props) {
  const { t } = useTranslation();
  const {
    lastLoadedQuery,
    loading,
    onChange,
    className = '',
    placeholder = '',
    buttonName = t('SearchInput.buttonName'),
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const queryFromUrl = searchParams.get(QueryUrlKey) || '';
  const [query, setQuery] = useState(queryFromUrl);
  const submitLoading =
    lastLoadedQuery !== undefined && lastLoadedQuery !== query && loading;

  useEffect(() => {
    onChange(queryFromUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFromUrl]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleSubmit = () => {
    if (loading) return;

    const params = Object.fromEntries(searchParams);

    if (query) {
      params[QueryUrlKey] = query;
    } else {
      delete params[QueryUrlKey];
    }

    setSearchParams(params, {
      replace: true,
    });
  };

  return (
    <div className={className}>
      <Input
        value={query}
        onChange={handleChange}
        placeholder={placeholder}
        prefix={<i className="icon icon-search" />}
        onPressEnter={handleSubmit}
        suffix={
          <Button loading={submitLoading} type="primary" onClick={handleSubmit}>
            {buttonName}
          </Button>
        }
      />
    </div>
  );
}
