import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ModalScrollWrapper, ModalFooterWrapper } from 'components/Modal';

import styles from './WithdrawnModal.module.less';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
};

export default function WithdrawnModal(props: Props) {
  const { t } = useTranslation();
  const { onClose, onSuccess } = props;

  const onSubmit = async () => {
    onSuccess();
  };

  return (
    <Modal
      title={null}
      maskClosable={false}
      visible
      centered
      destroyOnClose
      onCancel={onClose}
      footer={null}
      width={550}
      closable={false}
    >
      <Button
        icon={<i className="icon icon-close"></i>}
        onClick={onClose}
        className="btn-icon modal__close"
        size="small"
      />
      <h2 className="modal__title">{t('WithdrawnModal.title')}</h2>

      <ModalScrollWrapper>
        <div className={styles.content}>
          <div className={styles.description}>
            {t('WithdrawnModal.description')}
          </div>
        </div>
      </ModalScrollWrapper>

      <ModalFooterWrapper>
        <div>
          <Button
            className="custom-link"
            onClick={onClose}
            size="large"
            type="link"
          >
            {t('WithdrawnModal.Cancel')}
          </Button>
        </div>

        <div>
          <Button type="primary" onClick={onSubmit} size="large">
            {t('WithdrawnModal.Withdraw')}
          </Button>
        </div>
      </ModalFooterWrapper>
    </Modal>
  );
}
