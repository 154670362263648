/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_create_user_v1__internal__internal_create_user_post } from '../models/Body_create_user_v1__internal__internal_create_user_post';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create User
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public createUserV1InternalInternalCreateUserPost(
        requestBody: Body_create_user_v1__internal__internal_create_user_post,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/_internal/_internal/create_user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Login
     * @param email
     * @param password
     * @returns any Successful Response
     * @throws ApiError
     */
    public loginV1InternalInternalLoginPost(
        email: string,
        password: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/_internal/_internal/login',
            query: {
                'email': email,
                'password': password,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete User
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteUserV1InternalInternalDeleteUserDelete(
        requestBody: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/_internal/_internal/delete_user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
