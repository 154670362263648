import { useState, useEffect } from 'react';

import { ExternalListingDetail, ListingApi } from 'services/api';
import BidNowDataForm from './BidNowDataForm';
import BidNowSuccessStep from './BidNowSuccessStep';
import Spin from 'components/Spin';
import { useGetSocketRequestSingle } from 'utils/socket.utils';

type Props = {
  listingId: string;
  onClose: () => void;
  onSuccess: () => void;
};

export default function BidNowForm(props: Props) {
  const { onClose, onSuccess, listingId } = props;
  const [isSuccessStep, setIsSuccessStep] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listingDetails, setListingDetails] = useState<ExternalListingDetail>();

  useEffect(() => {
    const loadListingDetails = async () => {
      try {
        setIsLoading(true);

        const result = await ListingApi.getListingDetails(listingId);

        setListingDetails(result);
      } catch {
      } finally {
        setIsLoading(false);
      }
    };

    loadListingDetails();
  }, [listingId]);
  useGetSocketRequestSingle(listingDetails, (val) =>
    setListingDetails(val as ExternalListingDetail),
  );

  const refreshDataOnError = async () => {
    const result = await ListingApi.getListingDetails(listingId);
    setListingDetails(result);
  };
  if (isLoading) return <Spin />;
  if (!listingDetails) return null;

  const handleSuccess = () => {
    setIsSuccessStep(true);
    onSuccess();
  };

  return !isSuccessStep ? (
    <BidNowDataForm
      listing={listingDetails}
      onClose={onClose}
      onSuccess={handleSuccess}
      refreshDataOnError={refreshDataOnError}
    />
  ) : (
    <BidNowSuccessStep onClose={onClose} />
  );
}
