export const onlyNumbersRegExp = /^\d*$/;
export const onlyLettersRegExp = /^[a-z]*$/i;
export const atLeastOneLetterRegExp = /[a-z]/i;
export const regularTextRegExp = /^[a-z\s-]*$/i;
export const onlyLettersSpacesRegExp = /^[a-z\s]*$/i;
export const phoneE164RegExp = /^\+[1-9]\d{10,14}$/; // https://blog.kevinchisholm.com/javascript/javascript-e164-phone-number-validation/
export const onlySpacesRegExp = /^\s*$/;
export const onlySpacesHyphensRegExp = /^[\s-]*$/;
export const awsCognitoPasswordRegExp =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{6,16}$/;
export const noSpacesRegExp = /^\S*$/;
export const onlyLettersNumbersDashesRegExp = /^[a-z\d-]*$/i;
export const onlyLettersNumbersSpacesRegExp = /^[a-z\d\s]*$/i;
export const onlyLettersNumbersSpacesDashesRegExp = /^[a-z\d\s-]*$/i;
export const leadingEndingDashesRegExp = /(^-.?)|(.?-$)/i;
export const onlyLettersNumbersRegExp = /^[a-z\d]*$/i;
export const onlyLettersAndSpecialCharsRegExp =
  /^[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;

export const ValidateFormatter = (val: string | undefined) => {
  return val !== '<p><br></p>';
};

export const ValidateVideoURL = (url: string | undefined) => {
  if (!url) return true;

  // starts with https://youtube.com/embed/ OR https://www.youtube.com/embed/
  const youTubeRegExp = /^https:\/\/(www\.)?youtube.com\/embed\/.*/;

  return (
    url.startsWith('https://player.vimeo.com/video/') ||
    url.startsWith('https://my.matterport.com/show/?m=') ||
    youTubeRegExp.test(url)
  );
};

export const validateOptionZip = (value: string) => {
  if (!value) return false;
  return !!(value.length <= 6 && value.match(onlyNumbersRegExp));
};
