import { Tag } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { validateOptionZip } from 'utils/validation.utils';

export default function ZipRender(props: CustomTagProps) {
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const isOptionValid = validateOptionZip(value);

  return (
    <Tag
      color={isOptionValid ? undefined : 'red'}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
}
