import React, { useMemo, useRef, useState } from 'react';
import { Modal, Upload, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadProps } from 'antd/lib/upload/interface';
import { RcFile, UploadRequestOption } from 'rc-upload/lib/interface';
import axios, { Canceler } from 'axios';
import { ControllerRenderProps, FieldError } from 'react-hook-form';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { flushSync } from 'react-dom';
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  DragEndEvent,
  TouchSensor,
  MouseSensor,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';

import {
  isFileFormatValid,
  isFileSizeValid,
  UploadFileS3,
} from 'utils/file.utils';
import { ListingApi, MultimediaType } from 'services/api';

import styles from './FileUpload.module.less';

const CancelToken = axios.CancelToken;

export type FileUploadProps = {
  error?: FieldError;
  uploadLinkFunction?: Function;
  bucket: MultimediaType;
  field?: ControllerRenderProps;
  acceptedFileFormat?: string[];
  acceptedFileSize?: number;
  renderUploadButton: ({
    fileList,
    isMaxCountUploaded,
    isMaxCountSuccessfullyUploaded,
  }: {
    fileList: UploadFileS3[];
    isMaxCountUploaded: boolean;
    isMaxCountSuccessfullyUploaded: boolean;
  }) => React.ReactNode;
} & UploadProps;

interface ICancelRequests {
  [key: string]: Canceler;
}

const defaultIconRender = (file: UploadFileS3) =>
  file.status === 'uploading' ? (
    <LoadingOutlined />
  ) : (
    <i className="icon icon-prop-document"></i>
  );

export const getBase64 = (file: RcFile | File | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export default function FileUpload(props: FileUploadProps) {
  const { t } = useTranslation();
  const {
    maxCount = 1,
    acceptedFileSize,
    acceptedFileFormat,
    uploadLinkFunction = ListingApi.getUploadLink,
    bucket,
    className,
    error,
    field,
    renderUploadButton,
    ...rest
  } = props;
  const [fileList, setFileList] = useState<UploadFileS3[]>(field?.value || []);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const acceptedUploadFormat = acceptedFileFormat?.join(',');
  const cancelRef = useRef<ICancelRequests>({});
  const successfullyUploadedFileCount = fileList.filter(
    (file) => file.status === 'done',
  ).length;
  const isMaxCountSuccessfullyUploaded =
    successfullyUploadedFileCount >= maxCount;
  const isMaxCountUploaded = fileList.length >= maxCount;
  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 10,
      },
    }),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );
  const sortableContextItems = useMemo(
    () => fileList.map((file) => file.uid),
    [fileList],
  );

  const handleChange = ({ fileList: _fileList }: UploadChangeParam) => {
    const newFileList = [..._fileList];
    const removedFiles = newFileList.splice(0, newFileList.length - maxCount);

    removedFiles.forEach((file) => handleRemove(file)); // cancel requests for removed files

    // Prevent React18 auto batch since input[upload] trigger process at same time
    // which makes fileList closure problem
    // https://github.com/ant-design/ant-design/pull/36968
    flushSync(() => {
      setFileList(newFileList);
    });
    field?.onChange(newFileList);
  };

  const handleRequest = async (options: UploadRequestOption) => {
    const { onSuccess, onError, onProgress } = options;
    const file = options.file as RcFile;

    if (acceptedFileFormat && !isFileFormatValid(file, acceptedFileFormat)) {
      onError?.(new Error(t('FileUpload.error.format')));
      return;
    }

    if (acceptedFileSize && !isFileSizeValid(file, acceptedFileSize)) {
      onError?.(
        new Error(t('FileUpload.error.size', { limit: acceptedFileSize })),
      );
      return;
    }

    if (!fileList.some((f) => f.uid === file.uid)) return; // call upload API only for the files in the fileList

    try {
      const { url, fields } = await uploadLinkFunction({
        fileName: file.name,
        bucket,
      });

      const formData = new FormData();
      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });
      formData.append('file', file);

      const result: any = await axios({
        method: 'post',
        url: url,
        data: formData,
        onUploadProgress: (e) => {
          onProgress?.({ percent: (e.loaded / e.total) * 100 } as any);
        },
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current[file.uid] = c;
        }),
      });

      onSuccess?.({ ...result.body, s3Key: fields.key }, result);
    } catch {
      onError?.(new Error(t('FileUpload.error.upload')));
    }
  };

  const cancelRequest = (fileUid: string) => {
    if (!cancelRef.current[fileUid]) return;

    cancelRef.current[fileUid]();

    delete cancelRef.current[fileUid];
  };

  const handleRemove = (file: UploadFileS3) => {
    const { uid } = file;

    cancelRequest(uid);
  };

  const handleRemoveClick = (file: UploadFileS3) => {
    handleRemove(file);

    setTimeout(() => {
      field?.onBlur();
    }, 0);

    return true;
  };

  const handlePreview = async (file: UploadFileS3) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setFileList((items) => {
        const oldIndex = items.findIndex((f) => f.uid === active.id);
        const newIndex = items.findIndex((f) => f.uid === over?.id);
        const newFileList = arrayMove(items, oldIndex, newIndex);

        field?.onChange(newFileList);
        return newFileList;
      });
    }
  };

  return (
    <>
      <div className={cx(className, styles.upload)}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={sortableContextItems}>
            <Upload
              data-testid="file-upload"
              fileList={fileList}
              onChange={handleChange}
              customRequest={handleRequest}
              onRemove={handleRemoveClick}
              onPreview={handlePreview}
              accept={acceptedUploadFormat}
              showUploadList={{
                removeIcon: <i className="icon icon-trash"></i>,
              }}
              iconRender={defaultIconRender}
              {...rest}
            >
              {renderUploadButton({
                fileList,
                isMaxCountUploaded,
                isMaxCountSuccessfullyUploaded,
              })}
            </Upload>
          </SortableContext>
        </DndContext>
      </div>
      <Modal
        visible={previewVisible}
        title={null}
        footer={null}
        centered
        closable={false}
        onCancel={() => setPreviewVisible(false)}
        width={1104}
      >
        <div className={styles.modalImg}>
          <Button
            className={cx('btn-icon', styles.modalBtn)}
            size="small"
            icon={<i className="icon icon-close"></i>}
            onClick={() => setPreviewVisible(false)}
          ></Button>
          <img alt="example" src={previewImage} />
        </div>
      </Modal>
    </>
  );
}
