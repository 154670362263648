import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import TimerBadge from 'components/TimerBadge';
import { ExternalListingList, ListingStatus } from 'services/api';
import { getCurrencySign } from 'utils/price.utils';
import { getFormattedAddress } from 'utils/location.utils';
import { getBidderData, getPriceData, IsHotOffer } from 'utils/listing.utils';
import BidNowButton from 'features/listing/BidNowButton';
import { getFormattedNumber } from 'utils/number.utils';
import { getImageUrl, ImageSize } from 'utils/image.utils';
import emptyImg from 'assets/images/empty_img.svg';

import styles from './ListingCard.module.less';
const HotPropertyBadge = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.cardOffer}>
      <i className="icon icon-fire"></i> {t('ListingCard.HotProperty')}
    </div>
  );
};

type Props = {
  listing: ExternalListingList;
};

export default function ListingCard(props: Props) {
  const { t } = useTranslation();

  const { listing } = props;
  const {
    id,
    title,
    property_pictures = [],
    status,
    bedrooms,
    bathrooms,
    car_spaces,
    house_area,
    address,
    ends_at,
    units,
  } = listing;
  const isActive = status === ListingStatus.ACTIVE;
  const isHotOffer = IsHotOffer(listing);

  const bidderData = getBidderData(listing, t);

  const price = getPriceData(listing);

  return (
    <Link
      to={`/listings/${id}`}
      className={cx(styles.card, {
        [styles.cardSold]: !isActive,
      })}
    >
      <picture className={styles.cardImg}>
        {property_pictures[0] ? (
          <img
            src={getImageUrl(property_pictures[0].url, ImageSize.NORMAL)}
            alt=""
          />
        ) : (
          <img className={styles.empty} src={emptyImg} alt="" />
        )}
        <div className={styles.cardBadgeWrapper}>
          {isActive && <TimerBadge endDate={ends_at} />}
          {isHotOffer && <HotPropertyBadge />}
        </div>
      </picture>
      <div className={styles.cardContent}>
        <div className={styles.cardHead}>
          <div className={styles.cardHeadLeft}>
            <div className={styles.cardPrice}>
              <strong>{getCurrencySign(price?.currency)}</strong>
            </div>
            <div className={styles.cardHeadGrow}>
              <div className={styles.cardPrice}>
                {getFormattedNumber(price?.value)}
              </div>
              <div className={styles.cardHeadInfo}>
                <span>{bidderData.text}</span>{' '}
                <strong>{bidderData.nickname}</strong>
              </div>
            </div>
          </div>
          <div className={styles.cardHeadRight}>
            <BidNowButton
              listing={listing}
              buttonProps={{
                size: 'small',
                type: 'primary',
                className: 'btn-secondary',
              }}
            />
          </div>
        </div>
        <div className={styles.cardTitle}>{title}</div>
        <ul className={styles.cardInfo}>
          <li>
            <i className="icon icon-beds"></i>
            {bedrooms}
          </li>
          <li>
            <i className="icon icon-bathrooms"></i>
            {bathrooms}
          </li>
          <li>
            <i className="icon icon-garage"></i>
            {car_spaces}
          </li>
          <li>
            <i className="icon icon-size"></i>
            {getFormattedNumber(house_area)} {units || 'sqft'}
          </li>
        </ul>
        <address className={styles.cardAddress}>
          <i className="icon icon-map"></i>
          <span>{getFormattedAddress(address)}</span>
        </address>
      </div>
    </Link>
  );
}
