import { useState } from 'react';
import { Button } from 'antd';
import cx from 'classnames';

import { downloadFile } from 'utils/file.utils';
import { displayErrorMessage } from 'components/message';

import styles from './Attachment.module.less';

type Props = {
  downloadUrl: string;
  fileName: string;
};

export default function Attachment({ downloadUrl, fileName }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);

      await downloadFile({ downloadUrl, fileName });
    } catch (error) {
      displayErrorMessage({ error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      type="link"
      className={cx('with-icon', styles.btn)}
      size="small"
      icon={<i className="icon icon-prop-document" />}
      onClick={handleClick}
    >
      {fileName}
    </Button>
  );
}
