/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Currency {
    USD = 'usd',
    EUR = 'eur',
}
