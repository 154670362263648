import { cloneDeep } from 'lodash';
import useWebSocket from 'react-use-websocket';

import {
  ExternalListingDetail,
  ExternalListingList,
  ListingBid,
  ListingStatus,
  ExternalListingResponse,
  Price,
  ListingApi,
  ExternalListingOrderBy,
} from 'services/api';
enum SocketMessageTypeEnum {
  BIDS = 'bids',
  LISTINGS = 'listings',
}

type SocketListingsType = {
  status: ListingStatus;
  ends_at: string;
  initial_price: Price;
  pending_price: Price;
  sold_price: Price;
};
enum EventType {
  INSERT = 'INSERT',
  MODIFY = 'MODIFY',
  REMOVE = 'REMOVE',
}

type SocketMessageType = {
  type: SocketMessageTypeEnum;
  object: SocketListingsType | ListingBid;
  event_name: EventType;
};
type SocketMessageExtendedBidType = ListingBid & {
  listing_id: string;
};
type SocketMessageListingExtendedType = SocketListingsType & { id: string };

export function useGetSocketRequestSingle(
  listingDetail: ExternalListingList | ExternalListingDetail | undefined,
  fn: (val: ExternalListingList | ExternalListingDetail) => void,
) {
  const getSocketUrl = () => {
    if (!listingDetail) return null;
    return `${process.env.REACT_APP_WS_BASE_URL}?pk=${listingDetail.id}`;
  };

  useWebSocket(getSocketUrl(), {
    onMessage: (message) => {
      if (!listingDetail) return;
      const data: SocketMessageType[] = JSON.parse(message.data);
      data.forEach((item: SocketMessageType) => {
        if (item.type === SocketMessageTypeEnum.LISTINGS) {
          const { status, ends_at, initial_price, pending_price, sold_price } =
            item.object as SocketListingsType;
          listingDetail.status = status;
          listingDetail.ends_at = ends_at;
          listingDetail.initial_price = initial_price;
          listingDetail.pending_price = pending_price;
          listingDetail.sold_price = sold_price;
          fn(listingDetail);
        }
        if (item.type === SocketMessageTypeEnum.BIDS) {
          if (item.event_name === EventType.INSERT) {
            const { ...bid } = item.object as ListingBid;
            listingDetail.bids?.push(bid);
            listingDetail?.bids!.sort(
              (a: ListingBid, b: ListingBid) => b.timestamp - a.timestamp,
            );
            fn(listingDetail);
          } else if (item.event_name === EventType.MODIFY) {
            const { ...bid } = item.object as ListingBid;
            const isBidVoided = bid?.is_voided;
            const isNextBidVoid = bid?.is_next_void;
            const oldestBid = listingDetail.bids![0];
            if (listingDetail.bids?.length) {
              if (isNextBidVoid && bid.timestamp < oldestBid?.timestamp) {
                listingDetail.bids.shift();
                fn(listingDetail);
              } else if (
                isBidVoided &&
                listingDetail.bids?.[0]?.id === bid.id &&
                listingDetail.bids.length === 1
              ) {
                listingDetail.bids = [];
                fn(listingDetail);
              }
            }
          }
        }
      });
    },
    shouldReconnect: () => true,
    retryOnError: true,
  });
}

export async function useGetSocketRequestMultiple(
  listingsData: ExternalListingResponse | undefined,
  fn: (val: ExternalListingResponse) => void,
  query: string,
  page: number,
  pageSize: number,
  order: ExternalListingOrderBy,
) {
  const handleBids = (
    message: SocketMessageType,
    element: ExternalListingList,
    index: number,
  ) => {
    const { object } = message;
    const { listing_id, ...bid } = object as SocketMessageExtendedBidType;
    const newListingsData = cloneDeep(listingsData!.results!);
    if (element.id !== listing_id) return;
    if (message.event_name === EventType.INSERT) {
      if (!element.bids?.length || bid.timestamp > element.bids[0].timestamp) {
        element.bids = [bid];
        newListingsData[index] = element;
        fn({
          ...listingsData,
          results: newListingsData,
        });
      }
    } else if (message.event_name === EventType.MODIFY) {
      const bidsArray = element.bids;
      if (
        bidsArray &&
        bidsArray?.length !== 0 &&
        bid.is_next_void &&
        bid.timestamp < bidsArray[0]?.timestamp
      ) {
        element.bids = [bid];
        newListingsData[index] = element;
        fn({
          ...listingsData,
          results: newListingsData,
        });
      } else if (
        !bid.is_next_void &&
        bid.is_voided &&
        bidsArray?.[0]?.id === bid.id
      ) {
        const loadData = async () => {
          try {
            const result = await ListingApi.getListingList({
              query,
              pageSize,
              page,
              order,
            });

            fn(result);
          } catch {}
        };

        loadData();
      }
    }
  };

  const handleListings = (
    message: SocketMessageListingExtendedType,
    element: ExternalListingList,
    index: number,
  ) => {
    const { id, status, ends_at, initial_price, pending_price, sold_price } =
      message;
    const newListingsData = cloneDeep(listingsData!.results!);
    if (element.id === id) {
      element.status = status;
      element.ends_at = ends_at;
      element.initial_price = initial_price;
      element.pending_price = pending_price;
      element.sold_price = sold_price;
      newListingsData[index] = element;
      fn({
        ...listingsData,
        results: newListingsData,
      });
    }
  };

  const getSocketUrl = () => {
    if (!listingsData?.results) return null;

    const urlParams = listingsData.results
      .map((listing) => `pk=${listing.id}`)
      .join('&');

    return `${process.env.REACT_APP_WS_BASE_URL}?${urlParams}`;
  };

  useWebSocket(getSocketUrl(), {
    onMessage: (message) => {
      if (!listingsData) return;
      JSON.parse(message.data).forEach((e: SocketMessageType) => {
        listingsData?.results?.forEach(
          (element: ExternalListingList, i: number) => {
            if (e.type === SocketMessageTypeEnum.BIDS) {
              handleBids(e, element, i);
            } else if (e.type === SocketMessageTypeEnum.LISTINGS) {
              handleListings(
                e.object as SocketMessageListingExtendedType,
                element,
                i,
              );
            }
          },
        );
      });
    },
    shouldReconnect: (CloseEvent) => true,
    retryOnError: true,
  });
}
