import { Modal } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ForgotPasswordForm from '../ForgotPasswordForm';
import ResetPasswordForm from '../ResetPasswordForm';

type Props = {
  onClose: () => void;
};

export default function ForgotPasswordModal(props: Props) {
  const { t } = useTranslation();
  const { onClose } = props;
  const [activeModal, setActiveModal] = useState(true);
  const [email, setEmail] = useState('');

  return (
    <Modal
      title={null}
      visible
      centered
      closable={false}
      maskClosable={false}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      className="modal"
      width={550}
    >
      {activeModal ? (
        <>
          <h2 className="modal__title">{t('ForgotPasswordModal.Title')}</h2>
          <ForgotPasswordForm
            onClose={onClose}
            onSuccess={(val) => {
              setEmail(val);
              setActiveModal(false);
            }}
          />
        </>
      ) : (
        <>
          <h2 className="modal__title">{t('ResetPasswordModal.Title')}</h2>
          <ResetPasswordForm onClose={onClose} emailReset={email} />
        </>
      )}
    </Modal>
  );
}
