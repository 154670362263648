import React from 'react';
import cx from 'classnames';

import type { Price as PriceType } from 'services/api';
import { getFormattedNumber } from 'utils/number.utils';
import { getCurrencySign } from 'utils/price.utils';

import styles from './Price.module.less';

type Props = React.HTMLProps<HTMLDivElement> & {
  price: PriceType;
};

export default function Price({
  price,
  className = '',
  label = '',
  ...other
}: Props) {
  return (
    <div className={cx(styles.price, className)} {...other}>
      <strong>{getCurrencySign(price.currency)}</strong>
      {getFormattedNumber(price.value)}
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
}
