import { ExternalUserStaffResponse } from 'services/api';
import CertifiedAgentCard from 'components/cards/CertifiedAgentCard/CertifiedAgentCard';
import styles from './CertifiedAgentsList.module.less';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

type Props = {
  agentsData?: ExternalUserStaffResponse;
  query?: string;
};

export default function CertifiedAgentsList(props: Props) {
  const { agentsData, query } = props;
  const { t } = useTranslation();
  return (
    <>
      {agentsData && !agentsData?.total && (
        <div className={styles.empty}>
          <h3 className="h5">{t('CertifiedAgents.NoUser.header')}</h3>
          <p className={styles.subtitle}>
            {t('CertifiedAgents.NoUser.subheader')}
          </p>
        </div>
      )}
      {!!agentsData?.total && (
        <>
          {query && (
            <div className={styles.counter}>
              {t('CertifiedAgents.found', {
                count: agentsData.total,
              })}
            </div>
          )}
          <div className={styles.grid}>
            {agentsData.results?.map((certifiedAgent) => (
              <CertifiedAgentCard
                className={cx('paper', styles.card)}
                key={certifiedAgent.id}
                certifiedAgent={certifiedAgent}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
}
