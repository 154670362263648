import { useMemo } from 'react';
import { SpinProps, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { TFunction, useTranslation } from 'react-i18next';

import Spin from 'components/Spin';
import { AdminUserList, ExternalUserRole } from 'services/api';
import { useAuth } from 'components/AuthProvider';
import { AuthorizedUserModel } from 'services/models';
import { getUserRoleName } from 'utils/user.utils';

import cx from 'classnames';

import styles from './AdminsAndAgentsTable.module.less';
import { Link } from 'react-router-dom';

const getColumns = (
  t: TFunction,
  user: AuthorizedUserModel | null,
): ColumnsType<AdminUserList> => {
  let columns: ColumnsType<AdminUserList> = [
    {
      key: 'name',
      title: t('Dashboard.AdminsAndAgentsTable.Name'),
      render: (_, { id, first_name, last_name }) => (
        <Link to={`edit-user/${id}`}>
          {first_name} {last_name}
        </Link>
      ),
    },
    {
      key: 'email',
      title: t('Dashboard.AdminsAndAgentsTable.Email'),
      width: '25%',
      render: (_, { email }) => (
        <div className={cx(styles.ellipsis, styles.email)}>{email}</div>
      ),
    },
    {
      key: 'role',
      title: t('Dashboard.AdminsAndAgentsTable.Role'),
      render: (_, { role }) => (
        <div className="ws-nw">{getUserRoleName(role, t)}</div>
      ),
    },
    {
      key: 'market_center',
      title: t('Dashboard.AdminsAndAgentsTable.MarketCenter'),
      render: (_, { market_centers }) => (
        <>
          {market_centers?.map((market_center, idx) => (
            <div className="ws-nw" key={`market-center-${idx}`}>
              {market_center}
            </div>
          ))}
        </>
      ),
    },
    {
      key: 'brokerage',
      title: t('Dashboard.AdminsAndAgentsTable.Brokerage'),
      width: '33%',
      render: (_, { brokerages }) => (
        <>
          {brokerages?.map((brokerage, idx) => (
            <div
              className={cx(styles.ellipsis, styles.brokerage)}
              key={`brokerage-name-${idx}`}
            >
              {brokerage}
            </div>
          ))}
        </>
      ),
    },
  ];

  if (user?.role !== ExternalUserRole.SUPER_ADMIN) {
    columns = columns.filter((item) => {
      return item.key !== 'market_center' && item.key !== 'brokerage';
    });
  }

  return columns;
};

type Props = {
  isLoading: boolean;
  data?: AdminUserList[];
};

export default function AdminsAndAgentsTable({ data, isLoading }: Props) {
  const { user } = useAuth();
  const { t } = useTranslation();
  const columns = useMemo(() => getColumns(t, user), [t, user]);

  const tableLoading: SpinProps = {
    spinning: isLoading,
    indicator: <Spin />,
  };

  return (
    <Table
      scroll={{ x: '100%' }}
      dataSource={data}
      columns={columns}
      rowKey="email"
      loading={tableLoading}
      pagination={false}
    />
  );
}
