/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminAutoBidUpdate } from '../models/AdminAutoBidUpdate';
import type { AdminBidsAndAutoBidsLists } from '../models/AdminBidsAndAutoBidsLists';
import type { AdminBrokerageCreate } from '../models/AdminBrokerageCreate';
import type { AdminBrokerageDetail } from '../models/AdminBrokerageDetail';
import type { AdminBrokerageResponse } from '../models/AdminBrokerageResponse';
import type { AdminBrokerageShortDetail } from '../models/AdminBrokerageShortDetail';
import type { AdminBrokerageUpdate } from '../models/AdminBrokerageUpdate';
import type { AdminListingApprovalCount } from '../models/AdminListingApprovalCount';
import type { AdminListingApprovalDetail } from '../models/AdminListingApprovalDetail';
import type { AdminListingApprovalUpdate } from '../models/AdminListingApprovalUpdate';
import type { AdminListingCreate } from '../models/AdminListingCreate';
import type { AdminListingDetail } from '../models/AdminListingDetail';
import type { AdminListingResponse } from '../models/AdminListingResponse';
import type { AdminListingUpdate } from '../models/AdminListingUpdate';
import type { AdminUserCreate } from '../models/AdminUserCreate';
import type { AdminUserDetail } from '../models/AdminUserDetail';
import type { AdminUserResponse } from '../models/AdminUserResponse';
import type { AdminUserUpdate } from '../models/AdminUserUpdate';
import type { ExternalListingOrderBy } from '../models/ExternalListingOrderBy';
import type { Language } from '../models/Language';
import type { Message } from '../models/Message';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Admin Listings
     * @param page
     * @param pageSize
     * @param order
     * @param search
     * @returns AdminListingResponse Successful Response
     * @throws ApiError
     */
    public adminListListings(
        page: number = 1,
        pageSize: number = 12,
        order?: ExternalListingOrderBy,
        search?: string,
    ): CancelablePromise<AdminListingResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/listings/',
            query: {
                'page': page,
                'page_size': pageSize,
                'order': order,
                'search': search,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Admin Create
     * @param requestBody
     * @returns AdminListingDetail Successful Response
     * @throws ApiError
     */
    public adminCreateListing(
        requestBody: AdminListingCreate,
    ): CancelablePromise<AdminListingDetail> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/admin/listings/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Admin Listing
     * @param listingId
     * @returns AdminListingDetail Successful Response
     * @throws ApiError
     */
    public adminListing(
        listingId: string,
    ): CancelablePromise<AdminListingDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/listings/{listing_id}',
            path: {
                'listing_id': listingId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Admin Update Listing
     * @param listingId
     * @param requestBody
     * @returns AdminListingDetail Successful Response
     * @throws ApiError
     */
    public adminUpdateListing(
        listingId: string,
        requestBody: AdminListingUpdate,
    ): CancelablePromise<AdminListingDetail> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/admin/listings/{listing_id}',
            path: {
                'listing_id': listingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Admin Bids
     * @param listingId
     * @returns AdminBidsAndAutoBidsLists Successful Response
     * @throws ApiError
     */
    public adminListBids(
        listingId: string,
    ): CancelablePromise<AdminBidsAndAutoBidsLists> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/bids/',
            query: {
                'listing_id': listingId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Admin Void Bid
     * @param bidId
     * @returns any Successful Response
     * @throws ApiError
     */
    public adminVoidBid(
        bidId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/admin/bids/{bid_id}/void',
            path: {
                'bid_id': bidId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Admin Disable Auto Bid
     * @param autoBidId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public adminDisableAutoBid(
        autoBidId: string,
        requestBody: AdminAutoBidUpdate,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/admin/auto-bids/{auto_bid_id}',
            path: {
                'auto_bid_id': autoBidId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * User
     * @param userId
     * @returns AdminUserDetail Successful Response
     * @throws ApiError
     */
    public adminGetUser(
        userId: string,
    ): CancelablePromise<AdminUserDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update
     * @param userId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public adminUpdateUser(
        userId: string,
        requestBody: AdminUserUpdate,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/admin/users/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Users
     * @param page
     * @param pageSize
     * @param order
     * @param includeRoles
     * @returns AdminUserResponse Successful Response
     * @throws ApiError
     */
    public adminUsers(
        page: number = 1,
        pageSize: number = 12,
        order: string = 'first_name',
        includeRoles?: Array<string>,
    ): CancelablePromise<AdminUserResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/users/',
            query: {
                'page': page,
                'page_size': pageSize,
                'order': order,
                'include_roles': includeRoles,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create
     * @param requestBody
     * @returns Message Successful Response
     * @throws ApiError
     */
    public adminCreateUser(
        requestBody: AdminUserCreate,
    ): CancelablePromise<Message> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/admin/users/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Admin Approval Counts
     * @param listingId
     * @param approved
     * @returns AdminListingApprovalCount Successful Response
     * @throws ApiError
     */
    public approvalCountsAdmin(
        listingId: string,
        approved: boolean = false,
    ): CancelablePromise<AdminListingApprovalCount> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/listings/{listing_id}/approvals/count',
            path: {
                'listing_id': listingId,
            },
            query: {
                'approved': approved,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Admin Approvals
     * @param listingId
     * @param approved
     * @returns AdminListingApprovalDetail Successful Response
     * @throws ApiError
     */
    public approvalsAdmin(
        listingId: string,
        approved?: boolean,
    ): CancelablePromise<Array<AdminListingApprovalDetail>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/listings/{listing_id}/approvals/',
            path: {
                'listing_id': listingId,
            },
            query: {
                'approved': approved,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Admin Approval Update
     * @param listingId
     * @param userId
     * @param requestBody
     * @returns Message Successful Response
     * @throws ApiError
     */
    public updateApproval(
        listingId: string,
        userId: string,
        requestBody: AdminListingApprovalUpdate,
    ): CancelablePromise<Message> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/admin/listings/{listing_id}/approvals/{user_id}',
            path: {
                'listing_id': listingId,
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Brokerage
     * @param brokerageId
     * @returns AdminBrokerageDetail Successful Response
     * @throws ApiError
     */
    public adminGetBrokerage(
        brokerageId: string,
    ): CancelablePromise<AdminBrokerageDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/brokerages/{brokerage_id}',
            path: {
                'brokerage_id': brokerageId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update
     * @param brokerageId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public adminBrokeragesUpdate(
        brokerageId: string,
        requestBody: AdminBrokerageUpdate,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/admin/brokerages/{brokerage_id}',
            path: {
                'brokerage_id': brokerageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Brokerages
     * @param page
     * @param pageSize
     * @returns AdminBrokerageResponse Successful Response
     * @throws ApiError
     */
    public adminBrokerages(
        page: number,
        pageSize: number,
    ): CancelablePromise<AdminBrokerageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/brokerages/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public adminBrokeragesCreate(
        requestBody: AdminBrokerageCreate,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/admin/brokerages/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Admin List Current Brokerages
     * @returns AdminBrokerageShortDetail Successful Response
     * @throws ApiError
     */
    public adminListCurrentBrokerages(): CancelablePromise<Array<AdminBrokerageShortDetail>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/brokerages/list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Download Bids
     * @param listingId
     * @param timeOffset
     * @param language
     * @returns any Successful Response
     * @throws ApiError
     */
    public downloadBidsV1AdminReportsListingsListingIdBidsDownloadGet(
        listingId: string,
        timeOffset?: number,
        language?: Language,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/reports/listings/{listing_id}/bids/download',
            path: {
                'listing_id': listingId,
            },
            query: {
                'time_offset': timeOffset,
                'language': language,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
