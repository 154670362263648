import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Form, Input } from 'antd';
import { TFunction, useTranslation } from 'react-i18next';
import { useDidUpdate } from 'rooks';

import FormItem from 'components/form/FormItem';
import { useAuth } from 'components/AuthProvider';
import { onlyNumbersRegExp } from 'utils/validation.utils';
import { displayErrorMessage } from 'components/message';
import InfoBox, { BoxColor, BoxSize } from 'components/InfoBox';

interface IFormValues {
  email: string;
  code: string;
}

const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    code: yup
      .string()
      .required(t('validation.required'))
      .matches(onlyNumbersRegExp, t('validation.format'))
      .length(6, t('validation.format')),
  });

type Props = {
  email: string;
  onSuccess?: () => void;
  onClose?: () => void;
};
const secondsToBlockResend = 60 * 1000;
export default function SignUpConfirmationForm(props: Props) {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const { onClose, onSuccess, email } = props;
  const { resendSignUp, confirmSignUp, isAuthenticated, logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const { control, handleSubmit } = useForm<IFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email,
      code: '',
    },
  });

  useDidUpdate(() => {
    if (isAuthenticated) {
      setIsLoading(false);
      onSuccess?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const onSubmit = async (values: IFormValues) => {
    try {
      setIsLoading(true);

      if (isAuthenticated) {
        // possible AdminSetPassword case
        await logout();
      }

      await confirmSignUp(values);
    } catch (error: any) {
      if (error.code === 'CodeMismatchException') {
        error.code = 'CodeMismatchExceptionSignUp';
      }
      setIsLoading(false);
      displayErrorMessage({
        error,
        containerSelector: '.ant-modal',
      });
    }
  };

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
      <div className="modal__scroll">
        <div className="modal__content">
          <div className="modal__subtitle">
            {t('SignUpConfirmationForm.SomethingWrong')} &nbsp;
            <Button
              loading={isLoadingResend}
              type="link"
              className="custom-link"
              disabled={isDisabled}
              onClick={async () => {
                try {
                  setIsLoadingResend(true);
                  setIsDisabled(true);
                  await resendSignUp({ email });
                  setTimeout(() => setIsDisabled(false), secondsToBlockResend);
                } catch (error) {
                  setIsDisabled(false);
                  displayErrorMessage({
                    error,
                    containerSelector: '.ant-modal',
                  });
                } finally {
                  setIsLoadingResend(false);
                }
              }}
            >
              {t('SignUpConfirmationForm.ResendCode')}
            </Button>
            {isDisabled && (
              <h3 className="modal__label">
                {t('SignUpConfirmationForm.NewCodeJustSent')}
              </h3>
            )}
          </div>
          <h3 className="modal__label">
            {t('SignUpConfirmationForm.ConfirmYourEmail')}
          </h3>
          <FormItem
            name="code"
            control={control}
            render={({ field }) => (
              <Input
                placeholder={t('SignUpConfirmationForm.Code')}
                size="large"
                {...field}
              />
            )}
          />
          <div className="modal__message">
            <InfoBox
              color={BoxColor.Grey}
              size={BoxSize.Small}
              icon={<i className="icon icon-info"></i>}
              text={
                <>
                  {t('SignUpConfirmationForm.CodeWasSent')}{' '}
                  <strong>{email}</strong>. {t('SignUpConfirmationForm.info')}
                </>
              }
            />
          </div>
        </div>
      </div>

      <div className="modal__footer">
        <div className="modal__footer-row">
          <div>
            <Button
              type="link"
              className="custom-link"
              size="large"
              onClick={onClose}
            >
              {t('SignUpConfirmationForm.Cancel')}
            </Button>
          </div>
          <div>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              size="large"
            >
              {t('SignUpConfirmationForm.ConfirmEmail')}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
}
