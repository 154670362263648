import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ExternalUserRole } from 'services/api';
import ScrollToTop from 'components/ScrollToTop';
import ProtectedRoute from 'components/ProtectedRoute';
import HomePage from 'features/home/HomePage';
import ListingsPage from 'features/listing/ListingsPage';
import AboutUsPage from 'features/aboutUs/AboutUsPage';
import HowToBuyPage from 'features/howToBuy/HowToBuyPage';
import ListingDetailsPage from 'features/listing/ListingDetailsPage';
import ListingsManagementPage from 'features/dashboard/ListingsManagementPage';
import BidManagementPage from 'features/dashboard/BidManagementPage';
import NewListingPage from 'features/dashboard/NewListingPage';
import EditListingPage from 'features/dashboard/EditListingPage';
import EditBrokeragesPage from 'features/dashboard/Brokerages/EditBrokerages';
import PrivacyPolicyPage from 'features/privacyPolicy/PrivacyPolicyPage';
import TermsAndConditionsOfServicePage from 'features/termsAndConditionsOfService/TermsAndConditionsOfServicePage';
import EmailUnsubscribePage from 'features/auth/EmailUnsubscribePage';
import BrokeragePage from 'features/dashboard/Brokerages';
import NewBrokeragePage from 'features/dashboard/Brokerages/NewBrokeragePage';
import MyProfile from 'features/myProfile/MyProfilePage';
import AdminsAndAgentsPage from 'features/dashboard/AdminsAndAgents/AdminsAndAgentsPage';
import ReportsPage from 'features/reports/ReportsPage';
import NewUserPage from 'features/dashboard/NewUserPage';
import EditUserPage from 'features/dashboard/EditUserPage';
import CertifiedAgents from 'features/certifiedAgents/CertifiedAgents';
import SellersPage from 'features/sellers/SellersPage';
import FAQ from 'features/faq/FAQPage';

export default function Router() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="listings" element={<ListingsPage />} />
        <Route path="listings/:listingId" element={<ListingDetailsPage />} />
        <Route path="about-us" element={<AboutUsPage />} />
        <Route path="how-to-buy" element={<HowToBuyPage />} />
        <Route path="sellers" element={<SellersPage />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="certified-agents" element={<CertifiedAgents />} />
        <Route
          path="terms-and-conditions-of-service"
          element={<TermsAndConditionsOfServicePage />}
        />
        <Route
          path="my-profile"
          element={
            <ProtectedRoute>
              <MyProfile />
            </ProtectedRoute>
          }
        />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="email-unsubscribe" element={<EmailUnsubscribePage />} />
        {/* dashboard */}
        <Route
          path="brokerages"
          element={
            <ProtectedRoute roles={[ExternalUserRole.SUPER_ADMIN]}>
              <BrokeragePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="admins-and-agents"
          element={
            <ProtectedRoute
              roles={[
                ExternalUserRole.SUPER_ADMIN,
                ExternalUserRole.BROKERAGE_ADMIN,
              ]}
            >
              <AdminsAndAgentsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="listings-management"
          element={
            <ProtectedRoute
              roles={[
                ExternalUserRole.SUPER_ADMIN,
                ExternalUserRole.LISTING_AGENT,
                ExternalUserRole.BROKERAGE_ADMIN,
              ]}
            >
              <ListingsManagementPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="bids-management/:listingId"
          element={
            <ProtectedRoute
              roles={[
                ExternalUserRole.SUPER_ADMIN,
                ExternalUserRole.LISTING_AGENT,
                ExternalUserRole.BROKERAGE_ADMIN,
              ]}
            >
              <BidManagementPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="listings-management/new-listing"
          element={
            <ProtectedRoute
              roles={[
                ExternalUserRole.SUPER_ADMIN,
                ExternalUserRole.LISTING_AGENT,
              ]}
            >
              <NewListingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="listings-management/edit-listing/:listingId"
          element={
            <ProtectedRoute
              roles={[
                ExternalUserRole.SUPER_ADMIN,
                ExternalUserRole.LISTING_AGENT,
                ExternalUserRole.BROKERAGE_ADMIN,
              ]}
            >
              <EditListingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="brokerages/new-brokerage"
          element={
            <ProtectedRoute roles={[ExternalUserRole.SUPER_ADMIN]}>
              <NewBrokeragePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="brokerages/edit-brokerage/:brokerageId"
          element={
            <ProtectedRoute roles={[ExternalUserRole.SUPER_ADMIN]}>
              <EditBrokeragesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="admins-and-agents/new-user"
          element={
            <ProtectedRoute
              roles={[
                ExternalUserRole.SUPER_ADMIN,
                ExternalUserRole.BROKERAGE_ADMIN,
              ]}
            >
              <NewUserPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="admins-and-agents/edit-user/:userId"
          element={
            <ProtectedRoute
              roles={[
                ExternalUserRole.SUPER_ADMIN,
                ExternalUserRole.BROKERAGE_ADMIN,
              ]}
            >
              <EditUserPage />
            </ProtectedRoute>
          }
        />
        {/* end: dashboard */}

        {(process.env.REACT_APP_ENV === 'local' ||
          process.env.REACT_APP_ENV === 'dev') && (
          <Route path="/reports-test-page" element={<ReportsPage />} />
        )}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}
