import { useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { SpinProps, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';

import { AdminListingApprovalDetail, ListingApi } from 'services/api';
import Spin from 'components/Spin';
import Attachment from 'components/Attachment';
import { displayErrorMessage } from 'components/message';
import { dateTimeFormat } from 'utils/date.utils';
import DataPlaceholder from '../DataPlaceholder';
import BuyerInfo from '../BuyerInfo';
import ContactList from '../ContactList';

const getColumns = (t: TFunction): ColumnsType<AdminListingApprovalDetail> => [
  {
    key: 'date',
    title: t('BidManagement.Tabs.Table.Date'),
    render: (_, { created_at }) => (
      <span>{moment(created_at).format(dateTimeFormat)}</span>
    ),
  },
  {
    key: 'buyer',
    title: t('BidManagement.Tabs.Table.Buyer'),
    render: (_, { user, nickname, status, dre }) => (
      <>
        {user && (
          <BuyerInfo
            status={status}
            firstName={user.first_name}
            dre={dre}
            lastName={user.last_name}
            nickname={nickname}
          />
        )}
      </>
    ),
  },
  {
    key: 'contacts',
    title: t('BidManagement.Tabs.Table.Contacts'),
    render: (_, { user }) => <ContactList user={user} />,
  },
  {
    key: 'approval_docs',
    title: t('BidManagement.Tabs.Table.ApprovalInformation'),
    render: (_, { files }) => (
      <>
        {files?.map((file) => (
          // BE should describe model better in the future, but now it might work only that way
          // @ts-ignore
          <div key={file.url}>
            <Attachment
              // @ts-ignore
              fileName={file.filename}
              // @ts-ignore
              downloadUrl={file.url}
            />
          </div>
        ))}
      </>
    ),
  },
  {
    key: 'actions',
    width: '12%',
    render: () => null,
  },
];

type Props = {
  isActive: boolean;
  listingId?: string;
};

export default function ApprovedBuyers({ isActive, listingId }: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<AdminListingApprovalDetail[]>();
  const columns = useMemo(() => getColumns(t), [t]);

  useEffect(() => {
    if (!isActive || !listingId) return;

    const loadData = async () => {
      setIsLoading(true);

      try {
        const result = await ListingApi.getBidApprovedBuyers(listingId);

        setData(result);
      } catch (error) {
        displayErrorMessage({ error });
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [isActive, listingId]);

  const tableLoading: SpinProps = {
    indicator: <Spin />,
    spinning: isLoading,
  };

  return (
    <DataPlaceholder
      isLoading={!data?.length && isLoading}
      isDataExists={!!data && data.length > 0}
      noDataDescription={t('BidManagement.Tabs.ApprovedBuyers.Empty')}
    >
      <Table
        rowKey={(row) => row.user.id}
        scroll={{ x: '100%' }}
        dataSource={data}
        columns={columns}
        loading={tableLoading}
        pagination={false}
      />
    </DataPlaceholder>
  );
}
