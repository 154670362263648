import PrivacyPolicyPageEn from './translation/ENPrivacyPolicy';
import { useTranslation } from 'react-i18next';

export default function PrivacyPolicyPage() {
  const { i18n } = useTranslation();
  const renderPolicyLanguage = () => {
    switch (i18n.language) {
      case 'it':
        return null;
      case 'en':
      default:
        return <PrivacyPolicyPageEn />;
    }
  };
  return <div>{renderPolicyLanguage()}</div>;
}
