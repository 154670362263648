import { Currency } from 'services/api';

export const getCurrencySign = (_currency: Currency = Currency.USD) => {
  switch (_currency) {
    case Currency.EUR:
      return '€';
    case Currency.USD:
    default:
      return '$';
  }
};
