import { Button } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ListingApi } from 'services/api';
import { displayErrorMessage } from 'components/message';

type Props = {
  listingId: string;
  userId: string;
  isDisabledButton: boolean;
};

export default function ApproveButton({
  listingId,
  userId,
  isDisabledButton,
}: Props) {
  const { t } = useTranslation();
  const [isApproved, setIsApproved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isDisabled = isDisabledButton || isApproved;
  const handleApprove = async () => {
    setIsLoading(true);

    try {
      await ListingApi.approvePendingRequest(listingId, userId);

      setIsApproved(true);
    } catch (error) {
      displayErrorMessage({ error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      type="primary"
      size="small"
      loading={isLoading}
      disabled={isDisabled}
      onClick={handleApprove}
      className="btn-success"
    >
      {isApproved
        ? t('BidManagement.Tabs.Table.ApprovalInformation.Approved')
        : t('BidManagement.Tabs.Table.ApprovalInformation.Approve')}
    </Button>
  );
}
