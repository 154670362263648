import Empty, { EmptyProps } from 'components/Empty';
import Spin, { SpinProps } from 'components/Spin';

type Props = {
  isDataExists: boolean;
  children: React.ReactNode;
  // are used for simple props so instead of spinnerProps={{ spinning: isLoafing }} just isLoading={isLoading}
  isLoading?: boolean;
  // are used for simple props so instead of emptyProps={{ description: 'some description }} just noDataDescription="some description"
  noDataDescription?: React.ReactNode;
  emptyProps?: EmptyProps;
  spinnerProps?: SpinProps;
};

export default function DataPlaceholder(props: Props) {
  const {
    isDataExists,
    isLoading,
    noDataDescription,
    emptyProps,
    spinnerProps,
    children,
  } = props;

  return (
    <Spin spinning={isLoading || !!spinnerProps?.spinning} {...spinnerProps}>
      {!isDataExists && (
        <Empty
          description={noDataDescription || emptyProps?.description}
          {...emptyProps}
        />
      )}
      {isDataExists && children}
    </Spin>
  );
}
