import FAQEnPage from './translation/ENFAQ';
import { useTranslation } from 'react-i18next';

export default function FAQPage() {
  const { i18n } = useTranslation();
  const renderFAQLanguage = () => {
    switch (i18n.language) {
      case 'it':
        return null;
      case 'en':
      default:
        return <FAQEnPage />;
    }
  };
  return <div>{renderFAQLanguage()}</div>;
}
