import { useTranslation } from 'react-i18next';

import SellersPageEn from './translation/SellersPageEn';

export default function SellersPage() {
  const { i18n } = useTranslation();

  const renderPage = () => {
    switch (i18n.language) {
      case 'it':
        return null;
      case 'en':
      default:
        return <SellersPageEn />;
    }
  };
  return <div>{renderPage()}</div>;
}
