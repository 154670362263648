import { TFunction } from 'react-i18next';
import {
  AdminListingDetail,
  ListingStatus,
  ExternalUserRole,
  IncrementType,
  ListingType,
  PremiumType,
} from 'services/api';
import { AuthorizedUserModel } from 'services/models';

export const buyersPremiumOptions = [
  {
    value: PremiumType._0,
    label: '0 %',
  },
  {
    value: PremiumType._1,
    label: '1 %',
  },
  {
    value: PremiumType._2,
    label: '2 %',
  },
  {
    value: PremiumType._3,
    label: '3 %',
  },
  {
    value: PremiumType._4,
    label: '4 %',
  },
  {
    value: PremiumType._5,
    label: '5 %',
  },
  {
    value: PremiumType._6,
    label: '6 %',
  },
];

export const bidIncrementOptions = [
  {
    value: IncrementType._1000,
    label: '$ 1,000',
  },
  {
    value: IncrementType._5000,
    label: '$ 5,000',
  },
  {
    value: IncrementType._10000,
    label: '$ 10,000',
  },
  {
    value: IncrementType._15000,
    label: '$ 15,000',
  },
  {
    value: IncrementType._20000,
    label: '$ 20,000',
  },
];

export const getTypeOptions = (t: TFunction) => [
  {
    value: ListingType.APARTMENT,
    label: t('ListingType.Apartment'),
  },
  {
    value: ListingType.COMMERCIAL,
    label: t('ListingType.Commercial'),
  },
  {
    value: ListingType.CONDO,
    label: t('ListingType.Condo'),
  },
  {
    value: ListingType.DUET,
    label: t('ListingType.Duet'),
  },
  {
    value: ListingType.DUPLEX,
    label: t('ListingType.Duplex'),
  },
  {
    value: ListingType.FARM,
    label: t('ListingType.Farm'),
  },
  {
    value: ListingType.FIVE_UNITS,
    label: t('ListingType.FiveUnits'),
  },
  {
    value: ListingType.FOURPLEX,
    label: t('ListingType.Fourplex'),
  },
  {
    value: ListingType.HOTEL,
    label: t('ListingType.Hotel'),
  },
  {
    value: ListingType.HOUSE,
    label: t('ListingType.House'),
  },
  {
    value: ListingType.INDUSTRIAL,
    label: t('ListingType.Industrial'),
  },
  {
    value: ListingType.LAND,
    label: t('ListingType.Land'),
  },
  {
    value: ListingType.LUXURY,
    label: t('ListingType.Luxury'),
  },
  {
    value: ListingType.MOBILE_MANUFACTURED_HOME,
    label: t('ListingType.MobileManufacturedHome'),
  },
  {
    value: ListingType.STUDIO,
    label: t('ListingType.Studio'),
  },
  {
    value: ListingType.TOWNHOUSE,
    label: t('ListingType.Townhouse'),
  },
  {
    value: ListingType.TRIPLEX,
    label: t('ListingType.Triplex'),
  },
  {
    value: ListingType.VACATION_HOME,
    label: t('ListingType.VacationHome'),
  },
];

export const getStatusOptions = (
  t: TFunction,
  listing?: AdminListingDetail,
  user?: AuthorizedUserModel | null,
) => {
  const activeOption = {
    value: ListingStatus.ACTIVE,
    label: t('ListingStatus.Active'),
    color: '#23A557',
  };
  const endedOption = {
    value: ListingStatus.ENDED,
    label: t('ListingStatus.Ended'),
    color: '#A5AEBC',
  };
  const pendingOption = {
    value: ListingStatus.PENDING,
    label: t('ListingStatus.Pending'),
    color: '#FF8838',
  };
  const soldOption = {
    value: ListingStatus.SOLD,
    label: t('ListingStatus.Sold'),
    color: '#4747F5',
  };
  const withdrawnOption = {
    value: ListingStatus.WITHDRAWN,
    label: t('ListingStatus.Withdrawn'),
    color: '#FF4C61',
  };

  if (!listing) return [activeOption];

  const isWithdrawnAvailable =
    user?.role === ExternalUserRole.BROKERAGE_ADMIN ||
    user?.role === ExternalUserRole.SUPER_ADMIN;

  switch (listing.status) {
    case ListingStatus.ENDED:
      return isWithdrawnAvailable
        ? [activeOption, endedOption, pendingOption, withdrawnOption]
        : [activeOption, endedOption, pendingOption];

    case ListingStatus.PENDING:
    case ListingStatus.SOLD:
      return isWithdrawnAvailable
        ? [
            activeOption,
            endedOption,
            pendingOption,
            soldOption,
            withdrawnOption,
          ]
        : [activeOption, endedOption, pendingOption, soldOption];
    case ListingStatus.WITHDRAWN:
      return [
        activeOption,
        endedOption,
        pendingOption,
        soldOption,
        withdrawnOption,
      ];

    case ListingStatus.DRAFT:
      return [activeOption];
    case ListingStatus.ACTIVE:
    default:
      return isWithdrawnAvailable
        ? [activeOption, withdrawnOption]
        : [activeOption];
  }
};

export const getDefaultStatus = (listing: AdminListingDetail) => {
  switch (listing.status) {
    case ListingStatus.ENDED:
      return ListingStatus.ENDED;

    case ListingStatus.PENDING:
      return ListingStatus.PENDING;

    case ListingStatus.SOLD:
      return ListingStatus.SOLD;
    case ListingStatus.WITHDRAWN:
      return ListingStatus.WITHDRAWN;
    case ListingStatus.DRAFT:
    case ListingStatus.ACTIVE:
    default:
      return ListingStatus.ACTIVE;
  }
};
