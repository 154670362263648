import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExternalNotificationType } from 'services/api';

import ContactPerson from './ContactPerson';
import ContactUsForm from './ContactUsForm';
import styles from './ContactUsSection.module.less';

const ContactUsSectionId = 'contact-us';
export const ContactUsLink = () => {
  const { t } = useTranslation();

  return (
    <a href={`#${ContactUsSectionId}`}>
      {t('ContactUsSection.link.ContactUs')}
    </a>
  );
};

// ContactUsSection component
export default function ContactUsSection() {
  const { t } = useTranslation();

  return (
    <Row gutter={16} id={ContactUsSectionId}>
      <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
        <h2 className="h2">
          <span>{t('ContactUsSection.NeedTo')}</span>
          <div>{t('ContactUsSection.ReachUs')}</div>
        </h2>
      </Col>
      <Col xs={24} md={15} lg={8} xl={8}>
        <h3 className="h5">{t('ContactUsSection.form.title')}</h3>
        <ContactUsForm
          type={
            ExternalNotificationType.GENERAL_CONTACT_FORM_SUBMISSION_NOTIFICATION
          }
        />
      </Col>
      <Col xs={24} md={15} lg={7} xl={6}>
        <ContactPerson className={styles.paddingTop} />
      </Col>
    </Row>
  );
}
