import moment from 'moment';
import { TFunction } from 'react-i18next';

import { BidManagementTabsEnum } from 'features/dashboard/BidManagementPage';

export const getTimeAgoFormattedValue = (ends_at: string, t: TFunction) => {
  const endDate = moment.utc(ends_at);
  const now = moment.utc();
  const diff = endDate.diff(now);
  const duration = moment.duration(diff) as any;
  const durationInDays = duration.asDays();

  if (durationInDays < 1) {
    return t('Dashboard.ListingsManagement.Table.EndsIn.LessDay');
  }

  // rounded value
  const formattedDays = duration.format('d');

  return t('Dashboard.ListingsManagement.Table.EndsIn.Day', {
    count: +formattedDays,
  });
};

export const getBidManagementLink = (id: string, pendingRequests?: number) =>
  pendingRequests
    ? `../bids-management/${id}?tab=${BidManagementTabsEnum.PendingRequests}`
    : `../bids-management/${id}`;
