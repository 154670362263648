import React from 'react';
import { Row, Col } from 'antd';

import Sidebar from './Sidebar';
import styles from './SidebarLayout.module.less';

type Props = {
  children: JSX.Element;
};

export default function SidebarLayout(props: Props) {
  const { children } = props;

  return (
    <section className={styles.wrapper}>
      <div className="container">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={4}>
            <Sidebar />
          </Col>
          <Col xs={24} md={20}>
            {children}
          </Col>
        </Row>
      </div>
    </section>
  );
}
