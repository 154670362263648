import { useTranslation } from 'react-i18next';

import styles from './HomePageAdvertisingBanner.module.less';

const email = 'info@bidthisproperty.com';

export default function HomePageAdvertisingBanner() {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <span>
        {t('HomePageAdvertisingBanner.banner')}{' '}
        <a target="_blank" rel="noreferrer" href={`mailto:${email}`}>
          {email}
        </a>
      </span>
    </div>
  );
}
