/* eslint-disable react/jsx-pascal-case */
import { Spin as $Spin, SpinProps as $SpinProps } from 'antd';
import cx from 'classnames';

import spinner from 'assets/images/spinner.svg';
import styles from './Spin.module.less';

// may be extended by $SpinProps & {};
export type SpinProps = $SpinProps;

export default function Spin(props: SpinProps) {
  const { className } = props;

  const defaultIndicator = (
    <>
      <div className={cx(styles.spinnerWrap, className)}>
        <div className={styles.spinner}>
          <img src={spinner} alt="spinner" />
        </div>
      </div>
    </>
  );

  const { indicator = defaultIndicator, ...rest } = props;

  return <$Spin data-testid="spin" indicator={indicator} {...rest} />;
}
