import cx from 'classnames';

import { AdminBidBuyer, AdminListingBuyer } from 'services/api';

import styles from './ContactList.module.less';

type Props = {
  user: AdminBidBuyer | AdminListingBuyer;
};

export default function ContactList(props: Props) {
  const {
    user: { phone, email, is_subscribed },
  } = props;

  return (
    <ul className={styles.contactList}>
      {phone && (
        <li>
          <a href={`tel:${phone}`}>
            <i className="icon icon-phone"></i>
            {phone}
          </a>
        </li>
      )}

      {email && (
        <li>
          <a target="_blank" rel="noreferrer" href={`mailto:${email}`}>
            <i className="icon icon-mail"></i>
            <span
              className={cx({
                [styles.unsubscribed]: !is_subscribed,
              })}
            >
              {email}
            </span>
          </a>
        </li>
      )}
    </ul>
  );
}
