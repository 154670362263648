/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExternalListingApprovalCreate } from '../models/ExternalListingApprovalCreate';
import type { ExternalListingApprovalDetail } from '../models/ExternalListingApprovalDetail';
import type { ExternalListingApprovalResponse } from '../models/ExternalListingApprovalResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ApprovalsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Approvals
     * @param listingId
     * @param page
     * @param pageSize
     * @returns ExternalListingApprovalResponse Successful Response
     * @throws ApiError
     */
    public approvalsV1ListingsListingIdApprovalsGet(
        listingId: string,
        page: number = 1,
        pageSize: number = 12,
    ): CancelablePromise<ExternalListingApprovalResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/listings/{listing_id}/approvals/',
            path: {
                'listing_id': listingId,
            },
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Approval
     * @param listingId
     * @param requestBody
     * @returns ExternalListingApprovalDetail Successful Response
     * @throws ApiError
     */
    public createApprovalV1ListingsListingIdApprovalsPost(
        listingId: string,
        requestBody: ExternalListingApprovalCreate,
    ): CancelablePromise<ExternalListingApprovalDetail> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/listings/{listing_id}/approvals/',
            path: {
                'listing_id': listingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Approval
     * @param listingId
     * @param userId
     * @returns ExternalListingApprovalDetail Successful Response
     * @throws ApiError
     */
    public approvalV1ListingsListingIdApprovalsUserIdGet(
        listingId: string,
        userId: string,
    ): CancelablePromise<ExternalListingApprovalDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/listings/{listing_id}/approvals/{user_id}',
            path: {
                'listing_id': listingId,
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
