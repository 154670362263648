import { Button, Checkbox, Form, Input, Modal, Tooltip } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TFunction, useTranslation } from 'react-i18next';
import jwt_decode from 'jwt-decode';

import FormItem from 'components/form/FormItem';
import { ModalScrollWrapper, ModalFooterWrapper } from 'components/Modal';
import {
  awsCognitoPasswordRegExp,
  noSpacesRegExp,
} from 'utils/validation.utils';
import { useAuth } from 'components/AuthProvider';
import { displayErrorMessage } from 'components/message';
import SignUpConfirmationModal from '../SignUpConfirmationModal';

import cx from 'classnames';

import styles from './AdminSetPasswordModal.module.less';

const TRIGGER_ACTION = 'create-user';

interface IFormValues {
  password: string;
  passwordConfirmation: string;
  agreeCheckbox: boolean;
}

const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    password: yup
      .string()
      .required(t('validation.required'))
      .matches(noSpacesRegExp, t('validation.noSpace'))
      .max(
        16,
        t('validation.maxLength', {
          count: 16,
        }),
      )
      .matches(awsCognitoPasswordRegExp, t('validation.awsCognitoFormat')),
    passwordConfirmation: yup
      .string()
      .required(t('validation.required'))
      .oneOf([yup.ref('password')], t('validation.PasswordNotMatch')),
    agreeCheckbox: yup
      .boolean()
      .oneOf([true], t('validation.mustAgree'))
      .required(t('validation.mustAgree')),
  });

export default function AdminSetPasswordModal() {
  const { t } = useTranslation();
  const { signUp } = useAuth();
  const [isSetPasswordModalVisible, setIsSetPasswordModalVisible] =
    useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [signUpEmail, setSignUpEmail] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const action = searchParams.get('action') || '';
  const token = searchParams.get('token') || '';
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  useEffect(() => {
    if (action !== TRIGGER_ACTION) return;

    setIsSetPasswordModalVisible(true);
  }, [action]);

  const onSubmit = async (values: IFormValues) => {
    try {
      const { email, phone_number, given_name, family_name } = jwt_decode<{
        email: string;
        phone_number: string;
        given_name: string;
        family_name: string;
      }>(token);

      await signUp({
        email,
        firstName: given_name,
        lastName: family_name,
        phone: phone_number,
        password: values.password,
      });

      onSetPasswordModalClose();
      setSignUpEmail(email);
      setIsConfirmationModalVisible(true);
    } catch (error: any) {
      displayErrorMessage({
        error,
        containerSelector: '.ant-modal',
      });
    }
  };

  const onSetPasswordModalClose = () => {
    setSearchParams('', { replace: true });
    setIsSetPasswordModalVisible(false);
  };

  return (
    <>
      {isConfirmationModalVisible && (
        <SignUpConfirmationModal
          email={signUpEmail}
          onClose={() => setIsConfirmationModalVisible(false)}
        />
      )}
      <Modal
        title={null}
        visible={isSetPasswordModalVisible}
        centered
        closable={false}
        maskClosable={false}
        onCancel={onSetPasswordModalClose}
        footer={null}
        destroyOnClose
        className="modal"
        width={550}
      >
        <h2 className="modal__title">
          {t('AdminSetPasswordModal.SetPassword')}
        </h2>
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <ModalScrollWrapper>
            <div className="modal__subtitle">
              {t('AdminSetPasswordModal.description')}
            </div>
            <FormItem
              name="password"
              control={control}
              render={({ field }) => (
                <Tooltip
                  overlayStyle={{ maxWidth: '470px', width: '100%' }}
                  placement="topLeft"
                  title={t('Password.tooltip')}
                >
                  <Input.Password
                    size="large"
                    {...field}
                    placeholder={t(
                      'AdminSetPasswordModal.placeholder.CreatePassword',
                    )}
                  />
                </Tooltip>
              )}
            />
            <FormItem
              name="passwordConfirmation"
              control={control}
              render={({ field }) => (
                <Input.Password
                  size="large"
                  {...field}
                  placeholder={t(
                    'AdminSetPasswordModal.placeholder.ConfirmPassword',
                  )}
                />
              )}
            />
            <FormItem
              name="agreeCheckbox"
              className="clear-shadow"
              control={control}
              render={({ field }) => (
                <>
                  <Checkbox {...field}>
                    <div className={styles.checkboxLabel}>
                      {t('AdminSetPasswordModal.checkbox.agree.label')}
                    </div>
                  </Checkbox>
                  <div className={cx(styles.checkboxText, 'modal__subtitle')}>
                    {t('AdminSetPasswordModal.checkbox.agree.info')}
                    <Link to="/terms-and-conditions-of-service" target="_blank">
                      <Button type="link" className="custom-link">
                        {t('AdminSetPasswordModal.link.termsAndConditions')}
                      </Button>
                    </Link>
                    &nbsp;
                    {t('AdminSetPasswordModal.link.and')}
                    <Link to="/privacy-policy" target="_blank">
                      <Button type="link" className="custom-link">
                        {t('AdminSetPasswordModal.link.privacyPolicy')}
                      </Button>
                    </Link>
                    .
                  </div>
                </>
              )}
            />
          </ModalScrollWrapper>

          <ModalFooterWrapper>
            <div>
              <Button
                onClick={onSetPasswordModalClose}
                type="link"
                className="custom-link"
                size="large"
              >
                {t('AdminSetPasswordModal.Cancel')}
              </Button>
            </div>
            <div>
              <Button
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
                size="large"
              >
                {t('AdminSetPasswordModal.SetPassword')}
              </Button>
            </div>
          </ModalFooterWrapper>
        </Form>
      </Modal>
    </>
  );
}
