/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ExternalNotificationType {
    CONTACT_US_FORM_SUBMISSION_NOTIFICATION = 'ContactUsFormSubmissionNotification',
    GENERAL_CONTACT_FORM_SUBMISSION_NOTIFICATION = 'GeneralContactFormSubmissionNotification',
    SYSTEM_RESET_PASSWORD_NOTIFICATION = 'SystemResetPasswordNotification',
}
