import { Row, Col, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { CaretUpOutlined } from '@ant-design/icons';
import { isArray } from 'lodash';

const { Panel } = Collapse;

export default function AboutUsWhyJoinUsSection() {
  const { t } = useTranslation();
  const JoinUsValuesInfo = t<string, string[]>(
    'AboutUsWhyJoinUsSection.Values.info',
    {
      returnObjects: true,
    },
  );
  return (
    <Row gutter={16}>
      <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
        <h2 className="h2">
          <span>{t('AboutUsWhyJoinUsSection.Why')}</span>
          <div>{t('AboutUsWhyJoinUsSection.JoinUs')}</div>
        </h2>
      </Col>
      <Col xs={24} md={15} xl={14}>
        <div>
          <Collapse
            accordion
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretUpOutlined rotate={isActive ? 0 : 180} />
            )}
          >
            <Panel
              header={t('AboutUsWhyJoinUsSection.WhoWeAre')}
              key="UseAnAgent"
            >
              <p>{t('AboutUsWhyJoinUsSection.WhoWeAre.info1')}</p>
              <p>{t('AboutUsWhyJoinUsSection.WhoWeAre.info2')}</p>
              <p>{t('AboutUsWhyJoinUsSection.WhoWeAre.info3')}</p>
            </Panel>
            <Panel header={t('AboutUsWhyJoinUsSection.Mission')} key="Mission">
              <p>{t('AboutUsWhyJoinUsSection.Mission.info')}</p>
            </Panel>
            <Panel header={t('AboutUsWhyJoinUsSection.Vision')} key="Vision">
              <p>{t('AboutUsWhyJoinUsSection.Vision.info')}</p>
            </Panel>
            <Panel header={t('AboutUsWhyJoinUsSection.Values')} key="Values">
              <ul className="default-list">
                {isArray(JoinUsValuesInfo) &&
                  JoinUsValuesInfo.map((point, index) => (
                    <li key={`point-${index}`}>{point}</li>
                  ))}
              </ul>
            </Panel>
          </Collapse>
        </div>
      </Col>
    </Row>
  );
}
