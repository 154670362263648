export enum ImageSize {
  NORMAL = 320,
  BID_NOW_POPUP = 190,
  OTHER_CASES = 260,
  BIG = 880,
  SMALL = 282,
  EXTRA_SMALL = 76,
}

export const getImageUrl = (originalUrl?: string, size?: ImageSize) => {
  if (!originalUrl || !size || !(size in ImageSize)) return originalUrl;

  try {
    const imgWidth = window.devicePixelRatio > 1 ? size * 2 : size; // multiply by 2 for the Retina screens or zoomed pages
    const url = new URL(originalUrl);
    url.searchParams.set('w', imgWidth.toString());

    return url.toString();
  } catch {
    return originalUrl;
  }
};
