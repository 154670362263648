import React, { Ref } from 'react';
import { Switch as $Switch } from 'antd';
import { SwitchProps as $SwitchProps } from 'antd/lib/switch/index';
import { ControllerRenderProps } from 'react-hook-form';

export type SwitchProps = $SwitchProps & ControllerRenderProps;

export default React.forwardRef(
  (props: SwitchProps, ref: Ref<HTMLInputElement>) => {
    const { value, ...restProps } = props;
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <$Switch {...restProps} ref={ref} checked={value} />
    );
  },
);
