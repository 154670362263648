import {
  AdminListingCreate,
  AdminListingUpdate,
  apiClient,
  ExternalBidCreate,
  ExternalListingApprovalCreate,
  ExternalListingOrderBy,
  AdminBrokerageCreate,
  AdminBrokerageUpdate,
  AdminUserCreate,
  AdminUserUpdate,
  MultimediaType,
  ExternalAutoBidCreate,
  Language,
} from '.';

class ListingApi {
  public async getListingList({
    query,
    pageSize,
    page,
    order,
  }: {
    query: string;
    pageSize: number;
    page: number;
    order: ExternalListingOrderBy;
  }) {
    return apiClient.listings.listListings(page, pageSize, order, query);
  }

  public async getListingDetails(id: string) {
    return apiClient.listings.getListing(id);
  }

  public async createBid(data: ExternalBidCreate) {
    return apiClient.bids.createBid(data);
  }

  public async createAutoBid(data: ExternalAutoBidCreate) {
    return apiClient.bids.createAutoBid(data);
  }

  public async isApprovedForBid({
    listingId,
    userId,
  }: {
    listingId: string;
    userId: string;
  }) {
    return apiClient.approvals.approvalV1ListingsListingIdApprovalsUserIdGet(
      listingId,
      userId,
    );
  }

  public async getUploadLink({
    fileName,
    bucket,
  }: {
    fileName: string;
    bucket: MultimediaType;
  }) {
    return apiClient.multimedia.generateUploadUrlV1UploadUrlGet(
      fileName,
      bucket,
    );
  }

  public async sendApprovalRequest({
    listingId,
    data,
  }: {
    listingId: string;
    data: ExternalListingApprovalCreate;
  }) {
    return apiClient.approvals.createApprovalV1ListingsListingIdApprovalsPost(
      listingId,
      data,
    );
  }

  public async updateListingViews(id: string) {
    return apiClient.listings.updateListingViewsV1ListingsListingIdViewsPost(
      id,
    );
  }

  // admin
  public async getListingManagingList({
    pageSize,
    page,
    order,
  }: {
    pageSize: number;
    page: number;
    order: ExternalListingOrderBy;
  }) {
    return apiClient.admin.adminListListings(page, pageSize, order);
  }

  public getAdminListingDetails(listingId: string) {
    return apiClient.admin.adminListing(listingId);
  }

  public async getBidsManagementAllBids(listingId: string) {
    return apiClient.admin.adminListBids(listingId);
  }

  public async getAllBidsCSV(
    listingId: string,
    language: Language = Language.EN,
  ) {
    const timezoneOffset = new Date().getTimezoneOffset() * -1;

    return apiClient.admin.downloadBidsV1AdminReportsListingsListingIdBidsDownloadGet(
      listingId,
      timezoneOffset,
      language,
    );
  }

  public async getBidPendingRequests(listingId: string) {
    const isApproved = false;

    return apiClient.admin.approvalsAdmin(listingId, isApproved);
  }

  public async disableAutobid(autobidId: string) {
    return apiClient.admin.adminDisableAutoBid(autobidId, {
      is_enabled: false,
    });
  }

  public async getBidApprovedBuyers(listingId: string) {
    const isApproved = true;

    return apiClient.admin.approvalsAdmin(listingId, isApproved);
  }

  public async approvePendingRequest(listingId: string, userId: string) {
    return apiClient.admin.updateApproval(listingId, userId, {
      is_approved: true,
    });
  }

  public getBidPendingRequestCount(listingId: string) {
    return apiClient.admin.approvalCountsAdmin(listingId);
  }

  public async createListing(data: AdminListingCreate) {
    return apiClient.admin.adminCreateListing(data);
  }

  public async updateListing(listingId: string, data: AdminListingUpdate) {
    return apiClient.admin.adminUpdateListing(listingId, data);
  }

  public async voidBid(bidId: string) {
    return apiClient.admin.adminVoidBid(bidId);
  }
  public async getBrokeragesList({
    pageSize,
    page,
  }: {
    pageSize: number;
    page: number;
  }) {
    return apiClient.admin.adminBrokerages(page, pageSize);
  }
  public async createBrokerage(data: AdminBrokerageCreate) {
    return apiClient.admin.adminBrokeragesCreate(data);
  }
  public async getAdminBrokerageDetails(brokerageId: string) {
    return apiClient.admin.adminGetBrokerage(brokerageId);
  }
  public async updateBrokerage(
    brokerageId: string,
    data: AdminBrokerageUpdate,
  ) {
    return apiClient.admin.adminBrokeragesUpdate(brokerageId, data);
  }
  public async changeSubscription(id: string, checked: boolean) {
    return apiClient.users.updateUser(id, { is_subscribed: checked });
  }
  public async getProfileValue(id: string) {
    return apiClient.users.getUser(id);
  }
  public async getBrokeragesValues() {
    return apiClient.admin.adminListCurrentBrokerages();
  }
  public async createUser(user: AdminUserCreate) {
    return apiClient.admin.adminCreateUser(user);
  }
  //replace with real api
  public async getAdminUserDetails(userId: string) {
    return apiClient.admin.adminGetUser(userId);
  }
  public async updateUser(userId: string, user: AdminUserUpdate) {
    return apiClient.admin.adminUpdateUser(userId, user);
  }
  // end: admin
}

export default new ListingApi();
