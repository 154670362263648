import { useTranslation } from 'react-i18next';

// import AgentCard from 'components/AgentCard';

import styles from './ContactPerson.module.less';

type Props = {
  className?: string;
};

export default function ContactPerson(props: Props) {
  const { t } = useTranslation();
  const { className = '' } = props;

  return (
    <div className={className}>
      {/* <AgentCard /> */}
      {/* check this requirements */}
      <h4 className={styles.contactSubtitle}>
        {t('ContactPerson.statement.title')}
      </h4>
      <p className={styles.contactText}>{t('ContactPerson.statement.info')}</p>
    </div>
  );
}
