import { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ExternalListingDetail, ListingBid, ListingStatus } from 'services/api';

import BidNowButton from 'features/listing/BidNowButton';
import { getFormattedNumber } from 'utils/number.utils';
import { getCurrencySign } from 'utils/price.utils';

import styles from './BidsHistory.module.less';

type Props = {
  listing: ExternalListingDetail;
};

enum ViewMode {
  onlyOne = 'onlyOne',
  regular = 'regular',
  expanded = 'expanded',
  scroll = 'scroll',
}

enum LengthConfig {
  defaultSliceValRegular = 6,
  defaultSliceValShort = 4,
  regularStartLength = 2,
  regularEndLength = 6,
  expandedStartLength = 7,
  expandedEndLength = 11,
  scrollStartLength = 12,
}

export default function BidsHistory({ listing }: Props) {
  const { bids = [], status, initial_price } = listing;
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  const lengthBasedOnView =
    bids?.length <= LengthConfig.regularEndLength
      ? LengthConfig.defaultSliceValRegular
      : LengthConfig.defaultSliceValShort;
  const shouldShowBids =
    status === ListingStatus.ACTIVE ||
    status === ListingStatus.ENDED ||
    status === ListingStatus.PENDING ||
    status === ListingStatus.SOLD;

  const lastBid = bids[0];
  const nickname = lastBid?.buyer?.nickname || '';

  const getBidderText = () => {
    switch (status) {
      case ListingStatus.ACTIVE:
        return { text: t('BidsHistory.currentBid') };

      case ListingStatus.ENDED:
        return { text: t('BidsHistory.lastBid') };

      case ListingStatus.PENDING:
      case ListingStatus.SOLD:
        return { text: t('BidsHistory.finalBid') };

      default:
        return { text: '' };
    }
  };

  const bidderData = getBidderText();

  const getLocalizedValue = (val: boolean) => {
    return val ? t('BidsHistory.lessButton') : t('BidsHistory.moreButton');
  };

  const getBidsHistoryView = (count: number): string => {
    if (count === 1) {
      return ViewMode.onlyOne;
    } else if (
      count >= LengthConfig.regularStartLength &&
      count <= LengthConfig.regularEndLength
    ) {
      return ViewMode.regular;
    } else if (
      count >= LengthConfig.expandedStartLength &&
      count <= LengthConfig.expandedEndLength
    ) {
      return ViewMode.expanded;
    } else if (count >= LengthConfig.scrollStartLength) {
      return ViewMode.scroll;
    }
    return '';
  };
  const bidsHistoryViewMode = getBidsHistoryView(bids.length);

  const sliceEnd = showMore ? bids?.length : lengthBasedOnView;

  return (
    <div className={cx('paper', styles.wrap)}>
      <div className={cx(styles.title, 'h5')}>{t('BidsHistory.title')}</div>
      {!bidsHistoryViewMode || !shouldShowBids ? (
        <div className={styles.empty}>
          <i className="icon icon-folder"></i>
          <span>{t('BidsHistory.noBidsYet')}</span>
        </div>
      ) : (
        <>
          <div className={styles.subtitle}>{bidderData.text}</div>
          <div
            data-testid="last-bid-item"
            className={cx(styles.bidItem, styles.bidItemCurrent)}
          >
            <strong className={styles.bidItemIcon}>
              {getCurrencySign(lastBid?.price.currency)}{' '}
            </strong>
            <span className={styles.bidItemPrice}>
              {getFormattedNumber(lastBid?.price.value)} -{' '}
            </span>
            <span>{nickname}</span>
          </div>

          {bidsHistoryViewMode !== ViewMode.onlyOne && (
            <div data-testid="history-subtitle" className={styles.subtitle}>
              {t('BidsHistory.title')}
            </div>
          )}

          <ul
            data-testid="bid-ul-list"
            className={cx(styles.bidList, {
              [styles.scrollBox]:
                bidsHistoryViewMode === ViewMode.scroll && showMore,
            })}
          >
            {bids?.length !== 0 &&
              bidsHistoryViewMode !== ViewMode.onlyOne &&
              bids?.slice(1, sliceEnd).map((item: ListingBid) => {
                return (
                  <li data-testid="bid-li-item" key={item.id}>
                    <div className={styles.bidItem}>
                      <strong className={styles.bidItemIcon}>
                        {getCurrencySign(item.price?.currency)}
                      </strong>
                      <span className={styles.bidItemPrice}>
                        {getFormattedNumber(item.price.value)}
                      </span>{' '}
                      - <span> {item.buyer?.nickname}</span>
                    </div>
                  </li>
                );
              })}
          </ul>

          {(bidsHistoryViewMode === ViewMode.expanded ||
            bidsHistoryViewMode === ViewMode.scroll) && (
            <div className={styles.shoBtnBox}>
              <button
                data-testid="show-more-button"
                className={styles.showBtn}
                onClick={() => {
                  setShowMore(!showMore);
                }}
              >
                {getLocalizedValue(showMore)}
              </button>
            </div>
          )}
        </>
      )}

      {!!initial_price && (
        <div className={cx(styles.bidItem, styles.bidItemInitial)}>
          <strong className={styles.bidItemIcon}>
            {getCurrencySign(initial_price.currency)}
          </strong>

          <span>{getFormattedNumber(initial_price.value)} - </span>
          <span>{t('BidsHistory.initialPrice')}</span>
        </div>
      )}

      <div className={styles.btnWrap}>
        <BidNowButton
          listing={listing}
          buttonProps={{
            size: 'large',
            type: 'primary',
            block: true,
          }}
        />
      </div>
    </div>
  );
}
