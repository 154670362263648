/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExternalUserCreate } from '../models/ExternalUserCreate';
import type { ExternalUserDetail } from '../models/ExternalUserDetail';
import type { ExternalUserStaffResponse } from '../models/ExternalUserStaffResponse';
import type { ExternalUserUpdate } from '../models/ExternalUserUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * User
     * @param userId
     * @returns ExternalUserDetail Successful Response
     * @throws ApiError
     */
    public getUser(
        userId: string,
    ): CancelablePromise<ExternalUserDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update
     * @param userId
     * @param requestBody
     * @returns ExternalUserDetail Successful Response
     * @throws ApiError
     */
    public updateUser(
        userId: string,
        requestBody: ExternalUserUpdate,
    ): CancelablePromise<ExternalUserDetail> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/users/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Staff Users
     * @param page
     * @param pageSize
     * @param search
     * @param order
     * @param includeRoles
     * @returns ExternalUserStaffResponse Successful Response
     * @throws ApiError
     */
    public staffUsers(
        page: number = 1,
        pageSize: number = 50,
        search?: string,
        order: string = 'first_name',
        includeRoles?: Array<'listing_agent'>,
    ): CancelablePromise<ExternalUserStaffResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/',
            query: {
                'page': page,
                'page_size': pageSize,
                'search': search,
                'order': order,
                'include_roles': includeRoles,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create
     * @param requestBody
     * @returns ExternalUserDetail Successful Response
     * @throws ApiError
     */
    public createUser(
        requestBody: ExternalUserCreate,
    ): CancelablePromise<ExternalUserDetail> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/users/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Unsubscribe
     * @param userId
     * @param token
     * @returns any Successful Response
     * @throws ApiError
     */
    public unsubscribe(
        userId: string,
        token: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/users/{user_id}/unsubscribe/{token}',
            path: {
                'user_id': userId,
                'token': token,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
