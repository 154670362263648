/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Language {
    EN = 'en',
    IT = 'it',
}
