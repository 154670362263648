/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExternalMediaReadPresignedUrl } from '../models/ExternalMediaReadPresignedUrl';
import type { ExternalMediaUploadPresignedUrl } from '../models/ExternalMediaUploadPresignedUrl';
import type { MultimediaType } from '../models/MultimediaType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MultimediaService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Generate Upload Url
     * @param fileName
     * @param bucket
     * @param expiration
     * @returns ExternalMediaUploadPresignedUrl Successful Response
     * @throws ApiError
     */
    public generateUploadUrlV1UploadUrlGet(
        fileName: string,
        bucket?: MultimediaType,
        expiration: number = 3600,
    ): CancelablePromise<ExternalMediaUploadPresignedUrl> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/upload-url',
            query: {
                'bucket': bucket,
                'file_name': fileName,
                'expiration': expiration,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Generate Read Url
     * @param key
     * @param bucket
     * @param expiration
     * @returns ExternalMediaReadPresignedUrl Successful Response
     * @throws ApiError
     */
    public generateReadUrlV1ReadUrlGet(
        key: string,
        bucket?: MultimediaType,
        expiration: number = 3600,
    ): CancelablePromise<ExternalMediaReadPresignedUrl> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/read-url',
            query: {
                'bucket': bucket,
                'key': key,
                'expiration': expiration,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Images
     * @param keys
     * @param bucket
     * @returns void
     * @throws ApiError
     */
    public deleteImagesV1DeleteDelete(
        keys: Array<string>,
        bucket?: MultimediaType,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/delete',
            query: {
                'bucket': bucket,
                'keys': keys,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
