import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { ListingApi, ExternalListingList } from 'services/api';
import { useAuth } from 'components/AuthProvider';
import Spin from 'components/Spin';
import SignInForm from 'features/auth/SignInForm';
import SignUpForm from 'features/auth/SignUpForm';
import SignUpConfirmationForm from 'features/auth/SignUpConfirmationForm';
import GetApprovedForm from '../GetApprovedForm';
import BidNowForm from '../BidNowForm';

import styles from './BidNowModal.module.less';

type Props = {
  listing: ExternalListingList;
  onClose: () => void;
  onUnconfirmed: (email: string) => void;
  onForgotPassword: () => void;
};

enum BidNowStep {
  SignIn = 'SignIn',
  Register = 'Register',
  Confirmation = 'Confirmation',
  GetApproved = 'GetApproved',
  BidNow = 'BidNow',
}

export default function BidNowModal(props: Props) {
  const { t } = useTranslation();
  const { listing, onClose, onUnconfirmed, onForgotPassword } = props;
  const [steps, setSteps] = useState<BidNowStep[]>([]);
  const [currentStep, setCurrentStep] = useState<BidNowStep>();
  const { isAuthenticated, user } = useAuth();
  const [isApproveChecking, setIsApproveChecking] = useState(false);
  const [isApproveSuccessStep, setIsApproveSuccessStep] = useState(false);
  const [signUpEmail, setSignUpEmail] = useState('');
  const [showSteps, setShowSteps] = useState(true);

  useEffect(() => {
    const setApproveStepAsActive = () => {
      setCurrentStep(BidNowStep.GetApproved);
      setSteps((prevSteps) =>
        isEmpty(prevSteps)
          ? [BidNowStep.GetApproved, BidNowStep.BidNow]
          : prevSteps,
      );
    };

    const loadData = async () => {
      if (!user) return;

      try {
        setIsApproveChecking(true);

        const result = await ListingApi.isApprovedForBid({
          listingId: listing.id,
          userId: user.user_id,
        });

        if (result.is_approved) {
          setCurrentStep(BidNowStep.BidNow);
          setSteps((prevSteps) =>
            isEmpty(prevSteps) ? [BidNowStep.BidNow] : prevSteps,
          );
        } else {
          setIsApproveSuccessStep(true);
          setApproveStepAsActive();
        }
      } catch {
        setApproveStepAsActive();
      } finally {
        setIsApproveChecking(false);
      }
    };

    if (!isAuthenticated) {
      setCurrentStep(BidNowStep.SignIn);
      setSteps([BidNowStep.SignIn, BidNowStep.GetApproved, BidNowStep.BidNow]);
      return;
    }

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    // Modal triggers the clicks to the parent Link
    // we need an extra wrapper with stopPropagation to avoid this
    <div style={{ display: 'none' }} onClick={(e) => e.stopPropagation()}>
      <Modal
        title={null}
        visible
        centered
        closable={false}
        maskClosable={false}
        onCancel={onClose}
        footer={null}
        destroyOnClose
        width={'auto'}
        className={styles.modal}
      >
        <Spin spinning={isApproveChecking}>
          <>
            {showSteps && (
              <div className={cx(styles.step, 'modal__title')}>
                {steps.map((step, index) => (
                  <div key={`step-${step}`}>
                    <span
                      className={cx(styles.stepItem, {
                        [styles.activeStep]: step === currentStep,
                      })}
                    >
                      {t(`BidNowModal.step.${step}`)}
                    </span>
                    {index !== steps.length - 1 && (
                      <i className="icon icon-fill-arrow-left"></i>
                    )}
                  </div>
                ))}
              </div>
            )}

            <div className={styles.container}>
              {currentStep === BidNowStep.SignIn && (
                <SignInForm
                  onUnconfirmed={onUnconfirmed}
                  onForgotPassword={onForgotPassword}
                  onClose={onClose}
                  onRegisterClick={() => {
                    setCurrentStep(BidNowStep.Register);
                    setSteps([
                      BidNowStep.Register,
                      BidNowStep.Confirmation,
                      BidNowStep.GetApproved,
                      BidNowStep.BidNow,
                    ]);
                  }}
                />
              )}
              {currentStep === BidNowStep.Register && (
                <SignUpForm
                  onClose={onClose}
                  onSuccess={(email) => {
                    setSignUpEmail(email);
                    setCurrentStep(BidNowStep.Confirmation);
                  }}
                  onSignInClick={() => {
                    setCurrentStep(BidNowStep.SignIn);
                    setSteps([
                      BidNowStep.SignIn,
                      BidNowStep.GetApproved,
                      BidNowStep.BidNow,
                    ]);
                  }}
                />
              )}
              {currentStep === BidNowStep.Confirmation && (
                <SignUpConfirmationForm onClose={onClose} email={signUpEmail} />
              )}
              {currentStep === BidNowStep.GetApproved && (
                <GetApprovedForm
                  listing={listing}
                  initialSuccessStep={isApproveSuccessStep}
                  onClose={onClose}
                  onSuccess={() => {
                    setCurrentStep(BidNowStep.BidNow);
                  }}
                />
              )}
              {currentStep === BidNowStep.BidNow && (
                <BidNowForm
                  listingId={listing.id}
                  onClose={onClose}
                  onSuccess={() => setShowSteps(false)}
                />
              )}
            </div>
          </>
        </Spin>
      </Modal>
    </div>
  );
}
