import { RcFile } from 'rc-upload/lib/interface';
import { UploadFile } from 'antd';
import axios from 'axios';
import { nanoid } from '@reduxjs/toolkit';
import { FileMetadata } from 'services/api';
import { isArray } from 'lodash';

export type S3Response = {
  s3Key: string;
};

export type UploadFileS3 = UploadFile<S3Response>;

export const getFileExt = (fileName: string) => {
  if (!fileName) return '';

  const fileNameArray = fileName.split('.');

  return fileNameArray[fileNameArray.length - 1];
};

export const ValidateFiles = (files?: UploadFileS3[]) => {
  return !!files?.every(
    (file) => file.status === 'done' && !!file.response?.s3Key,
  );
};

export const getFormattedFiles = (
  files: FileMetadata | FileMetadata[] | null | undefined,
): UploadFileS3[] => {
  if (!files) return [];

  files = isArray(files) ? files : [files];

  return files.map((file) => ({
    uid: nanoid(),
    name: file.filename || '',
    url: file.url,
    status: !!file.key ? 'done' : 'error',
    response: !!file.key
      ? {
          s3Key: file.key,
        }
      : undefined,
  }));
};
export const isFileFormatValid = (
  file: RcFile | File,
  acceptedFileFormat: string[],
) => {
  const fileType = `.${getFileExt(file.name)}`;

  return acceptedFileFormat.some(
    (format: string) => format.toLowerCase() === fileType.toLowerCase(),
  );
};

export const isFileSizeValid = (file: RcFile, acceptedFileSize: number) =>
  file.size <= 1024 * 1024 * acceptedFileSize; // acceptedFileSize in Mb

export const downloadFileByObjectURL = async (
  objectURL: string,
  fileName: string = '',
) => {
  const link = document.createElement('a');

  link.href = objectURL;
  link.download = fileName;

  // mozilla requires link to be in the dom for downloading
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

interface DownloadFileModel {
  downloadUrl: string;
  fileName?: string;
}

export const downloadFile = async ({
  downloadUrl,
  fileName = '',
}: DownloadFileModel) => {
  const { data: fileBlob } = await axios({
    method: 'GET',
    url: downloadUrl,
    responseType: 'blob',
  });

  const urlObject = URL.createObjectURL(fileBlob);

  downloadFileByObjectURL(urlObject, fileName);
};

export const getSafeFileName = (fileName: string) => {
  const replaceRegExp = /[^a-z0-9#’'()+,\-.;_/]/gi;

  return fileName.replace(replaceRegExp, '');
};
