import { Navigate } from 'react-router-dom';

import { useAuth } from 'components/AuthProvider';
import { ExternalUserRole } from 'services/api';
import { hasUserRequiredRole } from 'components/Accessor/accessorUtils';

type Props = {
  children: JSX.Element;
  roles?: ExternalUserRole[];
};

export default function ProtectedRoute(props: Props) {
  const { children, roles } = props;
  const { user, isAuthenticated } = useAuth();
  const hasAccessByRole = roles ? hasUserRequiredRole(roles, user?.role) : true;

  if (!isAuthenticated || !hasAccessByRole) return <Navigate to="/" replace />;

  return children;
}
