import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import SignInForm from '../SignInForm';

type Props = {
  onClose: () => void;
  onRegisterClick: () => void;
  onForgotPassword: () => void;
  onUnconfirmed: (email: string) => void;
};

export default function SignInModal(props: Props) {
  const { t } = useTranslation();
  const { onClose, onRegisterClick, onUnconfirmed, onForgotPassword } = props;

  return (
    <Modal
      title={null}
      visible
      centered
      closable={false}
      maskClosable={false}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      className="modal"
      width={550}
    >
      <h2 className="modal__title">{t('SignInModal.SignIn')}</h2>
      <SignInForm
        onClose={onClose}
        onSuccess={onClose}
        onRegisterClick={onRegisterClick}
        onUnconfirmed={onUnconfirmed}
        onForgotPassword={onForgotPassword}
      />
    </Modal>
  );
}
