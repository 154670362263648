import { Carousel, Modal } from 'antd';
import {
  DetailedHTMLProps,
  IframeHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import styles from './VideoCarousel.module.less';

type Props = {
  onClose: () => void;
  videos: string[];
};

export const VideoIFrame = (
  props: DetailedHTMLProps<
    IframeHTMLAttributes<HTMLIFrameElement>,
    HTMLIFrameElement
  >,
) => {
  return (
    <iframe
      title="video"
      width="100%"
      height="100%"
      frameBorder={0}
      allowFullScreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; xr-spatial-tracking"
      {...props}
    ></iframe>
  );
};

export default function VideoCarousel(props: Props) {
  const { onClose, videos } = props;
  const [slideIndex, setSlideIndex] = useState(0); // to destroy players

  useEffect(() => {
    // to fix go back from Matterport full-screen on mobile devices
    window.addEventListener('popstate', onClose);

    return () => {
      window.removeEventListener('popstate', onClose);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Modal
        visible
        title={null}
        centered
        closable={false}
        maskClosable={false}
        footer={null}
        destroyOnClose
        width={'100%'}
        className={styles.modal}
      >
        <button className={styles.modalBtn} onClick={onClose}>
          <i className="icon icon-close"></i>
        </button>
        <Carousel
          dots={false}
          arrows={true}
          prevArrow={<LeftOutlined />}
          nextArrow={<RightOutlined />}
          afterChange={(currentSlide) => setSlideIndex(currentSlide)}
        >
          {videos.map((video, index) => (
            <div key={`video-slide-${index}`}>
              <div className={styles.slide}>
                {slideIndex === index && <VideoIFrame src={video} />}
              </div>
            </div>
          ))}
        </Carousel>
      </Modal>
    </div>
  );
}
