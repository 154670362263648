import { TFunction } from 'react-i18next';
import moment, { Moment } from 'moment';

import {
  ExternalListingDetail,
  ExternalListingList,
  ListingStatus,
} from 'services/api';

export const IsHotOffer = (
  listing: ExternalListingList | ExternalListingDetail,
) => {
  const { status, views = 0 } = listing;

  return (
    (status === ListingStatus.ACTIVE || status === ListingStatus.COMING_SOON) &&
    views >= 500
  );
};

export const getPriceData = (
  listing: ExternalListingList | ExternalListingDetail,
) => {
  const {
    status,
    bids = [],
    initial_price,
    pending_price,
    sold_price,
  } = listing;
  const lastBid = bids[0];

  if (status === ListingStatus.DRAFT || status === ListingStatus.COMING_SOON) {
    return initial_price;
  }

  if (status === ListingStatus.PENDING) {
    return pending_price;
  }

  if (status === ListingStatus.SOLD) {
    return sold_price;
  }

  return lastBid?.price || initial_price;
};

export const getBidderData = (listing: ExternalListingList, t: TFunction) => {
  const { status, bids = [] } = listing;
  const lastBid = bids[0];
  const nickname = lastBid?.buyer?.nickname;

  switch (status) {
    case ListingStatus.ACTIVE:
      return lastBid
        ? { text: t('ListingDetailsBidderData.CurrentBidBy'), nickname }
        : {
            text: t('ListingDetailsBidderData.InitialPrice'),
            nickname: '',
          };

    case ListingStatus.DRAFT:
    case ListingStatus.COMING_SOON:
      return {
        text: t('ListingDetailsBidderData.InitialPrice'),
        nickname: '',
      };

    case ListingStatus.WITHDRAWN:
    case ListingStatus.ENDED:
      return lastBid
        ? { text: t('ListingDetailsBidderData.LastBidBy'), nickname }
        : {
            text: t('ListingDetailsBidderData.InitialPrice'),
            nickname: '',
          };

    case ListingStatus.PENDING:
      return {
        text: t('ListingDetailsBidderData.PendingPrice'),
        nickname: '',
      };

    case ListingStatus.SOLD:
      return {
        text: t('ListingDetailsBidderData.SoldPrice'),
        nickname: '',
      };

    default:
      return { text: '', nickname: '' };
  }
};

export const getBidNowButtonProps = (status: ListingStatus, t: TFunction) => {
  switch (status) {
    case ListingStatus.COMING_SOON:
      return { children: t('bidNow.button.CommingSoon'), disabled: true };
    case ListingStatus.WITHDRAWN:
      return { children: t('bidNow.button.Withdrawn'), disabled: true };
    case ListingStatus.ENDED:
      return { children: t('bidNow.button.Ended'), disabled: true };
    case ListingStatus.DRAFT:
      return { children: t('bidNow.button.Draft'), disabled: true };
    case ListingStatus.PENDING:
      return { children: t('bidNow.button.Pending'), disabled: true };
    case ListingStatus.SOLD:
      return { children: t('bidNow.button.Sold'), disabled: true };
    case ListingStatus.ACTIVE:
    default:
      return { children: t('bidNow.button.Active'), disabled: false };
  }
};

export const getValidEndDate = () => {
  return moment().add('1', 'hour').startOf('hour');
};

export const getDisabledDates = (current: Moment) => {
  return current && current < getValidEndDate();
};
