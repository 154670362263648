/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExternalBrokerageDetail } from '../models/ExternalBrokerageDetail';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BrokeragesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Brokerage Settings
     * @returns ExternalBrokerageDetail Successful Response
     * @throws ApiError
     */
    public getBrokerageSettings(): CancelablePromise<ExternalBrokerageDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/brokerages/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
