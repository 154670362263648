import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import { ControllerRenderProps } from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import './TextEditor.less';
const Link = Quill.import('formats/link');

export type TextEditorProps = ControllerRenderProps & ReactQuillProps;

export default React.forwardRef(
  (props: TextEditorProps, ref: React.LegacyRef<ReactQuill>) => {
    const { value, ...restProps } = props;

    const { t } = useTranslation();

    useEffect(() => {
      let style: HTMLStyleElement | null = null;
      const headStyles = document.querySelector('head');

      const removeStyles = () => {
        if (style && headStyles) {
          headStyles.removeChild(style);
          style = null;
        }
      };

      removeStyles();

      style = document.createElement('style');
      style.innerHTML = `
        .ql-snow .ql-tooltip[data-mode=link]::before {
          content: '${t('TextEditor.link.enterLink')}';
        }
        .ql-snow .ql-tooltip::before {
          content: '${t('TextEditor.link.visitURL')}';
        }
        .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
          content: '${t('TextEditor.link.save')}';
        }
        .ql-snow .ql-tooltip a.ql-action::after {
          content: '${t('TextEditor.link.edit')}';
        }
        .ql-snow .ql-tooltip a.ql-remove::before {
          content: '${t('TextEditor.link.remove')}';
        }
      `;

      headStyles?.appendChild(style);

      const inputs = document.querySelectorAll('.ql-tooltip [data-link]');
      inputs.forEach((item) => {
        const inputElement = item as HTMLInputElement;
        inputElement.dataset.link = t('TextEditor.link.placeholder');
        inputElement.placeholder = t('TextEditor.link.placeholder');
      });

      return removeStyles;
    }, [t]);

    Link.sanitize = (url: string) => {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `http://${url}`;
      }
      return url;
    };

    const modules = {
      toolbar: [[{ list: 'bullet' }], ['bold'], ['link']],
    };

    const formats = ['bold', 'list', 'bullet', 'link'];
    return (
      <ReactQuill
        {...restProps}
        modules={modules}
        formats={formats}
        value={value}
        ref={ref}
      />
    );
  },
);
