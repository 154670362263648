import { useEffect, useMemo, useState } from 'react';
import { Button, Form, Select, Input, Row, Col, Divider, Popover } from 'antd';
import FormItem from 'components/form/FormItem';
import { useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  phoneE164RegExp,
  onlyLettersNumbersRegExp,
  regularTextRegExp,
  onlySpacesRegExp,
  onlySpacesHyphensRegExp,
  onlyLettersAndSpecialCharsRegExp,
  validateOptionZip,
} from 'utils/validation.utils';
import {
  ExternalUserRole,
  ListingApi,
  AdminBrokerageShortDetail,
  MultimediaType,
  AdminUserDetail,
} from 'services/api';
import { UploadFileS3, ValidateFiles } from 'utils/file.utils';
import cx from 'classnames';

import styles from './NewUserPage.module.less';
import PhoneInput from 'components/form/PhoneInput';
import FileUpload, { getBase64 } from 'components/form/FileUpload';
import UploadImageItem from 'components/form/FileUpload/UploadImageItem';
import { ImageSize } from 'utils/image.utils';
import { displayErrorMessage, message } from 'components/message';
import ZipRender from 'components/ZipRender';
import { isArray } from 'lodash';
import { useAuth } from 'components/AuthProvider';

export enum NewUserPageModeEnum {
  Add,
  Edit,
}
type Props = {
  mode?: NewUserPageModeEnum; // default to Add
  defaultValues?: IFormValues; // required for Edit mode
  userDetails?: AdminUserDetail; // required for Edit mode
};
interface IFormValues {
  role: ExternalUserRole;
  brokerages: string | string[] | undefined;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  dre: string;
  title: string;
  service_area: string[] | undefined;
  photo: UploadFileS3[];
}
const { Option } = Select;
const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    role: yup.string().required(t('validation.required')),
    brokerages: yup
      .mixed()
      .required(t('validation.required'))
      .test('brokerage', t('validation.required'), (val) => {
        if (!val || val.length === 0) return false;
        return true;
      }),
    first_name: yup
      .string()
      .required(t('validation.required'))
      .max(
        30,
        t('validation.maxLength', {
          count: 30,
        }),
      )
      .matches(regularTextRegExp, t('validation.format'))
      .test('fNameTest', t('validation.format'), (val) => {
        return !val?.match(onlySpacesHyphensRegExp);
      }),
    last_name: yup
      .string()
      .required(t('validation.required'))
      .max(
        30,
        t('validation.maxLength', {
          count: 30,
        }),
      )
      .matches(regularTextRegExp, t('validation.format'))
      .test('lNameTest', t('validation.format'), (val) => {
        return !val?.match(onlySpacesHyphensRegExp);
      }),
    email: yup
      .string()
      .required(t('validation.required'))
      .email(t('validation.format'))
      .max(
        120,
        t('validation.maxLength', {
          count: 120,
        }),
      ),
    phone: yup
      .string()
      .required(t('validation.required'))
      .matches(phoneE164RegExp, t('NewUserPage.Phone.format')),
    dre: yup.string().when('role', {
      is: ExternalUserRole.LISTING_AGENT,
      then: yup
        .string()
        .required(t('validation.required'))
        .matches(onlyLettersNumbersRegExp, t('validation.format'))
        .max(
          12,
          t('validation.maxLength', {
            count: 12,
          }),
        ),
    }),
    title: yup.string().when('role', {
      is: ExternalUserRole.LISTING_AGENT,
      then: yup
        .string()
        .required(t('validation.required'))
        .max(
          30,
          t('validation.maxLength', {
            count: 30,
          }),
        )
        .matches(onlyLettersAndSpecialCharsRegExp, t('validation.format'))
        .test('titleTest', t('validation.format'), (val) => {
          return !val?.match(onlySpacesRegExp);
        }),
    }),

    service_area: yup.array().when('role', {
      is: ExternalUserRole.LISTING_AGENT,
      then: yup
        .array()
        .required(t('validation.required'))
        .min(1, t('validation.required'))
        .test(
          'isSingleServiceAreaLengthDigitsValid',
          t('validation.format'),
          (serviceArea: string[] | undefined) => {
            if (!serviceArea) return false;
            return serviceArea?.every(validateOptionZip);
          },
        )
        .max(
          200,
          t('NewUserPage.validation.maxLength', {
            count: 200,
          }),
        ),
    }),
    photo: yup
      .array()
      .min(1, t('validation.required'))
      .test('ValidateFiles', '', ValidateFiles),
  });

type UploaderButtonProps = {
  isMaxCountUploaded: boolean;
};

const getRoleOptions = (t: TFunction) => [
  {
    value: ExternalUserRole.BROKERAGE_ADMIN,
    label: t('NewUserPage.role.option.siteAdministrator'),
  },
  {
    value: ExternalUserRole.LISTING_AGENT,
    label: t('NewUserPage.role.option.agent'),
  },
];

const UploaderButton = ({ isMaxCountUploaded }: UploaderButtonProps) => {
  const { t } = useTranslation();

  if (isMaxCountUploaded) return null;

  return (
    <div>
      <i className="icon icon-upload"></i>
      <div>{t('FileUpload.Upload')}</div>
    </div>
  );
};

export default function NewUserPage(props: Props) {
  const { user } = useAuth();
  const [brokerageOptions, setBrokeragesOptions] = useState(
    [] as AdminBrokerageShortDetail[],
  );
  const { t } = useTranslation();
  const roleOptions = useMemo(() => getRoleOptions(t), [t]);

  const {
    mode = NewUserPageModeEnum.Add,
    defaultValues = {
      role: roleOptions[1].value,
      brokerages: undefined,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      dre: '',
      title: '',
      service_area: undefined,
      photo: [],
    },
    userDetails,
  } = props;
  const isAddMode = mode === NewUserPageModeEnum.Add;
  const navigate = useNavigate();
  const validationSchema = useMemo(() => {
    return getValidationSchema(t);
  }, [t]);
  const {
    control,
    handleSubmit,
    watch,
    setValue: setFormValue,
    formState: { isSubmitting },
  } = useForm<IFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const chosenRole = watch('role');

  const getFilteredOption = (
    input: string,
    option: { data: AdminBrokerageShortDetail } | undefined,
  ) => {
    return (
      (option?.data.market_center ?? '')
        .toLowerCase()
        .includes(input.toLowerCase()) ||
      (option?.data.name ?? '').toLowerCase().includes(input.toLowerCase())
    );
  };

  useEffect(() => {
    const loadBrokerages = async () => {
      try {
        if (isAddMode || user?.role === ExternalUserRole.SUPER_ADMIN) {
          const result = await ListingApi.getBrokeragesValues();
          setBrokeragesOptions(result);
        } else {
          setBrokeragesOptions(userDetails?.brokerages || []);
        }
        if (!isAddMode) {
          setFormValue(
            'brokerages',
            userDetails?.brokerages?.map((b) => b.id),
          );
        } else {
          if (brokerageOptions.length === 1) {
            setFormValue('brokerages', brokerageOptions[0]?.id);
          }
        }
      } catch (error) {
        displayErrorMessage({ error });
      }
    };
    loadBrokerages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAddMode) {
      setFormValue('brokerages', undefined);
      setFormValue('dre', '');
      setFormValue('title', '');
      setFormValue('service_area', undefined);
    }
  }, [isAddMode, chosenRole, setFormValue]);

  const isBrokerageDisabled =
    (user?.role === ExternalUserRole.BROKERAGE_ADMIN ||
      chosenRole === ExternalUserRole.LISTING_AGENT) &&
    !isAddMode;
  const getFormattedValues = (values: IFormValues) => {
    const { role, first_name, last_name, email, phone, title, dre } = values;
    const photo = values.photo[0].response?.s3Key as string;
    const brokerages = isArray(values.brokerages)
      ? values.brokerages
      : [values.brokerages || ''];
    const serviceArea = isArray(values.service_area)
      ? values.service_area
      : [values.service_area || ''];
    return {
      role,
      first_name,
      photo,
      email,
      brokerages,
      last_name,
      phone,
      title: title || undefined,
      dre: dre || undefined,
      service_area: serviceArea,
    };
  };
  const onSubmit = async (values: IFormValues) => {
    const userValues = getFormattedValues(values);
    try {
      if (!isAddMode) {
        await ListingApi.updateUser(userDetails?.id || '', userValues);
      } else {
        await ListingApi.createUser(userValues);
      }
      message.success({
        content: isAddMode
          ? t('NewUserPage.Add.Success')
          : t('NewUserPage.Edit.Success'),
      });
      navigate('/admins-and-agents');
    } catch (error: any) {
      if (error?.body?.message) {
        error.message = error.body.message;
      }
      displayErrorMessage({ error });
    }
  };

  return (
    <div className="layout-wrap">
      <div className={styles.wrap}>
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <div className="container">
            <div className={styles.backBtn}>
              <Link to="/admins-and-agents">
                <Button
                  className="btn-icon"
                  size="small"
                  icon={<i className="icon icon-arrow-left"></i>}
                ></Button>
              </Link>
            </div>
            <Row gutter={[32, 16]} align="middle">
              <Col xs={24} sm={12} md={16} xl={{ span: 14, offset: 2 }}>
                <h1 className="h4">
                  {isAddMode
                    ? t('NewUserPage.NewUser')
                    : `${userDetails?.first_name} ${userDetails?.last_name}`}
                </h1>
              </Col>
              <Col xs={24} sm={12} md={8} xl={6}>
                <div className={styles.btn}>
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {isAddMode
                      ? t('NewUserPage.Save')
                      : t('NewUserPage.Update')}
                  </Button>
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} xl={{ span: 20, offset: 2 }}>
                <Divider className="divider-light" />
              </Col>
              <Col xs={24} sm={12} md={16} xl={{ span: 14, offset: 2 }}>
                <div className="paper">
                  <div className={styles.formBox}>
                    <div className={styles.box}>
                      <h3 className="form-label">{t('NewUserPage.Realty')}</h3>
                      <FormItem
                        name="role"
                        control={control}
                        render={({ field }) => (
                          <Input.Group compact className="input-group">
                            <div className="prefix-label prefix-label--lg">
                              {t('NewUserPage.Role')}
                            </div>
                            <Select
                              disabled={!isAddMode}
                              size="large"
                              suffixIcon={
                                <i className="icon icon-caret-down"></i>
                              }
                              {...field}
                            >
                              {roleOptions.map((option) => (
                                <Option
                                  key={`role-option-${option.value}`}
                                  value={option.value}
                                >
                                  {option.label}
                                </Option>
                              ))}
                            </Select>
                          </Input.Group>
                        )}
                      />
                      <FormItem
                        name="brokerages"
                        control={control}
                        render={({ field }) => (
                          <Input.Group compact className="input-group">
                            <div className="prefix-label prefix-label--lg">
                              {t('NewUserPage.Brokerage')}
                            </div>
                            <Select<string, { data: AdminBrokerageShortDetail }>
                              className={styles.test}
                              disabled={isBrokerageDisabled}
                              filterOption={getFilteredOption}
                              showSearch
                              placeholder={t(
                                'NewUserPage.Brokerage.placeholder',
                              )}
                              mode={
                                chosenRole === ExternalUserRole.BROKERAGE_ADMIN
                                  ? 'multiple'
                                  : undefined
                              }
                              size="large"
                              suffixIcon={
                                <i className="icon icon-caret-down"></i>
                              }
                              {...field}
                            >
                              {brokerageOptions.map((option) => (
                                <Option
                                  key={`brokerage-option-${option.id}`}
                                  value={option.id}
                                  data={option}
                                  className={styles.option}
                                >
                                  <div>
                                    <strong className={styles.title}>
                                      {option.name}
                                    </strong>

                                    <div className={styles.subTitle}>
                                      {option.market_center}
                                    </div>
                                  </div>
                                </Option>
                              ))}
                            </Select>
                          </Input.Group>
                        )}
                      />
                      <h3 className="form-label">{t('NewUserPage.Profile')}</h3>
                      <FormItem
                        name="first_name"
                        control={control}
                        render={({ field }) => (
                          <Input.Group compact className="input-group">
                            <div className="prefix-label prefix-label--lg">
                              {t('NewUserPage.FirstName')}
                            </div>
                            <Input
                              placeholder={t(
                                'NewUserPage.FirstName.placeholder',
                              )}
                              size="large"
                              {...field}
                            />
                          </Input.Group>
                        )}
                      />
                      <FormItem
                        name="last_name"
                        control={control}
                        render={({ field }) => (
                          <Input.Group compact className="input-group">
                            <div className="prefix-label prefix-label--lg">
                              {t('NewUserPage.LastName')}
                            </div>
                            <Input
                              placeholder={t(
                                'NewUserPage.LastName.placeholder',
                              )}
                              size="large"
                              {...field}
                            />
                          </Input.Group>
                        )}
                      />
                      <FormItem
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <Input.Group compact className="input-group">
                            <div className="prefix-label prefix-label--lg">
                              {t('NewUserPage.Email')}
                            </div>
                            <Input
                              disabled={!isAddMode}
                              placeholder={t('NewUserPage.Email.placeholder')}
                              size="large"
                              {...field}
                            />
                          </Input.Group>
                        )}
                      />

                      <div className={styles.popoverWrap}>
                        <FormItem
                          name="phone"
                          control={control}
                          render={({ field }) => (
                            <Input.Group compact className="input-group">
                              <div className="prefix-label prefix-label--lg">
                                {t('NewUserPage.Phone')}
                              </div>
                              <PhoneInput
                                placeholder={t('NewUserPage.Phone.placeholder')}
                                size="large"
                                {...field}
                              />
                            </Input.Group>
                          )}
                        />

                        <Popover
                          getPopupContainer={() =>
                            document.getElementById('root') as HTMLElement
                          }
                          placement="right"
                          overlayClassName="popover-lg"
                          content={
                            <div className={styles.popoverContainer}>
                              {t('NewUserPage.Phone.popover')}
                            </div>
                          }
                        >
                          <i
                            className={cx(
                              styles.popoverOpener,
                              'icon icon-info',
                            )}
                          ></i>
                        </Popover>
                      </div>
                      {chosenRole === ExternalUserRole.LISTING_AGENT && (
                        <>
                          <FormItem
                            name="dre"
                            control={control}
                            render={({ field }) => (
                              <Input.Group compact className="input-group">
                                <div className="prefix-label prefix-label--lg">
                                  {t('NewUserPage.License')}
                                </div>
                                <Input
                                  placeholder={t(
                                    'NewUserPage.License.placeholder',
                                  )}
                                  size="large"
                                  {...field}
                                />
                              </Input.Group>
                            )}
                          />

                          <div className={styles.popoverWrap}>
                            <FormItem
                              name="title"
                              control={control}
                              render={({ field }) => (
                                <Input.Group compact className="input-group">
                                  <div className="prefix-label prefix-label--lg">
                                    {t('NewUserPage.Title')}
                                  </div>
                                  <Input
                                    placeholder={t(
                                      'NewUserPage.Title.placeholder',
                                    )}
                                    size="large"
                                    {...field}
                                  />
                                </Input.Group>
                              )}
                            />
                            <Popover
                              getPopupContainer={() =>
                                document.getElementById('root') as HTMLElement
                              }
                              placement="right"
                              overlayClassName="popover-lg"
                              content={
                                <div className={styles.popoverContainer}>
                                  {t('NewUserPage.Title.popover')}
                                </div>
                              }
                            >
                              <i
                                className={cx(
                                  styles.popoverOpener,
                                  'icon icon-info',
                                )}
                              ></i>
                            </Popover>
                          </div>
                          <FormItem
                            name="service_area"
                            control={control}
                            render={({ field }) => (
                              <Input.Group compact className="input-group">
                                <div className="prefix-label prefix-label--lg">
                                  {t('NewUserPage.ServiceArea')}
                                </div>
                                <Select
                                  size="large"
                                  mode="tags"
                                  placeholder={t(
                                    'NewUserPage.ServiceArea.placeholder',
                                  )}
                                  tagRender={ZipRender}
                                  suffixIcon={
                                    <i className="icon icon-caret-down"></i>
                                  }
                                  {...field}
                                />
                              </Input.Group>
                            )}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} xl={6}>
                <div className="paper">
                  <h3 className="form-label">
                    {t('NewUserPage.ProfilePicture')}
                  </h3>
                  <FormItem
                    className={'clear-shadow'}
                    name="photo"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FileUpload
                        className={styles.upload}
                        field={field}
                        error={fieldState.error}
                        acceptedFileFormat={['.png', '.gif', '.jpeg', '.jpg']}
                        acceptedFileSize={50}
                        listType="picture-card"
                        previewFile={getBase64}
                        bucket={MultimediaType.IMAGE}
                        itemRender={(...params) =>
                          UploadImageItem(...params, {
                            imageSize: ImageSize.NORMAL,
                          })
                        }
                        renderUploadButton={UploaderButton}
                      />
                    )}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
}
