/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ExternalBidOrderBy {
    CREATED_AT = 'created_at',
    _CREATED_AT = '-created_at',
}
