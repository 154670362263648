import { useCallback, useEffect, useRef } from 'react';
import { Button, notification } from 'antd';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { ArgsProps } from 'antd/lib/notification';

import { MobileScreenQuery } from 'utils/mediaQuery.utils';
import { message } from 'components/message';

import styles from './useNetworkNotificationHelper.module.less';

const NotificationBody = ({ children }: { children: JSX.Element }) => {
  const { t } = useTranslation();

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div>
      {children}
      <div className={styles.btnBox}>
        <Button
          type="primary"
          size="large"
          className="btn-secondary"
          onClick={handleReload}
        >
          {t('useNetworkNotificationHelper.Reload')}
        </Button>
      </div>
    </div>
  );
};

const openNotification = (params: ArgsProps) => {
  const isMobileScreen = window.matchMedia(MobileScreenQuery).matches;

  notification.open({
    duration: null,
    placement: isMobileScreen ? 'bottom' : 'topRight',
    closeIcon: <i className="icon icon-close"></i>,
    className: styles.notification,
    ...params,
  });
};

export const useConnectionStatusHelper = () => {
  const { t } = useTranslation();
  const intervalId = useRef<number>();
  const isOfflineStatusDisplayed = useRef(false);

  const offlineMessageKey = 'offline-message';
  const offlineNotificationKey = 'offline-notification';

  const onNotificationClose = useCallback(() => {
    message.error({
      content: t('useNetworkNotificationHelper.offline.message'),
      duration: 0,
      icon: <i className="icon icon-link-broken"></i>,
      className: styles.errorMessage,
      key: offlineMessageKey,
    });
  }, [t]);

  const onOffline = useCallback(() => {
    if (isOfflineStatusDisplayed.current) return;

    openNotification({
      message: <h3>{t('useNetworkNotificationHelper.offline.title')}</h3>,
      description: (
        <NotificationBody>
          <>
            <div>{t('useNetworkNotificationHelper.offline.description1')}</div>
            <div className={styles.accent}>
              {t('useNetworkNotificationHelper.offline.description2')}
            </div>
          </>
        </NotificationBody>
      ),
      key: offlineNotificationKey,
      onClose: onNotificationClose,
    });

    isOfflineStatusDisplayed.current = true;
    clearInterval(intervalId.current);
  }, [t, onNotificationClose]);

  useEffect(() => {
    window.addEventListener('offline', onOffline);

    return () => window.removeEventListener('offline', onOffline);
  }, [t, onOffline]);

  useEffect(() => {
    const sendNetworkRequest = async () => {
      try {
        await axios.get('/network.json', {
          params: { t: Date.now() },
        });
      } catch {
        onOffline();
      }
    };

    intervalId.current = window.setInterval(sendNetworkRequest, 30000);
    return () => clearInterval(intervalId.current);
  }, [onOffline]);

  useEffect(() => {
    return () => {
      notification.close(offlineNotificationKey);
      message.destroy(offlineMessageKey);
    };
  }, []);

  return null;
};

export const useMobileVisibilityHelper = () => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!isMobile) return;

    const onVisibilityChange = () => {
      if (document.hidden) {
        openNotification({
          message: (
            <h3>{t('useNetworkNotificationHelper.visibilitychange.title')}</h3>
          ),
          description: (
            <NotificationBody>
              <div>
                {t('useNetworkNotificationHelper.visibilitychange.description')}
              </div>
            </NotificationBody>
          ),
          key: 'visibilitychange-notification',
        });
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () =>
      document.removeEventListener('visibilitychange', onVisibilityChange);
  }, [t]);

  return null;
};

const useNetworkNotificationHelper = () => {
  useConnectionStatusHelper();
  useMobileVisibilityHelper();

  return null;
};

export default useNetworkNotificationHelper;
