import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from 'services/store';
import Layout from 'components/layout/Layout';
import Localizer from 'components/Localizer';
import { AuthProvider } from 'components/AuthProvider';
import { AppSettingsProvider } from 'components/AppSettingsProvider';

import './styles/index.less';

function App() {
  return (
    <BrowserRouter>
      <AppSettingsProvider>
        <AuthProvider>
          <Provider store={store}>
            <Localizer>
              <Layout />
            </Localizer>
          </Provider>
        </AuthProvider>
      </AppSettingsProvider>
    </BrowserRouter>
  );
}

export default App;
