import { useMemo } from 'react';
import { Button, SpinProps, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { TFunction, useTranslation } from 'react-i18next';

import Spin from 'components/Spin';
import { AdminBrokerageList } from 'services/api';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './BrokerageTable.module.less';

const getColumns = (t: TFunction): ColumnsType<AdminBrokerageList> => [
  {
    key: 'name',
    title: t('Dashboard.BrokeragesManagement.Table.Name'),
    render: (_, { id, name }) => (
      <Link to={`edit-brokerage/${id}`}>{name}</Link>
    ),
  },
  {
    key: 'market_center',
    title: t('Dashboard.BrokeragesManagement.Table.MarketCenter'),
    render: (_, { market_center }) => (
      <div className="ws-nw">{market_center}</div>
    ),
  },
  {
    key: 'office_address',
    title: t('Dashboard.BrokeragesManagement.Table.Address'),
    render: (_, { office_address, city }) => (
      <div>{`${office_address}, ${city}`}</div>
    ),
  },
  {
    key: 'state',
    title: t('Dashboard.BrokeragesManagement.Table.State'),
    render: (_, { state }) => <div className="ws-nw">{state}</div>,
  },
  {
    key: 'subdomain',
    title: t('Dashboard.BrokeragesManagement.Table.Link'),
    render: (_, { link }) => (
      <div className={cx('ws-nw', styles.link)}>
        <a href={link} target="_blank" rel="noreferrer">
          <Button type="link" size="small" className="with-icon">
            {link}
            <i className="icon icon-arrow-up-right"></i>
          </Button>
        </a>
      </div>
    ),
  },
];

type Props = {
  isLoading: boolean;
  data?: AdminBrokerageList[];
};

export default function BrokerageTable({ data, isLoading }: Props) {
  const { t } = useTranslation();
  const columns = useMemo(() => getColumns(t), [t]);

  const tableLoading: SpinProps = {
    spinning: isLoading,
    indicator: <Spin />,
  };

  return (
    <Table
      scroll={{ x: '100%' }}
      dataSource={data}
      columns={columns}
      rowKey="id"
      loading={tableLoading}
      pagination={false}
    />
  );
}
