import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Form, Input, Radio, Space } from 'antd';
import { TFunction, useTranslation } from 'react-i18next';
import cx from 'classnames';

import FormItem from 'components/form/FormItem';
import {
  ExternalListingList,
  MultimediaType,
  ApprovalUserStatus,
  ListingApi,
} from 'services/api';
import { UploadFileS3, ValidateFiles } from 'utils/file.utils';
import { onlySpacesRegExp } from 'utils/validation.utils';
import FileUpload from 'components/form/FileUpload';
import Checkbox from 'components/form/Checkbox';
import { useAuth } from 'components/AuthProvider';

import styles from './GetApprovedDataForm.module.less';
import { ModalScrollWrapper, ModalFooterWrapper } from 'components/Modal';

interface IFormValues {
  nickname: string;
  buyerStatus: ApprovalUserStatus | null;
  dre: string;
  documents: UploadFileS3[];
  agreement: boolean;
}

const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    nickname: yup
      .string()
      .required(t('validation.required'))
      .test(
        'nicknameTest',
        t('validation.format'),
        (nickname) => !nickname?.match(onlySpacesRegExp),
      )
      .min(3, t('validation.format'))
      .max(
        12,
        t('validation.maxLength', {
          count: 12,
        }),
      ),
    buyerStatus: yup
      .string()
      .nullable()
      .required(t('GetApprovedDataForm.BuyerStatus.required')),
    dre: yup.string(),
    documents: yup
      .array()
      .min(1, t('GetApprovedDataForm.DocumentRequired'))
      .test('ValidateFiles', '', ValidateFiles),
    agreement: yup
      .boolean()
      .oneOf([true], t('GetApprovedDataForm.AgreementRequired')),
  });

type Props = {
  listing: ExternalListingList;
  onClose: () => void;
  onSuccess: () => void;
};

export default function GetApprovedDataForm(props: Props) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { listing, onClose, onSuccess } = props;
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IFormValues>({
    mode: 'onTouched',
    shouldUnregister: true,
    resolver: yupResolver(validationSchema),
    defaultValues: {
      nickname: '',
      buyerStatus: null,
      dre: '',
      documents: [],
      agreement: false,
    },
  });
  const buyerStatusValue = watch('buyerStatus');

  const onSubmit = async (values: IFormValues) => {
    const { buyerStatus, documents, dre, nickname } = values;

    if (!buyerStatus || !user) return;

    try {
      await ListingApi.sendApprovalRequest({
        listingId: listing.id,
        data: {
          nickname,
          status: buyerStatus,
          user_id: user.user_id,
          dre,
          files: documents.map((doc) => doc.response?.s3Key as string),
        },
      });

      onSuccess();
    } catch {}
  };

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
      <ModalScrollWrapper>
        <h3 className="modal__label">
          {t('GetApprovedDataForm.BiddingNickname')}
        </h3>
        <FormItem
          name="nickname"
          control={control}
          render={({ field }) => (
            <Input
              placeholder={t('GetApprovedDataForm.BiddingNickname')}
              size="large"
              {...field}
            />
          )}
        />
        <h3 className="modal__label m-0">
          {t('GetApprovedDataForm.ConfirmStatus')}
        </h3>
        <FormItem
          className="clear-shadow"
          name="buyerStatus"
          control={control}
          render={({ field }) => (
            <Radio.Group {...field}>
              <Space direction="vertical">
                <Radio value={ApprovalUserStatus.BUYER_WITHOUT_AGENT}>
                  {t('GetApprovedDataForm.BuyerStatus.BuyerWithoutAgent')}
                </Radio>
                <Radio value={ApprovalUserStatus.BUYER_WITH_AGENT}>
                  {t('GetApprovedDataForm.BuyerStatus.BuyerWithAgent')}
                </Radio>
                <Radio value={ApprovalUserStatus.AGENT}>
                  {t('GetApprovedDataForm.BuyerStatus.Agent')}
                </Radio>
              </Space>
            </Radio.Group>
          )}
        />
        {buyerStatusValue === ApprovalUserStatus.AGENT && (
          <FormItem
            name="dre"
            control={control}
            render={({ field }) => (
              <Input
                placeholder={t('GetApprovedDataForm.DRELicenseNumber')}
                size="large"
                {...field}
              />
            )}
          />
        )}
        <h3 className="modal__label m-0">
          {t('GetApprovedDataForm.SubmitPreapproval')}
        </h3>
        <div className={styles.uploadHint}>
          {t('GetApprovedDataForm.FileFormats')}
        </div>
        <FormItem
          className="clear-shadow"
          name="documents"
          control={control}
          render={({ field, fieldState }) => (
            <FileUpload
              maxCount={2}
              multiple
              field={field}
              error={fieldState.error}
              bucket={MultimediaType.DOCUMENT}
              acceptedFileFormat={['.doc', '.docx', '.pdf', '.xls', '.xlsx']}
              acceptedFileSize={20}
              onPreview={undefined}
              renderUploadButton={({ isMaxCountSuccessfullyUploaded }) => (
                <Button
                  size="large"
                  type="primary"
                  className="btn-secondary with-icon"
                  icon={<i className="icon icon-upload"></i>}
                  disabled={isMaxCountSuccessfullyUploaded}
                >
                  {isMaxCountSuccessfullyUploaded
                    ? t('FileUpload.Uploaded')
                    : t('FileUpload.Upload')}
                </Button>
              )}
            />
          )}
        />
        <div className={styles.info}>
          {t('GetApprovedDataForm.SecureInformation')}
        </div>
        <FormItem
          className={cx('clear-shadow', styles.checkbox)}
          name="agreement"
          control={control}
          render={({ field }) => (
            <>
              <Checkbox {...field}>
                <div className="modal__label m-0">
                  {t('GetApprovedDataForm.agreement.checkbox')}
                </div>
              </Checkbox>
              <div className={styles.checkboxText}>
                {t('GetApprovedDataForm.agreement.info')}
              </div>
            </>
          )}
        />
      </ModalScrollWrapper>
      <ModalFooterWrapper>
        <div>
          <Button
            onClick={onClose}
            type="link"
            className="custom-link"
            size="large"
          >
            {t('GetApprovedDataForm.Cancel')}
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            loading={isSubmitting}
            htmlType="submit"
            size="large"
          >
            {t('GetApprovedDataForm.GetApproved')}
          </Button>
        </div>
      </ModalFooterWrapper>
    </Form>
  );
}
