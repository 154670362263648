import { useTranslation } from 'react-i18next';
import { ExternalUserDetail } from 'services/api';

import { getImageUrl, ImageSize } from 'utils/image.utils';

import styles from './AgentCard.module.less';

type Props = {
  agent?: ExternalUserDetail;
  className?: string;
};

export default function AgentCard(props: Props) {
  const { t } = useTranslation();
  const { className = '', agent } = props;
  if (!agent) return null;
  const { first_name, last_name, dre, phone, email, photo, brokerage_name } =
    agent;

  return (
    <div className={className}>
      <div className={styles.contactHead}>
        <div className={styles.contactHeadImg}>
          {!!photo && (
            <img src={getImageUrl(photo, ImageSize.EXTRA_SMALL)} alt="" />
          )}
        </div>
        <div className={styles.contactHeadInfo}>
          <div className={styles.contactHeadName}>
            {first_name} {last_name}
          </div>
          {!!brokerage_name && (
            <div className={styles.contactHeadCompany}>{brokerage_name}</div>
          )}
          {!!dre && (
            <div className={styles.contactHeadLicense}>
              {t('AgentCard.DRE')} {dre}
            </div>
          )}
        </div>
      </div>
      <div className={styles.contactTitle}>{t('AgentCard.TextOrCall')}</div>
      <ul className={styles.contactList}>
        <li>
          <a href={`tel:${phone}`}>
            <i className="icon icon-phone"></i>
            {phone}
          </a>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href={`mailto:${email}`}>
            <i className="icon icon-mail"></i>
            {email}
          </a>
        </li>
      </ul>
    </div>
  );
}
