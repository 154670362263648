import { useMemo } from 'react';
import { Badge, Button, Space, SpinProps, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { TFunction, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Spin from 'components/Spin';
import { getFormattedAddress } from 'utils/location.utils';
import ListingStatusLabel from 'components/ListingStatusLabel';
import {
  AdminListingList,
  ExternalUserRole,
  ListingStatus,
} from 'services/api';
import { getFormattedNumber } from 'utils/number.utils';
import { dateTimeFormat } from 'utils/date.utils';
import {
  getBidManagementLink,
  getTimeAgoFormattedValue,
} from './listingsTable.utils';
import { AuthorizedUserModel } from 'services/models/AuthorizedUser.model';

import styles from './ListingsTable.module.less';
import { useAuth } from 'components/AuthProvider';

const dashPlaceholder = <span className={styles.dash}>&#8212;</span>;

const getEndsInEndedDate = (
  t: TFunction,
  status?: ListingStatus,
  ends_at?: string,
) => {
  if (!ends_at || !status) return dashPlaceholder;

  if (status === ListingStatus.ACTIVE) {
    return getTimeAgoFormattedValue(ends_at, t);
  } else if (
    status === ListingStatus.ENDED ||
    status === ListingStatus.PENDING ||
    status === ListingStatus.SOLD
  ) {
    return moment(ends_at).format(dateTimeFormat);
  }
  return dashPlaceholder;
};

const getColumns = (
  t: TFunction,
  user: AuthorizedUserModel | null,
): ColumnsType<AdminListingList> => {
  let columns: ColumnsType<AdminListingList> = [
    {
      key: 'address',
      title: t('Dashboard.ListingsManagement.Table.Address'),
      render: (_, { id, address }) => (
        <div className={styles.address}>
          <Link className={styles.addressLink} to={`edit-listing/${id}`}>
            {getFormattedAddress(address)}
          </Link>
        </div>
      ),
    },
    {
      key: 'market_center',
      title: t('Dashboard.ListingsManagement.Table.MarketCenter'),

      render: (_, { brokerage }) => <div>{brokerage?.market_center}</div>,
    },
    {
      key: 'brokerage',
      title: t('Dashboard.ListingsManagement.Table.Brokerage'),
      render: (_, { brokerage }) => (
        <div className={styles.brokerage}>{brokerage?.name}</div>
      ),
    },
    {
      key: 'agent',
      title: t('Dashboard.ListingsManagement.Table.ListingAgent'),
      render: (_, { user }) => (
        <div
          className={styles.agent}
        >{`${user?.first_name} ${user?.last_name}`}</div>
      ),
    },
    {
      key: 'date_published',
      title: t('Dashboard.ListingsManagement.Table.DatePublished'),
      render: (_, { status, published_date }) => (
        <span>
          {status !== ListingStatus.DRAFT && published_date
            ? moment(published_date).format(dateTimeFormat)
            : dashPlaceholder}
        </span>
      ),
    },
    {
      key: 'status',
      title: t('Dashboard.ListingsManagement.Table.Status'),
      render: (_, { status }) =>
        status && <ListingStatusLabel status={status} />,
    },
    {
      key: 'ends_at',
      title: t('Dashboard.ListingsManagement.Table.EndsInEnded'),
      render: (_, { ends_at, status }) => (
        <span>{getEndsInEndedDate(t, status, ends_at)}</span>
      ),
    },
    {
      key: 'views',
      render: (_, { views }) => (
        <div className={styles.views}>
          <i className="icon icon-eye" />
          {getFormattedNumber(views as number)}
        </div>
      ),
      className: styles.noSeparate,
    },
    {
      key: 'pending',
      render: (_, { id, pending_requests }) => (
        <>
          <Link to={getBidManagementLink(id, pending_requests)}>
            <Button type="link" size="small">
              <Space size={10}>
                {t('Dashboard.ListingsManagement.Table.Manage')}
                {!!pending_requests && <Badge count={pending_requests} />}
              </Space>
            </Button>
          </Link>
        </>
      ),
      className: styles.last,
    },
  ];
  if (user?.role !== ExternalUserRole.SUPER_ADMIN) {
    columns = columns.filter((item) => {
      return item.key !== 'market_center' && item.key !== 'brokerage';
    });
  }

  return columns;
};

type Props = {
  isLoading: boolean;
  data?: AdminListingList[];
};

export default function ListingsTable({ data, isLoading }: Props) {
  const { user } = useAuth();
  const { t } = useTranslation();
  const columns = useMemo(() => getColumns(t, user), [t, user]);

  const tableLoading: SpinProps = {
    spinning: isLoading,
    indicator: <Spin />,
  };

  return (
    <Table
      scroll={{ x: '100%' }}
      className={styles.table}
      dataSource={data}
      columns={columns}
      rowKey="id"
      loading={tableLoading}
      pagination={false}
    />
  );
}
