import { useTranslation } from 'react-i18next';

import ListingCard from 'components/cards/ListingCard';
import { ExternalListingResponse } from 'services/api';
import ListingListNotFound from '../ListingListNotFound';

import styles from './ListingList.module.less';

type Props = {
  listingsData?: ExternalListingResponse;
  query?: string;
};

export default function ListingList(props: Props) {
  const { t } = useTranslation();
  const { listingsData, query } = props;

  return (
    <div>
      {listingsData && !listingsData.total && (
        <ListingListNotFound query={query} />
      )}
      {!!listingsData?.total && (
        <>
          {query && (
            <div className={styles.counter}>
              {t('ListingList.found', {
                count: listingsData.total,
              })}{' '}
              {query}
            </div>
          )}
          <div className="grid-wrapper">
            {listingsData.results?.map((listing) => (
              <ListingCard key={listing.id} listing={listing} />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
