import { useMemo, useState } from 'react';
import { Button, Modal, Form, InputNumber, Input } from 'antd';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { AdminListingDetail } from 'services/api';
import { ModalScrollWrapper, ModalFooterWrapper } from 'components/Modal';
import { getCurrencySign } from 'utils/price.utils';
import { getFormattedNumber, numberFormatter } from 'utils/number.utils';
import FormItem from 'components/form/FormItem';

import styles from './SoldPriceModal.module.less';

const priceLimit = 999999999;
const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    soldPrice: yup
      .number()
      .nullable()
      .required(t('validation.required'))
      .min(0, t('validation.format'))
      .max(
        priceLimit,
        t('SoldPriceModal.ReachedPriceLimit', {
          limit: getFormattedNumber(priceLimit),
        }),
      ),
  });

interface IFormValues {
  soldPrice: number | null;
}

type Props = {
  listing: AdminListingDetail;
  onClose: () => void;
  onSuccess: (soldPrice: number) => void;
};

export default function SoldPriceModal(props: Props) {
  const { t } = useTranslation();
  const { listing, onClose, onSuccess } = props;
  const { initial_price, pending_price, sold_price, bids, buyers_premium } =
    listing;
  const isPriceSuggested = !sold_price;
  const listingCurrentPrice = bids?.[0]?.price.value || initial_price!.value;
  const suggestedPrice = Math.ceil(
    listingCurrentPrice + (buyers_premium! / 100) * listingCurrentPrice,
  );
  const formDefaultPrice = isPriceSuggested
    ? pending_price?.value || suggestedPrice
    : sold_price.value;

  const currencySign = getCurrencySign(initial_price!.currency);
  const [isEditMode, setIsEditMode] = useState(false);
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const { control, handleSubmit } = useForm<IFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      soldPrice: formDefaultPrice,
    },
  });

  const onSubmit = async (values: IFormValues) => {
    onSuccess(values.soldPrice!);
  };

  return (
    <Modal
      title={null}
      visible
      centered
      destroyOnClose
      maskClosable={false}
      onCancel={onClose}
      footer={null}
      width={550}
      closable={false}
    >
      <h2 className="modal__title">{t('SoldPriceModal.title')}</h2>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
        <Button
          icon={<i className="icon icon-close"></i>}
          onClick={onClose}
          className="btn-icon modal__close"
          size="small"
        ></Button>

        <ModalScrollWrapper>
          <div className={styles.content}>
            <div className={styles.description}>
              {t('SoldPriceModal.description')}
            </div>

            <div>
              <div className={styles.price}>
                <strong>{currencySign}</strong>
                {getFormattedNumber(formDefaultPrice)}
              </div>

              <div className={styles.priceLabel}>
                {t(
                  isPriceSuggested
                    ? 'SoldPriceModal.SuggestedPrice'
                    : 'SoldPriceModal.SoldPrice',
                )}
              </div>

              <div>
                {isEditMode && (
                  <div className={styles.formBox}>
                    <FormItem
                      control={control}
                      name="soldPrice"
                      render={({ field }) => (
                        <Input.Group compact className="input-group">
                          <div className="prefix-label prefix-label--lg">
                            {t('SoldPriceModal.input.SoldPrice')}
                          </div>
                          <InputNumber
                            size="large"
                            addonBefore={currencySign}
                            addonAfter={
                              initial_price!.currency?.toUpperCase() || 'USD'
                            }
                            placeholder={t('SoldPriceModal.input.placeholder')}
                            controls={false}
                            formatter={numberFormatter}
                            precision={0}
                            {...field}
                          />
                        </Input.Group>
                      )}
                    />
                  </div>
                )}
                {!isEditMode && (
                  <div className={styles.btnBox}>
                    <Button
                      icon={<i className="icon icon-edit"></i>}
                      type="link"
                      className="with-icon"
                      size="small"
                      onClick={() => setIsEditMode(true)}
                    >
                      {t('SoldPriceModal.ChangePrice')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalScrollWrapper>

        <ModalFooterWrapper>
          <div>
            <Button
              className="custom-link"
              onClick={onClose}
              size="large"
              type="link"
            >
              {t('SoldPriceModal.Cancel')}
            </Button>
          </div>

          <div>
            <Button type="primary" htmlType="submit" size="large">
              {t('SoldPriceModal.Confirm')}
            </Button>
          </div>
        </ModalFooterWrapper>
      </Form>
    </Modal>
  );
}
