import { isUndefined } from 'lodash';
import { ExternalUserRole } from 'services/api';

export const hasUserRequiredRole = (
  acceptableRoles: ExternalUserRole[],
  userRole?: ExternalUserRole,
): boolean => {
  // when user is not authorized the role equal undefined
  if (isUndefined(userRole)) return false;

  return acceptableRoles.includes(userRole);
};
