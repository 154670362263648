import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { AdminUserResponse, ExternalUserRole } from 'services/api';

import styles from './AdminsAndAgentsFilter.module.less';

export type AdminsAndAgentsFilterByOption = ExternalUserRole[];

interface IAdminsAndAgentsFilterBy {
  ALL: AdminsAndAgentsFilterByOption;
  BROKERAGE_ADMIN: AdminsAndAgentsFilterByOption;
  LISTING_AGENT: AdminsAndAgentsFilterByOption;
}

export const AdminsAndAgentsFilterBy: IAdminsAndAgentsFilterBy = {
  ALL: [ExternalUserRole.BROKERAGE_ADMIN, ExternalUserRole.LISTING_AGENT],
  BROKERAGE_ADMIN: [ExternalUserRole.BROKERAGE_ADMIN],
  LISTING_AGENT: [ExternalUserRole.LISTING_AGENT],
};

type Props = {
  isLoading: boolean;
  filter: AdminsAndAgentsFilterByOption;
  usersData: AdminUserResponse;
  onChange: (value: AdminsAndAgentsFilterByOption) => void;
};

export default function AdminsAndAgentsFilter(props: Props) {
  const { t } = useTranslation();
  const {
    isLoading,
    filter: selectedFilter,
    usersData: {
      total_by_roles: { listing_agent, brokerage_admin },
    },
    onChange,
  } = props;
  const totalAll = listing_agent + brokerage_admin;

  return (
    <ul className={styles.filter}>
      <li>
        <div
          className={cx(styles.filterItem, {
            [styles.disabled]: isLoading,
            [styles.active]: selectedFilter === AdminsAndAgentsFilterBy.ALL,
          })}
          onClick={() => onChange(AdminsAndAgentsFilterBy.ALL)}
        >
          <span>{t('AdminsAndAgentsFilter.All')} </span>
          <span className={styles.number}>({totalAll})</span>
        </div>
      </li>
      <li>
        <div
          className={cx(styles.filterItem, {
            [styles.disabled]: isLoading,
            [styles.active]:
              selectedFilter === AdminsAndAgentsFilterBy.BROKERAGE_ADMIN,
          })}
          onClick={() => onChange(AdminsAndAgentsFilterBy.BROKERAGE_ADMIN)}
        >
          <span>{t('AdminsAndAgentsFilter.SiteAdministrators')} </span>
          <span className={styles.number}>({brokerage_admin})</span>
        </div>
      </li>
      <li>
        <div
          className={cx(styles.filterItem, {
            [styles.disabled]: isLoading,
            [styles.active]:
              selectedFilter === AdminsAndAgentsFilterBy.LISTING_AGENT,
          })}
          onClick={() => onChange(AdminsAndAgentsFilterBy.LISTING_AGENT)}
        >
          <span>{t('AdminsAndAgentsFilter.Agents')} </span>
          <span className={styles.number}>({listing_agent})</span>
        </div>
      </li>
    </ul>
  );
}
