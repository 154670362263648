import { TFunction } from 'react-i18next';
import { ListingStatus } from 'services/api';

export const getLabelByStatus = (t: TFunction, status?: ListingStatus) => {
  switch (status) {
    case ListingStatus.ACTIVE:
      return t('BidManagement.Tabs.AllBids.Table.Date.CurrentBid');
    case ListingStatus.ENDED:
    case ListingStatus.WITHDRAWN:
      return t('BidManagement.Tabs.AllBids.Table.Date.LastBid');
    case ListingStatus.PENDING:
    case ListingStatus.SOLD:
      return t('BidManagement.Tabs.AllBids.Table.Date.FinalBid');

    default:
      return null;
  }
};
