import { Button, Col, Row, Table } from 'antd';
import { BlockDivider } from 'components/BlockDivider';
import cx from 'classnames';

import styles from '../PrivacyPolicyPage.module.less';

export default function PrivacyPolicyPageEn() {
  const dataSource = [
    {
      key: '1',
      category: [
        'Identifiers.',
        ' We collect this information from our users, customers, and business partners.  We also generate identifiers internally',
      ],
      howToUse:
        'To enable use of our sites, services, and products, to communicate with you, to understand how our users interact with our sites, and to improve our offerings',
      typeIndividualsAffected:
        'People who use our site or mobile application; create a user account; list a property for sale or place a bid on a property; send us an inquiry about buying or selling a property; purchase advertising from us, or obtain a service from us',
      howWeShare:
        'We share this data with our service providers and with business partners, including those to whom you instruct us to send this information',
    },
    {
      key: '2',
      category: [
        'Personal information under ',
        'California Civil Code section 1798.80.',
        'https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=1798.80.&lawCode=CIV',
        ' We collect this information from our users, customers, and business partners',
      ],
      howToUse:
        'To enable use of our sites, services, and products, to communicate with you, to understand how our users interact with our sites, and to improve our offerings',
      typeIndividualsAffected:
        'People who create a user account; list a property for sale or place a bid on a property; send us an inquiry about buying or selling a property; purchase advertising from us, or obtain a service from us',
      howWeShare:
        'We share this data with our service providers and with business partners, including those to whom you instruct us to send this information',
    },
    {
      key: '3',
      category: ['Protected classifications'],
      howToUse: 'We don’t collect this data',
      typeIndividualsAffected: 'None',
      howWeShare: 'None',
    },
    {
      key: '4',
      category: [
        'Commercial information. ',
        'We collect this information from our customers, and generate it internally during transactions with our customers',
      ],
      howToUse:
        'To transact with you if you use our services, and to enable transactions you’ve requested',
      typeIndividualsAffected:
        'People who create a user account; list a property for sale or place a bid on a property; send us an inquiry about buying or selling a property; purchase advertising from us, or obtain a service from us',
      howWeShare:
        'We share this data with our service providers and with business partners, including those to whom you instruct us to send this information',
    },
    {
      key: '5',
      category: ['Biometric information '],
      howToUse: 'We don’t collect this data',
      typeIndividualsAffected: 'None',
      howWeShare: 'None',
    },
    {
      key: '6',
      category: [
        'Electronic network activity information. ',
        'We collect this information from our users and customers',
      ],
      howToUse:
        'To enable use of our sites, services, and products, to communicate with you, to understand how our users interact with our sites, and to improve our offerings',
      typeIndividualsAffected: 'People who use our site or mobile application',
      howWeShare:
        'We share this data with our service providers and with business partners, including those to whom you instruct us to send this information',
    },
    {
      key: '7',
      category: [
        'Geolocation data. ',
        'We collect this information from our users and customers',
      ],
      howToUse:
        'To enable use of our site and service, to understand how our users interact with our site, and to improve our offerings',
      typeIndividualsAffected:
        'People who use our site or mobile application and allow us to collect their location data',
      howWeShare: 'We share this data with our service providers',
    },
    {
      key: '8',
      category: [
        'Audio, electronic, visual, thermal, olfactory, or similar information. ',
        'We collect this information from our users and customers',
      ],
      howToUse:
        'To identify you, and to enable use of our site and services, and for safety purposes',
      typeIndividualsAffected: 'People who call us',
      howWeShare: 'We share this data with our service providers',
    },
    {
      key: '9',
      category: ['Professional or employment-related information'],
      howToUse: 'We don’t collect this data',
      typeIndividualsAffected: 'None',
      howWeShare: 'None',
    },
    {
      key: '10',
      category: ['Education information '],
      howToUse: 'We don’t collect this data',
      typeIndividualsAffected: 'None',
      howWeShare: 'None',
    },
    {
      key: '11',
      category: ['Inferences. ', 'We generate these internally'],
      howToUse:
        'To understand how our users interact with our sites, to develop and offer more relevant and useful services and products, and to improve the products and services we offer',
      typeIndividualsAffected:
        'People who use the Website or mobile application, including users who search for properties, list properties for sale, or place bids on properties through the Website',
      howWeShare: 'We don’t share',
    },
  ];

  const columns = [
    {
      title: 'Category and sources of Information',
      dataIndex: 'category',
      key: 'category',
      render: (text: Array<string>) => {
        if (text.length === 1) {
          return <b>{text[0]}</b>;
        } else if (text.length === 2) {
          return (
            <>
              <b>{text[0]}</b>
              <span>{text[1]}</span>
            </>
          );
        } else if (text.length === 4) {
          return (
            <>
              <b>{text[0]}</b>
              <a href={text[2]} target="blank">
                {text[1]}
              </a>
              <span>{text[3]}</span>
            </>
          );
        }
      },
    },
    {
      title: 'How we use it',
      dataIndex: 'howToUse',
      key: 'howToUse',
    },
    {
      title: 'Types of individuals affected',
      dataIndex: 'typeIndividualsAffected',
      key: 'typeIndividualsAffected',
    },
    {
      title: 'How we share it',
      dataIndex: 'howWeShare',
      key: 'howWeShare',
    },
  ];

  return (
    <div className={styles.bgWrap}>
      <div className="container">
        <div className={styles.heroSection}>
          <h2 className="h2">Privacy Policy</h2>
          <h5 className="h5">www.bidthisproperty.com</h5>
          <h5 className="h6">LAST REVISED ON JANUARY 13, 2023</h5>
        </div>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Introduction</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                Fairy Pond Holdings, Inc. (hereinafter “FAIRY”) owns and
                operates the BidThisProperty.com (hereinafter “BID THIS
                PROPERTY”) website (the “Website”), which is hosted in the
                United States. We want you, the visitor (“you” or “your”) to
                this Website, to understand how we collect, use and share data
                about you. This Privacy Policy identifies what information we
                collect, and for what purposes it is used. We recognize the
                importance of our visitors’ privacy. We believe that privacy is
                an issue of trust and strive to manage your personal information
                in a fair and respectful manner. This policy describes:
              </p>
              <ul className="default-list">
                <li>How and why we collect your personal information</li>
                <li>How we use and safeguard your information</li>
                <li>When and with whom we share your information</li>
                <li>
                  The choices you can make about how we collect, use, and share
                  your information
                </li>
              </ul>
              <p>
                This Privacy Policy applies to customer information obtained by
                the Website in connection with its operations in the United
                States and its territories, and applies generally to the BID
                THIS PROPERTY website and content and other related websites
                where this policy appears in the footer of the page. The policy
                that applies on any of our domains or subdomains is always the
                policy that appears in the footer of each website.
              </p>
              <p>
                Our subsidiaries’ and joint ventures’ privacy practices are
                intended to be similar to, if not identical to, those described
                in this Privacy Policy. By accepting the Privacy Policy and the
                Terms and Conditions of Service in registration, you expressly
                consent to our collection, storage, use and disclosure of your
                personal information as described in this Privacy Policy.
              </p>
              <p>
                This Website may contain links to other sites on the Internet
                that are owned and operated by third parties. This Privacy
                Policy does not cover the information practices of those
                websites linked to our Website. We encourage you to be aware
                when you leave our Website and to read the privacy statements of
                each website that may collect your personal information.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Information We May Collect</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                You can browse our sites without telling us who you are or
                revealing any personal information about yourself. In choosing
                to provide us with any personal identifying information, you
                consent to the transfer and storage of that information on
                servers located in the United States.
              </p>
              <p>
                We may collect and store personal information about you to
                deliver the products and services you request and to help
                improve your experience on the Website. We limit the amount of
                personal information collected to that required to support our
                specific purpose in requesting it. The following are some
                examples of the types of information we may collect from you:
              </p>
              <ul className="default-list">
                <li>
                  email address, physical contact information (such as name,
                  phone number, postal code and physical or mailing address),
                  particularly if you register and use the services provided by
                  the Website or choose to receive marketing-related email
                  updates from us, or our affiliates;
                </li>
                <li>
                  log files, device identification, and other information
                  collected through automatic data collection technologies,
                  described below;
                </li>
                <li>
                  financial information, such as bank account numbers,
                  statements, or loan pre-approval status, but only if you
                  voluntarily provide that information to place a bid on a
                  property;
                </li>
                <li>
                  activity information, such as your property search history,
                  properties you view, purchase and/or sales activity, what
                  you’ve clicked on and other uses of our features, the amount
                  of time you spend looking at different parts of our websites,
                  and any items or content you generate or that relates to your
                  account;
                </li>
                <li>
                  if you call, text or email us, we may collect data about that
                  contact, including the date and time of the contact, your
                  phone number or email address, and the content of the
                  communication;
                </li>
                <li>
                  if you enable location services on your mobile device, we may
                  collect the location of your device. In that instance, we will
                  use that information to provide you with location-based
                  information and ads, such as properties available in your
                  area;
                </li>
                <li>
                  other information from your interaction with our sites,
                  services, content and advertising, including computer and
                  connection information, statistics on page views, traffic to
                  and from the sites, ad data, IP address and standard web log
                  information;
                </li>
                <li>
                  additional information we may ask you to submit to
                  authenticate yourself if you seek to list a property for sale,
                  place a bid on a property or if we believe you are violating
                  Website policies (for example, we may ask you to provide
                  identification verification confirm your contact information
                  address, or to answer additional questions online to help
                  verify your identity or ownership of a property you list);
                </li>
                <li>
                  information from other companies, such as demographic and
                  navigation data; and other supplemental information from third
                  parties (for example, if you incur a debt to BID THIS
                  PROPERTY.com, we will generally conduct a credit check by
                  obtaining additional information about you from a credit
                  bureau, as permitted by law).
                </li>
              </ul>
            </Col>
          </Row>
        </section>
        <BlockDivider />

        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">
                Information <br /> We Collect Through Automatic Data Collection
                Technologies
              </h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                As you navigate through and interact with our Website, we may
                use automatic data collection technologies to collect certain
                information about your equipment, browsing actions, and
                patterns, including:
              </p>
              <ul className="default-list">
                <li>
                  Details of your visits to our Website, including traffic data,
                  location data, logs, and other communication data and the
                  resources that you access and use on the Website.
                </li>
                <li>
                  Information about your computer and internet connection,
                  including your IP address, operating system, and browser type.
                </li>
              </ul>

              <p>
                We also may use these technologies to collect information about
                your online activities over time and across third-party websites
                or other online services (behavioral tracking).
              </p>
              <p>
                The information we collect automatically does not include
                personal information, but we may maintain it or associate it
                with personal information we collect in other ways or receive
                from third parties. It helps us to improve our Website and to
                deliver a better and more personalized service, including by
                enabling us to:
              </p>
              <ul className="default-list">
                <li>Estimate our audience size and usage patterns.</li>
                <li>
                  Store information about your preferences, allowing us to
                  customize our Website according to your individual interests.
                </li>
                <li>Speed up your searches.</li>
                <li>Recognize you when you return to our Website.</li>
              </ul>
              <p>
                The technologies we use for this automatic data collection may
                include:
              </p>
              <ul className="default-list">
                <li>
                  Cookies (or browser cookies). A cookie is a small file placed
                  on the hard drive of your computer. You may refuse to accept
                  browser cookies by activating the appropriate setting on your
                  browser. However, if you select this setting you may be unable
                  to access certain parts of our Website. Unless you have
                  adjusted your browser setting so that it will refuse cookies,
                  our system will issue cookies when you direct your browser to
                  our Website.
                </li>
                <li>
                  Flash Cookies. Certain features of our Website may use local
                  stored objects (or Flash cookies) to collect and store
                  information about your preferences and navigation to, from,
                  and on our Website. Flash cookies are not managed by the same
                  browser settings as are used for browser cookies.
                </li>
                <li>
                  Web Beacons. Pages of the Website and our e-mails may contain
                  small electronic files known as web beacons (also referred to
                  as clear gifs, pixel tags, and single-pixel gifs) that permit
                  BID THIS PROPERTY, for example, to count users who have
                  visited those pages or opened an email and for other related
                  website statistics (for example, recording the popularity of
                  certain website content and verifying system and server
                  integrity).
                </li>
              </ul>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Third-Party Use of Cookies</h2>
              <h5 className="h5">and Other Tracking Technologies</h5>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                Some content or applications, including advertisements, on the
                Website are served by third-parties, including advertisers, ad
                networks and servers, content providers, and application
                providers. These third parties may use cookies alone or in
                conjunction with web beacons or other tracking technologies to
                collect information about you when you use our website. The
                information they collect may be associated with your personal
                information or they may collect information, including personal
                information, about your online activities over time and across
                different websites and other online services. They may use this
                information to provide you with interest-based (behavioral)
                advertising or other targeted content.
              </p>
              <p>
                We do not control these third parties' tracking technologies or
                how they may be used. If you have any questions about an
                advertisement or other targeted content, you should contact the
                responsible provider directly.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Marketing</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                We don’t sell or rent your personal information to third parties
                for their marketing purposes without your explicit consent. We
                may combine your information with information we collect from
                other companies and use it to improve and personalize our
                services, content and advertising. If you don’t wish to receive
                marketing communications from us or participate in our
                ad-customization programs, simply indicate your preference in My
                Account or by following the directions provided with the
                communication or advertisement.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Our Use</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                We may use your information to offer and provide you with
                products and services and to support our core business
                functions. These include the purchase and/or sale process,
                internal business processes, marketing, authentication, fraud
                prevention and other correlated functions. Our collection of
                your information is intended to provide you with a safe, smooth,
                efficient, and customized experience. You agree that we may use
                your personal information to:
              </p>
              <ul className="default-list">
                <li>
                  To fulfill your requests for products and services and
                  communicate with you about those requests;
                </li>
                <li>
                  resolve disputes, collect fees, and troubleshoot problems;
                </li>
                <li>To register and service your account;</li>
                <li>To provide customer service;</li>
                <li>
                  To help us improve and customize our service offerings,
                  websites and marketing;
                </li>
                <li>
                  To send you information about our products, services and
                  promotions;
                </li>
                <li>
                  To compare information for accuracy, and verify it with third
                  parties;
                </li>
                <li>
                  To protect the security or integrity of the site and our
                  business, including the prevention of potentially prohibited
                  or illegal activities, and
                </li>
                <li>To enforce our Terms and Conditions of Service.</li>
              </ul>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Our Disclosure of Your Information</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                We may disclose personal information to respond to legal
                requirements, enforce our policies, respond to claims that a
                listing or other content violates the rights of others, or
                protect anyone’s rights, property, or safety. Such information
                will be disclosed in accordance with applicable laws and
                regulations.
              </p>
              <p>We may also share your personal information with:</p>
              <ul className="default-list">
                <li>
                  Service providers under contract who help with our business
                  operations (such as fraud investigations and bill collection).
                </li>
                <li>
                  Other third parties to whom you explicitly ask us to send your
                  information (or about whom you are otherwise explicitly
                  notified and consent to when using a specific service). For
                  example, if you place a bid on a property, the listing agent
                  for that property will have access to your identifying
                  information and a copy of any documentation you have uploaded
                  to the Website.
                </li>
                <li>
                  Law enforcement or other governmental officials, in response
                  to a subpoena or other authorized legal process, if we have a
                  good faith belief that disclosure is necessary to (i)
                  Investigate, prevent or take action regarding suspected or
                  actual illegal activities or to assist government enforcement
                  agencies; (ii) Enforce this Privacy Policy, investigate and
                  defend ourselves against any third-party claims or
                  allegations, or protect the security or integrity of our site;
                  and/or (iii) Exercise or protect the rights, property or
                  personal safety of FAIRY, BID THIS PROPERTY, our users,
                  employees, agents or others.
                </li>
                <li>
                  Other business entities, as part of reorganization, merger or
                  a sale of the assets of FAIRY, BID THIS PROPERTY, or its
                  subsidiaries or divisions. Any third party acquiring the
                  assets of the Website will have the right to continue to use
                  the personal and other information that you provide to us, in
                  compliance with this policy, unless you are notified to the
                  contrary.
                </li>
              </ul>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Information You Share on</h2>
              <h5 className="h5">BID THIS PROPERTY.com</h5>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                Your Display Nick Name is displayed throughout BID THIS
                PROPERTY.com (and so available to the public), and is connected
                to all of your BID THIS PROPERTY.com activity. Other Users can
                see your bids, purchases, properties listed for sale, and
                associated comments. Notices sent to other community members
                about suspicious activity and policy violations on our sites
                refer to Display Names and specific items. So if you associate
                your name with your Display Name, the people to whom you have
                revealed your name will be able to personally identify your BID
                THIS PROPERTY.com activities.
              </p>
              <p>
                If you access our sites from a shared computer or a computer in
                an internet café, certain information about you, such as your
                Display Name, activity or reminders from BID THIS PROPERTY, may
                also be visible to other individuals who use the computer after
                you.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Using Information from</h2>
              <h5 className="h5">BID THIS PROPERTY.com</h5>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                BID THIS PROPERTY enables you to share personal and financial
                information to commence transactions. We encourage you to
                disclose your privacy practices and respect the privacy of other
                users. We cannot guarantee the privacy or security of your
                information and therefore we encourage you to evaluate the
                privacy and security policies of your trading partner before
                entering into a transaction and choosing to share your
                information. To help protect your privacy, we allow only limited
                access to your contact and financial information to facilitate
                your transactions. When users are involved in a transaction,
                they may have access to each other’s name, Display Name, email
                address and other contact information through the means of BID
                THIS PROPERTY.com.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Cookies</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                We use “cookies” (small files placed on your hard drive) on
                certain of our pages to help analyze our web page flow;
                customize our services, content and advertising; measure
                promotional effectiveness, and promote trust and safety.
              </p>
              <p>
                A few important things you should know about cookies are that:
              </p>
              <ul className="default-list">
                <li>
                  We offer certain features that are available only through the
                  use of cookies.
                </li>
                <li>
                  We use cookies to help identify you and maintain your
                  signed-in status.
                </li>
                <li>
                  Most cookies are “session cookies,” meaning that they are
                  automatically deleted from your hard drive at the end of a
                  session.
                </li>
                <li>
                  You are always free to decline our cookies if your browser
                  permits, although doing so may interfere with your use of some
                  of our sites or services.
                </li>
                <li>
                  You may encounter cookies from third parties on certain pages
                  of the sites that we do not control (for example, if you view
                  a web page created by another user, there may be a cookie
                  placed by that web page).
                </li>
              </ul>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">No Spam, Spyware or Spoofing</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                We and our users do not tolerate spam. Make sure to set your BID
                THIS PROPERTY communication preferences so we communicate to you
                as you prefer. You are not licensed to add other BID THIS
                PROPERTY users, even a user who has purchased an item from you,
                to your mailing list (email or physical mail) without their
                express consent. To report BID THIS PROPERTY-related spam or
                spoof emails to BID THIS PROPERTY, please forward the email to{' '}
                <Button className="custom-link" type="link">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`mailto:support@bidthisproperty.com`}
                  >
                    support@bidthisproperty.com
                  </a>
                </Button>{' '}
                You may not use our communication tools to send spam or
                otherwise send content that would violate our Terms and
                Conditions of Service. We automatically scan and may manually
                filter messages to check for spam, viruses, phishing attacks and
                other malicious activity or illegal or prohibited content, but
                we do not permanently store messages sent through these tools.
              </p>
            </Col>
          </Row>
        </section>

        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Account Protection</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                Your password is the key to your account. Use unique numbers,
                letters and special characters, and do not disclose your BID
                THIS PROPERTY password to anyone. If you do share your password
                or your personal information with others, remember that you are
                responsible for all actions taken in the name of your account.
                If you lose control of your password, you may lose substantial
                control over your personal information. Therefore, if your
                password has been compromised for any reason, you should
                immediately notify BID THIS PROPERTY and change your password.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />

        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">
                Accessing, Reviewing and Changing Your Personal Information
              </h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                You can see, review and change most of your personal information
                by signing on to BID THIS PROPERTY. Generally, we will not
                manually modify your personal information because it is very
                difficult to verify your identity remotely. You must promptly
                update your personal information if it changes or is inaccurate.
                Once you make a public posting, you may not be able to change or
                remove it. Upon your request, we will close your account and
                remove your personal information from view as soon as reasonably
                possible, based on your account activity and in accordance with
                applicable law. We do retain personal information from closed
                accounts to comply with law, prevent fraud, collect any fees
                owed, resolve disputes, troubleshoot problems, assist with any
                investigations, enforce our BID THIS PROPERTY User’s Agreement,
                and take other actions otherwise permitted by law.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Security</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                Your information is stored on servers located in the United
                States. We treat data as an asset that must be protected and use
                lots of tools (encryption, passwords, physical security, etc.)
                to protect your personal information against unauthorized access
                and disclosure. However, as you probably know, third parties may
                unlawfully intercept or access transmissions or private
                communications, and other users may abuse or misuse your
                personal information that they collect from the Website.
                Therefore, although we work very hard to protect your privacy,
                we do not promise, and you should not expect, that your personal
                information or private communications will always remain
                private.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Third Parties</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                Except as otherwise expressly included in this Privacy Policy,
                this document addresses only the use and disclosure of
                information we collect from you. If you disclose your
                information to others, whether they are bidders, buyers or
                sellers on our sites or other sites throughout the internet,
                different rules may apply to their use or disclosure of the
                information you disclose to them. BID THIS PROPERTY.com does not
                control the privacy policies of third parties, and you are
                subject to the privacy policies of those third parties where
                applicable. We encourage you to ask questions before you
                disclose your personal information to others.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <div className={styles.generalSection}>
          <h2 className="h2">General</h2>
        </div>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Children and Minors</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                Our Website is not intended for children or minors under 18
                years of age. No one under age 18 may create an account or
                provide any information to or on the Website. We do not
                knowingly collect personal information from anyone under 18. If
                you are under 18, do not use or provide any information on this
                Website or through any of its features, register on the Website,
                use any of the interactive or public comment features of this
                Website, or provide any information about yourself to us,
                including your name, address, telephone number, email address,
                or any screen name or user name you may use. If we learn we have
                collected or received personal information from a minor, we will
                delete that information. If you believe we might have any
                information from or about a minor, please contact us at{' '}
                <Button className="custom-link" type="link">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`mailto:support@bidthisproperty.com`}
                  >
                    support@bidthisproperty.com
                  </a>
                </Button>
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">
                Choices About How We Use and Disclose Your Information
              </h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                We strive to provide you with choices regarding the personal
                information you provide to us. We have created mechanisms to
                provide you with the following control over your information:
              </p>
              <ul className="default-list">
                <li>
                  Tracking Technologies and Advertising. You can set your
                  browser to refuse all or some browser cookies, or to alert you
                  when cookies are being sent. If you disable or refuse cookies,
                  please note that some parts of this Website may then be
                  inaccessible or not function properly.
                </li>
                <li>
                  Disclosure of Your Information for Third-Party Advertising. If
                  you do not want us to share your personal information with
                  unaffiliated or non-agent third parties for promotional
                  purposes, you can opt-out sending us an email with your
                  request to{' '}
                  <Button className="custom-link" type="link">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:support@bidthisproperty.com`}
                    >
                      support@bidthisproperty.com
                    </a>
                  </Button>
                </li>
                <li>
                  Promotional Offers from BID THIS PROPERTY. If you do not wish
                  to have your contact information used by BID THIS PROPERTY to
                  promote our own or third parties' products or services, you
                  can opt-out by sending us an email stating your request to{' '}
                  <Button className="custom-link" type="link">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:support@bidthisproperty.com`}
                    >
                      support@bidthisproperty.com
                    </a>
                  </Button>
                </li>
                <li>
                  If we have sent you a promotional email, you may send us a
                  return email asking to be omitted from future email
                  distributions.
                </li>
                <li>
                  Targeted Advertising. If you do not want us to use information
                  that we collect or that you provide to us to deliver
                  advertisements according to our advertisers' target-audience
                  preferences, you can opt-out by sending us an email stating
                  your request to{' '}
                  <Button className="custom-link" type="link">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:support@bidthisproperty.com`}
                    >
                      support@bidthisproperty.com
                    </a>
                  </Button>
                </li>
              </ul>
              <p>
                We do not control third parties' collection or use of your
                information to serve interest-based advertising. However these
                third parties may provide you with ways to choose not to have
                your information collected or used in this way. You can opt out
                of receiving targeted ads from members of the Network
                Advertising Initiative ("NAI") on the NAI's website.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Accessing and Correcting Your Information</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                You can review and change your personal information by logging
                into the Website and visiting your account profile page.
              </p>
              <p>
                You may also send us an email at{' '}
                <Button className="custom-link" type="link">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`mailto:support@bidthisproperty.com`}
                  >
                    support@bidthisproperty.com
                  </a>
                </Button>{' '}
                to request access to, correct or delete any personal information
                that you have provided to us. We cannot delete your personal
                information except by also deleting your user account. We may
                not accommodate a request to change information if we believe
                the change would violate any law or legal requirement or cause
                the information to be incorrect.
              </p>
              <p>
                If you delete your User Contributions from the Website, copies
                of your User Contributions may remain viewable in cached and
                archived pages, or might have been copied or stored by other
                Website users. Proper access and use of information provided on
                the Website, including User Contributions, is governed by our
                Terms and Conditions of Service.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Data Security</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                We have implemented measures designed to secure your personal
                information from accidental loss and from unauthorized access,
                use, alteration, and disclosure. All information you provide to
                us is stored on our secure servers behind firewalls.
              </p>
              <p>
                The safety and security of your information also depends on you.
                Where we have given you (or where you have chosen) a password
                for access to certain parts of our Website, you are responsible
                for keeping this password confidential. We ask you not to share
                your password with anyone. We urge you to be careful about
                giving out information in public areas of the Website like
                message boards. The information you share in public areas may be
                viewed by any user of the Website.
              </p>
              <p>
                Unfortunately, the transmission of information via the internet
                is not completely secure. Although we do our best to protect
                your personal information, we cannot guarantee the security of
                your personal information transmitted to our Website. Any
                transmission of personal information is at your own risk. We are
                not responsible for circumvention of any privacy settings or
                security measures contained on the Website.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />

        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Changes to Our Privacy Policy</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                It is our policy to post any changes we make to our privacy
                policy on this page If we make material changes to how we treat
                our users' personal information, we will notify you by email to
                the email address specified in your account and/or through a
                notice on the Website home page. The date the privacy policy was
                last revised is identified at the top of the page. You are
                responsible for ensuring we have an up-to-date active and
                deliverable email address for you, and for periodically visiting
                our Website and this privacy policy to check for any changes.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Legal Disclosures</h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <p>
                This section contains some legally required disclosures under
                California privacy laws. Under the California Consumer Privacy
                Act or CCPA, California residents have certain rights regarding
                their data, including:
              </p>
              <ul className="default-list">
                <li>
                  The right to know the categories of personal information we’ve
                  collected and the categories of sources from which we got the
                  information
                </li>
                <li>
                  The right to know the business purposes for sharing personal
                  information
                </li>
                <li>
                  The right to know the categories of third parties with whom
                  we’ve shared personal information
                </li>
                <li>
                  The right to access the specific pieces of personal
                  information we’ve collected and the right to delete your
                  information
                </li>
              </ul>
              <p>
                California residents also have the right to not be discriminated
                against if they choose to exercise their privacy rights.
              </p>
            </Col>
          </Row>
        </section>
        <BlockDivider />
        <section>
          <Row gutter={24}>
            <Col xs={24} xl={{ span: 20, offset: 2 }}>
              <h2 className={cx('h3', styles.title)}>
                Categories of Personal Information under the California Consumer
                Privacy Act
              </h2>

              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                scroll={{
                  x: '100%',
                }}
              />
            </Col>
          </Row>
        </section>
        <section className={styles.contactSection}>
          <Row gutter={24}>
            <Col xs={24} xl={{ span: 6, offset: 2 }}>
              <h2 className="h3">Contact Information</h2>
            </Col>
            <Col xs={24} lg={14}>
              <p>
                To ask questions or comment about this privacy policy and our
                privacy practices, contact us at:
              </p>
              <ul>
                <li>
                  <strong>BID THIS PROPERTY</strong>
                </li>
                <li>
                  <address>
                    <strong>
                      696 San Ramon Valley Blvd, Suite 326 <br />
                      Danville, CA 94526
                    </strong>
                  </address>
                </li>
                <li></li>
                <li>
                  <strong>or via our toll-free number: (855)243-7767</strong>
                </li>
                <li>
                  To register a complaint or concern, please contact us by
                  e-mail at{' '}
                  <Button className="custom-link" type="link">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:support@bidthisproperty.com`}
                    >
                      support@bidthisproperty.com
                    </a>
                  </Button>
                </li>
              </ul>
            </Col>
          </Row>
        </section>
      </div>
    </div>
  );
}
