import { Col, Divider, Row } from 'antd';

export const BlockDivider = () => {
  return (
    <Row gutter={16}>
      <Col xs={24} xl={{ span: 20, offset: 2 }}>
        <Divider />
      </Col>
    </Row>
  );
};
