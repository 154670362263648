/* eslint-disable react/jsx-pascal-case */
import { Empty as $Empty, EmptyProps as $EmptyProps } from 'antd';

import styles from './Empty.module.less';

import emptyImg from 'assets/images/empty.svg';

// may be extended by $EmptyProps & {};
export type EmptyProps = $EmptyProps;

export default function Empty(props: EmptyProps) {
  return <$Empty className={styles.wrap} image={emptyImg} {...props} />;
}
