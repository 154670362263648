import { useEffect, useState } from 'react';

import { Empty } from 'antd';
import ListingCard from 'components/cards/ListingCard';
import Spin from 'components/Spin';
import { useTranslation } from 'react-i18next';
import {
  ExternalListingOrderBy,
  ExternalListingResponse,
  ListingApi,
} from 'services/api';
import styles from './ListingListNotFound.module.less';

type Props = {
  query?: string;
};

export default function ListingListNotFound(props: Props) {
  const { query } = props;
  const { t } = useTranslation();
  const [listingsData, setListingsData] = useState<ExternalListingResponse>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const result = await ListingApi.getListingList({
          query: '',
          pageSize: 12,
          page: 1,
          order: ExternalListingOrderBy._VIEWS,
        });

        setListingsData(result);
      } catch (error) {
        setListingsData({
          total: 0,
          page: 1,
          page_size: 12,
          results: [],
        });
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  return (
    <>
      <div className={styles.emptyMessage}>
        <h4 className="h5">
          {t('ListingListNotFound.NothingFound')}{' '}
          {query && (
            <span>
              {t('ListingListNotFound.in')} {query}
            </span>
          )}
        </h4>
        <div className={styles.subtitle}>
          {t('ListingListNotFound.YouMayLike')}
        </div>
      </div>
      <div>
        <Spin spinning={isLoading}>
          {listingsData && !listingsData.results?.length && <Empty />}
          {!!listingsData?.results?.length && (
            <div className="grid-wrapper">
              {listingsData.results.map((listing) => (
                <ListingCard key={listing.id} listing={listing} />
              ))}
            </div>
          )}
        </Spin>
      </div>
    </>
  );
}
