import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider, Carousel } from 'antd';

import ContactUsSection from 'components/ContactUsSection';
import HowToBuyFAQSection from '../HowToBuyFAQSection';
import InfoBox from 'components/InfoBox';

import imgHow from 'assets/images/howToBuy/imageMain.webp';
import autoBidAction from 'assets/images/howToBuy/autoBidAction.webp';
import gettingApproved from 'assets/images/howToBuy/gettingApproved.webp';
import gettingStarted1 from 'assets/images/howToBuy/gettingStarted1.webp';
import gettingStarted2 from 'assets/images/howToBuy/gettingStarted2.webp';
import lastSecondBidders1 from 'assets/images/howToBuy/lastSecondBidders1.webp';
import lastSecondBidders2 from 'assets/images/howToBuy/lastSecondBidders2.webp';

import styles from './HowToBuyPage.module.less';
import { isArray } from 'lodash';

type IHowToBuySlideProps = {
  slides: IHowToBuySlide[];
};
interface IHowToBuySlide {
  imageUrl: string;
}
const gettingStartedSlides: IHowToBuySlide[] = [
  {
    imageUrl: gettingStarted1,
  },
  {
    imageUrl: gettingStarted2,
  },
];
const lastSecondBiddersSlides: IHowToBuySlide[] = [
  {
    imageUrl: lastSecondBidders1,
  },
  {
    imageUrl: lastSecondBidders2,
  },
];

const CarouselHowToBuy = (props: IHowToBuySlideProps) => {
  const { slides } = props;
  return (
    <Carousel className={styles.imageWrap}>
      {slides.map((slide: IHowToBuySlide, index: number) => (
        <div key={`slide-${index}`}>
          <div className={styles.image}>
            <img src={slide.imageUrl} alt="" />
          </div>
        </div>
      ))}
    </Carousel>
  );
};

interface BaseWrapperProps {
  children: ReactNode;
}

const BlockWrapper = ({ children }: BaseWrapperProps) => {
  return (
    <Col xs={{ span: 24, order: 1 }} md={{ span: 12, offset: 2, order: 0 }}>
      {children}
    </Col>
  );
};

const BlockDivider = () => {
  return (
    <Row gutter={16}>
      <Col xs={24} xl={{ span: 20, offset: 2 }}>
        <Divider />
      </Col>
    </Row>
  );
};

export default function HowToBuyPage() {
  const { t } = useTranslation();

  const AboutApprovalDescription = t<string, string[]>(
    'HowToBuyPage.GettingApproved.AboutApproval.description',
    {
      returnObjects: true,
    },
  );
  // fix for sonar incorrect duplication calculation
  const GettingStarted = t('HowToBuyPage.GettingStarted');
  const GettingApproved = t('HowToBuyPage.GettingApproved');
  const GettingApprovedByListingAgentInfo = t(
    'HowToBuyPage.GettingApproved.GetThatListingAgent.info',
  );
  const AutoBidAction = t('HowToBuyPage.AutoBidAction');

  return (
    <div className={styles.bgWrap}>
      <div className="container">
        <Row gutter={16} align="middle">
          <Col xs={24} md={9} xl={{ span: 6, offset: 2 }}>
            <h2 className="h2 m-0">
              <span>{t('HowToBuyPage.YouLookReady')}</span>
              <div>{t('HowToBuyPage.HeresNow')}</div>
            </h2>
          </Col>
          <Col xs={24} md={15} xl={16}>
            <div className={styles.imgBox}>
              <img src={imgHow} alt="" />
            </div>
          </Col>
          <Col
            xs={24}
            md={{ span: 15, offset: 9 }}
            lg={{ span: 14, offset: 8 }}
          >
            <div>
              <div>{t('HowToBuyPage.YouLookReady.description1')}</div>
              <div>{t('HowToBuyPage.YouLookReady.description2')}</div>
              <div>{t('HowToBuyPage.YouLookReady.description3')}</div>
            </div>
          </Col>
          <Col xs={24} xl={{ span: 20, offset: 2 }}>
            <Divider />
          </Col>
        </Row>

        <section className={styles.indentBox}>
          <Row gutter={16}>
            <BlockWrapper>
              <h2 className="h2">{GettingStarted}</h2>
              <div>
                <h3 className="h5">
                  {t('HowToBuyPage.GettingStarted.GetSignIn')}
                </h3>
                <div>
                  <div>{t('HowToBuyPage.GettingStarted.GetSignIn.info1')}</div>
                  <div>{t('HowToBuyPage.GettingStarted.GetSignIn.info2')}</div>
                  <div></div>
                </div>
              </div>

              <Divider />

              <div>
                <h3 className="h5">
                  {t('HowToBuyPage.GettingStarted.AgentsWhoRep')}
                </h3>
                <div>
                  <div>
                    {t('HowToBuyPage.GettingStarted.AgentsWhoRep.info1')}
                  </div>
                  <div>
                    {t('HowToBuyPage.GettingStarted.AgentsWhoRep.info2')}
                  </div>
                </div>
              </div>

              <Divider />

              <div>
                <h3 className="h5">
                  {t('HowToBuyPage.GettingStarted.GetYourselfApproved')}
                </h3>
                <div>
                  <div>
                    {t('HowToBuyPage.GettingStarted.GetYourselfApproved.info')}
                  </div>
                </div>
              </div>
            </BlockWrapper>
            <Col xs={24} md={8}>
              <CarouselHowToBuy slides={gettingStartedSlides} />
            </Col>
          </Row>
        </section>

        <BlockDivider />

        <section className={styles.indentBox}>
          <Row gutter={20}>
            <BlockWrapper>
              <h2 className="h2">{GettingApproved}</h2>
              <div>
                <h3 className="h5">
                  {t('HowToBuyPage.GettingApproved.GetThatListingAgent')}
                </h3>
                <div>{GettingApprovedByListingAgentInfo}</div>
              </div>
              <Divider />
              <div>
                <h3 className="h5">
                  {t('HowToBuyPage.GettingApproved.AboutApproval.title')}
                </h3>
                <ul className="default-list">
                  {isArray(AboutApprovalDescription) &&
                    AboutApprovalDescription.map((point, index) => (
                      <li key={`point-${index}`}>{point}</li>
                    ))}
                </ul>
              </div>
            </BlockWrapper>
            <Col xs={24} md={8}>
              <div className={styles.imageWrap}>
                <div className={styles.image}>
                  <img src={gettingApproved} alt="" />
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <BlockDivider />

        <section className={styles.indentBox}>
          <Row gutter={20}>
            <BlockWrapper>
              <h2 className="h2">{AutoBidAction}</h2>
              <div>{t('HowToBuyPage.AutoBidAction.info')}</div>
              <Divider />
              <h3 className="h5">
                {t('HowToBuyPage.AutoBidAction.HereWhatItLooksLike.title')}
              </h3>
              <div>
                {t('HowToBuyPage.AutoBidAction.HereWhatItLooksLike.info')}
              </div>
            </BlockWrapper>
            <Col xs={24} md={8}>
              <div className={styles.imageWrap}>
                <div className={styles.image}>
                  <img src={autoBidAction} alt="" />
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <BlockDivider />

        <section className={styles.indentBox}>
          <Row gutter={20}>
            <BlockWrapper>
              <h2 className="h2">{t('HowToBuyPage.LastSecondBidders')}</h2>
              <div>{t('HowToBuyPage.LastSecondBidders.info')}</div>
              <Divider />
              <InfoBox
                icon={<i className="icon icon-info"></i>}
                title={t('HowToBuyPage.LastSecondBidders.box.title')}
                text={t('HowToBuyPage.LastSecondBidders.box.info')}
              />
            </BlockWrapper>
            <Col xs={24} md={8}>
              <CarouselHowToBuy slides={lastSecondBiddersSlides} />
            </Col>
          </Row>
        </section>

        <BlockDivider />

        <section className={styles.indentBox}>
          <Row gutter={16}>
            <Col xs={24} md={9} lg={{ span: 6, offset: 2 }}>
              <h2 className="h2 alert">
                {t('HowToBuyPage.HeadsUpHighestBidder')}
              </h2>
            </Col>
            <Col xs={24} md={15} lg={14}>
              <h3 className="h5">
                {t('HowToBuyPage.HeadsUpHighestBidder.title')}
              </h3>

              <InfoBox
                icon={<i className="icon icon-info"></i>}
                title={t('HowToBuyPage.HeadsUpHighestBidder.box.title')}
                text={t('HowToBuyPage.HeadsUpHighestBidder.box.info')}
              />
            </Col>
          </Row>
        </section>

        <BlockDivider />

        <section className={styles.indentBox}>
          <HowToBuyFAQSection />
        </section>

        <BlockDivider />

        <section className={styles.contactSection}>
          <ContactUsSection />
        </section>
      </div>
    </div>
  );
}
