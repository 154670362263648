import { useMemo, useState } from 'react';

import { Button, Col, Row, Tabs } from 'antd';
import message, { displayErrorMessage } from 'components/message';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ListingApi, AdminBrokerageDetail } from 'services/api';

import { yupResolver } from '@hookform/resolvers/yup';

import NewBrokerageBasicTab, {
  getBasicValidationSchema,
  IBasicFormValues,
} from './NewBrokerageBasicTab';
import NewBrokerageCompanyTab, {
  getCompanyValidationSchema,
  ICompanyFormValues,
} from './NewBrokerageCompanyTab';
import NewBrokerageCustomTab, {
  getCustomValidationSchema,
  ICustomFormValues,
} from './NewBrokerageCustomTab';
import styles from './NewBrokeragePage.module.less';
import { scrollTop } from 'utils/scrollTop.utils';

type Props = {
  mode?: NewBrokeragesPageModeEnum; // default to Add
  defaultValues?: IBasicFormValues & ICompanyFormValues & ICustomFormValues; // required for Edit mode
  brokerage?: AdminBrokerageDetail; // required for Edit mode
};
enum NewBrokeragePageTabsEnum {
  Basic = 'basic',
  Company = 'company',
  Custom = 'custom',
}
export enum NewBrokeragesPageModeEnum {
  Add,
  Edit,
}
export default function NewBrokeragePage(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    mode = NewBrokeragesPageModeEnum.Add,
    defaultValues = {
      name: '',
      market_center: '',
      brokerage_site_name: '',
      subdomain: '',
      color_logo: [],
      grey_logo: [],
      white_logo: [],
      favicon: [],
      office_address: '',
      office_phone_number: '',
      office_email: '',
      city: '',
      state: '',
      zip: '',
      linkedin_profile: '',
      facebook_profile: '',
      dre: '',
      home_page: {
        headline: '',
        image: [],
      },
      listing_page: {
        headline: '',
      },
      about_us_page: {
        images: [],
        section_1_headline: '',
        section_2_headline: '',
        section_1_subtitle: '',
        section_2_subtitle: '',
        section_1_body: '',
        section_2_body: '',
        section_3_body: '',
        section_3_image: [],
      },
    },
    brokerage,
  } = props;
  const isEditMode = mode === NewBrokeragesPageModeEnum.Edit;
  const basicValidationSchema = useMemo(() => getBasicValidationSchema(t), [t]);
  const basicFormMethods = useForm<IBasicFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(basicValidationSchema),
    defaultValues: {
      name: defaultValues.name,
      market_center: defaultValues.market_center,
      brokerage_site_name: defaultValues.brokerage_site_name,
      subdomain: defaultValues.subdomain,
    },
  });
  const companyValidationSchema = useMemo(
    () => getCompanyValidationSchema(t),
    [t],
  );
  const companyFormMethods = useForm<ICompanyFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(companyValidationSchema),
    defaultValues: {
      color_logo: defaultValues.color_logo,
      grey_logo: defaultValues.grey_logo,
      white_logo: defaultValues.white_logo,
      favicon: defaultValues.favicon,
      office_address: defaultValues.office_address,
      office_phone_number: defaultValues.office_phone_number,
      office_email: defaultValues.office_email,
      city: defaultValues.city,
      state: defaultValues.state,
      zip: defaultValues.zip,
      linkedin_profile: defaultValues.linkedin_profile,
      facebook_profile: defaultValues.facebook_profile,
      dre: defaultValues.dre,
    },
  });
  const customValidationSchema = useMemo(
    () => getCustomValidationSchema(t),
    [t],
  );
  const customFormMethods = useForm<ICustomFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(customValidationSchema),
    defaultValues: {
      home_page: {
        headline: defaultValues.home_page.headline,
        image: defaultValues.home_page.image,
      },
      listing_page: {
        headline: defaultValues.listing_page.headline,
      },
      about_us_page: {
        images: defaultValues.about_us_page.images,
        section_1_headline: defaultValues.about_us_page.section_1_headline,
        section_2_headline: defaultValues.about_us_page.section_2_headline,
        section_1_subtitle: defaultValues.about_us_page.section_1_subtitle,
        section_2_subtitle: defaultValues.about_us_page.section_2_subtitle,
        section_1_body: defaultValues.about_us_page.section_1_body,
        section_2_body: defaultValues.about_us_page.section_2_body,
        section_3_body: defaultValues.about_us_page.section_3_body,
        section_3_image: defaultValues.about_us_page.section_3_image,
      },
    },
  });
  const [currentTab, setCurrentTab] = useState<NewBrokeragePageTabsEnum>(
    NewBrokeragePageTabsEnum.Basic,
  );
  const getBasicFormattedData = (values: IBasicFormValues) => {
    const { name, market_center, brokerage_site_name, subdomain } = values;
    const subdomain_value = subdomain.toLowerCase();
    return {
      name,
      market_center,
      brokerage_site_name,
      subdomain: subdomain_value,
    };
  };
  const getCompanyFormattedData = (values: ICompanyFormValues) => {
    const color_logo = values.color_logo[0].response?.s3Key as string;
    const favicon = values.favicon[0].response?.s3Key as string;
    const {
      office_address,
      office_phone_number,
      office_email,
      city,
      state,
      zip,
      linkedin_profile,
      facebook_profile,
      dre,
    } = values;

    return {
      color_logo,
      favicon,
      white_logo: values.white_logo[0]
        ? (values.white_logo[0].response?.s3Key as string)
        : null,
      grey_logo: values.grey_logo[0]
        ? (values.grey_logo[0].response?.s3Key as string)
        : null,
      office_address,
      office_phone_number,
      office_email,
      city,
      state,
      zip,
      linkedin_profile,
      facebook_profile,
      dre,
    };
  };
  const getCustomFormattedData = (values: ICustomFormValues) => {
    const images = values.about_us_page.images.map(
      (photo) => photo.response?.s3Key as string,
    );
    const { about_us_page, home_page, listing_page } = values;
    const {
      section_1_headline,
      section_2_headline,
      section_1_subtitle,
      section_2_subtitle,
      section_1_body,
      section_2_body,
      section_3_body,
    } = about_us_page;
    return {
      home_page: {
        headline: home_page.headline,
        image: values?.home_page.image[0]
          ? (values?.home_page.image[0].response?.s3Key as string)
          : null,
      },
      listing_page: {
        headline: listing_page.headline,
      },
      about_us_page: {
        images,
        section_1_headline,
        section_2_headline,
        section_1_subtitle,
        section_2_subtitle,
        section_1_body,
        section_2_body,
        section_3_body,
        section_3_image: values.about_us_page.section_3_image[0]
          ? (values.about_us_page.section_3_image[0].response?.s3Key as string)
          : null,
      },
    };
  };
  const submitData = async (
    customFormValues: ICustomFormValues,
    companyFormValues: ICompanyFormValues,
    basicFormValues: IBasicFormValues,
  ) => {
    try {
      const basicFormattedData = getBasicFormattedData(basicFormValues);
      const companyFormattedData = getCompanyFormattedData(companyFormValues);
      const customFormattedData = getCustomFormattedData(customFormValues);
      if (isEditMode && brokerage) {
        let basicDataForUpdate = {
          name: basicFormattedData.name,
          brokerage_site_name: basicFormattedData.brokerage_site_name,
        };
        await ListingApi.updateBrokerage(brokerage.id, {
          ...customFormattedData,
          ...companyFormattedData,
          ...basicDataForUpdate,
        });
        message.success({
          content: t('NewBrokeragePage.ChangesSavedSuccessfully'),
        });
        navigate('/brokerages');
      } else {
        await ListingApi.createBrokerage({
          ...customFormattedData,
          ...companyFormattedData,
          ...basicFormattedData,
        });
        navigate('/brokerages');
      }
    } catch (error: any) {
      if (error?.body?.message) {
        error.message = error.body.message;
      }
      displayErrorMessage({ error });
    }
  };

  const handleSave = () => {
    customFormMethods.handleSubmit(async (customFormValues) => {
      await companyFormMethods.handleSubmit(async (companyFormValues) => {
        await basicFormMethods.handleSubmit(async (basicFormValues) => {
          await submitData(
            customFormValues,
            companyFormValues,
            basicFormValues,
          );
        })();
      })();
    })();
  };

  return (
    <div className="layout-wrap">
      <div className={styles.wrap}>
        <div className="container">
          <div className={styles.backBtn}>
            <Link to="/brokerages">
              <Button
                className="btn-icon"
                size="small"
                icon={<i className="icon icon-arrow-left"></i>}
              ></Button>
            </Link>
          </div>
          <Row>
            <Col xs={24} xl={{ span: 20, offset: 2 }}>
              <div className="paper paper--lg">
                <h1 className="h4">
                  {!isEditMode
                    ? t('NewBrokeragePage.NewBrokerage')
                    : brokerage?.name}
                </h1>

                <div>
                  <Tabs className={styles.tabs} activeKey={currentTab}>
                    <Tabs.TabPane
                      tab="Basic"
                      key={NewBrokeragePageTabsEnum.Basic}
                      disabled
                    >
                      <FormProvider {...basicFormMethods}>
                        <NewBrokerageBasicTab
                          isEditMode={isEditMode}
                          onNext={() => {
                            setCurrentTab(NewBrokeragePageTabsEnum.Company);
                            scrollTop();
                          }}
                        />
                      </FormProvider>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab="Company"
                      key={NewBrokeragePageTabsEnum.Company}
                      disabled
                    >
                      <FormProvider {...companyFormMethods}>
                        <NewBrokerageCompanyTab
                          onBack={() => {
                            setCurrentTab(NewBrokeragePageTabsEnum.Basic);
                            scrollTop();
                          }}
                          onNext={() => {
                            setCurrentTab(NewBrokeragePageTabsEnum.Custom);
                            scrollTop();
                          }}
                        />
                      </FormProvider>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab="Custom Content"
                      key={NewBrokeragePageTabsEnum.Custom}
                      disabled
                    >
                      <FormProvider {...customFormMethods}>
                        <NewBrokerageCustomTab
                          isEditMode={isEditMode}
                          onBack={() => {
                            setCurrentTab(NewBrokeragePageTabsEnum.Company);
                            scrollTop();
                          }}
                          onSave={handleSave}
                        />
                      </FormProvider>
                    </Tabs.TabPane>
                  </Tabs>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
