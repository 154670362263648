import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTimeoutWhen } from 'rooks';

import img from 'assets/images/success-bid.svg';

import styles from './BidNowSuccessStep.module.less';
import InfoBox, { BoxColor, BoxSize } from 'components/InfoBox';

type Props = {
  onClose: () => void;
};

const closeInSeconds = 5 * 1000;

export default function BidNowSuccessStep(props: Props) {
  const { t } = useTranslation();
  const { onClose } = props;

  useTimeoutWhen(onClose, closeInSeconds);

  return (
    <div className={styles.wrap}>
      <div className={styles.btn}>
        <Button
          data-test-id="bidNow-success-close-btn"
          className="btn-icon"
          size="small"
          icon={<i className="icon icon-close"></i>}
          onClick={onClose}
        ></Button>
      </div>
      <div className={styles.img}>
        <img src={img} alt="check" />
      </div>
      <div className={styles.title}>
        <h2
          data-test-id="bidNow-success-yourBidPlacedSuccessfully"
          className="h4"
        >
          {t('ListingDetailsPage.YourBidPlacedSuccessfully')}
        </h2>
      </div>
      <div
        data-test-id="bidNow-success-yourBidPlacedSuccessfully-info"
        className={styles.infoBox}
      >
        <InfoBox
          color={BoxColor.Grey}
          size={BoxSize.Small}
          icon={<i className="icon icon-flag"></i>}
          text={
            <div className={styles.infoText}>
              {t('ListingDetailsPage.YourBidPlacedSuccessfully.infobox')}
            </div>
          }
        />
      </div>
    </div>
  );
}
