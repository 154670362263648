import { apiClient } from '.';
import {
  ExternalGeneralContactNotification,
  ExternalNotificationContactUsNotification,
} from './.openapi';

class ContactApi {
  public async sendContactUsFormRequest(
    data:
      | ExternalGeneralContactNotification
      | ExternalNotificationContactUsNotification,
  ) {
    return apiClient.notifications.sendNotification(data);
  }
}

export default new ContactApi();
