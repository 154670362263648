import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Form, Input, Tooltip } from 'antd';
import { TFunction, useTranslation } from 'react-i18next';
import FormItem from 'components/form/FormItem';
import { useAuth } from 'components/AuthProvider';
import { displayErrorMessage } from 'components/message';
import { ModalScrollWrapper, ModalFooterWrapper } from 'components/Modal';
import {
  onlyNumbersRegExp,
  awsCognitoPasswordRegExp,
  noSpacesRegExp,
} from 'utils/validation.utils';
interface IFormValues {
  email: string;
  code: string;
  password: string;
  passwordConfirmation: string;
}

const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    code: yup
      .string()
      .required(t('validation.required'))
      .matches(onlyNumbersRegExp, t('validation.format'))
      .length(6, t('validation.format')),
    password: yup
      .string()
      .required(t('validation.required'))
      .matches(noSpacesRegExp, t('validation.noSpace'))
      .max(
        16,
        t('validation.maxLength', {
          count: 16,
        }),
      )
      .matches(awsCognitoPasswordRegExp, t('validation.awsCognitoFormat')),
    passwordConfirmation: yup
      .string()
      .required(t('validation.required'))
      .oneOf([yup.ref('password')], t('validation.PasswordNotMatch')),
  });

type Props = {
  emailReset: string;
  onClose: () => void;
};

export default function ForgotPasswordForm(props: Props) {
  const { t } = useTranslation();
  const { forgotPasswordSubmit } = useAuth();
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const { onClose, emailReset } = props;
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: emailReset,
      code: '',
      password: '',
      passwordConfirmation: '',
    },
  });

  const onSubmit = async (values: IFormValues) => {
    try {
      await forgotPasswordSubmit({
        email: values.email,
        code: values.code,
        password: values.password,
      });
      onClose();
    } catch (error: any) {
      displayErrorMessage({
        error,
        containerSelector: '.ant-modal',
      });
    }
  };

  return (
    <>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
        <ModalScrollWrapper>
          <div className="modal__label">{t('ResetPasswordModal.Email')}</div>
          <FormItem
            name="email"
            control={control}
            render={({ field }) => <Input disabled size="large" {...field} />}
          />
          <div className="modal__label">
            {t('ResetPasswordModal.RecoveryCode')}
          </div>
          <FormItem
            name="code"
            control={control}
            render={({ field }) => (
              <Input
                placeholder={t('ResetPasswordModal.PlaceholderRecoveryCode')}
                size="large"
                {...field}
              />
            )}
          />
          <div className="modal__label">
            {t('ResetPasswordModal.NewPassword')}
          </div>
          <FormItem
            name="password"
            control={control}
            render={({ field }) => (
              <Tooltip
                overlayStyle={{ maxWidth: '470px', width: '100%' }}
                placement="topLeft"
                title={t('Password.tooltip')}
              >
                <Input.Password
                  size="large"
                  {...field}
                  placeholder={t('ResetPasswordModal.PlaceholderNewPassword')}
                />
              </Tooltip>
            )}
          />
          <FormItem
            name="passwordConfirmation"
            control={control}
            render={({ field }) => (
              <Input.Password
                size="large"
                {...field}
                placeholder={t(
                  'ResetPasswordModal.PlaceholderConfirmNewPassword',
                )}
              />
            )}
          />
        </ModalScrollWrapper>

        <ModalFooterWrapper>
          <div>
            <Button
              onClick={() => {
                onClose();
              }}
              type="link"
              className="custom-link"
              size="large"
            >
              {t('ResetPasswordModal.Cancel')}
            </Button>
          </div>
          <div>
            <Button
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              size="large"
            >
              {t('ResetPasswordModal.Reset')}
            </Button>
          </div>
        </ModalFooterWrapper>
      </Form>
    </>
  );
}
