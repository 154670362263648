import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import InfoBox, { BoxColor, BoxSize } from 'components/InfoBox';
import styles from './BidNowInfo.module.less';

export default function BidNowInfo() {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.textBox}>
        <strong>{t('BidNowInfo.title1')}</strong>
        <div>{t('BidNowInfo.description1')}</div>
      </div>

      <div className={styles.textBox}>
        <strong>{t('BidNowInfo.title2')}</strong>
        <div>{t('BidNowInfo.description2')}</div>
      </div>

      <div className={styles.textBox}>
        <strong>{t('BidNowInfo.title3')}</strong>{' '}
        <Link to="/how-to-buy">
          <Button type="link" className="custom-link">
            {t('BidNowInfo.ClickHere')}
          </Button>
        </Link>
      </div>

      <InfoBox
        color={BoxColor.Grey}
        size={BoxSize.Small}
        icon={<i className="icon icon-flag"></i>}
        text={
          <div className={styles.message}>{t('BidNowInfo.boxDescription')}</div>
        }
      />
    </div>
  );
}
