import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import SignUpForm from '../SignUpForm';

type Props = {
  onClose: () => void;
  onSignInClick: () => void;
  onSuccess: (email: string) => void;
};

export default function SignUpModal(props: Props) {
  const { t } = useTranslation();
  const { onClose, onSignInClick, onSuccess } = props;

  return (
    <Modal
      title={null}
      visible
      centered
      closable={false}
      maskClosable={false}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      width={550}
    >
      <h2 className="modal__title">{t('SignUpModal.Register')}</h2>
      <SignUpForm
        onClose={onClose}
        onSuccess={onSuccess}
        onSignInClick={onSignInClick}
      />
    </Modal>
  );
}
