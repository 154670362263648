import { ReactNode } from 'react';

interface BaseWrapperProps {
  children: ReactNode;
}

export const ModalScrollWrapper = ({ children }: BaseWrapperProps) => {
  return (
    <div className="modal__scroll">
      <div className="modal__content">{children}</div>
    </div>
  );
};
export const ModalFooterWrapper = ({ children }: BaseWrapperProps) => {
  return (
    <div className="modal__footer">
      <div className="modal__footer-row">{children}</div>
    </div>
  );
};
