import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Empty } from 'antd';

import { ListingApi, AdminUserDetail, ExternalUserRole } from 'services/api';
import Spin from 'components/Spin';

import styles from './EditUserPage.module.less';
import { getFormattedFiles } from 'utils/file.utils';
import NewUserPage, { NewUserPageModeEnum } from '../NewUserPage/NewUserPage';

export default function EditUserPage() {
  const { userId = '' } = useParams<'userId'>();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<AdminUserDetail>();

  useEffect(() => {
    const loadUserDetails = async () => {
      try {
        setIsLoading(true);

        const result = await ListingApi.getAdminUserDetails(userId);
        setUserDetails(result);
      } catch {
      } finally {
        setIsLoading(false);
      }
    };

    loadUserDetails();
  }, [userId]);

  if (isLoading)
    return (
      <div className={styles.spinWrap}>
        <Spin />
      </div>
    );

  if (!userDetails)
    return (
      <div className="layout-wrap">
        <Empty />
      </div>
    );

  const {
    role,
    first_name,
    last_name,
    email,
    phone,
    dre,
    title,
    service_area,
    photo,
  } = userDetails;
  const defaultValues = {
    role: role || ExternalUserRole.BROKERAGE_ADMIN,
    first_name: first_name || '',
    brokerages: undefined,
    last_name: last_name || '',
    email: email || '',
    phone: phone || '',
    dre: dre || '',
    title: title || '',
    service_area: service_area || [''],
    photo: getFormattedFiles(photo) || [],
  };

  return (
    <NewUserPage
      mode={NewUserPageModeEnum.Edit}
      userDetails={userDetails}
      defaultValues={defaultValues}
    />
  );
}
