import { useEffect, useState } from 'react';
import cx from 'classnames';
import Spin from 'components/Spin';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

import Pagination, { DefaultPageSizeOptions } from 'components/Pagination';
import {
  ExternalListingOrderBy,
  ExternalListingResponse,
  ListingApi,
} from 'services/api';
import { useGetSocketRequestMultiple } from 'utils/socket.utils';
import { useAppSettings } from 'components/AppSettingsProvider';
import ListingList from '../ListingList';
import SearchInput from 'components/SearchInput';
import ListingsSorting from '../ListingsSorting';
import { scrollTop } from 'utils/scrollTop.utils';
import useNetworkNotificationHelper from 'hooks/useNetworkNotificationHelper';

import styles from './ListingsPage.module.less';

const DefaultPageSize = DefaultPageSizeOptions[0];

export default function ListingsPage() {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>();
  const [lastLoadedQuery, setLastLoadedQuery] = useState<string>();
  const [sorting, setSorting] = useState<ExternalListingOrderBy>();
  const [listingsData, setListingsData] = useState<ExternalListingResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPageSize);
  const { settings } = useAppSettings();
  const { listing_page } = settings || {};

  useEffect(() => {
    const loadData = async () => {
      if (isNil(query) || isNil(sorting)) return; // query, sorting haven't been initialized/validated yet

      try {
        setIsLoading(true);

        const result = await ListingApi.getListingList({
          query,
          pageSize,
          page,
          order: sorting,
        });

        setListingsData(result);
      } catch (error) {
        setListingsData({
          total: 0,
          page,
          page_size: pageSize,
          results: [],
        });
      } finally {
        setIsLoading(false);
        setLastLoadedQuery(query);
      }
    };

    loadData();
  }, [query, sorting, page, pageSize]);

  useNetworkNotificationHelper();
  useGetSocketRequestMultiple(
    listingsData,
    (val) => setListingsData(val),
    query || '',
    page,
    pageSize,
    sorting || ExternalListingOrderBy._VIEWS,
  );

  const handlePaginationChange = (newPage: number, newPageSize: number) => {
    if (isLoading) return;

    setPage(newPage);
    setPageSize(newPageSize);

    scrollTop();
  };

  const handleSearchInputChange = (newQuery: string) => {
    setPage(1);
    setQuery(newQuery);
  };

  const handleSortingChange = (newSorting: ExternalListingOrderBy) => {
    setPage(1);
    setSorting(newSorting);
  };

  return (
    <section className={cx('layout-wrap', styles.wrap)}>
      <div className="container">
        <h2 className={cx('h4', styles.title)}>
          {!!listing_page?.headline ? (
            <div>{listing_page?.headline}</div>
          ) : (
            <>
              <span>{t('ListingsPage.banner1')}</span>
              <div className={styles.contrast}>{t('ListingsPage.banner2')}</div>
            </>
          )}
        </h2>
        <SearchInput
          className={styles.input}
          lastLoadedQuery={lastLoadedQuery}
          loading={isLoading}
          placeholder={t('ListingsPage.search.placeholder')}
          onChange={handleSearchInputChange}
        />

        <Spin spinning={isLoading}>
          <div className={styles.sortBox}>
            <ListingsSorting onChange={handleSortingChange} />
          </div>

          <div className={styles.border} />

          <div className="container">
            <ListingList listingsData={listingsData} query={lastLoadedQuery} />

            {!!listingsData?.total && listingsData.total > DefaultPageSize && (
              <Pagination
                current={page}
                pageSize={pageSize}
                showSizeChanger
                onChange={handlePaginationChange}
                total={listingsData.total}
              />
            )}
          </div>
        </Spin>
      </div>
    </section>
  );
}
