import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import SignUpConfirmationForm from '../SignUpConfirmationForm';

type Props = {
  email: string;
  onClose: () => void;
};

export default function SignUpConfirmationModal(props: Props) {
  const { t } = useTranslation();
  const { onClose, email } = props;

  return (
    <Modal
      title={null}
      visible
      centered
      closable={false}
      maskClosable={false}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      width={550}
    >
      <h2 className="modal__title">
        {t('SignUpConfirmationModal.Confirmation')}
      </h2>
      <SignUpConfirmationForm
        onClose={onClose}
        email={email}
        onSuccess={onClose}
      />
    </Modal>
  );
}
